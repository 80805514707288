import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Color } from 'ngx-color';

@Component({
	selector: 'app-dialog-color-picker',
	templateUrl: './dialog-color-picker.component.html',
	styleUrls: [ './dialog-color-picker.component.css' ]
})
export class DialogColorPickerComponent implements OnInit {
	color = null;

	constructor(
		public dialogRef: MatDialogRef<DialogColorPickerComponent>,
		@Inject(MAT_DIALOG_DATA) public data: Color
	) {}

	ngOnInit(): void {
		this.color = this.data;
	}

	changeComplete({ color }) {
		this.color = color;
	}

	onClickClose(): void {
		this.dialogRef.close(this.color);
	}
}
