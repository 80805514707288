<div style="position: relative">
	<div class="top-bar">
		<div class="like-share-container">
			<button style="visibility: hidden">
				<div class="text-s button-div">
					<i-feather name="upload"> </i-feather>
					<div>Compartir</div>
				</div>
			</button>
			<!-- <button>
				<div class="text-s button-div">
					<i-feather name="Heart"></i-feather>
					<div>Guardar</div>
				</div>
			</button> -->
		</div>

		<button mat-button (click)="close()">
			<i-feather name="X"> </i-feather>
		</button>
	</div>
	<div class="img-container-wrapper">
		<div class="img-list-container">
			<img
				*ngFor="let item of images; let i = index"
				[src]="item.image"
				style="height: auto; width: 100%; max-height: 600px; object-fit: cover"
				(click)="onClickImg(i)"
				alt=""
			/>
		</div>
	</div>
</div>
