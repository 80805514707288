<div>
	<color-chrome [color]="color.hex" id="primary_color" (onChangeComplete)="changeComplete($event)"> </color-chrome>
	<div style="display: flex">
		<button mat-stroked-button style="width: 100%; border-radius: 0" (click)="dialogRef.close()" color="primary">
			<ng-container i18n> Cancelar </ng-container>
		</button>
		<button mat-stroked-button style="width: 100%; border-radius: 0" (click)="onClickClose()" color="primary">
			<ng-container i18n> Aplicar </ng-container>
		</button>
	</div>
</div>
