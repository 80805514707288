import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { shareReplay, tap } from 'rxjs/operators';
import { SearchQuery } from '../interfaces/BusquedaInterface';
import { PropertyType } from '../listings-component/listings-component.component';
import { PreQualification } from '../interfaces/PreQualification';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	private readonly URI = `${environment.api}/users`;
	newNotifications: EventEmitter<number> = new EventEmitter<number>();

	constructor(private readonly http: HttpClient) {}

	public updateUserName(first_name: string, last_name: string): Observable<any> {
		return this.http.put(`${this.URI}/update/name`, { first_name: first_name, last_name: last_name });
	}

	public requestUpdatePhoneToken(phone: string, voice: boolean) {
		const url = voice ? `/update/phone/request-code?voice=true` : `/update/phone/request-code`;
		return this.http.post(
			`${this.URI}${url}`,
			JSON.stringify({
				phone
			}),
			{
				headers: { 'Content-Type': 'application/json' }
			}
		);
	}

	public updateAgentProfileStatus(status: boolean) {
		return this.http.put(
			`${this.URI}/update/agent-profile-status?status=${status}`,
			{},
			{
				headers: { 'Content-Type': 'application/json' }
			}
		);
	}

	public cofirmUpdatePhoneCode(code: string, phone: string) {
		return this.http.put(`${this.URI}/update/phone`, { code: code, phone });
	}

	public updateEmail(email: string, code: string) {
		return this.http.put(`${this.URI}/update/email`, { email, code });
	}

	public requestUpdateEmailToken(email: string) {
		email = email.trim().toLocaleLowerCase();
		return this.http.post(`${this.URI}/update/email/request-code`, JSON.stringify({ email }), {
			headers: { 'Content-Type': 'application/json' }
		});
	}

	public updatePassword(current_password: string, new_password: string, refresh_token: string) {
		return this.http.put(`${this.URI}/update/password`, { current_password, new_password, refresh_token });
	}

	public getMyProfile() {
		return this.http.get(`${this.URI}/myAgentProfile`);
	}

	public updateAgentAcerca(acerca: string) {
		return this.http.put(`${this.URI}/agent-profile/update/about`, { about: acerca });
	}

	public updateAgentAddress(address: string) {
		return this.http.put(`${this.URI}/agent-profile/update/address`, { address });
	}

	public updateAgentWebsite(website: string) {
		return this.http.put(`${this.URI}/agent-profile/update/website`, { website });
	}

	public updateAgentTime(payload: Object) {
		return this.http.put(`${this.URI}/agent-profile/update/time`, payload);
	}

	public updateCoverPicture(payload: Object) {
		return this.http.put(`${this.URI}/agent-profile/update/cover-image`, payload);
	}

	public updateAgentBrokerage(newBrokerage: string) {
		return this.http.put(`${this.URI}/updateBrokerage`, { newBrokerage: newBrokerage });
	}

	public getLanguages() {
		return this.http.get(`${this.URI}/getLanguages`);
	}

	public updateUserLanguages(languages: number[], languagesToDelete: number[]) {
		return this.http.put(
			`${this.URI}/updateAgentLanguages`,
			{ languages: languages, languagesToDelete: languagesToDelete },
			{ headers: { 'Content-Type': 'application/json' } }
		);
	}

	public updateSkills(newSkills: string) {
		return this.http.put(`${this.URI}/updateAgentSkills`, { newSkills: newSkills });
	}

	public updateLicenses(licenses: Object) {
		return this.http.put(`${this.URI}/updateLicenses`, licenses, {
			headers: { 'Content-Type': 'application/json' }
		});
	}

	public getCoverPicture() {
		return this.http.get(`${this.URI}/getCoverPicture`, { headers: { 'Content-Type': 'application/json' } });
	}

	public uploadCoverPicture(image: FormData) {
		return this.http.put(`https://upload.finditpr.com/users/updateCoverImage`, image, {
			reportProgress: true,
			observe: 'events'
		});
	}

	public uploadProfilePicture(image: FormData) {
		return this.http.put(`https://upload.finditpr.com/users/updateProfileImage`, image, {
			reportProgress: true,
			observe: 'events'
		});
	}

	public uploadServiceProviderLogo(image: FormData) {
		return this.http.post(`https://upload.finditpr.com/service-provider/logo`, image, {
			reportProgress: true,
			observe: 'events'
		});
	}

	public updateProfilePicture(payload: Object) {
		return this.http.put(`${this.URI}/update/profile-picture`, payload);
	}

	public updateAgentCities(payload: Object) {
		return this.http.put(`${this.URI}/updateAgentCities`, payload, {
			headers: { 'Content-Type': 'application/json' }
		});
	}

	public updateAgentFacebook(website: Object) {
		return this.http.put(`${this.URI}/updateAgentFacebook`, website);
	}

	public updateAgentInstagram(website: Object) {
		return this.http.put(`${this.URI}/updateAgentInstagram`, website);
	}

	public updateAgentContact(payload: Object) {
		return this.http.put(`${this.URI}/updateAgentContact`, payload);
	}

	public removeFavoriteListing(listingID: number) {
		return this.http.delete(`${this.URI}/remove/favorite/${listingID}`, {});
	}

	public addFavoriteListing(listingID: number) {
		return this.http.put(`${this.URI}/addFavoriteListing/${listingID}`, {});
	}

	public getSavedSearches(index: number) {
		return this.http.get(`${this.URI}/saved-searches/${index}`);
	}

	public removeSavedSearch(id: number) {
		return this.http.delete(`${this.URI}/remove/saved-search/${id}`);
	}

	public getNotifications(offset: number) {
		return this.http.get(`${this.URI}/notifications/${offset}`);
	}

	public deleteNotification(notificationID: number) {
		return this.http.delete(`${this.URI}/remove/notification/${notificationID}`);
	}

	public getNotificationPreferences() {
		return this.http.get(`${this.URI}/notificationsPreferences`);
	}

	public setFavListingsNotificacionEmailPreference(value: boolean) {
		return this.http.put(
			`${this.URI}/update/preference/favorites/email-notification`,
			{ decision: value },
			{ headers: { 'Content-Type': 'application/json' } }
		);
	}

	public setFavListingsNotificacionInAppPreference(value: boolean) {
		return this.http.put(
			`${this.URI}/update/preference/favorites/web-notification`,
			{ decision: value },
			{ headers: { 'Content-Type': 'application/json' } }
		);
	}

	public setSavedSearchNotificacionInAppPreference(value: boolean) {
		return this.http.put(
			`${this.URI}/update/preference/saved-search/web-notification`,
			{ decision: value },
			{ headers: { 'Content-Type': 'application/json' } }
		);
	}

	public setSavedSearchNotificacionEmailPreference(value: boolean) {
		return this.http.put(
			`${this.URI}/update/preference/saved-search/email-notification`,
			{ decision: value },
			{ headers: { 'Content-Type': 'application/json' } }
		);
	}

	public setPromotionalEmailsNotificationPreference(value: boolean) {
		return this.http.put(
			`${this.URI}/update/preference/promotional/email-notification`,
			{ decision: value },
			{ headers: { 'Content-Type': 'application/json' } }
		);
	}

	public setPromotionalNotificationPreference(value: boolean) {
		return this.http.put(
			`${this.URI}/update/preference/promotional/web-notification`,
			{ decision: value },
			{ headers: { 'Content-Type': 'application/json' } }
		);
	}

	public sendEmailContactMessage(payload: Object) {
		return this.http.post(`${this.URI}/sendContactMessage`, payload, {
			headers: { 'Content-Type': 'application/json' }
		});
	}

	public shareListingViaFindit(payload: Object) {
		return this.http.post(`${this.URI}/shareViaEmail`, JSON.stringify(payload), {
			headers: { 'Content-Type': 'application/json' }
		});
	}

	public getProfessionalInformation() {
		return this.http.get(`${this.URI}/professional-information`, {
			headers: { 'Content-Type': 'application/json' }
		});
	}

	public updateProfessionalInformation(payload) {
		console.log(payload);
		return this.http.put(`${this.URI}/update/professional-information`, JSON.stringify(payload), {
			headers: { 'Content-Type': 'application/json' }
		});
	}

	public sendAgentProfileContact(payload: Object) {
		return this.http.post(`${this.URI}/agent-profile/send-contact-email`, payload, {
			headers: { 'Content-Type': 'application/json' }
		});
	}

	public getMLSProfiles() {
		return this.http.get(`${this.URI}/synced-mls-profiles`);
	}

	public syncMLSProfile(payload: Object) {
		return this.http.post(`${this.URI}/sync-mls-profile`, payload);
	}

	public getMLSAgentInfo(mls: number, mls_user_id: number) {
		return this.http.get(`${this.URI}/mls-id-info`, {
			params: {
				mls,
				mls_user_id
			}
		});
	}

	public removeMLSProfile(id: number) {
		return this.http.delete(`${this.URI}/remove-mls-profile`, {
			body: {
				id
			}
		});
	}

	public getNewGeneralUserDetails() {
		return this.http.get(`${this.URI}/general-information`).pipe(
			tap((user_details: Object) => {
				if (user_details) {
					const oldUserDetails = JSON.parse(localStorage.getItem('user-details'));
					localStorage.setItem('user-details', JSON.stringify({ ...oldUserDetails, ...user_details }));
				}
			})
		);
	}

	public sendMortgageLead(payload: PreQualification): Observable<any> {
		return this.http.post(`${this.URI}/pre-qualify`, payload);
	}
}
