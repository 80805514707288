import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { IServiceProviderUpdate } from '../user-panel/configuraciones/pagos-y-suscripciones/edit-ad-dialog/edit-ad-dialog.component';

export type DirectoryAdsAreas = 'METRO' | 'NORTH' | 'CENTER' | 'WEST' | 'EAST' | 'SOUTH' | 'ALL';

@Injectable({
	providedIn: 'root'
})
export class AdsService {
	private readonly URI = `${environment.api}/ads`;

	constructor(private readonly http: HttpClient) {}

	public getList(params: { area: DirectoryAdsAreas | 'null'; category: string }) {
		if (!params.area || params.area === 'null') {
			delete params['area'];
		}

		if (!params.category || params.category === 'null') {
			delete params['category'];
		}
		return this.http.get(`${this.URI}/service-providers`, {
			params
		});
	}

	public getServiceProvider(id: number) {
		return this.http.get(`${this.URI}/service-providers/${id}`);
	}

	public updateServiceProviderAd(payload: IServiceProviderUpdate) {
		return this.http.post(`${this.URI}/update/service-provider`, payload);
	}
}
