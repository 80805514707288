import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { MetaTagsService } from '../../services/meta-tags.service';

@Component({
	selector: 'app-site-map',
	templateUrl: './site-map.component.html',
	styleUrls: ['./site-map.component.css']
})
export class SiteMapComponent implements OnInit, OnDestroy {
	constructor(private readonly metaTagService: MetaTagsService, @Inject(LOCALE_ID) public locale: string) {
		let metaTitle = 'Búsqueda de Bienes Raíces & Alquileres | Findit';
		let metaDescription =
			'Encuentra propiedades en venta o alquiler en Puerto Rico. Búsqueda con mapa de casas, apartamentos, locales comerciales, terrenos, y mucho más.';
		if (this.locale === 'en') {
			metaTitle = 'Real Estate & Rentals in Puerto Rico | Findit';
			metaDescription =
				'Find properties for sale or rent in Puerto Rico. Search houses, apartments, commercial premises, land, and much more.';
		}
		this.metaTagService.setCustomTags(metaTitle, metaDescription);
	}
	ngOnDestroy(): void {
		this.metaTagService.setDefaultTags();
	}

	ngOnInit() {}
}
