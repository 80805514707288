import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
@Injectable()
export class AuthGuardService implements CanActivate {
	constructor(
		private readonly auth: AuthenticationService,
		private readonly router: Router,
		@Inject(PLATFORM_ID) private platformId: any
	) {}

	/**
	 * Check if the localStorage has a 'x-access-token'(JWT), if not then remove everything
	 */
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		// return true;
		if (this.auth.isAuthenticated()) {
			return true;
		} else if (route.queryParams.access_token != null && route.queryParams.refresh_token != null) {
			if (isPlatformBrowser(this.platformId)) {
				const { access_token, refresh_token, new_user } = route.queryParams;
				this.auth.setSession(access_token, refresh_token, new_user);
				return this.auth.isAuthenticated();
			} else {
				return true;
			}
		} else {
			try {
				this.auth.doLogout().subscribe();
			} catch (err) {
				console.log('error at log out');
			}
			const redirectURI = state.url;
			this.router.navigate(['/iniciar-sesion'], {
				queryParams: {
					redirectURI: redirectURI
				}
			});
			return false;
		}
	}
}
