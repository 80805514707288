<ng-container *ngIf="listing$ | async as data;">
    <ng-container *ngIf="data?.listing; else outOfMarket">

        <div class="wrapper">
            <div class="container-property" cdkScrollable>
                <div class="grid-1">
                    <h1 class="text-l-sb hide-mobile">{{ data.listing.street }}</h1>
                    <h1 class="text-m hide-mobile" style="grid-column-start: 1">
                        {{ data.listing.city + ', PR ' + data.listing.zip }}
                    </h1>
                </div>

                <div class="images-hero" style="overflow: hidden">
                    <div class="label-container">
                        <div class="opcionado" i18n>REO</div>
                        <div class="vendido" *ngIf="
								data.listing.listing_status === 'Alquilado/Alquilada' ||
								data.listing.listing_status === 'Vendido/Vendida'
							">
                            <h6>
                                {{ data.listing.listing_status === 'Alquilado/Alquilada' ? 'Alquilado' : 'Vendido' }}
                                <span *ngIf="data.listing.status_update_date !== null">el {{
                                    getSpanishDate(data.listing.status_update_date) }}</span>
                            </h6>
                        </div>
                    </div>
                    <div class="image-box">
                        <div class="images-grid" (click)="openImgs()">
                            <div class="hero-image">
                                <img [src]="data.listing.listing_cover_picture"
                                    alt="{{ data.listing.street }} {{ data.listing.city }}, PR {{ data.listing.zip }}" />
                            </div>
                            <div class="first-image">
                                <img [src]="
										imageObject.length > 1
											? imageObject[1].image
											: data.listing.listing_cover_picture
									" alt="{{ data.listing.street }} {{ data.listing.city }}, PR {{ data.listing.zip }}" />
                            </div>
                            <div class="second-image">
                                <img [src]="
										imageObject.length > 2
											? imageObject[2].image
											: data.listing.listing_cover_picture
									" alt="{{ data.listing.street }} {{ data.listing.city }}, PR {{ data.listing.zip }}" />
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div style="width: 100%; position: relative">
                        <div class="first-section">
                            <div class="text-m" style="color: var(--grayscale-1)" *ngIf="
									data.listing.listing_status !== 'Alquilado/Alquilada' &&
										data.listing.listing_status !== 'Vendido/Vendida';
									else fueraDelMercadoTemplate
								">
                                <span [ngClass]="data.listing.type === 'Alquiler' ? 'dot-yellow' : 'dot-blue'"></span>
                                {{ homeType }}
                                <ng-container i18n>
                                    {data.listing.type, select, Venta {en venta} other {en alquiler}}
                                </ng-container>
                            </div>
                            <div class="heading-s text-align-end">
                                <i-feather *ngIf="
										data.listing.starting_price &&
										data.listing.starting_price > data.listing.current_price
									" name="arrow-down" class="arrow-down-icon" style="color: var(--primary-color-1)"></i-feather>
                                {{ data.listing.current_price | currency: 'USD':'symbol':'1.0'
                                }}<span
                                    *ngIf="data.listing.type === 'Alquiler' && listing.price_type === 'Total'; else perSqft"
                                    class="mes" i18n>/mes</span>
                            </div>
                            <ng-container [ngSwitch]="data.listing.property_type">
                                <ng-container *ngSwitchCase="'Terreno'" [ngTemplateOutlet]="showLandInfo">
                                </ng-container>
                                <ng-container *ngSwitchDefault [ngTemplateOutlet]="showUnitInfo"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="
									data.listing.starting_price &&
									data.listing.starting_price > data.listing.current_price
								">
                                <div class="text-s text-align-end" style="color: var(--grayscale-4)">
                                    <ng-container i18n> Precio inicial: </ng-container>
                                    {{ data.listing.starting_price | currency: 'USD':'symbol':'1.0' }}
                                </div>
                            </ng-container>
                        </div>

                        <div class="first-section-mobile">
                            <div>
                                <h1 class="text-l-sb">{{ data.listing.street }}</h1>
                                <h1 class="text-m" style="margin-bottom: 24px">
                                    {{ data.listing.city + ', PR ' + data.listing.zip }}
                                </h1>
                                <div class="heading-s" style="margin-bottom: 24px">
                                    <i-feather *ngIf="
											data.listing.starting_price &&
											data.listing.starting_price > data.listing.current_price
										" name="arrow-down" class="arrow-down-icon" style="color: var(--primary-color-1)">
                                    </i-feather>
                                    {{ data.listing.current_price | currency: 'USD':'symbol':'1.0'
                                    }}<span
                                        *ngIf="data.listing.type === 'Alquiler' && listing.price_type === 'Total'; else perSqft"
                                        class="mes" i18n>/mes</span>
                                    <div class="text-s" style="color: var(--grayscale-4)" *ngIf="
											data.listing.starting_price &&
											data.listing.starting_price > data.listing.current_price
										">
                                        <ng-container i18n> Precio inicial: </ng-container>
                                        {{ data.listing.starting_price | currency: 'USD':'symbol':'1.0' }}
                                    </div>
                                </div>
                                <div class="text-m" style="color: var(--grayscale-1)" style="margin-bottom: 8px" *ngIf="
										data.listing.listing_status !== 'Alquilado/Alquilada' &&
											data.listing.listing_status !== 'Vendido/Vendida';
										else fueraDelMercadoTemplate
									">
                                    <span
                                        [ngClass]="data.listing.type === 'Alquiler' ? 'dot-yellow' : 'dot-blue'"></span>
                                    {{ homeType }}
                                    <ng-container i18n>
                                        {data.listing.type, select, Venta {en venta} other {en alquiler}}
                                    </ng-container>
                                </div>
                                <ng-container [ngSwitch]="data.listing.property_type">
                                    <ng-container *ngSwitchCase="'Terreno'" [ngTemplateOutlet]="showLandInfo">
                                    </ng-container>
                                    <ng-container *ngSwitchDefault [ngTemplateOutlet]="showUnitInfo"></ng-container>
                                </ng-container>
                            </div>
                        </div>

                        <div class="third-section">
                            <hr class="content-separator" />
                            <h5 class="text-m" style="margin-bottom: 0; margin-top: 16px" i18n>
                                Este listado tiene multiples vendedores
                            </h5>
                            <h6 class="text-s" style="margin-bottom: 16px; color: var(--grayscale-4);" i18n>
                                Seleccione un vendedor para obtener más información del listado
                            </h6>
                            <ul class="repo-agents">
                                <li *ngFor="let agent of data.listing_agents">
                                    <a [routerLink]="agent.detailURL" target="_blank">
                                        <div class="agent-image-grid">
                                            <ng-container *ngIf="data.listing.mls_id; else pictureTemplate">
                                                <img [src]="getAgentPicture()"
                                                    onerror="this.onerror=null;this.src='https://images.finditpr.com/default-images/user-picture.svg';"
                                                    [alt]="agent.name" width="60px" height="60px" />
                                            </ng-container>
                                            <ng-template #pictureTemplate>
                                                <img [src]="agent.img" [alt]="agent.name" width="60px" height="60px" />
                                            </ng-template>

                                            <div class="agent-image-grid-info">
                                                <div class="detalles-fonts" style="margin-bottom: 4px">
                                                    {{ agent.name }}
                                                </div>
                                                <div class="detalles-fonts" style="margin-bottom: 4px"
                                                    *ngIf="agent.business_name">
                                                    {{ agent.business_name }}
                                                </div>
                                                <div class="detalles-fonts" style="margin-bottom: 4px">
                                                    {{ formatPhoneNumber(agent.phone) }}
                                                </div>

                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <ng-container *ngIf="data.listing.isForeclosure === 1">
                            <div class="eight-section">
                                <h2 class="text-l-sb section-headings" style="margin-bottom: 4px !important" i18n>
                                    Propiedad
                                    Reposeída</h2>
                                <div class="detalles-fonts" style="margin-bottom: 4px">
                                    <ng-container i18n> Proveedor: </ng-container>
                                    <a href="https://www.hudhomestore.gov/" target="_blank"
                                        *ngIf="data.listing.repo_source === 'HUD'; else HUDsource">
                                        {{ data.listing.repo_source }}
                                    </a>
                                    <ng-template #HUDsource>
                                        {{ data.listing.repo_source }}
                                    </ng-template>
                                </div>
                                <div class="detalles-fonts" style="margin-bottom: 0">
                                    ID:
                                    <a href="https://www.hudhomestore.gov/Listing/PropertyDetails.aspx?caseNumber={{data.listing.repo_id}}&sLanguage=ENGLISH&zipCode=&city=&county=&sState=PR&fromPrice=0&toPrice=0&fCaseNumber=&bed=0&bath=0&street=&buyerType=0&specialProgram=&Status=0&indoorAmenities=&outdoorAmenities=&housingType=&stories=&parking=&propertyAge=&OrderbyName=SCASENUMBER&OrderbyValue=ASC&sPageSize=10&pageId=1"
                                        target="_blank" *ngIf="data.listing.repo_source === 'HUD'; else otherSource">
                                        {{ data.listing.repo_id || 'N/A' }}
                                    </a>
                                    <ng-template #otherSource>
                                        {{ data.listing.repo_id || 'N/A' }}
                                    </ng-template>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                </div>

            </div>
        </div>

        <ng-container *ngTemplateOutlet="additionalWrappers"></ng-container>
    </ng-container>

    <ng-template #additionalWrappers>
        <div class="additional-wrappers" style="border-bottom: 1px solid var(--grayscale-9)">
            <div class="container-listings">
                <h2 class="text-l-sb section-headings" i18n>Listados Similares</h2>
                <ul class="ul-listings">
                    <li *ngFor="let listing of similarListings">
                        <app-listing-card [listing]="listing"></app-listing-card>
                    </li>
                </ul>
            </div>
        </div>

        <div class="additional-wrappers">
            <div class="nearby-cities">
                <h2 class="text-l-sb section-headings" i18n>Pueblos Cercanos</h2>
                <div class="nearby-grid">
                    <a class="text-m" *ngFor="let item of nearbyCities" [routerLink]="item.url">{{ item.name }}</a>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #outOfMarket>
        <div class="wrapper">
            <div class="container-property" style="text-align: center; padding-top: 64px">
                <img src="../../assets/404-property.svg" alt="Propiedad fuera del mercado" width="100%" height="175" />
                <a [routerLink]="data.not_found_info.link">
                    <button mat-raised-button color="primary"
                        style="box-shadow: none; margin-top: 32px; margin-bottom: 32px; font-size: 18px">
                        {{ locale === 'en' ? data.not_found_info.btn_message_en : data.not_found_info.btn_message }}
                    </button>
                </a>
                <div class="heading-s" i18n>Este listado no está activo.</div>
                <div class="text-m" style="color: var(--grayscale-5)" i18n>
                    Es posible que esta propiedad ya no este en el mercado.
                </div>
                <div class="text-m" style="color: var(--grayscale-5)" i18n>Intente buscar otras propiedades similares.
                </div>
            </div>
        </div>
        <ng-container *ngTemplateOutlet="additionalWrappers"></ng-container>
    </ng-template>

    <ng-template #unknownSize> -- ft<sup>2</sup> </ng-template>

    <ng-template #fueraDelMercadoTemplate>
        <div class="text-m" style="color: var(--grayscale-1); margin-bottom: 8px" i18n>Fuera del mercado</div>
    </ng-template>

    <ng-template #showUnitInfo>
        <div class="text-s" style="color: var(--grayscale-4)">
            <ng-container *ngIf="data.listing.bedrooms">
                <span> {{ data.listing.bedrooms }} { data.listing.bedrooms, plural, =1 {Cuarto} other {Cuartos}} </span>
                <span style="opacity: 0.2"> | </span>
            </ng-container>

            <ng-container *ngIf="data.listing.property_units">
                {{ data.listing.property_units }}

                <ng-container i18n>
                    { data.listing.property_units, plural, =1 {Unidad} other {Unidades}}
                </ng-container>

                <span style="color: var(--grayscale-9)"> | </span>
            </ng-container>

            <ng-container *ngIf="data.listing.bathrooms > 0">
                <span>
                    {{ +data.listing.bathrooms + +data.listing.half_bathrooms }}&nbsp;
                    <ng-container i18n>
                        { +data.listing.bathrooms + +data.listing.half_bathrooms, plural, =1 {Baño} other {Baños} }
                    </ng-container>
                </span>
                <span style="opacity: 0.2"> | </span>
            </ng-container>

            <ng-container *ngIf="data.listing.livable_area > 0; else unknownSize">
                <span>{{ data.listing.livable_area | number }} {{ ' ' + data.listing.livable_area_unit
                    }}<sup *ngIf="
        							data.listing.livable_area_unit != 'Desconocido' &&
        							data.listing.livable_area_unit != 'acres' &&
        							data.listing.livable_area_unit != 'cuerdas'
        						">2</sup></span>
            </ng-container>
        </div>
    </ng-template>
</ng-container>