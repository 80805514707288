import { isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	Directive,
	ElementRef,
	EventEmitter,
	Host,
	Inject,
	OnDestroy,
	Output,
	PLATFORM_ID
} from '@angular/core';

@Directive({ selector: '[enterTheViewportNotifier]' })
export class EnterViewportDirective implements AfterViewInit, OnDestroy {
	@Output() visibilityChange = new EventEmitter<'VISIBLE' | 'HIDDEN'>();
	private _observer: IntersectionObserver;

	constructor(@Host() private _elementRef: ElementRef, @Inject(PLATFORM_ID) private platformId: any) {}

	ngAfterViewInit(): void {
		const options = { root: null, rootMargin: '0px', threshold: 0.0 };
		if (isPlatformBrowser(this.platformId)) {
			this._observer = new IntersectionObserver(this._callback, options);
			this._observer.observe(this._elementRef.nativeElement);
		}
	}

	ngOnDestroy() {
		if (this._observer) {
			this._observer.disconnect();
		}
	}

	private _callback = (entries, observer) => {
		entries.forEach((entry) => this.visibilityChange.emit(entry.isIntersecting ? 'VISIBLE' : 'HIDDEN'));
	};
}
