<ng-container *ngIf="!loading; else loadingTemplate">
	<article (visibilityChange)="_visibilityChangeHandler($event)" enterTheViewportNotifier>
		<app-image-card-carousel
			[listingLink]="listingLink"
			[images]="listing.images"
			[firstImage]="listing.listing_cover_picture"
			[address]="address"
		>
			<div
				class="label-container"
				*ngIf="
					listing.listing_status === 'Alquilado/Alquilada' ||
					listing.listing_status === 'vendido' ||
					listing.listing_status === 'Opcionado/Opcionada'
				"
			>
				<ng-container [ngSwitch]="listing.listing_status">
					<div class="text-s-sb" *ngSwitchCase="'Opcionado/Opcionada'" i18n>Opcionado</div>
					<div class="text-s-sb vendido" *ngSwitchCase="'Alquilado/Alquilada'" i18n>Alquilado</div>
					<div class="text-s-sb vendido" *ngSwitchCase="'vendido'">
						<ng-container i18n>
							{ listing.property_type, select, Casa {vendida} Multi-familiar {vendida} Oficina {vendida}
							other {vendido} } en
						</ng-container>
						{{ listing.sold_date | date : 'MMM d, y' }}
					</div>
				</ng-container>

				<!-- <div class="text-s-sb" *ngIf="listing.isForeclosure === 1" i18n>REO</div> -->
			</div>
			<ng-container *ngIf="showHeart">
				<i-feather
					name="Heart"
					class="heart-position"
					[ngClass]="{
						liked: listing.isListingSaved == 1,
						'not-liked': !listing.isListingSaved || listing.isListingSaved == 0
					}"
					(click)="onLikeAction()"
				></i-feather>
			</ng-container>
			<ng-content></ng-content>
		</app-image-card-carousel>
		<a [routerLink]="listingLink" target="_blank">
			<div class="price-n-tag-div">
				<div class="price">
					<ng-container *ngIf="listing.listing_status === 'vendido'; else standardPrice">
						{{ listing.sold_price | currency : 'USD' : 'symbol' : '1.0' }}
						<span class="price_type" i18n> /precio de venta </span>
					</ng-container>

					<ng-template #standardPrice>
						{{ listing.price | currency : 'USD' : 'symbol' : '1.0' }}
						<span class="price_type" *ngIf="listing.price_type !== 'Total'">
							/{{ locale === 'en' ? listing.price_type_en : listing.price_type }}</span
						>
					</ng-template>
				</div>

				<div class="new-tag" *ngIf="daysOnFindit === 0" i18n>NUEVO</div>
			</div>

			<address class="text-s text-overflow">
				{{ listing.street }}
				<br />
				{{ listing.city + ' ' + listing.zip + ' PR' }}
			</address>

			<div
				*ngIf="listing.property_type !== 'Terreno'; else lands"
				class="text-s"
				style="color: var(--grayscale-3)"
			>
				<ng-container *ngIf="listing.bedrooms">
					<ng-container i18n
						>{{ listing.bedrooms }}&nbsp;{listing.bedrooms, plural, =1 {Cuarto} other
						{Cuartos}}</ng-container
					>
					<span style="color: var(--grayscale-9)"> | </span>
				</ng-container>

				<ng-container *ngIf="listing.property_units">
					{{ listing.property_units }}
					<ng-container i18n> {listing.property_units, plural, =1 {Unidad} other {Unidades}} </ng-container>
					<span style="color: var(--grayscale-9)"> | </span>
				</ng-container>

				<ng-container *ngIf="listing.bathrooms > 0">
					<ng-container i18n>
						{{ +listing.bathrooms + +listing.half_bathrooms }}
						{ baGetter, plural, =1 {Baño} other {Baños} }
					</ng-container>
					<span style="color: var(--grayscale-9)"> | </span>
				</ng-container>

				<ng-container *ngIf="listing.livable_area > 0; else unknownSize">
					{{ +listing.livable_area | number }} {{ listing.livable_area_unit
					}}<sup *ngIf="listing.livable_area_unit === 'm' || listing.livable_area_unit === 'ft'">2</sup>
				</ng-container>
			</div>

			<ng-template #lands>
				<div class="text-s" style="color: var(--grayscale-3)">
					<ng-container *ngIf="listing.land_type && listing.land_type !== 'N/A'">
						{{ locale === 'en' ? listing.land_type_en : listing.land_type }}
						<span style="color: var(--grayscale-9)"> | </span>
					</ng-container>

					{{ locale === 'en' ? listing.land_sub_type_en : listing.land_sub_type }}
					<span style="color: var(--grayscale-9)"> | </span>
					<ng-container *ngIf="listing.lot_size > 0; else unknown">
						{{ +listing.lot_size | number }} {{ listing.lot_unit
						}}<sup *ngIf="listing.lot_unit === 'm' || listing.lot_unit === 'ft'">2</sup>
					</ng-container>
				</div>
			</ng-template>

			<ng-template #unknownSize> -- ft<sup>2</sup> </ng-template>

			<div class="text-s" style="color: var(--grayscale-3); margin-top: 4px; margin-bottom: 8px">
				<span
					[ngClass]="{
						'dot-blue': listing.for_sale === true || listing.for_sale === 'true',
						'dot-yellow': listing.for_sale === false || listing.for_sale === 'false',
						'dot-sold': listing.listing_status === 'vendido'
					}"
				></span>
				{{ homeType }}
				<ng-container *ngIf="listing.listing_status !== 'vendido'; else soldTemplate">
					{ listing.for_sale, select, true {en Venta} other {en Alquiler} }
				</ng-container>
				<ng-template #soldTemplate>
					<ng-container i18n> fuera del mercado</ng-container>
				</ng-template>
			</div>

			<div
				class="time-on-findit"
				*ngIf="daysOnFindit === 0; else daysOnFinditTemplate"
				[ngSwitch]="hoursOnFindit"
			>
				<ng-container *ngSwitchCase="0" i18n> Publicado hace menos de 1 hora </ng-container>
				<ng-container *ngSwitchDefault i18n>
					Publicado hace {{ hoursOnFindit }} { hoursOnFindit, plural, =1 {hora} other {horas} }
				</ng-container>
			</div>
			<ng-template #daysOnFinditTemplate>
				<div class="time-on-findit" i18n>
					Publicado hace {{ daysOnFindit }} { daysOnFindit, plural, =1 {día} other {días} }
				</div>
			</ng-template>
		</a>
	</article>
</ng-container>

<ng-template #loadingTemplate>
	<div class="cards-margins cards-width">
		<ngx-skeleton-loader
			count="1"
			appearance="circle"
			[theme]="{
				width: '-webkit-fill-available',
				height: '160px',
				'border-radius': '4px',
				margin: '0px'
			}"
		></ngx-skeleton-loader>
		<ngx-skeleton-loader
			count="1"
			[theme]="{
				width: '110px',
				'border-radius': '4px',
				'margin-top': '12px',
				'margin-bottom': '8px'
			}"
		></ngx-skeleton-loader>
		<br />
		<ngx-skeleton-loader
			count="1"
			[theme]="{
				width: '175px',
				'border-radius': '4px',
				margin: '0px'
			}"
		></ngx-skeleton-loader>
	</div>
</ng-template>
