import { Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Filters } from '../../interfaces/BusquedaInterface';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ListingsService } from '../../services/listings.service';

@Component({
	selector: 'app-filters-dialog',
	templateUrl: './filters-dialog.component.html',
	styleUrls: ['./filters-dialog.component.css']
})
export class FiltersDialogComponent implements OnInit, OnDestroy {
	requestInProgress = false;
	selectAll = false;
	total = 0;
	filters: Filters = null;
	keywordFocusOut = true;
	@ViewChild('keywordInputMobile') keywordInputMobile: ElementRef<HTMLInputElement>;

	private ngUnsubscribe = new Subject<void>();
	parentPipe = new EventEmitter();

	constructor(
		public dialogRef: MatDialogRef<FiltersDialogComponent>,
		private readonly listingService: ListingsService,
		@Inject(MAT_DIALOG_DATA) public data: { filters: Filters; total: number; selectAll: boolean }
	) {}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	ngOnInit(): void {
		this.filters = this.data.filters;
		this.total = this.data.total;
		this.selectAll = this.data.selectAll;

		this.scrollUserToTop();

		this.listingService.matDialogChildrenPipe.pipe(takeUntil(this.ngUnsubscribe.asObservable())).subscribe(
			(_: boolean) => {
				this.requestInProgress = _;
			},
			() => {
				// console.log('error');
			},
			() => {
				// console.log('successfully unsubcribed');
			}
		);
		this.listingService.totalEventEmitter.pipe(takeUntil(this.ngUnsubscribe.asObservable())).subscribe(
			(total: number) => {
				this.total = total;
			},
			() => {
				// console.log('error');
			},
			() => {
				// console.log('successfully unsubcribed');
			}
		);
	}

	private scrollUserToTop(): void {
		//Scroll user to the top
		setTimeout(() => {
			const selector = document.querySelector('.dialog-wrapper');
			selector.scrollTop = 0;
		}, 200);
	}

	public filterValues(): void {
		this.updateSelectAll();
		this.parentPipe.emit(this.filters);
	}

	private updateSelectAll(): void {
		for (let key in this.filters.home_types) {
			const item = this.filters.home_types[key];
			if (!item) {
				this.selectAll = true;
				return;
			}
		}
		this.selectAll = false;
	}

	public onClickSelectAll(): void {
		for (let key in this.filters.home_types) {
			this.filters.home_types[key] = !!this.selectAll;
		}
		this.filterValues();
	}

	public onSubmitKeyword($): void {
		if ($?.target?.length > 0) {
			const keyword = $.target[0].value;
			this.onEnterKeyword(keyword);
		}
	}

	public removeKeyword(keyword: string): void {
		const index = this.filters.keywords.indexOf(keyword);
		if (index >= 0) {
			this.filters.keywords.splice(index, 1);
		}
		this.filterValues();
	}
	public onEnterKeyword(keyword: string): void {
		if (this.filters.keywords?.length >= 5) return;

		keyword = keyword?.trim().substring(0, 25);
		if (this.filters.keywords.indexOf(keyword) < 0) {
			this.filters.keywords.push(keyword);
			this.keywordInputMobile.nativeElement.value = '';
			this.filterValues();
		}
	}

	public onFocusIn($event): void {
		this.keywordFocusOut = false;
		// console.log('focus in');
	}
	public onFocusOut($event): void {
		this.keywordFocusOut = true;
		// console.log('focus out');
	}

	public resetFilters(): void {
		this.filters = {
			...this.filters,
			bds: -1,
			bhs: -1,
			home_types: {
				local: true,
				terreno: true,
				oficina: true,
				apartamento: true,
				almacen: true,
				edificio: true,
				casa: true,
				multi_familiar: true,
				estudio: true
			},
			keywords: [],
			price: {
				max: null,
				min: null
			}
		};
		this.filterValues();
	}

	public onClickClose(): void {
		document.getElementsByClassName('animate__animated')[0].classList.remove('animate__slideIUp');
		document.getElementsByClassName('animate__animated')[0].classList.add('animate__slideOutDown');
		setTimeout(() => {
			this.dialogRef.close(this.filters);
		}, 300);
	}
}
