import {
	Component,
	OnInit,
	ViewChild,
	ElementRef,
	Inject,
	PLATFORM_ID,
	OnDestroy,
	AfterViewInit,
	LOCALE_ID
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ListingsService } from '../services/listings.service';
import { Filters, HomeTypesFilter, Region, RegionType, SearchQuery } from '../interfaces/BusquedaInterface';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Listing } from '../interfaces/ListingInterface';
import { Observable, Subject } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UtilsService } from '../services/utils.service';
import { SearchListingsService } from '../services/search.listings.service';
import { isPlatformBrowser, Location } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FiltersDialogComponent } from './filters-dialog/filters-dialog.component';
import { ISearchDialog, SearchDialogComponent } from './search-dialog/search-dialog.component';
import { GeographyService } from '../services/geography.service';
import { Pipe, PipeTransform } from '@angular/core';
import { DotListing } from './map-component/map-component.component';
import { UserService } from '../services/user.service';
import { AuthenticationService } from '../services/authentication.service';
import { RequestCancelerService } from '../services/request-canceler.service';
import { ListingPreviewDirective } from './listingPreview.directive';
import { SigninPopUpComponent } from '../auth/signin-pop-up/signin-pop-up.component';
import { SavedSearchSuccessDialogComponent } from '../saved-search-success-dialog/saved-search-success-dialog.component';
import { HomeTypeSEO, MetaTagsService } from '../services/meta-tags.service';
import { PageEvent } from '@angular/material/paginator';

export interface APIResponse {
	map_listings: DotListing[];
	page_listings: Listing[];
	total: number;
}

interface RouteObject {
	search_val: string;
	search_query: string;
}

export type PropertyType = 'for_sale' | 'sold' | 'for_rent';

@Component({
	selector: 'app-listings-component',
	templateUrl: './listings-component.component.html',
	styleUrls: ['./listings-component.component.css']
})
export class ListingsComponentComponent implements OnInit, OnDestroy, AfterViewInit {
	private ngUnsubscribe = new Subject<void>();
	private ngUnsubscribeDialog = new Subject<void>();
	requestInProgress = false;
	requestInProgressListings = false;
	@ViewChild(ListingPreviewDirective, { static: false })
	listingPreview: ListingPreviewDirective;

	//Search
	separatorKeysCodes: number[] = [ENTER, COMMA];
	searchSuggestions = [];
	@ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
	saveSearchName = new FormControl('', {
		validators: [Validators.maxLength(50), Validators.minLength(2), Validators.required]
	});

	userSettings = {
		openSearch: false,
		search: new FormControl(''),
		showMapMobile: false,
		selectedRegions: [],
		suggestions: false,
		polygons: [],
		errors: {
			search: false
		},
		api_response: {
			map_listings: [],
			selected_regions: [],
			total: 0
		},
		property_type: 'for_sale',
		home_type: 'propiedades',
		pop_overs: {
			price: false,
			home_type: false,
			bds_bhs: false,
			more_filters: false,
			for_sale: false,
			saved_searches: false
		},
		selectAll: false,
		selectedCounter: 0,
		filtersApplied: 0,
		seo: {
			title: 'Puerto Rico'
		}
	};

	saveSearchRequest: 'idle' | 'loading' | 'success' | 'error' = 'idle';

	isBrowser: boolean;
	searchQuery: SearchQuery = null;
	@ViewChild('keywordInput') keywordInput: ElementRef<HTMLInputElement>;

	readonly dataObs = new Observable((subscriber) => {
		const search_query = this.route.snapshot.queryParams['busqueda'] || null;
		const search_val = this.route.snapshot.params['parametro'];
		this.checkIfForSale();

		subscriber.next({ search_val, search_query });
	}).pipe(
		map(({ search_val, search_query }: RouteObject) => {
			search_val = this.utilService.getCleanString(search_val);
			this.searchQuery = this.searchService.defaultSearch();
			this.searchQuery.search = search_val;

			const parsedJSON = this.utilService.ParseJSON(search_query);
			if (parsedJSON) {
				this.searchQuery = this.searchService.validateSearch({ ...parsedJSON });
			}
			this.filterHomesByURL();
			return this.searchQuery;
		}),
		switchMap((_: SearchQuery) => {
			return this.listingService.searchListings(_, this.userSettings.property_type as any);
		}),
		tap((API_RESPONSE) => {
			if (API_RESPONSE?.redirect_link) {
				this.router.navigate([API_RESPONSE.redirect_link]);
				return;
			}
			this.userSettings.api_response = { ...API_RESPONSE };
			this.userSettings.selectedRegions = [...this.userSettings.api_response.selected_regions];
			this.searchQuery.selectedRegions = [...this.userSettings.selectedRegions];
			this.updateSelectAll();
			this.updateTotalFiltersApplied();
			this.updateMetaTags();
		}),
		takeUntil(this.ngUnsubscribe.asObservable())
	);

	constructor(
		private readonly auth: AuthenticationService,
		private readonly router: Router,
		private _location: Location,
		private readonly listingService: ListingsService,
		private readonly route: ActivatedRoute,
		private readonly utilService: UtilsService,
		private readonly searchService: SearchListingsService,
		private readonly geographyService: GeographyService,
		@Inject(PLATFORM_ID) private platformId: any,
		public readonly dialog: MatDialog,
		private requestCanceler: RequestCancelerService,
		private readonly metaDataService: MetaTagsService,
		@Inject(LOCALE_ID) public readonly locale: string
	) {
		this.isBrowser = isPlatformBrowser(platformId);
	}

	ngAfterViewInit(): void {
		this.setRegionsOnSSR();
	}

	ngOnInit(): void {
		this.userSettings.search.valueChanges
			.pipe(debounceTime(275), distinctUntilChanged(), takeUntil(this.ngUnsubscribe.asObservable()))
			.subscribe(() => {
				this.userSettings.suggestions = false;
				const search = this.userSettings.search.value;
				if (search && search !== '') {
					this.requestInProgress = true;
					this.listingService
						.getSuggestionsV1(search)
						.pipe(takeUntil(this.ngUnsubscribe.asObservable()))
						.subscribe(
							(response: []) => {
								this.searchSuggestions = response;
								this.requestInProgress = false;
							},
							(error) => this.searchIndexError(error)
						);
				} else {
					this.searchSuggestions = [];
				}
			});

		this.listingService.mapLoaded.pipe(takeUntil(this.ngUnsubscribe.asObservable())).subscribe(() => {
			this.setRegionsOnSSR();
		});
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.requestCanceler.cancelRequest();
	}

	public selectSuggestion(suggestion: Region): void {
		if (this.userSettings.selectedRegions.length >= 5 && suggestion.region_type !== RegionType.NOT_REGION) {
			return;
		}

		if (suggestion.region_type !== RegionType.NOT_REGION) {
			suggestion = {
				category: suggestion.category,
				city: suggestion.city,
				db_id: suggestion.db_id,
				search_term: suggestion.search_term,
				region_type: suggestion.region_type
			};

			this.userSettings.search.setValue(null);
			this.searchInput.nativeElement.value = '';
			this.searchInput.nativeElement.focus();

			if (
				!this.userSettings.selectedRegions.find(
					($) => $.db_id === suggestion.db_id && $.region_type === suggestion.region_type
				)
			) {
				this.userSettings.selectedRegions.push(suggestion);
				this.searchQuery.selectedRegions = [...this.userSettings.selectedRegions];
				this.addPolygonFromSelection(suggestion);
				this.updateQueryParams();
			}
		} else {
			window.open(`https://www.finditpr.com${suggestion['link']}`, '_blank');
		}
	}

	public removeChip(suggestion: Region): void {
		if (window.visualViewport.width <= 768) return;

		const index = this.userSettings.selectedRegions.indexOf(suggestion);
		if (index >= 0) {
			this.userSettings.selectedRegions.splice(index, 1);
		}
		this.searchQuery.selectedRegions = [...this.userSettings.selectedRegions];

		this.removePolygon(suggestion);
		this.updateQueryParams();
	}

	public removeKeyword(keyword: string): void {
		const index = this.searchQuery.filters.keywords.indexOf(keyword);
		if (index >= 0) {
			this.searchQuery.filters.keywords.splice(index, 1);
		}
		this.updateQueryParams();
	}

	public onEnterKeyword(event: KeyboardEvent): void {
		if (this.searchQuery.filters.keywords?.length >= 5) return;

		let keyword: string = null;
		if (!event) {
			keyword = this.keywordInput?.nativeElement?.value;
		} else {
			keyword = event.target['value'];
		}
		if (!keyword) return;

		keyword = keyword.trim().substring(0, 25);
		if (this.searchQuery.filters.keywords.indexOf(keyword) < 0) {
			this.searchQuery.filters.keywords.push(keyword);
			this.keywordInput.nativeElement.value = '';
			this.updateQueryParams();
		}
	}

	private checkEmptyRegions(): void {
		const cleanURL = this.getURLWithoutParams();

		if (this.searchQuery.selectedRegions.length === 0 || this.searchQuery.selectedRegions.length > 1) {
			this.searchQuery.search = 'Puerto Rico';
			let regex = /\/([^\/]*)\//;
			const URL = `${cleanURL.replace(regex, '/' + 'puerto-rico' + '/')}?busqueda=${encodeURI(
				JSON.stringify(this.searchQuery)
			)}`;
			this._location.go(URL);
		} else {
			let result: string;
			const term = this.searchQuery.selectedRegions[0];
			if (term.category !== 'Pueblo' && term.category !== 'Zip' && term.category !== 'Area') {
				result = `${term.search_term}, ${term.city}`;
			} else {
				result = `${term.search_term}`;
			}

			const regexType = /\/([^\/]*)$/;
			const type = regexType.exec(cleanURL);
			const newCleanURL = result.replace(/[^a-zA-Z0-9]+/g, '-').toLowerCase();
			const newURL = `${newCleanURL}/${type[1]}?busqueda=${encodeURI(JSON.stringify(this.searchQuery))}`;
			this._location.go(newURL);
		}
	}

	private removePolygon($: Region, mobile: boolean = false): void {
		const polygon = this.userSettings.polygons.find(
			(_) => _.properties.db_id === $.db_id && _.properties.region_type === $.region_type
		);
		const index = this.userSettings.polygons.indexOf(polygon);
		if (index >= 0) {
			this.userSettings.polygons.splice(index, 1);
			this.userSettings.polygons = [...this.userSettings.polygons];
			this.listingService.setPolygonBoundsEmitter.emit({
				polygons: this.userSettings.polygons,
				setBounds: true
			});
		}

		if (mobile && this.userSettings.polygons.length === 0) {
			// console.log('mobile reset bounds');
			this.listingService.resetMapPositionMobile.emit();
		}
	}

	public onFocusInEvent(): void {
		this.closeAllPopOvers();
		this.userSettings.openSearch = true;
	}

	public onFocusOutEvent(): void {
		this.searchSuggestions = [];
		this.userSettings.openSearch = false;
		this.userSettings.suggestions = false;
		this.userSettings.errors.search = false;
	}

	public updateQueryParams(resetPagination: boolean = true): void {
		if (resetPagination) {
			this.searchQuery.pagina = 0;
		}
		this.updateSelectAll();
		this.requestCanceler.cancelRequest();
		this.setListingsLoading(true);
		this.updateURL();
		this.updateTotalFiltersApplied();
		this.checkEmptyRegions();
		//TODO: Do something when an error occurs
		this.listingService
			.searchListings(this.searchQuery, this.userSettings.property_type as any)
			.pipe(takeUntil(this.requestCanceler.getRequestCancelationObservable()))
			.subscribe((res) => {
				this.userSettings.api_response = res;
				this.listingService.totalEventEmitter.emit(this.userSettings.api_response.total);
				this.setListingsLoading(false);
				this.updateMetaTags();
			});
	}

	private updateMetaTags(): void {
		let result = 'Puerto Rico';
		if (this.userSettings.selectedRegions.length > 0 && this.userSettings.selectedRegions.length < 2) {
			const term: Region = this.userSettings.selectedRegions[0];
			if (term.category !== 'Pueblo' && term.category !== 'Area') {
				result = `${term.search_term}, ${term.city} PR`;
			} else {
				result = `${term.search_term}, PR`;
			}
		}
		this.userSettings.seo.title = result;
		this.metaDataService.setSearchTags(
			this.userSettings.seo.title,
			this.userSettings.property_type as PropertyType,
			this.userSettings.home_type as HomeTypeSEO
		);
		this.userSettings.seo.title = this.metaDataService.getTitleMetaTag().split('|')[0];
	}

	private updateTotalFiltersApplied(): void {
		let counter = 0;
		if (this.userSettings.selectedCounter > 0 && this.userSettings.selectedCounter < 9) {
			counter = counter + 1;
		}

		if (+this.searchQuery.filters.bds > 0) {
			counter = counter + 1;
		}

		if (+this.searchQuery.filters.bhs > 0) {
			counter = counter + 1;
		}

		if (this.searchQuery.filters.price.min > 0 || this.searchQuery.filters.price.max > 0) {
			counter = counter + 1;
		}

		if (this.searchQuery.filters.keywords?.length > 0) {
			counter = counter + 1;
		}
		this.userSettings.filtersApplied = counter;
	}

	private setListingsLoading(_: boolean): void {
		this.requestInProgressListings = _;
		this.listingService.matDialogChildrenPipe.emit(_);
		if (!_) {
			this.scrollUserToTop();
		}
	}

	private scrollUserToTop(): void {
		const selector = document.querySelector('.listings-container');
		selector.scrollTop = 0;
	}

	public onBounds(_: { zoom: number; bounds: google.maps.LatLngBounds }): void {
		this.searchQuery.bounds = _.bounds.toJSON();
		this.closeAllPopOvers();
		this.updateQueryParams();
	}

	public onClickGetSuggestions(): void {
		//TODO: THIS IS A QUICK FIX TO GET BETTER UX ON DESKTOP, WE NEED TO IMPROVE IT
		if (this.searchSuggestions?.length > 0) {
			this.selectSuggestion(this.searchSuggestions[0]);
			return;
		} else {
			this.userSettings.suggestions = false;
			const search = this.userSettings.search.value;
			if (search && search !== '') {
				this.requestInProgress = true;
				this.listingService
					.getSuggestionsV1(search)
					.pipe(takeUntil(this.ngUnsubscribe.asObservable()))
					.subscribe(
						(response: []) => {
							this.searchSuggestions = response;
							this.requestInProgress = false;

							if (this.searchSuggestions?.length > 0) {
								this.selectSuggestion(this.searchSuggestions[0]);
								return;
							}
						},
						(error) => this.searchIndexError(error)
					);
			} else {
				this.searchSuggestions = [];
			}
		}
		// const q = this.userSettings.search.value;
		// this.requestInProgress = true;
		// this.listingService
		// 	.searchSimilar(q)
		// 	.pipe(takeUntil(this.ngUnsubscribe.asObservable()))
		// 	.subscribe(
		// 		(_) => {
		// 			this.userSettings.errors.search = false;
		// 			this.userSettings.suggestions = true;
		// 			this.searchSuggestions = _;
		// 			this.requestInProgress = false;
		// 		},
		// 		(error) => this.searchIndexError(error)
		// 	);
	}

	public onClickSearchMobile(): void {
		this.closeListingPreview();

		const data: ISearchDialog = {
			search: this.userSettings.search,
			selectedRegions: this.userSettings.selectedRegions,
			total: this.userSettings.api_response.total,
			suggestions: this.userSettings.suggestions
		};

		const ref = this.dialog.open(SearchDialogComponent, {
			data,
			panelClass: ['animate__animated', 'animate__slideInUp', 'dialog-edit', 'app-full-bleed-dialog'],
			width: '100%',
			height: '100vh',
			maxWidth: '100vw'
		});

		const ref_pipe_sub = ref.componentInstance.parentPipe.subscribe(($) => {
			this.searchQuery.selectedRegions = $.selectedRegions;
			this.userSettings.selectedRegions = $.selectedRegions;
			if ($.removed) {
				this.removePolygon($.suggestion, true);
			} else {
				this.addPolygonFromSelection($.suggestion);
			}
			this.updateQueryParams();
		});

		ref.afterClosed()
			.pipe(takeUntil(this.ngUnsubscribeDialog))
			.subscribe(($: ISearchDialog) => {
				this.userSettings = { ...this.userSettings, ...$, search: this.userSettings.search };

				ref_pipe_sub.unsubscribe();
				this.ngUnsubscribeDialog.next();
				this.ngUnsubscribeDialog.complete();
			});
	}

	private closeListingPreview(): void {
		// Close any listing previews before opening the modal
		if (this.listingPreview.viewContainerRef.length > 0) {
			this.listingPreview.viewContainerRef.clear();
		}
	}

	public onClickFiltersMobile(): void {
		this.closeListingPreview();
		const mobileFiltersDialogRef = this.dialog.open(FiltersDialogComponent, {
			data: {
				filters: { ...this.searchQuery.filters },
				total: this.userSettings.api_response.total,
				selectAll: this.userSettings.selectAll
			},
			panelClass: ['animate__animated', 'animate__slideInUp', 'dialog-edit', 'app-full-bleed-dialog'],
			width: '100%',
			height: '100vh',
			maxWidth: '100vw',
			autoFocus: false
		});

		mobileFiltersDialogRef.componentInstance.parentPipe
			.pipe(takeUntil(this.ngUnsubscribe.asObservable()))
			.subscribe(($: Filters) => {
				this.searchQuery.filters = { ...$ };
				this.updateQueryParams();
			});

		mobileFiltersDialogRef
			.afterClosed()
			.pipe(takeUntil(this.ngUnsubscribe.asObservable()))
			.subscribe(($: Filters) => {
				// this.searchQuery.filters = { ...$ };
				// this.updateQueryParams();
			});
	}

	public onClickPopOver(key: string): void {
		//Close any other opened pop overs
		for (let k in this.userSettings.pop_overs) {
			if (k !== key) {
				this.userSettings.pop_overs[k] = false;
			}
		}
		this.userSettings.pop_overs[key] = !this.userSettings.pop_overs[key];
		if (key === 'saved_searches' && this.userSettings.pop_overs[key]) {
			this.setSavedSearchDefaultName();
		}
		this.saveSearchRequest = 'idle';
	}

	private setSavedSearchDefaultName(): void {
		if (this.searchQuery.selectedRegions.length > 0) {
			const search_term = this.searchQuery.selectedRegions.map((_) => _.search_term);
			const name = search_term.length > 0 ? search_term.join(', ') : '';
			this.saveSearchName.setValue(name);
		}
	}

	public onClickCheckBox(): void {
		this.updateQueryParams();
	}

	private updateSelectAll(): void {
		let counter = 0;

		for (let key in this.searchQuery.filters.home_types) {
			const item = this.searchQuery.filters.home_types[key];
			if (item) {
				counter = counter + 1;
			}
		}
		this.userSettings.selectAll = counter < 9;
		this.userSettings.selectedCounter = counter;
	}

	public unSelectAll(): void {
		for (let k in this.searchQuery.filters.home_types) {
			this.searchQuery.filters.home_types[k] = !!this.userSettings.selectAll;
		}
		this.updateQueryParams();
	}

	public onClickSaveSearch(): void {
		if (this.auth.isAuthenticated()) {
			this.saveSearch();
		} else {
			this.logInFirst(false);
		}
	}

	public onClickSaveSearchMobile(): void {
		if (this.auth.isAuthenticated()) {
			this.openSaveSearchDialogMobile();
		} else {
			this.logInFirst(true);
		}
	}

	private saveSearch(): void {
		this.saveSearchRequest = 'loading';
		this.listingService
			.saveSearch(
				this.searchQuery,
				this.userSettings.property_type as PropertyType,
				this._location.path(),
				this.saveSearchName.value
			)
			.pipe(takeUntil(this.ngUnsubscribe.asObservable()))
			.subscribe(
				() => {
					this.saveSearchRequest = 'success';
				},
				(error) => {
					this.saveSearchRequest = 'error';
					console.log(error);
				}
			);
	}

	public logInFirst(mobile: boolean = false): void {
		const ref = this.dialog.open(SigninPopUpComponent, {
			panelClass: ['animate__animated', 'animate__slideInUp', 'mobile-bottom-transition'],
			width: '100%',
			maxWidth: '468px'
		});
		if (mobile) {
			ref.afterClosed()
				.pipe(takeUntil(this.ngUnsubscribe.asObservable()))
				.subscribe(() => {
					if (this.auth.isAuthenticated()) {
						this.openSaveSearchDialogMobile();
					}
				});
		}
	}

	private openSaveSearchDialogMobile(): void {
		const ref = this.dialog.open(SavedSearchSuccessDialogComponent, {
			panelClass: [
				'animate__animated',
				'animate__slideInUp',
				'mobile-bottom-transition',
				'app-full-bleed-dialog'
			],
			width: '100%',
			maxWidth: '468px',
			data: {
				query: this.searchQuery,
				name: this.saveSearchName?.value || '',
				type: this.userSettings.property_type,
				path: this._location.path()
			}
		});

		ref.afterClosed()
			.pipe(takeUntil(this.ngUnsubscribeDialog.asObservable()))
			.subscribe(() => {
				this.ngUnsubscribeDialog.next();
				this.ngUnsubscribeDialog.complete();
			});
	}

	public onClickShowMap(): void {
		this.userSettings.showMapMobile = !this.userSettings.showMapMobile;
		this.scrollUserToTop();
	}

	public getSource(suggestion: any): string {
		const on = this.locale === 'en' ? 'on ' : 'en ';
		if (suggestion.city) {
			return on + suggestion.city;
		}

		if (suggestion.office) {
			return on + suggestion.office;
		}

		if (suggestion.source) {
			return suggestion.source;
		}
	}

	private updateURL(): void {
		const q = { ...this.searchQuery };
		let url = this.getURLWithoutParams();
		const regex = /\/([^\/]*)$/;
		let replacement: 'alquiler' | 'vendidas' | 'venta';

		if (url.includes('venta') && this.userSettings.property_type !== 'for_sale') {
			replacement = this.userSettings.property_type === 'for_rent' ? 'alquiler' : 'vendidas';
		} else if (url.includes('alquiler') && this.userSettings.property_type !== 'for_rent') {
			replacement = this.userSettings.property_type === 'for_sale' ? 'venta' : 'vendidas';
		} else if (url.includes('vendidas') && this.userSettings.property_type !== 'sold') {
			replacement = this.userSettings.property_type === 'for_sale' ? 'venta' : 'alquiler';
		} else {
			const _ = {
				for_sale: 'venta',
				for_rent: 'alquiler',
				sold: 'vendidas'
			};
			replacement = _[this.userSettings.property_type];
		}

		url = url.replace(regex, '/' + replacement);
		let URL = `${url}?busqueda=${encodeURI(JSON.stringify(q))}`;
		this._location.go(URL);
	}

	private getURLWithoutParams(): string {
		let urlTree = this.router.parseUrl(this._location.path());
		urlTree.queryParams = {};
		urlTree.fragment = null; // optional
		const url = urlTree.toString();
		return url;
	}

	private searchIndexError(error: any): void {
		console.log(error);
		this.userSettings.errors.search = true;
		this.requestInProgress = false;
		this.searchSuggestions = [];
	}

	private setRegionsOnSSR(): void {
		const regions = this.userSettings.api_response.selected_regions;
		for (let i = 0; i < regions.length; i++) {
			const region = regions[i];
			this.addPolygon(region);
		}
	}

	private addPolygon(_: Region): void {
		this.makeGetGeometryCall(_, false);
	}

	private addPolygonFromSelection(_: Region): void {
		this.makeGetGeometryCall(_, true);
	}

	private makeGetGeometryCall(_: Region, setBounds: boolean): void {
		this.geographyService
			.getGeoJSON(_.region_type, _.db_id)
			.pipe(takeUntil(this.ngUnsubscribe.asObservable()))
			.subscribe((geojson) => {
				this.userSettings.polygons = [
					...this.userSettings.polygons,
					{
						...geojson,
						properties: { db_id: _.db_id, region_type: _.region_type, ...geojson['properties'] }
					}
				];
				this.listingService.setPolygonBoundsEmitter.emit({
					polygons: this.userSettings.polygons,
					setBounds
				});
			});
	}

	private checkIfForSale(): void {
		const str = this.getURLWithoutParams();
		if (str.includes('venta')) {
			this.userSettings.property_type = 'for_sale';
		} else if (str.includes('alquiler')) {
			this.userSettings.property_type = 'for_rent';
		} else if (str.includes('vendidas')) {
			this.userSettings.property_type = 'sold';
		}
	}

	private filterHomesByURL(): void {
		let home_type: HomeTypeSEO;
		let filter: HomeTypesFilter = {
			almacen: false,
			apartamento: false,
			casa: false,
			edificio: false,
			estudio: false,
			local: false,
			multi_familiar: false,
			oficina: false,
			terreno: false
		};
		const str = this.getURLWithoutParams();

		if (str.includes('casas')) {
			home_type = 'casas';
			filter.casa = true;
		} else if (str.includes('apartamentos')) {
			home_type = 'apartamentos';
			filter.apartamento = true;
		} else if (str.includes('locales')) {
			home_type = 'locales';
			filter.local = true;
		} else if (str.includes('terrenos')) {
			home_type = 'terrenos';
			filter.terreno = true;
		} else if (str.includes('edificios')) {
			home_type = 'edificios';
			filter.edificio = true;
		} else if (str.includes('oficinas')) {
			home_type = 'oficinas';
			filter.oficina = true;
		} else if (str.includes('almacenes')) {
			home_type = 'almacenes';
			filter.almacen = true;
		} else if (str.includes('estudios')) {
			home_type = 'estudios';
			filter.estudio = true;
		} else {
			home_type = 'propiedades';
			filter = {
				...this.searchService.defaultSearch().filters.home_types
			};
		}

		if (this.searchQuery && !this._location.path().includes('?busqueda=')) {
			this.searchQuery.filters.home_types = { ...filter };
		}
		this.userSettings.home_type = home_type;
	}

	private closeAllPopOvers(): void {
		//Close any other opened pop overs
		for (let k in this.userSettings.pop_overs) {
			this.userSettings.pop_overs[k] = false;
		}
		this.saveSearchRequest = 'idle';
	}

	public onClickOutSide(): void {
		this.closeAllPopOvers();
	}

	public onPageEvent($event: PageEvent): void {
		this.searchQuery.pagina = $event.pageIndex;
		this.updateQueryParams(false);
	}

	public onChangeSort(): void {
		this.updateQueryParams();
	}

	get sortByCurrentVal(): string {
		const values = {
			MOST_PHOTOS: {
				en: 'Most Photos',
				es: 'Cantidad de fotos (Mayor - Menor)'
			},
			PRICE_MINMAX: {
				es: 'Precio (Menor - Mayor)',
				en: 'Price (Low - Hi)'
			},
			PRICE_MAXMIN: {
				es: 'Precio (Mayor - Menor)',
				en: 'Price (Hi - Low)'
			},
			NEWEST: {
				es: 'Nuevas - Viejas',
				en: 'Newest to Oldest'
			},
			OLDEST: {
				es: 'Viejas - Nuevas',
				en: 'Oldest to Newest'
			},
			BH: {
				es: 'Baños',
				en: 'Bathrooms'
			},
			BD: {
				es: 'Cuartos',
				en: 'Bedrooms'
			}
		};
		if (this.locale === 'en') {
			return values[this.searchQuery.filters.sortBy]?.en || values['NEWEST'].en;
		}
		return values[this.searchQuery.filters.sortBy]?.es || values['NEWEST'].es;
	}
}

@Pipe({
	name: 'currencySuffix'
})
export class CurrencySuffixPipe implements PipeTransform {
	transform(value: number): string {
		if (value >= 1000000000) {
			const num = value / 1000000000;
			if (Number.isInteger(num)) {
				return num + 'B';
			} else {
				return num.toFixed(1) + 'B';
			}
		} else if (value >= 1000000) {
			const num = value / 1000000;
			if (Number.isInteger(num)) {
				return num + 'M';
			} else {
				return num.toFixed(1) + 'M';
			}
		} else if (value >= 1000) {
			const num = value / 1000;
			if (Number.isInteger(num)) {
				return num + 'K';
			} else {
				return num.toFixed(1) + 'K';
			}
		} else {
			return value.toString();
		}
	}
}
