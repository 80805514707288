import { Inject, LOCALE_ID } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';
import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

//For google analytics
declare var gtag;
declare var fbq: Function;
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
	constructor(
		@Inject(PLATFORM_ID) private platformId: any,
		@Inject(LOCALE_ID) private locale: string,
		@Inject(DOCUMENT) private document: any,
		public auth: AuthenticationService,
		private router: Router
	) {
		const navEndEvents = router.events.pipe(filter((event) => event instanceof NavigationEnd));

		navEndEvents.subscribe((event: NavigationEnd) => {
			if (isPlatformBrowser(this.platformId)) {
				gtag('config', 'G-14CGZT8QB6', {
					page_path: event.urlAfterRedirects
				});
				fbq('track', 'PageView');
			}
		});
	}

	public ngOnInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			this.auth.setLocale(this.locale);
		}
		//Scroll user to the top
		this.router.events.subscribe((event) => {
			const selector = document.querySelector('.mat-sidenav-content');
			if (event instanceof NavigationEnd && selector) {
				if (!this.router.url.includes('servicios')) {
					selector.scrollTop = 0;
				}
			}
		});
	}

	isHidden() {
		return (
			this.router.url.includes('registrate') ||
			this.router.url.includes('mi-perfil') ||
			this.router.url.includes('venta') ||
			this.router.url.includes('vendidas') ||
			this.router.url.includes('alquiler') ||
			this.router.url.includes('iniciar-sesion') ||
			this.router.url.includes('restablecer') ||
			this.router.url.includes('mapa-de-sitio') ||
			this.router.url.includes('publica') ||
			this.router.url.includes('precualificate')
		);
	}
}
