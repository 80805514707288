import {
	Component,
	OnInit,
	OnDestroy,
	AfterViewInit,
	ElementRef,
	ViewChild,
	HostListener,
	ChangeDetectorRef,
	PLATFORM_ID,
	LOCALE_ID
} from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ListingsService } from '../services/listings.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Listing } from '../interfaces/ListingInterface';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { map, switchMap, tap } from 'rxjs/operators';
import { NearbyDialogBoxComponent } from './nearby-dialog-box/nearby-dialog-box.component';
import { isPlatformBrowser, Location } from '@angular/common';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { ContactDialogBoxComponent } from './contact-dialog-box/contact-dialog-box.component';
import { DialogCompartirComponent } from '../dialog-compartir/dialog-compartir.component';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';
import { SigninPopUpComponent } from '../auth/signin-pop-up/signin-pop-up.component';
import { ReportDialogComponent } from '../report-dialog/report-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MetaTagsService } from '../services/meta-tags.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Inject } from '@angular/core';
import { RecaptchaComponent } from 'ng-recaptcha';
import { ImagePreviewDialogComponent } from './image-preview-dialog/image-preview-dialog.component';
@Component({
	selector: 'app-selected-property',
	templateUrl: './selected-property.component.html',
	styleUrls: ['./selected-property.component.css']
})
export class SelectedPropertyComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild(RecaptchaComponent, { static: false }) captchaRef: RecaptchaComponent;
	@ViewChild('slider', { static: false, read: ElementRef })
	public slider: ElementRef<any>;
	blurRight: boolean = true;
	blurLeft: boolean = false;
	showHeader = false;
	seeMore: boolean = false;
	defaultImage = '../../assets/loading.svg';
	similarListings = [];
	nearbyCities = [];
	imageObject: any[] = [];
	imageURL = '../assets/ogimage.jpg';
	listing: Listing = null;
	params: ParamMap;
	flashContactCard: boolean = false;

	disabledContactBtn: boolean = false;
	contactMessagePending: boolean = false;
	contactMessageSent: boolean = false;

	id: string;
	isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);
	streetViewAvailable: boolean = false;
	listing$: Observable<any> = this.route.paramMap.pipe(
		switchMap((params) => {
			this.params = params;
			return this.listingService.getListingById(+params.get('id'));
		}),
		tap(async ({ listing, images, nearbyCities, nearbyListings, not_found_info }) => {
			if (!not_found_info && !listing) {
				this.router.navigateByUrl('**');
			}
			this.listing = listing;
			this.similarListings = nearbyListings;
			this.nearbyCities = nearbyCities;

			this.imageObject = images.map((value) => {
				return { image: `${value.image}`, thumbImage: `${value.image}`, alt: '', title: '' };
			});

			if (this.router.url !== this.listing.detailURL) {
				this._location.go(this.listing.detailURL);
			}

			if (!this.listing.agent_slug && this.listing.mls_agent_findit_id) {
				const slug = this.listing.business_name || this.listing.agent_name;
				let newSlug = slug
					.trim()
					.toLowerCase()
					.replace(/\s\s+/g, ' ')
					.replace(/[^\w\s]/gi, '')
					.replace(/\s+/g, '-');
				this.listing.agent_slug = `/agente/mls/${newSlug}/${this.listing.mls_agent_findit_id}`;
			}

			this.metaTagsService.setListingTags(this.listing, this.router.url);
			this.formatUtilitiesIncluded();
			this.formatAmenidadesUnidad();
			this.formatAmenidadesComplejo();
		})
	);

	isHandset$: Observable<boolean> = this.breakpointObserver
		.observe(Breakpoints.Handset)
		.pipe(map((result) => result.matches));

	utilitiesIncluded: string[] = [];
	amenidadesUnidad: string[] = [];
	amenidadesComplejo: string[] = [];

	contactForm: FormGroup = this._fb.group({
		name: ['', [Validators.required, Validators.maxLength(50), Validators.minLength(3)]],
		phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
		email: ['', [Validators.required, Validators.email]],
		message: [
			this.locale === 'en'
				? "I'd like to know more about this property."
				: `Me interesa saber más información sobre esta propiedad`
		],
		token: ['', [Validators.required]]
	});

	crim_polygon = null;
	constructor(
		public authService: AuthenticationService,
		private readonly userService: UserService,
		private route: ActivatedRoute,
		private listingService: ListingsService,
		private readonly breakpointObserver: BreakpointObserver,
		public dialog: MatDialog,
		private router: Router,
		private readonly metaTagsService: MetaTagsService,
		private _location: Location,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		private scrollDispatcher: ScrollDispatcher,
		private cd: ChangeDetectorRef,
		private readonly _fb: FormBuilder,
		@Inject(PLATFORM_ID) private platformId: any,
		@Inject(LOCALE_ID) public locale: any
	) {
		this.matIconRegistry.addSvgIcon(
			'360-icon',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/streetview.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'lugares-icon',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/lugares.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'direccion-icon',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/direcciones.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'pin-icon',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/coordenadas.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'tour-icon',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/3dtour.svg')
		);
	}

	async ngAfterViewInit() {
		if (isPlatformBrowser(this.platformId)) {
			await this.isStreetView();
		}
	}

	ngOnInit() {
		this.scrollDispatcher.scrolled().subscribe((x: any) => {
			const pos = x.measureScrollOffset('top');
			this.showHeader = pos > 1050;
			this.cd.detectChanges();
		});

		if (this.authService.isAuthenticated()) {
			const { first_name, last_name, email, phone } = this.authService.getUserDetails();
			this.contactForm.get('name').setValue(`${first_name} ${last_name}`);
			this.contactForm.get('phone').setValue(`${phone}`);
			this.contactForm.get('email').setValue(`${email}`);
		}
	}

	// Open nearby Dialog
	openDialog(numero: number): void {
		const data = {
			number: numero,
			listing: this.listing,
			parcel_json: this.listing['parcel_json']
		};

		const currentDialog = this.dialog.open(NearbyDialogBoxComponent, {
			panelClass: 'app-full-bleed-dialog',
			width: 'calc(99% - 50px)',
			height: '85%',
			maxWidth: '100vw',
			data: data,
			backdropClass: 'backdropBackground'
		});

		const xsmallDialogSubscription = this.isExtraSmall.subscribe((size) => {
			if (size.matches) {
				currentDialog.updateSize('100vw', '100vh');
			} else {
				currentDialog.updateSize('calc(99% - 50px)', '80%');
			}
		});

		currentDialog.afterClosed().subscribe(() => {
			xsmallDialogSubscription.unsubscribe();
		});
	}

	public sendContactMessage(): void {
		if (this.contactForm.valid) {
			const payload = {
				listingID: this.listing.listing_id,
				name: this.contactForm.get('name').value.trim(),
				email: this.contactForm.get('email').value.trim().toLowerCase(),
				phone: this.contactForm.get('phone').value.trim(),
				message: this.contactForm.get('message').value.trim(),
				listingPrice: this.listing.current_price,
				cuartosOterreno: this.listing.bedrooms || this.listing.land_type,
				banosOcategoria: this.listing.bathrooms || this.listing.land_sub_type,
				listingStreet: this.listing.street,
				listingCity: this.listing.city,
				listingImage: this.listing.listing_cover_picture,
				home_type: `${this.listing.property_type} en ${this.listing.type}`,
				captcha: this.contactForm.get('token').value.trim()
			};

			this.contactForm.disable();
			this.contactMessagePending = true;
			this.userService.sendEmailContactMessage(payload).subscribe(
				() => {
					console.log('message sent');

					this.contactMessagePending = false;
					this.contactMessageSent = true;
					this.disabledContactBtn = true;
				},
				(error) => {
					this.contactForm.enable();
					this.contactMessagePending = false;
					this.captchaRef.reset();
					console.log(error);
				}
			);
		}
	}

	public openImgs(): void {
		this.dialog.open(ImagePreviewDialogComponent, {
			autoFocus: false,
			maxWidth: '100vw',
			maxHeight: '100vh',
			height: '100%',
			width: '100%',
			data: this.imageObject,
			panelClass: ['app-full-bleed-dialog', 'animate__animated', 'animate__slideInUp']
		});
	}

	openContactBox(): void {
		const data = {
			userName: this.listing.agent_name,
			userPhone: this.listing.agent_phone,
			userEmail: this.listing.agent_email,
			userPicture: this.listing.agent_picture,
			listing: this.listing
		};

		const currentDialog = this.dialog.open(ContactDialogBoxComponent, {
			// panelClass: 'app-full-bleed-dialog',
			width: '456px',
			// height: '85%',
			data: data,
			maxWidth: '100vw'
			// backdropClass: 'backdropBackground'
		});

		const xsmallDialogSubscription = this.isExtraSmall.subscribe((size) => {
			if (size.matches) {
				currentDialog.updateSize('100vw', '100vh');
			} else {
				currentDialog.updateSize('456px', 'auto');
			}
		});

		currentDialog.afterClosed().subscribe(() => {
			xsmallDialogSubscription.unsubscribe();
		});
	}

	getTitle() {
		return `${this.listing.street}, ${this.listing.city}, ${this.listing.zip} | Find it`;
	}

	ngOnDestroy(): void {
		this.metaTagsService.setDefaultTags();
	}

	isStreetView(): Promise<boolean> {
		if (!this.listing) {
			return Promise.reject();
		}
		const streetViewService = new google.maps.StreetViewService();
		const STREETVIEW_MAX_DISTANCE = 100;
		const latLng = new google.maps.LatLng(this.listing.latitude, this.listing.longitude);

		return new Promise((resolve, reject) => {
			streetViewService.getPanoramaByLocation(
				latLng,
				STREETVIEW_MAX_DISTANCE,
				(streetViewPanoramaData, status) => {
					if (status === google.maps.StreetViewStatus.OK) {
						// ok
						this.streetViewAvailable = true;
						resolve(true);
					} else {
						// no street view available in this range, or some error occurred
						this.streetViewAvailable = false;
						resolve(false);
					}
				}
			);
		});
	}

	numberWithCommas(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}

	getListingImage(): string {
		return `${this.listing.listing_cover_picture}`;
	}

	volver(): void {
		this._location.back();
	}

	private formatUtilitiesIncluded() {
		for (let k in this.listing) {
			switch (k) {
				case 'included_water': {
					if (!!this.listing[k]) this.utilitiesIncluded.push(this.locale === 'en' ? 'water' : 'Agua');
					break;
				}
				case 'included_electricity': {
					if (!!this.listing[k]) this.utilitiesIncluded.push(this.locale === 'en' ? 'power' : 'Luz');
					break;
				}
				case 'included_internet': {
					if (!!this.listing[k]) this.utilitiesIncluded.push('Internet');
					break;
				}
				case 'included_trash': {
					if (!!this.listing[k]) this.utilitiesIncluded.push(this.locale === 'en' ? 'trash' : 'Basura');
					break;
				}
				case 'included_gas': {
					if (!!this.listing[k]) this.utilitiesIncluded.push(this.locale === 'en' ? 'gas' : 'Gas');
					break;
				}
				case 'included_tv': {
					if (!!this.listing[k]) this.utilitiesIncluded.push(this.locale === 'en' ? 'cable tv' : 'Cable TV');
					break;
				}
				case 'included_mantenimiento': {
					if (!!this.listing[k])
						this.utilitiesIncluded.push(this.locale === 'en' ? 'maintenance' : 'mantenimiento');
					break;
				}
			}
		}
	}

	get showRentDetails(): boolean {
		return (
			!!this.listing['available_date'] ||
			!!this.listing.leasing_term ||
			!!this.listing.pet_friendly ||
			this.utilitiesIncluded.length !== 0
		);
	}

	private formatAmenidadesUnidad() {
		for (let k in this.listing) {
			switch (k) {
				case 'ac': {
					if (!!this.listing[k]) this.amenidadesUnidad.push('A/C');
					break;
				}
				case 'water_heater': {
					if (!!this.listing[k])
						this.amenidadesUnidad.push(this.locale === 'en' ? 'water heater' : 'Calentador');
					break;
				}
				case 'water_cistern': {
					if (!!this.listing[k])
						this.amenidadesUnidad.push(this.locale === 'en' ? 'water cistern system' : 'Cisterna');
					break;
				}
				case 'full_generator': {
					if (!!this.listing[k])
						this.amenidadesUnidad.push(this.locale === 'en' ? 'power generator' : 'Planta eléctrica total');
					break;
				}
				case 'partial_generator': {
					if (!!this.listing[k])
						this.amenidadesUnidad.push(
							this.locale === 'en' ? 'partial power generator' : 'Planta eléctrica parcial'
						);
					break;
				}
				case 'pool': {
					if (!!this.listing[k]) this.amenidadesUnidad.push(this.locale === 'en' ? 'pool' : 'Piscina');
					break;
				}
				case 'furnished': {
					if (!!this.listing[k]) this.amenidadesUnidad.push(this.locale === 'en' ? 'furnished' : 'Amueblado');
					break;
				}
				case 'laundry': {
					if (!!this.listing[k]) this.amenidadesUnidad.push(this.locale === 'en' ? 'laundry' : 'Lavandería');
					break;
				}
				case 'balcony': {
					if (!!this.listing[k]) this.amenidadesUnidad.push(this.locale === 'en' ? 'balcony' : 'Balcón');
					break;
				}
				case 'terrace': {
					if (!!this.listing[k]) this.amenidadesUnidad.push(this.locale === 'en' ? 'terrace' : 'Terraza');
					break;
				}
				case 'jacuzzi': {
					if (!!this.listing[k]) this.amenidadesUnidad.push('Jacuzzi');
					break;
				}
				case 'gazebo': {
					if (!!this.listing[k]) this.amenidadesUnidad.push('Gazebo');
					break;
				}
				case 'kids_place': {
					if (!!this.listing[k])
						this.amenidadesUnidad.push(this.locale === 'en' ? 'play grounds' : 'Área de juegos para niños');
					break;
				}
				case 'court': {
					if (!!this.listing[k])
						this.amenidadesUnidad.push(this.locale === 'en' ? 'sports courts' : 'Canchas deportivas');
					break;
				}
				case 'wheelchair_acces': {
					if (!!this.listing[k])
						this.amenidadesUnidad.push(
							this.locale === 'en' ? 'wheelchair access' : 'Acceso a sillas de rueda'
						);
					break;
				}
				case 'alarm_system': {
					if (!!this.listing[k])
						this.amenidadesUnidad.push(this.locale === 'en' ? 'alarm' : 'Sistema de Alarma');
					break;
				}
				case 'security_cameras': {
					if (!!this.listing[k])
						this.amenidadesUnidad.push(this.locale === 'en' ? 'security cameras' : 'Camaras de Seguridad');
					break;
				}
				case 'elevator': {
					if (!!this.listing[k]) this.amenidadesUnidad.push(this.locale === 'en' ? 'elevator' : 'Elevador');
					break;
				}
			}
		}
	}

	private formatAmenidadesComplejo() {
		for (let k in this.listing) {
			switch (k) {
				case 'shared_internet': {
					if (!!this.listing[k]) this.amenidadesComplejo.push('Internet');
					break;
				}
				case 'shared_security_cameras': {
					if (!!this.listing[k])
						this.amenidadesComplejo.push(
							this.locale === 'en' ? 'security cameras' : 'Camaras de Seguridad'
						);
					break;
				}
				case 'shared_elevator': {
					if (!!this.listing[k]) this.amenidadesComplejo.push(this.locale === 'en' ? 'elevator' : 'Elevador');
					break;
				}
				case 'coworking_space': {
					if (!!this.listing[k])
						this.amenidadesComplejo.push(
							this.locale === 'en' ? 'co-working space' : 'Sala de estudio y trabajo'
						);
					break;
				}
				case 'shared_gym': {
					if (!!this.listing[k]) this.amenidadesComplejo.push(this.locale === 'en' ? 'gym' : 'Gimnasio');
					break;
				}
				case 'shared_court': {
					if (!!this.listing[k])
						this.amenidadesComplejo.push(this.locale === 'en' ? 'sports courts' : 'Canchas Deportivas');
					break;
				}
				case 'events_room': {
					if (!!this.listing[k])
						this.amenidadesComplejo.push(this.locale === 'en' ? 'activities room' : 'Salón de Actividades');
					break;
				}
				case 'shared_laundry': {
					if (!!this.listing[k])
						this.amenidadesComplejo.push(this.locale === 'en' ? 'shared laundry' : 'Lavandería Compartida');
					break;
				}
				case 'security_guard': {
					if (!!this.listing[k])
						this.amenidadesComplejo.push(
							this.locale === 'en' ? 'security guards' : 'Guardias de seguridad'
						);
					break;
				}
				case 'controlled_access': {
					if (!!this.listing[k])
						this.amenidadesComplejo.push(this.locale === 'en' ? 'controlled access' : 'Control de acceso');
					break;
				}
				case 'shared_pool': {
					if (!!this.listing[k]) this.amenidadesComplejo.push(this.locale === 'en' ? 'pool' : 'Piscina');
					break;
				}
				case 'shared_wheelchair_access': {
					if (!!this.listing[k])
						this.amenidadesComplejo.push(
							this.locale === 'en' ? 'wheelchair access' : 'Acceso a sillas de rueda'
						);
					break;
				}
				case 'shared_alarm_system': {
					if (!!this.listing[k])
						this.amenidadesComplejo.push(this.locale === 'en' ? 'alarm' : 'Sistema de Alarma');
					break;
				}
				case 'shared_kids_place': {
					if (!!this.listing[k])
						this.amenidadesComplejo.push(
							this.locale === 'en' ? 'play grounds' : 'Área de juegos para niños'
						);
					break;
				}
				case 'shared_jacuzzi': {
					if (!!this.listing[k]) this.amenidadesComplejo.push('Jacuzzi');
					break;
				}
				case 'visitor_parking': {
					if (!!this.listing[k])
						this.amenidadesComplejo.push(
							this.locale === 'en' ? 'guest parking spots' : 'Estacionamiento de visitas'
						);
					break;
				}
			}
		}
	}

	onClickShare(listing: Listing) {
		const currentDialog = this.dialog.open(DialogCompartirComponent, {
			width: '456px',
			data: listing,
			maxWidth: '100vw'
		});

		const xsmallDialogSubscription = this.isExtraSmall.subscribe((size) => {
			if (size.matches) {
				currentDialog.updateSize('100vw', '100vh');
			} else {
				currentDialog.updateSize('456px', 'auto');
			}
		});

		currentDialog.afterClosed().subscribe(() => {
			xsmallDialogSubscription.unsubscribe();
		});
	}

	onClickReport(listing: Listing) {
		const currentDialog = this.dialog.open(ReportDialogComponent, {
			width: '456px',
			data: listing,
			maxWidth: '100vw'
		});

		const xsmallDialogSubscription = this.isExtraSmall.subscribe((size) => {
			if (size.matches) {
				currentDialog.updateSize('100vw', '100vh');
			} else {
				currentDialog.updateSize('456px', 'auto');
			}
		});

		currentDialog.afterClosed().subscribe(() => {
			xsmallDialogSubscription.unsubscribe();
		});
	}

	onClickLike(listing_id: number) {
		console.log(this.listing.isListingSaved == 0, this.listing.isListingSaved == 1, this.listing.isListingSaved);
		if (!this.authService.isAuthenticated()) {
			this.logInFirst();
		} else if (!this.listing.isListingSaved || this.listing.isListingSaved == 0) {
			this.userService.addFavoriteListing(listing_id).subscribe();
			this.listing.isListingSaved = 1;
		} else {
			this.userService.removeFavoriteListing(listing_id).subscribe();
			this.listing.isListingSaved = 0;
		}
	}

	logInFirst(): void {
		this.dialog.open(SigninPopUpComponent, {
			maxWidth: '375px'
		});
	}

	getSpanishDate(input: any): string {
		const date = new Date(input || new Date());
		const options = { year: 'numeric', month: 'short', day: 'numeric' };
		let lang = 'es-ES';
		if (this.locale === 'en') {
			lang = 'en-US';
		}
		return date.toLocaleDateString(lang, options as any);
	}

	private removeAccents(s: string): string {
		var r = s;
		r = r.replace(new RegExp(/[àá]/g), 'a');
		r = r.replace(new RegExp(/[ÀÁ]/g), 'A');

		r = r.replace(new RegExp(/[èé]/g), 'e');
		r = r.replace(new RegExp(/[ÈÉ]/g), 'E');

		r = r.replace(new RegExp(/[ìí]/g), 'i');
		r = r.replace(new RegExp(/[ÌÍ]/g), 'I');

		r = r.replace(new RegExp(/ñ/g), 'n');
		r = r.replace(new RegExp(/Ñ/g), 'N');

		r = r.replace(new RegExp(/[òó]/g), 'o');
		r = r.replace(new RegExp(/[ÒÓ]/g), 'O');

		r = r.replace(new RegExp(/[ùúü]/g), 'u');
		r = r.replace(new RegExp(/[ÙÚŪ]/g), 'U');

		return r;
	}

	@HostListener('document:scroll', ['$event'])
	onWindowScroll($event) {
		console.log('Scroll Event', $event);
	}

	get propSubType(): string {
		if (this.locale === 'en' && this.listing.property_sub_type === 'Multi-familiar') {
			return 'Multi-family home';
		}
		return this.listing.property_sub_type;
	}

	get homeType(): string {
		if (this.listing.property_sub_type === 'Multi-familiar') {
			return this.propSubType;
		}

		const listing = this.listing;
		return this.locale === 'en' ? listing.property_type_en : listing.property_type;
	}

	onScroll(event: Event) {
		//llego al final de la derecha
		if (
			this.slider.nativeElement.clientWidth + this.slider.nativeElement.scrollLeft >=
			this.slider.nativeElement.scrollWidth
		) {
			this.blurRight = false;
		} else {
			this.blurRight = true;
		}
		//Llego al final de la izquierda
		if (this.slider.nativeElement.scrollLeft <= 0) {
			this.blurLeft = false;
		} else {
			this.blurLeft = true;
		}
	}

	formatPhoneNumber(phoneNumberString): string {
		const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
		const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			return '(' + match[1] + ')-' + match[2] + '-' + match[3];
		}
		return null;
	}

	onClickSeeMore(): void {
		this.seeMore = !this.seeMore;
	}

	getAgentPicture() {
		const num = +this.listing['agent_id'];
		let string = 'https://images.finditpr.com/xposure/agents/';
		if (num < 100) {
			return `${string}000${this.listing['agent_id']}.jpg`;
		} else if (num < 1000) {
			return `${string}00${this.listing['agent_id']}.jpg`;
		} else if (num < 10000) {
			return `${string}0${this.listing['agent_id']}.jpg`;
		} else {
			return `${string}${this.listing['agent_id']}.jpg`;
		}
	}

	scrollContactForm() {
		const element = document.getElementById('contactFormEl');
		element.scrollIntoView({ behavior: 'smooth', block: 'center' });
		this.flashContactCard = true;
		setTimeout(() => {
			this.flashContactCard = false;
		}, 1250);
	}
}
