<div class="contenedor">
    <div class="child-contenedor">
        <h1 class="heading-m" i18n>Findit Mapa del Sitio</h1>
        <h2 class="text-l" style="color: var(--grayscale-5) !important" i18n>Propiedades en venta en Puerto Rico por
            Municipios</h2>
        <hr>
        <div class="first-grid">
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/adjuntas-bienes-raices']" i18n>Adjuntas Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/aguada-bienes-raices']" i18n>Aguada Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/aguadilla-bienes-raices']" i18n>Aguadilla Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/aguas-buenas-bienes-raices']" i18n>Aguas Buenas
                Bienes Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/aibonito-bienes-raices']" i18n>Aibonito Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/arecibo-bienes-raices']" i18n>Arecibo Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/arroyo-bienes-raices']" i18n>Arroyo Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/anasco-bienes-raices']" i18n>Añasco Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/barceloneta-bienes-raices']" i18n>Barceloneta Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/barranquitas-bienes-raices']" i18n>Barranquitas
                Bienes Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/bayamon-bienes-raices']" i18n>Bayamon Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/cabo-rojo-bienes-raices']" i18n>Cabo Rojo Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/caguas-bienes-raices']" i18n>Caguas Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/canovanas-bienes-raices']" i18n>Canovanas Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/carolina-bienes-raices']" i18n>Carolina Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/catano-bienes-raices']" i18n>Cataño Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/camuy-bienes-raices']" i18n>Camuy Bienes Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/cayey-bienes-raices']" i18n>Cayey Bienes Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/ceiba-bienes-raices']" i18n>Ceiba Bienes Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/ciales-bienes-raices']" i18n>Ciales Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/cidra-bienes-raices']" i18n>Cidra Bienes Raíces</a>

            <a class="primary-color" [routerLink]="['/mapa-de-sitio/coamo-bienes-raices']" i18n>Coamo Bienes Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/comerio-bienes-raices']" i18n>Comerío Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/corozal-bienes-raices']" i18n>Corozal Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/culebra-bienes-raices']" i18n>Culebra Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/dorado-bienes-raices']" i18n>Dorado Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/fajardo-bienes-raices']" i18n>Fajardo Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/florida-bienes-raices']" i18n>Florida Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/guayama-bienes-raices']" i18n>Guayama Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/guayanilla-bienes-raices']" i18n>Guayanilla Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/guaynabo-bienes-raices']" i18n>Guaynabo Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/gurabo-bienes-raices']" i18n>Gurabo Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/guanica-bienes-raices']" i18n>Guanica Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/hatillo-bienes-raices']" i18n>Hatillo Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/hormigueros-bienes-raices']" i18n>Hormigueros Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/humacao-bienes-raices']" i18n>Humacao Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/isabela-bienes-raices']" i18n>Isabela Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/jayuya-bienes-raices']" i18n>Jayuya Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/juana-diaz-bienes-raices']" i18n>Juana Díaz Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/juncos-bienes-raices']" i18n>Juncos Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/lajas-bienes-raices']" i18n>Lajas Bienes Raíces</a>

            <a class="primary-color" [routerLink]="['/mapa-de-sitio/lares-bienes-raices']" i18n>Lares Bienes Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/las-marias-bienes-raices']" i18n>Las Marias Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/las-piedras-bienes-raices']" i18n>Las Piedras Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/loiza-bienes-raices']" i18n>Loíza Bienes Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/luquillo-bienes-raices']" i18n>Luquillo Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/manati-bienes-raices']" i18n>Manatí Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/maricao-bienes-raices']" i18n>Maricao Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/maunabo-bienes-raices']" i18n>Maunabo Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/mayaguez-bienes-raices']" i18n>Mayagüez Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/moca-bienes-raices']" i18n>Moca Bienes Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/morovis-bienes-raices']" i18n>Morovis Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/naguabo-bienes-raices']" i18n>Naguabo Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/naranjito-bienes-raices']" i18n>Naranjito Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/orocovis-bienes-raices']" i18n>Orocovis Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/patillas-bienes-raices']" i18n>Patillas Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/penuelas-bienes-raices']" i18n>Peñuelas Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/ponce-bienes-raices']" i18n>Ponce Bienes Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/quebradillas-bienes-raices']" i18n>Quebradillas
                Bienes Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/rincon-bienes-raices']" i18n>Rincón Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/rio-grande-bienes-raices']" i18n>Rio Grande Bienes
                Raíces</a>

            <a class="primary-color" [routerLink]="['/mapa-de-sitio/sabana-grande-bienes-raices']" i18n>Sabana Grande
                Bienes Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/salinas-bienes-raices']" i18n>Salinas Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/san-german-bienes-raices']" i18n>San Germán Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/san-juan-bienes-raices']" i18n>San Juan Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/san-lorenzo-bienes-raices']" i18n>San Lorenzo Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/san-sebastian-bienes-raices']" i18n>San Sebastian
                Bienes Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/santa-isabel-bienes-raices']" i18n>Santa Isabel
                Bienes Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/toa-alta-bienes-raices']" i18n>Toa Alta Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/toa-baja-bienes-raices']" i18n>Toa Baja Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/trujillo-alto-bienes-raices']" i18n>Trujillo Alto
                Bienes Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/utuado-bienes-raices']" i18n>Utuado Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/vega-alta-bienes-raices']" i18n>Vega Alta Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/vega-baja-bienes-raices']" i18n>Vega Baja Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/vieques-bienes-raices']" i18n>Vieques Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/villalba-bienes-raices']" i18n>Villalba Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/yabucoa-bienes-raices']" i18n>Yabucoa Bienes
                Raíces</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/yauco-bienes-raices']" i18n>Yauco Bienes Raíces</a>
        </div>

        <h2 class="text-l" style="color: var(--grayscale-5) !important" i18n>Propiedades en alquiler en Puerto Rico por
            Municipios</h2>
        <hr>
        <div class="second-grid">
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/adjuntas-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Adjuntas</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/aguada-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Aguada</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/aguadilla-propiedades-en-alquiler']"
                i18n>Propiedades en Alquiler en Aguadilla</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/aguas-buenas-propiedades-en-alquiler']"
                i18n>Propiedades en Alquiler en Aguas Buenas</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/aibonito-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Aibonito</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/arecibo-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Arecibo</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/arroyo-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Arroyo</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/anasco-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Añasco</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/barceloneta-propiedades-en-alquiler']"
                i18n>Propiedades en Alquiler en Barceloneta</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/barranquitas-propiedades-en-alquiler']"
                i18n>Propiedades en Alquiler en Barranquitas</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/bayamon-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Bayamon</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/cabo-rojo-propiedades-en-alquiler']"
                i18n>Propiedades en Alquiler en Cabo Rojo</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/caguas-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Caguas</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/canovanas-propiedades-en-alquiler']"
                i18n>Propiedades en Alquiler en Canovanas</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/carolina-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Carolina</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/catano-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Cataño</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/camuy-propiedades-en-alquiler']" i18n>Propiedades en
                Alquiler en Camuy</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/cayey-propiedades-en-alquiler']" i18n>Propiedades en
                Alquiler en Cayey</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/ceiba-propiedades-en-alquiler']" i18n>Propiedades en
                Alquiler en Ceiba</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/ciales-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Ciales</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/cidra-propiedades-en-alquiler']" i18n>Propiedades en
                Alquiler en Cidra</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/coamo-propiedades-en-alquiler']" i18n>Propiedades en
                Alquiler en Coamo</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/comerio-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Comerío</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/corozal-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Corozal</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/culebra-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Culebra</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/dorado-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Dorado</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/fajardo-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Fajardo</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/florida-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Florida</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/guayama-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Guayama</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/guayanilla-propiedades-en-alquiler']"
                i18n>Propiedades en Alquiler en Guayanilla</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/guaynabo-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Guaynabo</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/gurabo-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Gurabo</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/guanica-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Guanica</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/hatillo-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Hatillo</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/hormigueros-propiedades-en-alquiler']"
                i18n>Propiedades en Alquiler en Hormigueros</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/humacao-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Humacao</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/isabela-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Isabela</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/jayuya-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Jayuya</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/juana-diaz-propiedades-en-alquiler']"
                i18n>Propiedades en Alquiler en Juana Díaz</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/juncos-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Juncos</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/lajas-propiedades-en-alquiler']" i18n>Propiedades en
                Alquiler en Lajas</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/lares-propiedades-en-alquiler']" i18n>Propiedades en
                Alquiler en Lares</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/las-marias-propiedades-en-alquiler']"
                i18n>Propiedades en Alquiler en Las Marias</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/las-piedras-propiedades-en-alquiler']"
                i18n>Propiedades en Alquiler en Las Piedras</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/loiza-propiedades-en-alquiler']" i18n>Propiedades en
                Alquiler en Loíza</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/luquillo-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Luquillo</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/manati-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Manatí</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/maricao-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Maricao</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/maunabo-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Maunabo</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/mayaguez-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Mayagüez</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/moca-propiedades-en-alquiler']" i18n>Propiedades en
                Alquiler en Moca</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/morovis-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Morovis</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/naguabo-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Naguabo</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/naranjito-propiedades-en-alquiler']"
                i18n>Propiedades en Alquiler en Naranjito</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/orocovis-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Orocovis</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/patillas-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Patillas</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/penuelas-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Peñuelas</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/ponce-propiedades-en-alquiler']" i18n>Propiedades en
                Alquiler en Ponce</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/quebradillas-propiedades-en-alquiler']"
                i18n>Propiedades en Alquiler en Quebradillas</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/rincon-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Rincón</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/rio-grande-propiedades-en-alquiler']"
                i18n>Propiedades en Alquiler en Rio Grande</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/sabana-grande-propiedades-en-alquiler']"
                i18n>Propiedades en Alquiler en Sabana Grande</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/salinas-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Salinas</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/san-german-propiedades-en-alquiler']"
                i18n>Propiedades en Alquiler en San Germán</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/san-juan-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en San Juan</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/san-lorenzo-propiedades-en-alquiler']"
                i18n>Propiedades en Alquiler en San Lorenzo</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/san-sebastian-propiedades-en-alquiler']"
                i18n>Propiedades en Alquiler en San Sebastian</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/santa-isabel-propiedades-en-alquiler']"
                i18n>Propiedades en Alquiler en Santa Isabel</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/toa-alta-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Toa Alta</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/toa-baja-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Toa Baja</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/trujillo-alto-propiedades-en-alquiler']"
                i18n>Propiedades en Alquiler en Trujillo Alto</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/utuado-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Utuado</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/vega-alta-propiedades-en-alquiler']"
                i18n>Propiedades en Alquiler en Vega Alta</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/vega-baja-propiedades-en-alquiler']"
                i18n>Propiedades en Alquiler en Vega Baja</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/vieques-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Vieques</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/villalba-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Villalba</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/yabucoa-propiedades-en-alquiler']" i18n>Propiedades
                en Alquiler en Yabucoa</a>
            <a class="primary-color" [routerLink]="['/mapa-de-sitio/yauco-propiedades-en-alquiler']" i18n>Propiedades en
                Alquiler en Yauco</a>
        </div>
    </div>
</div>