import { Component, OnInit, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { UserService } from '../../services/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Listing } from 'src/app/interfaces/ListingInterface';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component({
	selector: 'app-contact-dialog-box',
	templateUrl: './contact-dialog-box.component.html',
	styleUrls: ['./contact-dialog-box.component.css']
})
export class ContactDialogBoxComponent implements OnInit {
	@ViewChild('capchaModal', { static: false }) captchaRef: RecaptchaComponent;
	disabledContactBtn: boolean = false;
	contactMessagePending: boolean = false;
	contactMessageSent: boolean = false;
	listing: Listing = null;
	//Form
	contactForm: FormGroup;

	readonly listingMessage =
		this.locale === 'en'
			? "I'd like to know more about this property."
			: `Me interesa saber más información sobre esta propiedad.`;
	readonly profileMessage =
		this.locale === 'en'
			? "I'd like to know more about your services."
			: `Me interesa saber más información sobre esta propiedad.`;

	constructor(
		public dialogRef: MatDialogRef<ContactDialogBoxComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { listing: Listing; payload: Object },
		@Inject(LOCALE_ID) public locale: string,
		public readonly userService: UserService,
		public readonly formBuilder: FormBuilder,
		private readonly authService: AuthenticationService
	) {}

	ngOnInit() {
		if (this.data.listing) {
			this.listing = this.data.listing;
		}
		this.contactForm = this.formBuilder.group({
			name: ['', [Validators.required]],
			email: ['', [Validators.required, Validators.email]],
			phone: ['', [Validators.required]],
			message: [
				this.data.payload ? this.profileMessage : this.listingMessage,
				[Validators.required, Validators.maxLength(255)]
			],
			token: ['', [Validators.required]]
		});

		if (this.authService.isAuthenticated()) {
			const { first_name, last_name, phone, email } = this.authService.getUserDetails();
			this.contactForm.get('name').setValue(`${first_name} ${last_name}`);
			this.contactForm.get('email').setValue(email);
			this.contactForm.get('phone').setValue(phone);
		}
	}

	onClickContact(): void {
		this.contactForm.disable();
		this.contactMessagePending = true;
		if (this.data.payload) {
			const payload = {
				...this.data.payload,
				name: this.contactForm.get('name').value.trim(),
				email: this.contactForm.get('email').value.trim().toLowerCase(),
				phone: this.contactForm.get('phone').value.trim(),
				message: this.contactForm.get('message').value.trim(),
				captcha: this.contactForm.get('token').value.trim()
			};
			this.userService.sendAgentProfileContact(payload).subscribe(
				() => this.onSuccess(),
				(error) => this.onError(error)
			);
		} else {
			const payload = {
				listingID: this.listing.listing_id,
				name: this.contactForm.get('name').value.trim(),
				email: this.contactForm.get('email').value.trim().toLowerCase(),
				phone: this.contactForm.get('phone').value.trim(),
				message: this.contactForm.get('message').value.trim(),
				listingPrice: this.listing.current_price,
				cuartosOterreno: this.listing.bedrooms || this.listing.land_type,
				banosOcategoria: this.listing.bathrooms || this.listing.land_sub_type,
				listingStreet: this.listing.street,
				listingCity: this.listing.city,
				listingImage: this.listing.listing_cover_picture,
				home_type: `${this.listing.property_type} en ${this.listing.type}`,
				captcha: this.contactForm.get('token').value.trim()
			};
			this.userService.sendEmailContactMessage(payload).subscribe(
				() => this.onSuccess(),
				(error) => this.onError(error)
			);
		}
	}

	private onError(error): void {
		this.contactForm.enable();
		this.contactMessagePending = false;
		this.captchaRef.reset();
		console.log(error);
	}

	private onSuccess(...agrs): void {
		console.log('message sent');
		this.contactMessagePending = false;
		this.contactMessageSent = true;
		this.disabledContactBtn = true;
	}
}
