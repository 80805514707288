import { Component, Inject, LOCALE_ID, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdsService } from '../services/ads.service';
import { MoreInfoDialogComponent } from './more-info-dialog/more-info-dialog.component';
import { ServiceProviderCategories, ServiceProviderCategoriesEN } from '../data/ServiceProvider';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MetaTagsService } from '../services/meta-tags.service';

@Component({
	selector: 'app-service-providers-directory',
	templateUrl: './service-providers-directory.component.html',
	styleUrls: ['./service-providers-directory.component.css']
})
export class ServiceProvidersDirectoryComponent implements OnDestroy {
	filter = {
		area: null,
		category: null
	};

	obs: Observable<any> = this.route.paramMap.pipe(
		switchMap((params) => {
			return this.adsService.getList(this.filter);
		}),
		tap(async (data) => {
			this.providers = data;
		})
	);

	loading = false;
	categories = [...ServiceProviderCategories];

	providers = [];

	constructor(
		@Inject(LOCALE_ID) public readonly locale: string,
		private readonly metaTagsService: MetaTagsService,
		private readonly route: ActivatedRoute,
		private readonly adsService: AdsService,
		private readonly dialog: MatDialog
	) {
		this.metaTagsService.setDirectoryAdsDefaultTags();
		this.categories = this.locale === 'en' ? [...ServiceProviderCategoriesEN] : [...ServiceProviderCategories];
	}

	ngOnDestroy(): void {
		this.metaTagsService.setDefaultTags();
	}

	public onClickLearnMore(ad: IServiceProviderAd): void {
		this.dialog.open(MoreInfoDialogComponent, {
			maxWidth: '765px',
			width: '100%',
			panelClass: [
				'animate__animated',
				'animate__slideInUp',
				'app-full-bleed-dialog',
				'mobile-bottom-transition'
			],
			data: {
				ad
			},
			minHeight: '340px'
		});
	}

	public onClickChangeArea($event: any): void {
		const { value } = $event.target;
		this.filter.area = value;
		this.getProviders();
	}

	public onClickChangeCategory($event: any): void {
		const { value } = $event.target;
		this.filter.category = value;
		this.getProviders();
	}

	private getProviders(): void {
		this.loading = true;
		this.adsService.getList(this.filter).subscribe((data: any) => {
			this.providers = data;
			this.loading = false;
		});
	}
}

export interface IServiceProviderAd {
	slug: string;
	business_name: string;
	categories: string;
	categories_en: string;
	description: string;
	img: string;
	phone: string;
	service_areas: string;
	service_areas_en: string;
	website: string;
}
