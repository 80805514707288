<div class="title-container">
	<div>
		<h1 mat-dialog-title class="heading-s" style="margin: 0" i18n>Contacto</h1>
		<h6 class="text-s">
			<ng-container i18n> Informacion Requerida </ng-container>
			<span style="color: var(--notification-error)">*</span>
		</h6>
	</div>
	<div class="spacer"></div>
	<button mat-icon-button matPrefix class="close-icon" (click)="dialogRef.close()">
		<mat-icon>add</mat-icon>
	</button>
</div>

<div mat-dialog-content>
	<form [formGroup]="contactForm" action="." id="contactFormMobile">
		<label for="name" class="text-s-sb label-margin-top">
			<ng-container i18n>Nombre Completo </ng-container><span
				style="color: var(--notification-error) !important">*</span>
		</label>
		<input type="text" formControlName="name" id="name" />

		<label for="phone" class="text-s-sb label-margin-top">
			<ng-container i18n>Numero de Teléfono</ng-container>
			<span style="color: var(--notification-error) !important">*</span>
		</label>
		<input type="text" formControlName="phone" id="phone" mask="(000)-000-0000" />

		<label for="email" class="text-s-sb label-margin-top">
			<ng-container i18n> Correo Electrónico </ng-container><span
				style="color: var(--notification-error) !important">*</span>
		</label>
		<input type="email" formControlName="email" id="email" />

		<label for="message" class="text-s-sb label-margin-top">
			<ng-container i18n> Mensaje </ng-container>
			<span style="color: var(--grayscale-4); font-weight: normal" i18n>(opcional)</span>
		</label>
		<textarea style="margin-bottom: 16px;" id="message" formControlName="message" cols="30" rows="2"></textarea>

		<!-- CAPTCHA -->
		<div style="width: 100%;">
			<re-captcha #capchaModal id="recaptchaModal" name="recaptchaModal" formControlName="token">
			</re-captcha>
		</div>
		<!-- CAPTCHA END -->
	</form>

	<button mat-button class="contactarBtn text-m" id="contactSubmitModal"
		[ngStyle]="{ 'background-color': contactMessagePending ? 'var(--notification-warning)' : '' }"
		[ngClass]="{ 'success-bg': contactMessageSent }" [disabled]="contactForm.invalid || disabledContactBtn"
		(click)="onClickContact()">
		<span *ngIf="!contactMessageSent && !contactMessagePending" i18n>Enviar</span>
		<span *ngIf="contactMessagePending" i18n>Enviando...</span>
		<span *ngIf="contactMessageSent" style="color: var(--grayscale-1)">
			<ng-container i18n> ¡Mensaje Enviado! </ng-container>
			<i-feather name="check" class="success-color"></i-feather>
		</span>
	</button>

	<p class="disclaimer" i18n>
		*Al oprimir el botón de "Enviar", usted acepta nuestros
		<a routerLink="/terminos-condiciones" target="_blank">
			términos de uso
		</a>
		y
		<a routerLink="/politicas-privacidad" target="_blank">
			política de privacidad.
		</a>
		Además, usted está de acuerdo con recibir llamadas o mensajes para discutir su
		solicitud de información. Esta
		solicitud no lo obliga a comprar o alquilar la propiedad.
	</p>
</div>