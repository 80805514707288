import { Component, OnInit, OnDestroy, Inject, LOCALE_ID } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmploymentInfo, PreQualification } from '../../app/interfaces/PreQualification';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Subscription, interval } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { ContactUsService } from '../services/contact-us.service';
import { MetaTagsService } from '../services/meta-tags.service';
declare var hj;
@Component({
	selector: 'app-prequalification',
	templateUrl: './prequalification.component.html',
	styleUrls: ['./prequalification.component.css'],
	animations: [
		trigger('slideFade', [
			state('void', style({ opacity: 0, transform: 'translateY(-100%)' })),
			transition(':enter', [animate('500ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))]),
			transition(':leave', [animate('500ms ease-out', style({ opacity: 0, transform: 'translateY(100%)' }))])
		]),
		trigger('fadeInOut', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translateX({{ direction }})' }),
				animate('0.4s ease-out', style({ opacity: 1, transform: 'translateX(0)' }))
			]),
			transition(':leave', [
				style({ opacity: 1, transform: 'translateX(0)' }),
				animate('0.4s ease-out', style({ opacity: 0, transform: 'translateX({{ direction }})' }))
			])
		])
	]
})
export class PrequalificationComponent implements OnInit, OnDestroy {
	preQualification: PreQualification;
	downPaymentFormControl = new FormControl(null, [Validators.required, Validators.minLength(1)]);
	contactForm: FormGroup;

	stages = {
		clicked_start: false,
		loading: false,
		form_ready: false,
		sending_request: false,
		success: false
	};

	progressValue: number = 0;
	errorSendingRequest = false;
	private loading_experience_subscription: Subscription;
	private sending_request_subscription: Subscription;

	qualifies = true;
	error = false;

	//New code
	current_step = 1;
	current_step_display = 1;
	total_steps = 11;
	fadeDirection: string = '-100%';

	constructor(
		private formBuilder: FormBuilder,
		private readonly userService: UserService,
		private readonly contactUs: ContactUsService,
		private readonly metaTagsService: MetaTagsService,
		@Inject(LOCALE_ID) public readonly locale: string
	) {
		let title = 'Obtén tu pre-cualificación | Findit';
		let description =
			'Contesta unas breves preguntas y un consultor hipotecario se comunicará contigo para ayudarte a encontrar el préstamo adecuado para ti. Libre de costo y sin verificación de crédito.';
		if (this.locale === 'en') {
			title = 'Get prequalified | Findit';
			description =
				'Answer a few brief questions, and a mortgage consultant will contact you to assist you in finding the right loan for you. Free of charge and no credit check required.';
		}
		this.metaTagsService.setCustomTags(title, description, null, null, 'https://www.finditpr.com/precualificate');
	}

	ngOnDestroy(): void {
		this.loading_experience_subscription?.unsubscribe();
		this.metaTagsService.setDefaultTags();
	}

	ngOnInit() {
		this.preQualification = {
			home_type: null,
			buying_with: null,
			residence_type: null,
			process: null,
			employment_info: null,
			job_permanence: null,
			two_years_employed: null,
			income: null,
			down_payment: null,
			credit_score: null,
			veteran: null,
			working_with_agent: null,
			contact: {
				name: null,
				phone: null,
				email: null,
				hotJarId: null
			},
			permanent_job_time: null,
			less_than_a_year_employed: null
		};

		this.contactForm = this.formBuilder.group({
			name: [null, [Validators.required, Validators.minLength(4)]],
			phone: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
			email: [null, [Validators.required, Validators.email]]
		});
	}

	public onClickStart(): void {
		this.stages.clicked_start = true;
		setTimeout(() => {
			this.stages.loading = true;
		}, 500);
		setTimeout(() => {
			this.startProgressOnLoading();
		}, 700);
	}

	private startProgressOnLoading(): void {
		const duration = 2000; // 3 seconds
		const intervalTime = 100; // 100 milliseconds
		const steps = duration / intervalTime;
		const stepSize = 100 / steps;

		this.loading_experience_subscription = interval(intervalTime)
			.pipe(take(steps))
			.subscribe(() => {
				this.progressValue += stepSize;
				if (this.progressValue === 100) {
					setTimeout(() => {
						this.stages.loading = false;
					}, 100);
					setTimeout(() => {
						this.stages.form_ready = true;
					}, 725);
				}
			});
	}

	private startProgressOnRequest(): void {
		const intervalTime = 100; // 100 milliseconds
		this.sending_request_subscription = interval(intervalTime)
			.pipe(
				finalize(() => {
					this.sending_request_subscription.unsubscribe();
				})
			)
			.subscribe(() => {
				if (this.progressValue < 100) {
					this.progressValue += 1;
				} else {
					this.finishRequest();
				}
			});
	}

	private finishRequest(): void {
		this.stages.sending_request = false;
		setTimeout(() => {
			this.stages.success = true;
		}, 700);
	}

	private makeHttpRequest(): void {
		const startTime = new Date().getTime();
		this.userService
			.sendMortgageLead({
				...this.preQualification,
				contact: {
					...this.contactForm.value,
					hotJarId: hj?.globals?.get('userId').split('-').shift() || null
				}
			})
			.pipe(
				finalize(() => {
					const endTime = new Date().getTime(); // Record the end time of the request
					const elapsedTime = endTime - startTime;
					const maxDuration = 2000; // 2 seconds

					if (elapsedTime < maxDuration) {
						const remainingDuration = maxDuration - elapsedTime;
						const remainingSteps = Math.floor(remainingDuration / 100);

						interval(100)
							.pipe(
								finalize(() => {
									this.progressValue = 100; // Set progress to 100% when the remaining time completes
								})
							)
							.subscribe(() => {
								if (this.progressValue < 100) {
									this.progressValue += 100 / remainingSteps;
								} else {
									this.finishRequest();
								}
							});
					} else {
						this.progressValue = 100; // Set progress to 100% if the request takes longer than 2 seconds
					}
				})
			)

			.subscribe(
				(response) => {
					this.qualifies = response.qualifies;
				},
				(error) => {
					console.log(error);
					this.error = true;
					this.sendJaimeAMsg();
					console.log(error);
				}
			);
	}

	private sendJaimeAMsg(): void {
		let msg = null;
		try {
			msg = JSON.stringify({
				...this.preQualification,
				contact: {
					...this.contactForm.value,
					hotJarId: hj?.globals?.get('userId').split('-').shift() || null
				}
			});
		} catch (error) {
			console.log(error);
		}
		const problemas_tecnicos = 4;
		const payload = {
			user: 'Jaime Ginorio',
			user_email: 'jaime.ginorio@gmail.com',
			reason: problemas_tecnicos,
			message: msg
		};

		this.contactUs.contactStaff(payload).subscribe();
	}

	public onClickStep(step: number, key: string, value: string | number): void {
		if (key === 'down_payment') {
			if (this.downPaymentFormControl.invalid) {
				this.downPaymentFormControl.markAsTouched();
				return;
			}
			value = +value;
		}
		this.updateTotalSteps(key, value);

		this.fadeDirection = '-100%';
		setTimeout(() => {
			this.current_step = null;
		}, 1);
		setTimeout(() => {
			this.fadeDirection = '100%';
			this.current_step = step;
			this.preQualification[key] = value;
			this.resetEmploymentValues(key);
			this.updateCurrentStep();
			console.log(this.preQualification);
		}, 450);
	}

	public stepBack(): void {
		if (this.current_step === 1 || this.current_step === null) return;
		let step = this.current_step - 1;

		if (this.current_step === 6) {
			if (this.preQualification.employment_info === 'EMPLOYED') {
				if (this.preQualification.job_permanence === true) {
					step = 5.3;
				} else {
					step = 5.2;
				}
			} else if (this.preQualification.employment_info === 'SELF_EMPLOYED') {
				step = 5.4;
			} else {
				step = 5;
			}
		}

		if (this.hasDecimals(this.current_step)) {
			step = this.current_step + 1;
			if (this.current_step === 5.1 || this.current_step === 5.4) {
				step = 5;
			} else {
				step = 5.1;
			}
		}

		this.fadeDirection = '100%';
		setTimeout(() => {
			this.current_step = null;
		}, 1);
		setTimeout(() => {
			this.fadeDirection = '-100%';
			this.current_step = step;
			this.current_step_display = this.current_step_display - 1;
		}, 450);
	}

	private updateCurrentStep(): void {
		if (
			this.preQualification.employment_info === 'EMPLOYED' ||
			this.preQualification.employment_info === 'SELF_EMPLOYED'
		) {
			if (this.current_step === 5.1 || this.current_step === 5.4) {
				this.current_step_display = 6;
				return;
			}

			this.current_step_display = this.current_step_display + 1;
			return;
		}
		this.current_step_display = this.current_step;
	}

	private hasDecimals(number: number): boolean {
		return number % 1 !== 0;
	}

	private resetEmploymentValues(key: string): void {
		if (key === 'employment_info') {
			//EMPLOYMENT INFO
			this.preQualification.job_permanence = null;
			this.preQualification.less_than_a_year_employed = null;
			this.preQualification.permanent_job_time = null;

			//SELF EMPLOYED INFO
			this.preQualification.two_years_employed = null;
		}

		if (key === 'job_permanence') {
			//EMPLOYMENT INFO
			this.preQualification.less_than_a_year_employed = null;
			this.preQualification.permanent_job_time = null;
		}
	}

	private updateTotalSteps(key: string, value: string | number | EmploymentInfo): void {
		if (key === 'employment_info') {
			if (value === 'SELF_EMPLOYED') this.total_steps = 12;
			if (value === 'RETIRED') this.total_steps = 11;
			if (value === 'EMPLOYED') this.total_steps = 13;
		}
	}

	public get name(): AbstractControl {
		return this.contactForm.get('name');
	}
	public get phone(): AbstractControl {
		return this.contactForm.get('phone');
	}
	public get email(): AbstractControl {
		return this.contactForm.get('email');
	}

	public onSubmitContactInfo(): void {
		this.progressValue = 0;
		if (this.contactForm.invalid) {
			this.contactForm.markAllAsTouched();
			return;
		}

		this.stages.form_ready = false;
		setTimeout(() => {
			this.stages.sending_request = true;
		}, 500);

		setTimeout(() => {
			this.startProgressOnRequest();
			this.makeHttpRequest();
		}, 700);
	}
}
