<div *ngIf="dataObs | async as $; else Loading" style="position: relative">
	<div class="top-bar-container">
		<div class="search-field-container" clickOutside (clickOutside)="onFocusOutEvent()">
			<div class="absolute-container">
				<div
					class="search-input-container"
					[ngClass]="{
						'search-input-container-chips-preview': !userSettings.openSearch,
						'focused-search': userSettings.openSearch
					}"
				>
					<div class="chips-container" *ngIf="userSettings.openSearch">
						<div class="chip text-s" *ngFor="let region of userSettings.selectedRegions">
							{{ region.search_term }}
							<button (click)="removeChip(region)">
								<i-feather class="remove-feather-icon" name="X"></i-feather>
							</button>
						</div>
					</div>
					<ng-container *ngTemplateOutlet="chipsPreview"></ng-container>
					<ng-container
						*ngIf="
							!(userSettings.selectedRegions.length >= 5 && userSettings.openSearch);
							else showLimitMsg
						"
					>
						<input
							(focusin)="onFocusInEvent()"
							class="text-s"
							[formControl]="userSettings.search"
							list="suggestions"
							(keyup.enter)="onClickGetSuggestions()"
							i18n-placeholder
							placeholder="Area, Pueblo, Zip, Barrio, Findit ID, MLS ID, Profesional"
							i18n-placeholder
							#searchInput
						/>
					</ng-container>
					<ng-template #showLimitMsg>
						<div class="text-s max-message" i18n>
							Puedes hacer un máximo de <strong>5</strong> búsquedas
						</div>
					</ng-template>

					<div class="search-button-container" id="remove-chip-btn">
						<button>
							<i-feather class="search-feather-icon" name="search"></i-feather>
						</button>
					</div>
				</div>
				<div class="suggestions-container">
					<ng-container *ngIf="!requestInProgress; else loadingSuggestions">
						<ng-container [ngSwitch]="userSettings.suggestions">
							<div *ngSwitchCase="true" class="recomendations-container">
								<div class="recomendations-container-first-div">
									<div class="text-s not-found-bold">
										No encontramos "<span>{{ userSettings.search.value }}</span
										>"
									</div>
									<div class="not-found-recomendations">nuestras recomendaciones:</div>
								</div>
								<ng-container *ngTemplateOutlet="suggestionsTemplate"></ng-container>
							</div>
							<ng-container *ngSwitchDefault>
								<ng-container *ngTemplateOutlet="suggestionsTemplate"></ng-container>
							</ng-container>
						</ng-container>
					</ng-container>
				</div>
			</div>
			<div class="absolute-container-mobile">
				<div class="search-input-container-mobile" (click)="onClickSearchMobile()">
					<ng-container *ngTemplateOutlet="chipsPreview"></ng-container>
					<div
						class="text-s search-placeholder-mobile hide-overflow-mobile"
						*ngIf="userSettings.selectedRegions.length === 0"
						i18n
					>
						Buscar...
					</div>
					<button i18n>editar</button>
				</div>
			</div>
		</div>
		<div class="for-sale-container">
			<button class="text-s filter-btn" (click)="onClickPopOver('for_sale')">
				<span
					[ngClass]="{
						'for-sale': userSettings.property_type === 'for_sale',
						'for-rent': userSettings.property_type === 'for_rent',
						sold: userSettings.property_type === 'sold'
					}"
				></span>
				<ng-container i18n>
					{userSettings.property_type, select, for_sale {venta} for_rent {alquiler} sold {vendidas}}
				</ng-container>
			</button>
			<div class="pop-over pop-over-property-type" *ngIf="userSettings.pop_overs.for_sale">
				<mat-radio-group
					class="property-type-radio-container"
					[(ngModel)]="userSettings.property_type"
					(change)="updateQueryParams()"
				>
					<mat-radio-button class="text-s property-type-txt for-sale-radio-btn" value="for_sale" i18n>
						venta
					</mat-radio-button>
					<mat-radio-button class="text-s property-type-txt for-rent-radio-btn" value="for_rent" i18n>
						alquiler
					</mat-radio-button>
					<mat-radio-button class="text-s property-type-txt sold-radio-btn" value="sold" i18n>
						vendidas (beta)
					</mat-radio-button>
				</mat-radio-group>
				<div class="ok-btn-container">
					<button class="text-s ok-btn" (click)="onClickPopOver('for_sale')" i18n>Listo</button>
				</div>
			</div>
		</div>
		<div class="more-filter-mobile-container">
			<button
				class="text-s filter-btn"
				(click)="onClickFiltersMobile()"
				[ngClass]="{ 'active-filter-btn': userSettings.filtersApplied > 0 }"
			>
				<ng-container i18n> más filtros </ng-container>
				<ng-container *ngIf="userSettings.filtersApplied > 0">
					({{ userSettings.filtersApplied }})
				</ng-container>
			</button>
		</div>
		<div class="home-type-container">
			<button
				class="text-s filter-btn"
				(click)="onClickPopOver('home_type')"
				[ngStyle]="{
					color:
						userSettings.selectedCounter > 0 && userSettings.selectedCounter < 9
							? 'var(--primary-color-1)'
							: 'unset'
				}"
				i18n
			>
				tipo
				<ng-container *ngIf="userSettings.selectedCounter > 0 && userSettings.selectedCounter < 9">
					({{ userSettings.selectedCounter }})
				</ng-container>
			</button>
			<div class="pop-over pop-over-home-type" *ngIf="userSettings.pop_overs.home_type">
				<div class="pop-over-title" style="text-align: center">
					<button class="select-all-btn text-s" (click)="unSelectAll()" i18n>
						{userSettings.selectAll, select, false {Deseleccionar Todos} true {Seleccionar Todos} }
					</button>
				</div>
				<div class="home-type-radio-container">
					<mat-checkbox
						[(ngModel)]="searchQuery.filters.home_types.casa"
						[checked]="searchQuery.filters.home_types.casa"
						(change)="onClickCheckBox()"
						class="text-s"
					>
						<span i18n> Casa </span>
					</mat-checkbox>
					<mat-checkbox
						[(ngModel)]="searchQuery.filters.home_types.apartamento"
						[checked]="searchQuery.filters.home_types.apartamento"
						(change)="onClickCheckBox()"
						class="text-s"
					>
						<span i18n> Apartamento </span>
					</mat-checkbox>
					<mat-checkbox
						[(ngModel)]="searchQuery.filters.home_types.multi_familiar"
						[checked]="searchQuery.filters.home_types.multi_familiar"
						(change)="onClickCheckBox()"
						class="text-s"
					>
						<span i18n> Casa Multi-Familiar </span>
					</mat-checkbox>
					<mat-checkbox
						[(ngModel)]="searchQuery.filters.home_types.terreno"
						[checked]="searchQuery.filters.home_types.terreno"
						(change)="onClickCheckBox()"
						class="text-s"
					>
						<span i18n> Terreno </span>
					</mat-checkbox>
					<mat-checkbox
						[(ngModel)]="searchQuery.filters.home_types.local"
						[checked]="searchQuery.filters.home_types.local"
						(change)="onClickCheckBox()"
						class="text-s"
					>
						<span i18n> Local Comercial </span>
					</mat-checkbox>
					<mat-checkbox
						[(ngModel)]="searchQuery.filters.home_types.oficina"
						[checked]="searchQuery.filters.home_types.oficina"
						(change)="onClickCheckBox()"
						class="text-s"
					>
						<span i18n> Oficina </span>
					</mat-checkbox>
					<mat-checkbox
						[(ngModel)]="searchQuery.filters.home_types.almacen"
						[checked]="searchQuery.filters.home_types.almacen"
						(change)="onClickCheckBox()"
						class="text-s"
					>
						<span i18n> Almacen </span>
					</mat-checkbox>
					<mat-checkbox
						[(ngModel)]="searchQuery.filters.home_types.edificio"
						[checked]="searchQuery.filters.home_types.edificio"
						(change)="onClickCheckBox()"
						class="text-s"
					>
						<span i18n> Edificio </span>
					</mat-checkbox>
					<mat-checkbox
						[(ngModel)]="searchQuery.filters.home_types.estudio"
						[checked]="searchQuery.filters.home_types.estudio"
						(change)="onClickCheckBox()"
						class="text-s check-box"
					>
						<span i18n> Estudio </span>
					</mat-checkbox>
					<!-- <mat-checkbox *ngIf="!isVenta" formControlName="habitacion" class="text-s check-box">
								<span i18n> Habitacion </span>
							</mat-checkbox> -->
				</div>
				<div class="ok-btn-container">
					<button class="text-s ok-btn" (click)="onClickPopOver('home_type')" i18n>Listo</button>
				</div>
			</div>
		</div>
		<div class="price-container">
			<button class="text-s filter-btn" (click)="onClickPopOver('price')">
				<ng-container *ngIf="!searchQuery.filters.price.min && !searchQuery.filters.price.max" i18n>
					precio
				</ng-container>
				<div
					style="color: var(--primary-color-1)"
					*ngIf="searchQuery.filters.price.min && !searchQuery.filters.price.max"
				>
					${{ +searchQuery.filters.price.min | currencySuffix }}+
				</div>
				<div
					style="color: var(--primary-color-1)"
					*ngIf="!searchQuery.filters.price.min && searchQuery.filters.price.max"
				>
					<ng-container i18n> hasta </ng-container>
					${{ +searchQuery.filters.price.max | currencySuffix }}
				</div>
				<div
					style="color: var(--primary-color-1)"
					*ngIf="searchQuery.filters.price.min && searchQuery.filters.price.max"
				>
					${{ +searchQuery.filters.price.min | currencySuffix }} - ${{
						+searchQuery.filters.price.max | currencySuffix
					}}
				</div>
			</button>
			<div class="pop-over price-pop-over" *ngIf="userSettings.pop_overs.price">
				<div class="text-m pop-over-title" i18n>Precio</div>
				<div class="price-input-container">
					<div>
						<label for="price_min" class="text-s-sb">Min.</label>
						<input
							id="price_min"
							type="text"
							prefix="$"
							mask="separator.2"
							maxlength="14"
							(keyup.enter)="updateQueryParams()"
							thousandSeparator=","
							autocomplete="off"
							[(ngModel)]="searchQuery.filters.price.min"
						/>
					</div>
					<div class="price-dash-container">
						<div class="price-dash"></div>
					</div>
					<div>
						<label for="price_max" class="text-s-sb">Max.</label>
						<input
							id="price_max"
							type="text"
							prefix="$"
							mask="separator.2"
							(keyup.enter)="updateQueryParams()"
							thousandSeparator=","
							maxlength="14"
							autocomplete="off"
							[(ngModel)]="searchQuery.filters.price.max"
						/>
					</div>
				</div>
				<div class="ok-btn-container">
					<button class="text-s ok-btn" (click)="onClickPopOver('price'); updateQueryParams()" i18n>
						Listo
					</button>
				</div>
			</div>
		</div>
		<div class="bds-bhs-container">
			<button class="text-s filter-btn" (click)="onClickPopOver('bds_bhs')">
				<ng-container *ngIf="searchQuery.filters.bds < 0 && searchQuery.filters.bhs < 0" i18n>
					Cuartos & Baños
				</ng-container>
				<div
					style="color: var(--primary-color-1)"
					*ngIf="searchQuery.filters.bds > 0 || searchQuery.filters.bhs > 0"
				>
					<ng-container *ngIf="searchQuery.filters.bds > 0">
						<ng-container i18n> Ct: </ng-container>
						{{ searchQuery.filters.bds }}+
					</ng-container>
					<ng-container *ngIf="searchQuery.filters.bhs > 0">
						<ng-container i18n> Bn: </ng-container>

						{{ searchQuery.filters.bhs }}+
					</ng-container>
				</div>
			</button>
			<div class="pop-over bds-bhs-pop-over" *ngIf="userSettings.pop_overs.bds_bhs">
				<div class="text-m pop-over-title" i18n>Cuartos & Baños</div>
				<div class="bds-bhs-input-container">
					<label for="bds" class="text-s" i18n>Cuartos</label>
					<select
						class="text-s"
						name="bds"
						id="bds"
						style="margin-bottom: 12px"
						(change)="updateQueryParams()"
						[(ngModel)]="searchQuery.filters.bds"
					>
						<option [value]="-1" i18n>Todos</option>
						<option *ngFor="let num of [1, 2, 3, 4, 5, 6]" [value]="num">{{ num }}+</option>
					</select>
					<label for="bhs" class="text-s" i18n>Baños</label>
					<select
						class="text-s"
						name="bhs"
						id="bhs"
						(change)="updateQueryParams()"
						[(ngModel)]="searchQuery.filters.bhs"
					>
						<option [value]="-1" i18n>Todos</option>
						<option *ngFor="let num of [1, 2, 3, 4, 5, 6]" [value]="num">{{ num }}+</option>
					</select>
				</div>
				<div class="ok-btn-container">
					<button class="text-s ok-btn" (click)="onClickPopOver('bds_bhs'); updateQueryParams()" i18n>
						Listo
					</button>
				</div>
			</div>
		</div>
		<div class="more-filters-container">
			<button
				class="text-s filter-btn"
				(click)="onClickPopOver('more_filters')"
				[ngStyle]="{ color: searchQuery.filters.keywords.length > 0 ? 'var(--primary-color-1)' : 'unset' }"
				i18n
			>
				otros
			</button>
			<div class="pop-over more-filters-pop-over" *ngIf="userSettings.pop_overs.more_filters">
				<div class="text-m pop-over-title" i18n>Más filtros</div>
				<div class="more-filters-input-container">
					<label class="text-s" for="keywords">Keywords</label>
					<div class="keywords-input-container">
						<div class="chips-container">
							<div class="chip text-s" *ngFor="let keyword of searchQuery.filters.keywords">
								{{ keyword }}
								<button (click)="removeKeyword(keyword)">
									<i-feather class="remove-feather-icon" name="X"></i-feather>
								</button>
							</div>
						</div>

						<ng-container *ngIf="!(searchQuery.filters.keywords.length >= 5); else maxKeywordsTemplate">
							<input
								class="text-s"
								type="text"
								maxlength="25"
								i18n-placeholder
								placeholder="Vista al mar, Airbnb, etc"
								#keywordInput
								(keyup.enter)="onEnterKeyword($event)"
								autocomplete="off"
								id="keywords"
							/>
						</ng-container>
						<ng-template #maxKeywordsTemplate>
							<div class="text-s max-message" i18n>
								Puedes hacer un máximo de <strong>5</strong> keywords
							</div>
						</ng-template>
					</div>
				</div>
				<div class="ok-btn-container">
					<button class="text-s ok-btn" (click)="onClickPopOver('more_filters'); onEnterKeyword(null)" i18n>
						Listo
					</button>
				</div>
			</div>
		</div>
		<div class="save-search-container">
			<button
				(click)="onClickPopOver('saved_searches')"
				class="text-s save-search-btn"
				mat-flat-button
				color="primary"
			>
				<ng-container i18n> Guardar Búsqueda </ng-container>
			</button>
			<div class="pop-over save-search-pop-over" *ngIf="userSettings.pop_overs.saved_searches">
				<div class="text-m pop-over-title" i18n>Guardar Búsqueda</div>
				<form action="." (ngSubmit)="onClickSaveSearch()" class="save-search-input-container">
					<label class="text-s" for="save-search-name" i18n>Nombre de la búsqueda</label>
					<input
						[formControl]="saveSearchName"
						class="text-s"
						type="text"
						i18n-placeholder
						placeholder="Ej: Casas en San Juan"
						autocomplete="off"
						maxlength="50"
						id="save-search-name"
						name="save-search-name"
					/>

					<ng-container [ngSwitch]="saveSearchRequest">
						<div *ngSwitchCase="'loading'" class="save-search-requestInProgress">
							<mat-spinner [diameter]="36" [strokeWidth]="1.5"></mat-spinner>
						</div>
						<div *ngSwitchCase="'success'" class="save-search-success">
							<div class="text-s">
								<i-feather name="check-circle" class="check-icon"></i-feather>
								<ng-container i18n> ¡Busqueda guardada exitosamente! </ng-container>
							</div>
						</div>
						<div class="save-search-success" *ngSwitchCase="'error'">
							<div class="text-s">
								<i-feather name="frown" class="sad-icon"></i-feather>
								<ng-container i18n> Algo salio mal, por favor intente más tarde </ng-container>
							</div>
						</div>
						<div class="save-search-btn-container" *ngSwitchDefault>
							<button class="text-s" (click)="onClickPopOver('saved_searches')" i18n>Cancelar</button>
							<button class="text-s" type="submit" [disabled]="!saveSearchName.valid" i18n>
								Guardar
							</button>
						</div>
					</ng-container>
				</form>
			</div>
		</div>
	</div>
	<div class="map-listings-container">
		<app-map-component
			class="map-container"
			(bounds)="onBounds($event)"
			[bounds]="searchQuery?.bounds"
			[markers]="userSettings.api_response.map_listings"
			[for_sale]="userSettings.property_type"
			[ngClass]="{ 'hide-mobile': !userSettings.showMapMobile }"
			[listingPreviewRef]="listingPreview"
		></app-map-component>
		<div
			class="listings-container"
			style="flex-flow: column"
			[ngClass]="{ 'hide-mobile': userSettings.showMapMobile }"
		>
			<app-listings-by-pages
				style="width: 100%"
				(triggerSaveSearch)="saveSearch()"
				[requestInProgress]="requestInProgressListings"
				[listings]="$.pages_listings || userSettings.api_response.page_listings"
			>
				<h1 class="resultados text-s-sb" style="margin-bottom: 8px !important">
					{{ userSettings.seo.title }}
				</h1>
				<h2 class="text-s">
					{{ userSettings.api_response.total | number }}
					<ng-container i18n>
						{userSettings.api_response.total, plural, =1 {propiedad disponible en Findit} other {propiedades
						disponibles en Findit}}
					</ng-container>
				</h2>
				<div class="text-s sort-by-container">
					<div>
						<ng-container i18n> Ordenar por: </ng-container>
						<span style="color: var(--primary-color-1)">
							{{ sortByCurrentVal }}
						</span>
					</div>
					<div class="sort-by-arrow">
						<i-feather name="chevron-down"></i-feather>
					</div>
					<select
						id="sort_by"
						(change)="onChangeSort()"
						[(ngModel)]="searchQuery.filters.sortBy"
						class="text-s sort-by-select"
					>
						<option [ngValue]="'NEWEST'" i18n>Ordenar por: Nuevas - Viejas</option>
						<option [ngValue]="'PRICE_MAXMIN'" i18n>Ordenar por: Precio (Mayor - Menor)</option>
						<option [ngValue]="'PRICE_MINMAX'" i18n>Ordenar por: Precio (Menor - Mayor)</option>
						<option [ngValue]="'MOST_PHOTOS'" i18n>Ordenar por: Cantidad de fotos (Mayor - Menor)</option>
						<option [ngValue]="'BH'" i18n>Ordenar por: Baños</option>
						<option [ngValue]="'BD'" i18n>Ordenar por: Cuartos</option>
						<option [ngValue]="'OLDEST'" i18n>Ordenar por: Viejas - Nuevas</option>
					</select>
				</div>
			</app-listings-by-pages>
			<div [ngStyle]="{ display: requestInProgressListings ? 'none' : 'unset' }" class="mat-paginator-container">
				<mat-paginator
					[length]="userSettings.api_response.total"
					[pageSize]="20"
					[pageIndex]="searchQuery.pagina"
					(page)="onPageEvent($event)"
				>
				</mat-paginator>
			</div>
		</div>
	</div>

	<div class="mobile-show-map-btn-container">
		<button (click)="onClickShowMap()">
			<ng-container *ngIf="userSettings.showMapMobile; else showListBtnTemplate">
				<i-feather name="List"></i-feather>
				<ng-container i18n> lista </ng-container>
			</ng-container>
			<ng-template #showListBtnTemplate>
				<i-feather name="Map-Pin"></i-feather>
				<ng-container i18n> mapa </ng-container>
			</ng-template>
		</button>
		<button (click)="onClickSaveSearchMobile()">
			<i-feather name="Bell"></i-feather>
			<ng-container i18n> Guardar Búsqueda </ng-container>
		</button>
	</div>
	<ng-template listingPreview></ng-template>
</div>

<!-- Templates -->
<ng-template #chipsPreview>
	<div class="chips-preview-container" *ngIf="!userSettings.openSearch && userSettings.selectedRegions.length > 0">
		<div class="chip text-s" *ngIf="userSettings.selectedRegions.length > 0">
			{{ userSettings.selectedRegions[0].search_term }}
			<button (click)="removeChip(userSettings.selectedRegions[0])">
				<i-feather class="remove-feather-icon" name="X"></i-feather>
			</button>
		</div>
		<div class="chip text-s" *ngIf="userSettings.selectedRegions.length > 1">
			<ng-container i18n>
				{ userSettings.selectedRegions.length - 1, plural, =1 {Otro:} other {Otros:} }
			</ng-container>
			{{ userSettings.selectedRegions.length - 1 }}
		</div>
	</div>
</ng-template>
<ng-template #suggestionsTemplate>
	<div class="error-container" *ngIf="userSettings.errors.search">
		<div class="text-s" i18n>Algo anda mal :(</div>
		<div class="not-found-recomendations" i18n>Por favor intente más tarde</div>
	</div>

	<ul role="listbox" *ngIf="!userSettings.errors.search">
		<li
			role="listitem"
			class="text-s"
			*ngFor="let suggestion of searchSuggestions"
			(click)="selectSuggestion(suggestion)"
		>
			<div style="position: relative">
				<span style="font-weight: 600 !important">{{
					locale === 'en' ? suggestion.category_en : suggestion.category
				}}</span>
				<span>
					<i-feather name="chevron-right" style="height: 16px; width: 16px"></i-feather>
				</span>
				<span class="hide-overflow">
					{{ suggestion.search_term }}
				</span>
				<ng-container *ngIf="suggestion.category !== 'Pueblo' && suggestion.category !== 'Area'">
					<div style="position: absolute; top: 20px; font-size: 12px">
						{{ getSource(suggestion) }}
					</div>
				</ng-container>
			</div>
		</li>
	</ul>
</ng-template>
<ng-template #loadingSuggestions>
	<ul role="listbox">
		<li role="listitem" class="text-s">
			<div>
				<mat-spinner [strokeWidth]="1" [diameter]="20"></mat-spinner>
			</div>
		</li>
	</ul>
</ng-template>
<ng-template #Loading>
	<div style="position: relative">
		<div class="map-listings-container">
			<div class="map-container" [ngClass]="{ 'hide-mobile': !userSettings.showMapMobile }"></div>
			<div class="listings-container" style="flex-flow: column">
				<app-listings-by-pages style="width: 100%" [requestInProgress]="true"></app-listings-by-pages>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #mapMessage>
	<ng-container i18n> Mostrando {{ summarizedTotal }} </ng-container>

	<ng-container *ngIf="summarizedTotal !== total"> {{ locale === 'en' ? 'of' : 'de' }} {{ total }} </ng-container>
	<ng-container i18n> {total, plural, =1 {propiedad} other {propiedades}} </ng-container>
</ng-template>
