import { EventEmitter, Injectable } from '@angular/core';
import { catchError, map, retry, share, shareReplay, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CityShape } from '../interfaces/CityShapeInterface';
import { environment } from '../../environments/environment';
import { RegionType } from '../interfaces/BusquedaInterface';

@Injectable({
	providedIn: 'root'
})
export class GeographyService {
	readonly API_URI = `${environment.api}/geography`;

	constructor(private readonly http: HttpClient) {}

	public getGoogleMapStaticImage(center, zoom: number) {
		if (!zoom) {
			zoom = 11;
		} else {
			zoom = zoom - 1;
		}
		const key = 'AIzaSyCliFrXU1RTuT1TjpjK9zlau2Epjsf1a7k';
		const URL = `https://maps.googleapis.com/maps/api/staticmap?center=${center}&zoom=${zoom}&size=905x720&maptype=roadmap&key=${key}&style=feature%3Aall%7Celement%3Alabels%7Cvisibility%3Aoff`;
		return URL;
	}

	public cityMetaData(id: number): Observable<any> {
		return this.http
			.get(`${this.API_URI}/city-meta-data/${id}`)
			.pipe(retry(3), catchError(this.handleError), shareReplay(1));
	}

	private handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
		}
		// return an observable with a user-facing error message
		return throwError('Something bad happened; please try again later.');
	}

	public getCities() {
		return this.http.get(`${this.API_URI}/getCities`);
	}

	public getBarrios(city: string) {
		return this.http.post(`${this.API_URI}/getBarrios`, JSON.stringify({ search: city }), {
			headers: { 'Content-Type': 'application/json' }
		});
	}

	public getListingsByBarriosVenta(city: string, barrio: string) {
		return this.http.post(
			`${this.API_URI}/getListingsByBarrioVenta`,
			JSON.stringify({ city: city, barrio: barrio }),
			{ headers: { 'Content-Type': 'application/json' } }
		);
	}

	public getListingsByBarriosAlquiler(city: string, barrio: string) {
		return this.http.post(
			`${this.API_URI}/getListingsByBarrioAlquiler`,
			JSON.stringify({ city: city, barrio: barrio }),
			{ headers: { 'Content-Type': 'application/json' } }
		);
	}

	public getGeoJSON(region_type: RegionType, db_id: number) {
		return this.http.get(`${this.API_URI}/get-geometry?region_type=${region_type}&db_id=${db_id}`);
	}
}
