import { ChangeDetectorRef, Inject, LOCALE_ID, OnDestroy, Renderer2 } from '@angular/core';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ImageInterface } from 'src/app/interfaces/ImageInterface';
import { Listing } from 'src/app/interfaces/ListingInterface';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ListingsService } from 'src/app/services/listings.service';
import { UserService } from 'src/app/services/user.service';
import { ListingCardAbstract } from '../../listing-card/listing-card.abstract';
@Component({
	selector: 'app-horizontal-listing-card',
	templateUrl: './horizontal-listing-card.component.html',
	styleUrls: ['./horizontal-listing-card.component.css']
})
export class HorizontalListingCardComponent extends ListingCardAbstract implements OnInit, OnDestroy {
	@Input() listing: Listing;
	@Input() sold: boolean = false;

	listingImages: ImageInterface[] = [];
	protected ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		public dialog: MatDialog,
		public authService: AuthenticationService,
		private readonly listingService: ListingsService,
		private readonly userService: UserService,
		private cdr: ChangeDetectorRef,
		@Inject(LOCALE_ID) public locale: string
	) {
		super();
		this._dialog = this.dialog;
		this._cdr = this.cdr;
		this._authService = this.authService;
		this._userService = this.userService;
	}

	ngOnInit(): void {
		this.listingService
			.getListingImages(+this.listing.listing_id, true)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((images: ImageInterface[]) => {
				this.listingImages = images;
			});
	}

	ngOnDestroy(): void {
		// This aborts all HTTP requests.
		this.ngUnsubscribe.next();
		// This completes the subject properlly.
		this.ngUnsubscribe.complete();
	}

	public getSoldString(): string {
		if (this.listing.property_type.trim() === 'Casa' || this.listing.property_type.trim() === 'Oficina') {
			return 'vendida';
		} else {
			return 'vendido';
		}
	}

	get homeType(): string {
		const listing = this.listing;
		if (listing.property_type === 'Casa' && !listing.bedrooms) {
			return this.locale === 'en' ? 'Multi-family home' : 'Multi-familiar';
		}

		return this.locale === 'en' ? listing['property_type_en'] : listing.property_type;
	}
}
