import { Injectable } from '@angular/core';
import { CurrencySuffixPipe } from '../listings-component/listings-component.component';

@Injectable({
	providedIn: 'root'
})
export class UtilsService {
	constructor() {}

	public removeAccents(s: string): string {
		let r = s;
		r = r.replace(new RegExp(/[àá]/g), 'a');
		r = r.replace(new RegExp(/[ÀÁ]/g), 'A');

		r = r.replace(new RegExp(/[èé]/g), 'e');
		r = r.replace(new RegExp(/[ÈÉ]/g), 'E');

		r = r.replace(new RegExp(/[ìí]/g), 'i');
		r = r.replace(new RegExp(/[ÌÍ]/g), 'I');

		r = r.replace(new RegExp(/ñ/g), 'n');
		r = r.replace(new RegExp(/Ñ/g), 'N');

		r = r.replace(new RegExp(/[òó]/g), 'o');
		r = r.replace(new RegExp(/[ÒÓ]/g), 'O');

		r = r.replace(new RegExp(/[ùúü]/g), 'u');
		r = r.replace(new RegExp(/[ÙÚŪ]/g), 'U');

		return r;
	}

	public getCleanString(str: string) {
		if (!str || str.length < 1 || str === null) return '';
		return str.split('-').join(' ').trim().split(' ').map(this.capitalize).join(' ');
	}

	public getLinkString(str: string) {
		str = this.removeAccents(str);
		return str.trim().split(' ').join('-').toLocaleLowerCase();
	}

	public capitalize(str: string): string {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	public ParseJSON(str: string): any {
		try {
			const _JSON = JSON.parse(str);
			return _JSON;
		} catch (error) {
			return null;
		}
	}

	public getFormattedSuffixPrice(price: number): string {
		const pipe = new CurrencySuffixPipe();
		return pipe.transform(price);
	}
}
