<footer class="site-footer">
	<div class="footer-wrapper">
		<div class="venta-alquiler-block">
			<div class="venta-block">
				<h4 class="text-s" i18n>Bienes raíces</h4>
				<div class="div-links">
					<a class="clean-a-tag" [routerLink]="['/adjuntas/venta']" i18n>Adjuntas Bienes Raíces</a>
					<a class="clean-a-tag" [routerLink]="['/aguada/venta']" i18n>Aguada Bienes Raíces</a>
					<a class="clean-a-tag" [routerLink]="['/aguadilla/venta']" i18n>Aguadilla Bienes Raíces</a>
					<a class="clean-a-tag" [routerLink]="['/aguas-buenas/venta']" i18n>Aguas Buenas Bienes Raíces</a>
					<a class="clean-a-tag" [routerLink]="['/aibonito/venta']" i18n>Aibonito Bienes Raíces</a>

					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/arecibo/venta']"
						i18n>Arecibo Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/arroyo/venta']" i18n>Arroyo
						Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/anasco/venta']" i18n>Añasco
						Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/barceloneta/venta']"
						i18n>Barceloneta Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/barranquitas/venta']"
						i18n>Barranquitas Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/bayamon/venta']"
						i18n>Bayamon Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/cabo-rojo/venta']"
						i18n>Cabo Rojo Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/caguas/venta']" i18n>Caguas
						Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/canovanas/venta']"
						i18n>Canovanas Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/carolina/venta']"
						i18n>Carolina Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/catano/venta']" i18n>Cataño
						Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/camuy/venta']" i18n>Camuy
						Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/cayey/venta']" i18n>Cayey
						Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/ceiba/venta']" i18n>Ceiba
						Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/ciales/venta']" i18n>Ciales
						Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/cidra/venta']" i18n>Cidra
						Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/coamo/venta']" i18n>Coamo
						Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/comerio/venta']"
						i18n>Comerío Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/corozal/venta']"
						i18n>Corozal Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/culebra/venta']"
						i18n>Culebra Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/dorado/venta']" i18n>Dorado
						Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/fajardo/venta']"
						i18n>Fajardo Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/florida/venta']"
						i18n>Florida Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/guayama/venta']"
						i18n>Guayama Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/guayanilla/venta']"
						i18n>Guayanilla Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/guaynabo/venta']"
						i18n>Guaynabo Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/gurabo/venta']" i18n>Gurabo
						Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/guanica/venta']"
						i18n>Guanica Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/hatillo/venta']"
						i18n>Hatillo Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/hormigueros/venta']"
						i18n>Hormigueros Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/humacao/venta']"
						i18n>Humacao Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/isabela/venta']"
						i18n>Isabela Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/jayuya/venta']" i18n>Jayuya
						Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/juana-diaz/venta']"
						i18n>Juana Díaz Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/juncos/venta']" i18n>Juncos
						Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/lajas/venta']" i18n>Lajas
						Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/lares/venta']" i18n>Lares
						Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/las-marias/venta']"
						i18n>Las Marias Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/las-piedras/venta']"
						i18n>Las Piedras Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/loiza/venta']" i18n>Loíza
						Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/luquillo/venta']"
						i18n>Luquillo Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/manati/venta']" i18n>Manatí
						Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/maricao/venta']"
						i18n>Maricao Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/maunabo/venta']"
						i18n>Maunabo Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/mayaguez/venta']"
						i18n>Mayagüez Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/moca/venta']" i18n>Moca
						Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/morovis/venta']"
						i18n>Morovis Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/naguabo/venta']"
						i18n>Naguabo Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/naranjito/venta']"
						i18n>Naranjito Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/orocovis/venta']"
						i18n>Orocovis Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/patillas/venta']"
						i18n>Patillas Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/penuelas/venta']"
						i18n>Peñuelas Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/ponce/venta']" i18n>Ponce
						Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/quebradillas/venta']"
						i18n>Quebradillas Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/rincon/venta']" i18n>Rincón
						Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/rio-grande/venta']"
						i18n>Rio Grande Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/sabana-grande/venta']"
						i18n>Sabana Grande Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/salinas/venta']"
						i18n>Salinas Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/san-german/venta']"
						i18n>San Germán Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/san-juan/venta']" i18n>San
						Juan Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/san-lorenzo/venta']"
						i18n>San Lorenzo Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/san-sebastian/venta']"
						i18n>San Sebastian Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/santa-isabel/venta']"
						i18n>Santa Isabel Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/toa-alta/venta']" i18n>Toa
						Alta Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/toa-baja/venta']" i18n>Toa
						Baja Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/trujillo-alto/venta']"
						i18n>Trujillo Alto Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/utuado/venta']" i18n>Utuado
						Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/vega-alta/venta']"
						i18n>Vega Alta Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/vega-baja/venta']"
						i18n>Vega Baja Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/vieques/venta']"
						i18n>Vieques Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/villalba/venta']"
						i18n>Villalba Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/yabucoa/venta']"
						i18n>Yabucoa Bienes Raíces</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showVenta }" [routerLink]="['/yauco/venta']" i18n>Yauco
						Bienes Raíces</a>
				</div>
				<h4 class="ver-mas" (click)="showNHideVenta()">
					<ng-container *ngIf="!showVenta; else verMenosTemplate" i18n>Ver más</ng-container>
					<i-feather name="{{ showVenta ? 'chevron-up' : 'chevron-down' }}"></i-feather>
				</h4>
			</div>

			<div class="alquiler-block">
				<h4 class="text-s" i18n>Alquiler</h4>
				<div class="div-links">
					<a class="clean-a-tag" [routerLink]="['/adjuntas/apartamentos-en-alquiler']" i18n>Apartamentos en
						alquiler en Adjuntas</a>
					<a class="clean-a-tag" [routerLink]="['/aguada/apartamentos-en-alquiler']" i18n>Apartamentos en
						alquiler en Aguada</a>
					<a class="clean-a-tag" [routerLink]="['/aguadilla/apartamentos-en-alquiler']" i18n>Apartamentos en
						alquiler en Aguadilla</a>
					<a class="clean-a-tag" [routerLink]="['/aguas-buenas/apartamentos-en-alquiler']" i18n>Apartamentos
						en alquiler en Aguas Buenas</a>
					<a class="clean-a-tag" [routerLink]="['/aibonito/apartamentos-en-alquiler']" i18n>Apartamentos en
						alquiler en Aibonito</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/arecibo/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Arecibo</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/arroyo/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Arroyo</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/anasco/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Añasco</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/barceloneta/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Barceloneta</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/barranquitas/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Barranquitas</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/bayamon/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Bayamon</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/cabo-rojo/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Cabo
						Rojo</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/caguas/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Caguas</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/canovanas/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Canovanas</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/carolina/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Carolina</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/catano/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Cataño</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/camuy/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Camuy</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/cayey/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Cayey</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/ceiba/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Ceiba</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/ciales/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Ciales</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/cidra/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Cidra</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/coamo/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Coamo</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/comerio/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Comerío</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/corozal/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Corozal</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/culebra/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Culebra</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/dorado/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Dorado</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/fajardo/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Fajardo</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/florida/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Florida</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/guayama/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Guayama</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/guayanilla/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Guayanilla</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/guaynabo/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Guaynabo</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/gurabo/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Gurabo</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/guanica/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Guanica</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/hatillo/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Hatillo</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/hormigueros/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Hormigueros</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/humacao/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Humacao</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/isabela/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Isabela</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/jayuya/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Jayuya</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/juana-diaz/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Juana
						Díaz</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/juncos/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Juncos</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/lajas/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Lajas</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/lares/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Lares</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/las-marias/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Las
						Marias</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/las-piedras/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Las
						Piedras</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/loiza/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Loíza</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/luquillo/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Luquillo</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/manati/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Manatí</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/maricao/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Maricao</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/maunabo/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Maunabo</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/mayaguez/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Mayagüez</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/moca/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Moca</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/morovis/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Morovis</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/naguabo/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Naguabo</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/naranjito/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Naranjito</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/orocovis/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Orocovis</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/patillas/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Patillas</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/penuelas/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Peñuelas</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/ponce/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Ponce</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/quebradillas/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Quebradillas</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/rincon/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Rincón</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/rio-grande/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Rio
						Grande</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/sabana-grande/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Sabana Grande</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/salinas/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Salinas</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/san-german/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en San
						Germán</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/san-juan/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en San
						Juan</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/san-lorenzo/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en San
						Lorenzo</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/san-sebastian/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en San
						Sebastian</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/santa-isabel/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Santa
						Isabel</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/toa-alta/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Toa
						Alta</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/toa-baja/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Toa
						Baja</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/trujillo-alto/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Trujillo Alto</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/utuado/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Utuado</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/vega-alta/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Vega
						Alta</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/vega-baja/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Vega
						Baja</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/vieques/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Vieques</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/villalba/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Villalba</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/yabucoa/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en
						Yabucoa</a>
					<a class="clean-a-tag" [ngClass]="{ hide: !showAlquiler }"
						[routerLink]="['/yauco/apartamentos-en-alquiler']" i18n>Apartamentos en alquiler en Yauco</a>
				</div>
				<h4 class="ver-mas" (click)="showNHideAlquiler()">
					<ng-container *ngIf="!showAlquiler; else verMenosTemplate" i18n>Ver más</ng-container>
					<i-feather name="{{ showAlquiler ? 'chevron-up' : 'chevron-down' }}"></i-feather>
				</h4>
			</div>

			<div class="compania-block">
				<h6 class="text-s" i18n>Compañía</h6>
				<a class="clean-a-tag a-tag" [routerLink]="'/contactanos'" i18n>Contáctanos</a>
				<a class="clean-a-tag a-tag" [routerLink]="'/terminos-condiciones'" i18n>Términos de Uso</a>
				<a class="clean-a-tag a-tag" [routerLink]="'/politicas-privacidad'" i18n>Política de Privacidad</a>
				<a class="clean-a-tag a-tag" [routerLink]="'/mapa-de-sitio'" i18n>Mapa del Sitio</a>
			</div>

			<div class="explora-block">
				<h6 class="text-s" i18n>Explora</h6>
				<a class="clean-a-tag a-tag" [routerLink]="'/blog'" i18n>Findit Blog</a>
				<a class="clean-a-tag a-tag" [routerLink]="'/acerca'" i18n>Acerca de Nosotros</a>
			</div>
		</div>
		<hr class="hr-dark" />
	</div>
	<div class="contenedor">
		<div class="sub-contenedor">
			<div class="second-group">
				<button mat-button class="link-button" [disableRipple]="true">
					<a class="link clean-a-tag" href="https://www.facebook.com/finditpuertorico/">
						<mat-icon svgIcon="f-logo"></mat-icon>
					</a>
				</button>
				<button mat-button class="link-button" [disableRipple]="true">
					<a class="link clean-a-tag" href="https://www.instagram.com/findit.pr/">
						<mat-icon svgIcon="ig-logo"></mat-icon>
					</a>
				</button>
				<button mat-button class="link-button" [disableRipple]="true">
					<a class="link clean-a-tag" href="https://twitter.com/findit_pr">
						<mat-icon svgIcon="tw-logo"></mat-icon>
					</a>
				</button>
			</div>
			<div class="third-group">
				<h6 class="copy">
					&#169; 2023 Bluepath Group LLC.
					<a class="link clean-a-tag" href="https://www.hud.gov/program_offices/fair_housing_equal_opp"
						target="_">Equal Housing Opportunity</a>
				</h6>
			</div>
			<div class="fourth-group">
				<h6 class="cat" i18n>Hecho con 💙 en Puerto Rico</h6>
			</div>
		</div>
	</div>
</footer>

<ng-template #verMenosTemplate>
	<ng-container i18n>Ver menos</ng-container>
</ng-template>