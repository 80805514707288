<div class="heading-s title-container">{{ data.editTitle }}</div>
<div class="content-container">
	<ng-container [ngSwitch]="data.edit">
		<ng-container *ngSwitchCase="'about'" [ngTemplateOutlet]="editAbout"></ng-container>
		<ng-container *ngSwitchCase="'address'" [ngTemplateOutlet]="editAddress"></ng-container>
		<ng-container *ngSwitchCase="'website'" [ngTemplateOutlet]="editWebsite"></ng-container>
		<ng-container *ngSwitchCase="'time'" [ngTemplateOutlet]="editTime"></ng-container>
		<ng-container *ngSwitchCase="'phone'" [ngTemplateOutlet]="editPhone"></ng-container>
		<ng-container *ngSwitchCase="'qrcode'" [ngTemplateOutlet]="editQRCode"></ng-container>
		<ng-container *ngSwitchCase="'share'" [ngTemplateOutlet]="shareDialog"></ng-container>
		<ng-container *ngSwitchCase="'contact'" [ngTemplateOutlet]="contactDialog"></ng-container>
	</ng-container>

	<ng-container *ngIf="data.edit !== 'phone' && data.edit !== 'share'; else okTemp">
		<div class="btn-container" *ngIf="!requestInProgress; else loadingTemp">
			<div>
				<button
					mat-button
					(click)="dialogRef.close()"
					style="color: var(--grayscale-4) !important; margin-right: 8px"
				>
					<ng-container i18n> Cancelar </ng-container>
				</button>
				<button
					mat-button
					(click)="onClickSave()"
					style="background-color: var(--primary-color-1); color: white"
				>
					{data.edit, select, qrcode {Descargar} other {Guardar}}
				</button>
			</div>
		</div>
	</ng-container>
</div>

<ng-template #okTemp>
	<div class="btn-container">
		<button mat-button (click)="dialogRef.close()" style="background-color: var(--primary-color-1); color: white">
			<ng-container i18n> Listo </ng-container>
		</button>
	</div>
</ng-template>

<ng-template #loadingTemp>
	<div class="dummy-div"></div>
	<div class="loading-container">
		<mat-progress-bar mode="indeterminate"></mat-progress-bar>
	</div>
</ng-template>

<ng-template #shareDialog>
	<div class="share-grid">
		<a
			class="link text-m"
			style="cursor: pointer"
			[cdkCopyToClipboard]="'https://www.finditpr.com' + data.agentInfo.slug"
			(click)="openSnackBar()"
		>
			<mat-icon style="vertical-align: bottom; margin-right: 6px" svgIcon="copy-logo"></mat-icon>
			<ng-container i18n> Copia el enlace </ng-container>
		</a>
		<a
			class="link text-m"
			href="https://wa.me/?text={{ 'https://www.finditpr.com' + data.agentInfo.slug }}"
			data-action="share/whatsapp/share"
			target="_blank"
		>
			<mat-icon style="vertical-align: bottom; margin-right: 6px" svgIcon="wa-logo"></mat-icon>
			Whatsapp
		</a>
		<a
			class="link text-m"
			href="https://www.facebook.com/dialog/share?app_id=2267378210234221&display=popup&href={{
				'https://www.finditpr.com' + data.agentInfo.slug
			}}&redirect_uri=https://finditpr.com/mi-perfil/mis-listados"
			target="_blank"
		>
			<mat-icon style="vertical-align: bottom; margin-right: 6px" svgIcon="f-logo"></mat-icon>
			Facebook
		</a>
		<a
			class="link text-m"
			href="http://twitter.com/share?&url={{
				'https://www.finditpr.com' + data.agentInfo.slug
			}}&hashtags=finditpr"
			target="_blank"
		>
			<mat-icon style="vertical-align: bottom; margin-right: 6px" svgIcon="tw-logo"></mat-icon>
			Twitter
		</a>
	</div>
</ng-template>

<ng-template #contactDialog> </ng-template>

<ng-template #editPhone>
	<div class="text-m" i18n>El número telefonico se edita en las configuraciones de su cuenta.</div>
</ng-template>

<ng-template #editAbout>
	<textarea rows="8" [formControl]="aboutForm" cols="50" maxlength="2000"></textarea>
	<div class="text-s" style="color: var(--grayscale-5) !important">
		{{ aboutForm.value.length }}/2000 <ng-container i18n> caracteres </ng-container>
	</div>
</ng-template>

<ng-template #editAddress>
	<label for="address" class="text-s-sb" i18n>Dirección de la empresa</label>
	<input type="text" [formControl]="addressForm" name="address" id="address" maxlength="250" />
	<div class="text-s" style="color: var(--grayscale-5) !important">
		{{ addressForm.value.length }}/250 <ng-container i18n> caracteres </ng-container>
	</div>
</ng-template>

<ng-template #editWebsite>
	<label for="website" class="text-s-sb">Website</label>
	<input type="text" [formControl]="websiteForm" name="website" id="website" />
	<!-- <mat-radio-button [value]="true" style="margin-top: 12px;">
        <span class="text-s">Mi empresa no tiene un website</span>
    </mat-radio-button> -->
</ng-template>

<ng-template #editTime>
	<form [formGroup]="timeForm">
		<label for="opening-time" class="text-s-sb" i18n>Horario de apertura</label>
		<input
			formControlName="opening"
			type="time"
			id="opening-time"
			name="opening-time"
			style="margin-bottom: 12px; min-width: 75%"
		/>

		<label for="closing-time" class="text-s-sb" i18n>Horario de cierre</label>
		<input formControlName="closing" type="time" id="closing-time" name="closing-time" style="min-width: 75%" />
	</form>
</ng-template>

<ng-template #editQRCode>
	<div class="options-container">
		<div style="display: block">
			<form [formGroup]="qrForm">
				<div>
					<mat-radio-group class="text-s radio-container" formControlName="custom_type">
						<mat-radio-button id="on-rad" [value]="true">
							<ng-container i18n> Personalizado </ng-container>
						</mat-radio-button>
						<mat-radio-button id="off-rad" [value]="false">
							<ng-container i18n> Sencillo </ng-container>
						</mat-radio-button>
					</mat-radio-group>
				</div>
				<div [ngStyle]="{ display: isCustomQRCode ? 'unset' : 'none' }">
					<div>
						<label for="primary_color" class="text-s-sb" i18n>Color Primario</label>
						<button
							mat-stroked-button
							style="width: 100%; margin-bottom: 8px"
							[ngStyle]="{ color: default_primary_color.hex }"
							(click)="openColorPicker(true)"
							color="primary"
						>
							{{ default_primary_color.hex?.toUpperCase() }}
						</button>
					</div>
					<div>
						<label for="secondary_color" class="text-s-sb" i18n>Color Secundario</label>
						<button
							mat-stroked-button
							style="width: 100%; margin-bottom: 24px"
							[ngStyle]="{ color: default_secondary_color.hex }"
							(click)="openColorPicker(false)"
							color="primary"
						>
							{{ default_secondary_color.hex?.toUpperCase() }}
						</button>
					</div>
					<input
						type="file"
						name="qr_logo"
						(change)="onFileSelected($event)"
						accept="image/*"
						id="qr_logo"
						#qrLogo
						hidden
					/>
					<button mat-stroked-button class="subir-logo-btn" (click)="qrLogo.click()" color="primary">
						<i-feather name="upload" class="upload-icon"> </i-feather>
						<ng-container i18n> Subir logo </ng-container>
					</button>
				</div>
			</form>
		</div>
	</div>

	<div id="QRCode" style="margin-top: 24px; margin-bottom: 40px" class="qr-code-container"></div>
</ng-template>
