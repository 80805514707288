// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	api: 'http://localhost:3000',
	stripe_public_key:
		'pk_test_51L0qRaKWO6oDcHrClcci86StRgO4HZUWEmbnPkjQGF1JSkLnsZMHzLLcAkaCMQktJ6lN4NnjzlApQsXtBewnRkGF00p3wagVbq',
	baseHref: '/',
	recaptcha: {
		siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
