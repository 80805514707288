import { ChangeDetectorRef, EventEmitter, Injectable, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SigninPopUpComponent } from '../auth/signin-pop-up/signin-pop-up.component';
import { ListingCard } from '../interfaces/ListingCardInterface';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';
@Injectable()
export abstract class ListingCardAbstract {
	loading: boolean = false;
	listing: ListingCard = null;
	@Input() showHeart: boolean = true;
	@Output() disliked = new EventEmitter<number>();

	_cdr: ChangeDetectorRef = null;
	_userService: UserService;
	_authService: AuthenticationService;

	// Dialog Ref
	_dialog: MatDialog = null;

	onLikeAction(): void {
		if (this._authService.isAuthenticated()) {
			if (this.listing.isListingSaved === 1 || this.listing.isListingSaved === '1') {
				this.onClickDisLike();
			} else {
				this.onClickLike();
			}
		} else {
			this.logInFirst();
		}
	}

	logInFirst(): void {
		const ref = this._dialog.open(SigninPopUpComponent, { maxWidth: '375px' });

		ref.afterClosed().subscribe((data) => {
			console.log(data);
			if (data !== undefined && data['refresh']) {
				this._cdr.detectChanges();
				//Temp fix, reload page
				location.reload();
			}
		});
	}

	private onClickLike(): void {
		this.listing.isListingSaved = 1;
		this._userService.addFavoriteListing(this.listing.listing_id).subscribe();
	}

	private onClickDisLike() {
		this.loading = true;
		this.listing.isListingSaved = 0;
		this._userService.removeFavoriteListing(this.listing.listing_id).subscribe(
			() => {
				this.disliked.emit(this.listing.listing_id);
				this.loading = false;
			},
			(err) => {
				this.loading = false;
			}
		);
	}
}
