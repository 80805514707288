import { Component, ViewChild, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { BdcWalkService } from 'bdc-walkthrough';
import { NgOneTapService } from 'ng-google-one-tap';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';

@Component({
	selector: 'app-main-nav',
	templateUrl: './main-nav.component.html',
	styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent implements OnInit {
	showWindow: boolean = false;
	responsive$: boolean = false;
	@ViewChild(MatSidenav, { static: false })
	sidenav: MatSidenav;
	redirectURI = null;

	constructor(
		@Inject(LOCALE_ID) public locale: string,
		public auth: AuthenticationService,
		public readonly router: Router,
		private route: ActivatedRoute,
		private socialAuthService: SocialAuthService,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		private readonly bdcWalkService: BdcWalkService,
		private onetap: NgOneTapService
	) {
		this.matIconRegistry.addSvgIcon(
			'findit-logo',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/findit.svg')
		);
	}
	ngOnInit(): void {
		// Check out for the email in the parameters and fill the email input with it.
		this.route.queryParams.subscribe((params) => {
			if (params.redirectURI != null) {
				this.redirectURI = params.redirectURI;
			}
		});
		if (!this.auth.isAuthenticated()) {
			this.onetap.tapInitialize();
			this.onetap.oneTapCredentialResponse.subscribe((res) => {
				// After continue with one tap JWT credentials response.
				this.auth.signInGoogle(res.credential).subscribe(() => {
					if (this.redirectURI == null) {
						location.reload();
					} else {
						if (!this.auth.getUserDetails().phone && this.redirectURI.includes('publica')) {
							this.redirectURI = 'publica';
						}
						this.router.navigateByUrl(this.redirectURI);
						this.redirectURI = null;
					}
				});
			});
		}

		this.socialAuthService.authState.subscribe((user: SocialUser) => {
			if (user) {
				this.getFinditSession(user);
			}
		});
	}

	private getFinditSession(payload: SocialUser) {
		if (payload.provider === GoogleLoginProvider.PROVIDER_ID) {
			const googleToken = payload.idToken;
			this.auth.signInGoogle(googleToken).subscribe(
				(res) => {
					if (this.redirectURI == null) {
						location.reload();
					} else {
						if (!this.auth.getUserDetails().phone && this.redirectURI.includes('publica')) {
							this.redirectURI = 'publica';
						}
						this.router.navigateByUrl(this.redirectURI);
					}
				},
				(err) => {
					console.log(err);
				}
			);
		}
		if (payload.provider === FacebookLoginProvider.PROVIDER_ID) {
			// this.auth.signInFacebook(payload.authToken).subscribe(
			// 	(res) => {
			// 		if (this.redirectURI == null) {
			// 			location.reload();
			// 		} else {
			// 			if (!this.auth.getUserDetails().phone && this.redirectURI.includes('publica')) {
			// 				this.redirectURI = 'publica';
			// 			}
			// 			this.router.navigateByUrl(this.redirectURI);
			// 		}
			// 	},
			// 	(err) => {
			// 		console.log(err);
			// 	}
			// );
		}
	}

	/**
	 * Make a HTTP request to the API to delete the refreshToken.
	 */
	doLogoutDesktop() {
		this.showWindow = false;
		this.auth.doLogout().subscribe();
	}

	isHidden() {
		return (
			this.router.url.includes('registrate') ||
			this.router.url.includes('iniciar-sesion') ||
			this.router.url.includes('recuperar-contrasena') ||
			this.router.url.includes('restablecer-contrasena')
		);
	}

	getShortName(): string {
		if (this.auth.isAuthenticated()) {
			const usr = this.auth.getUserDetails();
			const result = `${usr.first_name.charAt(0).toLocaleUpperCase()}${usr.last_name
				.charAt(0)
				.toLocaleUpperCase()}`;
			return result;
		} else {
			return 'N/A';
		}
	}

	onClickAgentProfile(): void {
		this.bdcWalkService.setTasks({
			activateProfileStep2: true
		});
	}

	onClickProfile(): void {
		if (this.auth.isAuthenticated()) {
			this.showWindow = !this.showWindow;
		}
	}
}
