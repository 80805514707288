<div class="contenedor" *ngIf="neighborObs | async as listings">
    <div class="child-contenedor">
        <h1 class="heading-s">
            <ng-container i18n>
                Propiedades en {isVenta, select, true {venta} other {alquiler}} en
            </ng-container>
            {{neighborhood}}
        </h1>
        <hr>
        <div class="propiedades-grid">
            <a *ngFor="let listing of listings" [routerLink]="[listing.detailURL]">{{listing.address}}</a>
            <h3 *ngIf="listings.length === 0" i18n>No hay propiedades en esta area por el momento.</h3>
        </div>
    </div>
</div>