import { Component, ElementRef, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ListingPopupService } from '../../services/listing-pop-up.service';
import { Params } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'app-nearby-dialog-box',
	templateUrl: './nearby-dialog-box.component.html',
	styleUrls: ['./nearby-dialog-box.component.css']
})
export class NearbyDialogBoxComponent implements OnInit, AfterViewInit {
	elements = [];
	tourError = false;

	//Google maps
	@ViewChild('mapContainerNearbyPopUp', { static: false })
	gmap: ElementRef;
	googleMap: google.maps.Map;
	markersToRemove = [];
	hayStreetView = true;
	hideStreetView = true;
	activeInfoWindow;
	trustedURL;
	hideTour = true;
	number;
	//Yelp
	lugar = 'Restaurantes';
	hideMenu = true;
	icon = 'keyboard_arrow_down';

	verUbicacion: boolean = false;

	requestInProgress: boolean = false;

	// subcriptionYelp: Subscription
	@ViewChild('buttonsContainer', { static: false, read: ElementRef })
	public widgetsContent: ElementRef<any>;

	constructor(
		public dialogRef: MatDialogRef<NearbyDialogBoxComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private http: HttpClient,
		private placePopUp: ListingPopupService,
		private sanitizer: DomSanitizer
	) {
		//If domain match matter port and doesn't have query param of play add it
		const URI = this.addPlayQueryOnMatterPort(data.listing.virtual_tour);
		this.trustedURL = sanitizer.bypassSecurityTrustResourceUrl(URI);
	}
	ngAfterViewInit(): void {
		// Initialize Map
		this.initializeMap();
		setTimeout(() => {
			this.tourError = true;
		}, 5000);
	}

	private addPlayQueryOnMatterPort(str: string) {
		const URLTOMATCH = new RegExp(/matterport\.com\//);
		//If is a matterport link enter
		if (str && str.match(URLTOMATCH)) {
			//If it doesn't have play enter
			if (str.indexOf('play=1') === -1) {
				//If it doesn't have queryParams add it
				if (str.indexOf('?') === -1) {
					return str + '?play=1';
				} else {
					return str + '&play=1';
				}
			}
		}

		return str;
	}

	ngOnInit() {
		if (this.data.number === 1 || this.data.number === 2 || this.data.number === 3 || this.data.number === 4) {
			this.onSelect(this.data.number);
		}
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	initializeMap(): void {
		// Disable restaurants etc
		const noPoi = [
			{
				featureType: 'poi',
				stylers: [{ visibility: 'on' }]
			}
		];

		const latitude = this.data.listing.latitude as any;
		const longitude = this.data.listing.longitude as any;
		let coordinates = new google.maps.LatLng(latitude, longitude);

		const mapOptions: google.maps.MapOptions = {
			center: coordinates,
			zoom: 18,
			streetViewControl: false,
			fullscreenControl: false,
			styles: noPoi as any,
			clickableIcons: false,
			zoomControl: true,
			zoomControlOptions: { position: google.maps.ControlPosition.TOP_LEFT },
			gestureHandling: 'greedy',
			mapTypeId: google.maps.MapTypeId.HYBRID
		};

		this.isStreetView();
		this.googleMap = new google.maps.Map(this.gmap.nativeElement, mapOptions);

		const parcel_json = this.data.parcel_json;
		if (parcel_json) {
			const latitude = parcel_json.features[0]['properties']['lat'];
			const longitude = parcel_json.features[0]['properties']['lng'];
			coordinates = new google.maps.LatLng(latitude, longitude);

			this.googleMap.setCenter(coordinates);
			this.googleMap.data.addGeoJson(parcel_json);
			this.googleMap.data.setStyle({
				fillColor: 'white',
				strokeColor: '#3C98F1',
				strokeOpacity: 1,
				strokeWeight: 2.5,
				fillOpacity: 0.3
			});
		}

		const marcador = new google.maps.Marker({
			position: coordinates,
			draggable: false,
			map: this.googleMap
		});

		marcador.setMap(this.googleMap);
	}

	getNearby(term: string) {
		let cat = '';
		switch (term) {
			case 'Restaurantes':
				term = 'Food';
				// cat = "food";
				break;
			case 'Vida Nocturna':
				term = 'NightLife';
				// cat = "nightlife"
				break;
			// case 'Farmacias':
			//   term = 'Drugstores';
			//   cat = "pharmacy"
			//   break;
			case 'Fitness':
				term = 'Fitness';
				break;
			case 'Compras':
				term = 'Shopping';
				cat = 'shopping';
				break;
		}

		this.requestInProgress = true;
		const queryParams: Params = {
			latitude: this.data.listing.latitude,
			longitude: this.data.listing.longitude,
			term: term,
			cat: cat
		};
		this.http
			.get('https://tpqb0p4m1b.execute-api.us-east-1.amazonaws.com/production/', { params: queryParams })
			.subscribe((response: any) => {
				response.forEach((element) => {
					const coordenadas = new google.maps.LatLng(
						element.coordinates.latitude,
						element.coordinates.longitude
					);

					let color = '#273655';

					const marcador = new google.maps.Marker({
						position: coordenadas,
						icon: {
							path: google.maps.SymbolPath.CIRCLE,
							scale: 7.5,
							strokeColor: '#fff',
							strokeOpacity: 1,
							strokeWeight: 2.5,
							fillColor: color,
							fillOpacity: 1
						},
						draggable: false,
						map: this.googleMap
					});

					let rating = element.rating.toString();
					rating = rating.toString().replace('.5', '_5');
					element.rating = rating;
					const infowindow = new google.maps.InfoWindow({
						content: this.placePopUp.makeYelpPopUp(element, rating)
					});
					this.elements = response;

					//For touch devices
					marcador.addListener('click', () => {
						this.activeInfoWindow && this.activeInfoWindow.close();
						infowindow.open(this.googleMap, marcador);
						this.activeInfoWindow = infowindow;
					});

					marcador.addListener('mouseover', () => {
						infowindow.open(this.googleMap, marcador);
					});

					marcador.addListener('mouseout', (e) => {
						infowindow.close();
					});

					marcador.setMap(this.googleMap);
					this.markersToRemove.push(marcador);
				});
				this.requestInProgress = false;
			});
	}

	onClickYelp(string: string) {
		this.lugar = string;
		this.removeMarkers();
		this.getNearby(string);
	}

	hideYelpMenu() {
		this.hideMenu = !this.hideMenu;

		if (!this.hideMenu) {
			this.icon = 'keyboard_arrow_up';
		} else {
			this.icon = 'keyboard_arrow_down';
		}
	}

	isStreetView() {
		const streetViewService = new google.maps.StreetViewService();
		const STREETVIEW_MAX_DISTANCE = 100;
		const latLng = new google.maps.LatLng(this.data.listing.latitude, this.data.listing.longitude);
		streetViewService.getPanoramaByLocation(latLng, STREETVIEW_MAX_DISTANCE, (streetViewPanoramaData, status) => {
			if (status === google.maps.StreetViewStatus.OK) {
				this.hayStreetView = true;
			} else {
				this.hayStreetView = false;
			}
		});
	}

	onSelect(number: number) {
		this.number = number;
		// Encuentra cerca
		if (number === 1 || number === 3) {
			this.hideStreetView = true;
			this.hideTour = true;

			if (number === 3) {
				this.verUbicacion = true;
				//remove markers
				this.removeMarkers();
			} else {
				this.verUbicacion = false;
				this.getNearby('Restaurantes');
			}
		} else if (number === 4) {
			this.hideTour = false;

			this.hideStreetView = true;
		} else {
			this.hideStreetView = false;

			this.hideTour = true;
		}
	}

	private removeMarkers(): void {
		this.markersToRemove.forEach((marker: google.maps.Marker) => {
			marker.setMap(null);
		});
		this.markersToRemove = [];
	}

	// ngOnDestroy(): void {
	//   this.subcriptionYelp.unsubscribe();
	// }

	scrollRight() {
		this.widgetsContent.nativeElement.scrollTo({
			left: this.widgetsContent.nativeElement.scrollLeft + 150,
			behavior: 'smooth'
		});
	}
	scrollLeft() {
		this.widgetsContent.nativeElement.scrollTo({
			left: this.widgetsContent.nativeElement.scrollLeft - 150,
			behavior: 'smooth'
		});
	}

	myLoadEvent(event): void {
		// console.log(event, '<= event');
	}
}
