import { NgModule } from '@angular/core';
import { HomePageComponent } from './home-page/home-page.component';
import { SelectedPropertyComponent } from './selected-property/selected-property.component';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { SitemapCityComponent } from './site-map-main/site-map/sitemap-city/sitemap-city.component';
import { SiteMapComponent } from './site-map-main/site-map/site-map.component';
import { SiteMapMainComponent } from './site-map-main/site-map-main.component';
import { SiteMapBarriosComponent } from './site-map-main/site-map/sitemap-city/site-map-barrios/site-map-barrios.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SigninSignupGuard } from './services/signup-signin.guard';
import { AgentProfileComponent } from './agent-profile/agent-profile.component';
import { RepoPropertyComponent } from './repo-property/repo-property.component';
import { ServiceProvidersDirectoryComponent } from './service-providers-directory/service-providers-directory.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { HostMoreInfoDialogComponent } from './service-providers-directory/more-info-dialog/more-info-dialog.component';
import { PrequalificationComponent } from './prequalification/prequalification.component';

//Create the routes for the components in here
//First you need to use the <router-outlet></router-outlet> in the HTML
const appRoutes: Routes = [
	{
		path: 'checkout',
		loadChildren: () => import('./Lazy-Loading-Modules/checkout.module').then((m) => m.CheckoutModule)
	},
	{
		path: 'servicios',
		component: ServiceProvidersDirectoryComponent,
		children: [
			{
				path: ':slug/:id',
				component: HostMoreInfoDialogComponent
			}
		]
	},
	/**
	 *  START PROFILES ROUTES
	 */
	{
		path: 'agente/mls/:user/:mlsID',
		component: AgentProfileComponent
	},
	{
		path: 'agente/:user/:id',
		component: AgentProfileComponent
	},
	{
		path: 'vendedor/:user/:id',
		component: AgentProfileComponent
	},
	{
		path: 'empresa/:user/:id',
		component: AgentProfileComponent
	},
	/**
	 *  END PROFILES ROUTES
	 */

	{
		path: 'propiedad/:address/:id',
		component: SelectedPropertyComponent
	},
	{
		path: 'propiedad-reposeida/:address/:id',
		component: RepoPropertyComponent
	},
	{
		path: 'mapa-de-sitio',
		component: SiteMapMainComponent,
		children: [
			{
				path: '',
				component: SiteMapComponent
			},
			{
				path: ':city',
				component: SitemapCityComponent
			},
			{
				path: ':city/:barrio',
				component: SiteMapBarriosComponent
			}
		]
	},
	{
		path: 'propiedad/:id',
		component: SelectedPropertyComponent
	},
	{
		path: '',
		component: HomePageComponent,
		pathMatch: 'full'
	},
	{
		path: 'publica',
		loadChildren: () => import('./publicar-main/publicar.module').then((m) => m.PublicarModule)
	},
	{
		path: 'blog',
		loadChildren: () => import('./blog-main/blog.module').then((m) => m.BlogModule)
	},
	{
		path: 'contactanos',
		loadChildren: () => import('./Lazy-Loading-Modules/contact-us.module').then((m) => m.ContactUsModule)
	},
	{
		path: 'acerca',
		loadChildren: () => import('./Lazy-Loading-Modules/acerca.module').then((m) => m.AcercaModule)
	},
	{
		path: 'politicas-privacidad',
		loadChildren: () =>
			import('./Lazy-Loading-Modules/politicas-privacidad.module').then((m) => m.PoliticasPrivacidadModule)
	},
	{
		path: 'terminos-condiciones',
		loadChildren: () =>
			import('./Lazy-Loading-Modules/user-terms-conditions.module').then((m) => m.UserTermsConditionsModule)
	},
	// {
	// 	path: 'directorio-agentes',
	// 	loadChildren: () =>
	// 		import('./Lazy-Loading-Modules/directorio-agentes.module').then((m) => m.DirectorioAgentesModule)
	// },
	{
		path: 'perfil-profesional',
		loadChildren: () =>
			import('./Lazy-Loading-Modules/perfil-pro-onboarding.module').then((m) => m.PerfilProOnBoardingModule)
	},

	/* RUTAS LISTADOS START */

	{
		path: ':parametro/locales-comerciales-en-venta',
		loadChildren: () => import('./Lazy-Loading-Modules/listings-module').then((m) => m.ListingsModule)
	},
	{
		path: ':parametro/locales-comerciales-en-alquiler',
		loadChildren: () => import('./Lazy-Loading-Modules/listings-module').then((m) => m.ListingsModule)
	},

	{
		path: ':parametro/terrenos-en-venta',
		loadChildren: () => import('./Lazy-Loading-Modules/listings-module').then((m) => m.ListingsModule)
	},
	{
		path: ':parametro/terrenos-en-alquiler',
		loadChildren: () => import('./Lazy-Loading-Modules/listings-module').then((m) => m.ListingsModule)
	},

	{
		path: ':parametro/apartamentos-en-alquiler',
		loadChildren: () => import('./Lazy-Loading-Modules/listings-module').then((m) => m.ListingsModule)
	},
	{
		path: ':parametro/apartamentos-en-venta',
		loadChildren: () => import('./Lazy-Loading-Modules/listings-module').then((m) => m.ListingsModule)
	},

	{
		path: ':parametro/casas-en-venta',
		loadChildren: () => import('./Lazy-Loading-Modules/listings-module').then((m) => m.ListingsModule)
	},
	{
		path: ':parametro/casas-en-alquiler',
		loadChildren: () => import('./Lazy-Loading-Modules/listings-module').then((m) => m.ListingsModule)
	},

	{
		path: ':parametro/oficinas-en-venta',
		loadChildren: () => import('./Lazy-Loading-Modules/listings-module').then((m) => m.ListingsModule)
	},
	{
		path: ':parametro/oficinas-en-alquiler',
		loadChildren: () => import('./Lazy-Loading-Modules/listings-module').then((m) => m.ListingsModule)
	},

	{
		path: ':parametro/edificios-en-venta',
		loadChildren: () => import('./Lazy-Loading-Modules/listings-module').then((m) => m.ListingsModule)
	},
	{
		path: ':parametro/edificios-en-alquiler',
		loadChildren: () => import('./Lazy-Loading-Modules/listings-module').then((m) => m.ListingsModule)
	},

	{
		path: ':parametro/almacenes-en-venta',
		loadChildren: () => import('./Lazy-Loading-Modules/listings-module').then((m) => m.ListingsModule)
	},
	{
		path: ':parametro/almacenes-en-alquiler',
		loadChildren: () => import('./Lazy-Loading-Modules/listings-module').then((m) => m.ListingsModule)
	},

	{
		path: ':parametro/habitaciones-en-alquiler',
		loadChildren: () => import('./Lazy-Loading-Modules/listings-module').then((m) => m.ListingsModule)
	},
	{
		path: ':parametro/estudios-en-alquiler',
		loadChildren: () => import('./Lazy-Loading-Modules/listings-module').then((m) => m.ListingsModule)
	},

	{
		path: ':parametro/alquiler',
		loadChildren: () => import('./Lazy-Loading-Modules/listings-module').then((m) => m.ListingsModule)
	},
	{
		path: ':parametro/venta',
		loadChildren: () => import('./Lazy-Loading-Modules/listings-module').then((m) => m.ListingsModule)
	},
	{
		path: ':parametro/vendidas',
		loadChildren: () => import('./Lazy-Loading-Modules/listings-module').then((m) => m.ListingsModule)
	},

	/* RUTAS LISTADOS END */

	{
		path: 'registrate',
		canActivate: [SigninSignupGuard],
		loadChildren: () => import('./Lazy-Loading-Modules/sign-up-module').then((m) => m.SignUpModule)
	},
	{
		path: 'iniciar-sesion',
		canActivate: [SigninSignupGuard],
		loadChildren: () => import('./Lazy-Loading-Modules/sign-in-module').then((m) => m.SignInModule)
	},
	{
		path: 'mi-perfil',
		loadChildren: () => import('./Lazy-Loading-Modules/user-panel-module').then((m) => m.UserPanelModule)
	},
	{
		path: 'recuperar-email',
		loadChildren: () => import('./Lazy-Loading-Modules/email-recovery-module').then((m) => m.EmailRecoveryModule)
	},
	{
		path: 'recuperar-contrasena',
		loadChildren: () => import('./Lazy-Loading-Modules/password-reset-module').then((m) => m.PasswordResetModule)
	},
	{
		path: 'restablecer-contrasena',
		loadChildren: () =>
			import('./Lazy-Loading-Modules/password-reset-token-module').then((m) => m.PasswordResetTokenModule)
	},
	// {
	//   path: 'verify-account/:secretToken',
	//   loadChildren: './Lazy-Loading-Modules/verify-account-module#VerifyAccountModule'
	// },
	{
		path: 'precualificate',
		component: PrequalificationComponent
	},
	{
		path: '**',
		redirectTo: '/not-found'
	},
	{
		path: 'not-found',
		component: NotFoundComponent
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes, {
			preloadingStrategy: PreloadAllModules,
			relativeLinkResolution: 'legacy',
			initialNavigation: 'enabled'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
