export const getOptions = (primary_color, secondary_color) => {
	return {
		width: 248,
		height: 248,
		data: '',
		margin: 0,
		qrOptions: {
			typeNumber: '0',
			mode: 'Byte',
			errorCorrectionLevel: 'H'
		},
		imageOptions: {
			hideBackgroundDots: true,
			imageSize: 0.5,
			margin: 0,
			crossOrigin: 'anonymous'
		},
		dotsOptions: {
			type: 'extra-rounded',
			color: primary_color,
			gradient: {
				type: 'linear',
				rotation: 2.6179938779914944,
				colorStops: [
					{
						offset: 0,
						color: primary_color
					},
					{
						offset: 1,
						color: secondary_color
					}
				]
			}
		},
		backgroundOptions: {
			color: '#ffffff'
		},
		image: null,
		dotsOptionsHelper: {
			colorType: {
				single: true,
				gradient: false
			},
			gradient: {
				linear: true,
				radial: false,
				color1: primary_color,
				color2: secondary_color,
				rotation: '0'
			}
		},
		cornersSquareOptions: {
			type: 'extra-rounded',
			color: '#000000',
			gradient: {
				type: 'radial',
				rotation: 0,
				colorStops: [
					{
						offset: 0,
						color: primary_color
					},
					{
						offset: 1,
						color: secondary_color
					}
				]
			}
		},
		cornersSquareOptionsHelper: {
			colorType: {
				single: true,
				gradient: false
			},
			gradient: {
				linear: true,
				radial: false,
				color1: '#000000',
				color2: '#000000',
				rotation: '0'
			}
		},
		cornersDotOptions: {
			type: '',
			color: primary_color,
			gradient: {
				type: 'linear',
				rotation: 1.9896753472735356,
				colorStops: [
					{
						offset: 0,
						color: primary_color
					},
					{
						offset: 1,
						color: secondary_color
					}
				]
			}
		},
		cornersDotOptionsHelper: {
			colorType: {
				single: true,
				gradient: false
			},
			gradient: {
				linear: true,
				radial: false,
				color1: '#000000',
				color2: '#000000',
				rotation: '0'
			}
		},
		backgroundOptionsHelper: {
			colorType: {
				single: true,
				gradient: false
			},
			gradient: {
				linear: true,
				radial: false,
				color1: '#ffffff',
				color2: '#ffffff',
				rotation: '0'
			}
		}
	};
};

export default {
	width: 248,
	height: 248,
	data: '',
	margin: 0,
	qrOptions: {
		typeNumber: '0',
		mode: 'Byte',
		errorCorrectionLevel: 'H'
	},
	imageOptions: {
		hideBackgroundDots: true,
		imageSize: 0.5,
		margin: 0,
		crossOrigin: 'anonymous'
	},
	dotsOptions: {
		type: 'extra-rounded',
		color: '#6a1a4c',
		gradient: {
			type: 'linear',
			rotation: 2.6179938779914944,
			colorStops: [
				{
					offset: 0,
					color: '#222149'
				},
				{
					offset: 1,
					color: '#b62632'
				}
			]
		}
	},
	backgroundOptions: {
		color: '#ffffff'
	},
	image: null,
	dotsOptionsHelper: {
		colorType: {
			single: true,
			gradient: false
		},
		gradient: {
			linear: true,
			radial: false,
			color1: '#6a1a4c',
			color2: '#6a1a4c',
			rotation: '0'
		}
	},
	cornersSquareOptions: {
		type: 'extra-rounded',
		color: '#000000',
		gradient: {
			type: 'radial',
			rotation: 0,
			colorStops: [
				{
					offset: 0,
					color: '#222149'
				},
				{
					offset: 1,
					color: '#b62632'
				}
			]
		}
	},
	cornersSquareOptionsHelper: {
		colorType: {
			single: true,
			gradient: false
		},
		gradient: {
			linear: true,
			radial: false,
			color1: '#000000',
			color2: '#000000',
			rotation: '0'
		}
	},
	cornersDotOptions: {
		type: '',
		color: '#222149',
		gradient: {
			type: 'linear',
			rotation: 1.9896753472735356,
			colorStops: [
				{
					offset: 0,
					color: '#222149'
				},
				{
					offset: 1,
					color: '#b62632'
				}
			]
		}
	},
	cornersDotOptionsHelper: {
		colorType: {
			single: true,
			gradient: false
		},
		gradient: {
			linear: true,
			radial: false,
			color1: '#000000',
			color2: '#000000',
			rotation: '0'
		}
	},
	backgroundOptionsHelper: {
		colorType: {
			single: true,
			gradient: false
		},
		gradient: {
			linear: true,
			radial: false,
			color1: '#ffffff',
			color2: '#ffffff',
			rotation: '0'
		}
	}
};
