import { Inject, Injectable, Optional } from '@angular/core';
import {
	HttpErrorResponse,
	HttpHandler,
	HttpInterceptor,
	HttpParams,
	HttpRequest,
	HttpResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { catchError, filter, finalize, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { RequestCancelerService } from './request-canceler.service';
@Injectable({
	providedIn: 'root'
})
export class WebRequestInterceptorService implements HttpInterceptor {
	private refreshTokenInProgress = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	constructor(
		@Optional() @Inject(REQUEST) private httpRequest,
		private authService: AuthenticationService,
		private cookieService: CookieService,
		private requestCanceler: RequestCancelerService
	) {}

	/**
	 * This method intercept the HTTP request in order for us to make the changes that we desired.
	 * In this case we want to add the 'x-access-token' to each HTTP request we do to the API so we attach
	 * the JWT token and then handle any 401 HTTP error response
	 * @param request HTTP Request
	 * @param next
	 */
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
		const busquedaQuery = request.params.get('busquedaQuery');
		const search_location = 'search_location';
		if (busquedaQuery) {
			try {
				const parsedBusqueda = JSON.parse(busquedaQuery);
				const storedResults = this.cookieService.get(search_location);
				if (storedResults) {
					try {
						const parsedStoredResults = JSON.parse(storedResults);
						parsedBusqueda.type = parsedStoredResults.type;
						parsedBusqueda.id = parsedStoredResults.id;
						const newParams = new HttpParams();
						newParams.append('busquedaQuery', JSON.stringify(parsedBusqueda));
						this.cookieService.delete(search_location, '/', null);
						request = request.clone({
							setParams: {
								busquedaQuery: JSON.stringify(parsedBusqueda)
							}
						});
					} catch (error) {
						console.log('error trying to parse search_location');
					}
				}
			} catch (error) {
				console.log('error trying to parse search query');
			}
		}
		//If optional request is provided, we are server side, else we are client side
		if (this.httpRequest) {
			request = request.clone({
				setHeaders: {
					Cookie: this.httpRequest.headers.cookie
				}
			});
		} else {
			const url = request.url;
			let withCredentials = true;
			// If you are calling an outside domain then do not add cookies because it will cause an error.
			if (
				!url.match(/api.finditpr.com\//) &&
				!url.match(/localhost:3000\//) &&
				!url.match(/testapi.finditpr.com\//)
			) {
				withCredentials = false;
			}
			request = request.clone({
				withCredentials
			});
		}

		const errorRequest = request.clone();
		request = this.addXSRFheader(request);

		return next.handle(request).pipe(
			// takeUntil(this.requestCanceler.getRequestCancelationObservable()),
			catchError((error: HttpErrorResponse) => {
				if (error.error?.message === 'jwt expired' && error.status === 401) {
					// 401 errors are most likely going to be because we have an expired token that we need to refresh.
					if (this.refreshTokenInProgress) {
						// If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
						// which means the new token is ready and we can retry the request again
						return this.refreshTokenSubject.pipe(
							filter((result) => result !== null),
							take(1),
							switchMap(() => next.handle(this.addXSRFheader(request)))
						);
					} else {
						this.refreshTokenInProgress = true;

						// Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
						this.refreshTokenSubject.next(null);

						return this.refreshAccessToken().pipe(
							switchMap((success: HttpResponse<any>) => {
								this.refreshTokenSubject.next(success.body.access_token);
								return next.handle(this.addXSRFheader(request));
							}),
							catchError((err: any, caught: any) => {
								//Remove user session & navigate out of profile
								this.authService.removeSession(true);
								//Handle the request without attaching session data to it.
								return next.handle(errorRequest);
							}),
							// When the call to refreshToken completes we reset the refreshTokenInProgress to false
							// for the next time the token needs to be refreshed
							finalize(() => (this.refreshTokenInProgress = false))
						);
					}
				} else if (error.status === 401) {
					try {
						//If user was authenticated a
						// if (this.authService.isAuthenticated()) {
						// 	this.authService.removeSession();
						// }
						return throwError(error);
					} catch (err) {
						return throwError(err);
					}
				} else {
					return next.handle(request);
				}
			})
		);
	}

	/**
	 * The purpose of this method is to add the 'access-token' to the headers of each request made to the API
	 * @param request HTTP Request
	 */
	private addXSRFheader(request: HttpRequest<any>) {
		if (request.method === 'GET') {
			return request;
		}
		// Get X-XSRF-TOKEN
		const _csrf = this.cookieService.get('X-XSRF-TOKEN');

		// If you are calling an outside domain then do not add the token because it will cause an error.
		if (!request.url.match(/finditpr.com\//) && !request.url.match(/localhost:3000\//)) {
			return request;
		} else if (request.url.match(/upload.finditpr.com\//)) {
			return request.clone({
				setHeaders: {
					Authorization: `Bearer ${this.authService.getAccessToken()}`
				}
			});
		} else if (_csrf) {
			return request.clone({
				setHeaders: {
					'X-XSRF-TOKEN': _csrf
				}
			});
		} else {
			return request;
		}
	}

	/**
	 * Method to refresh the access token:
	 * This will call a method in the auth service to send a request to refresh the access token
	 */
	private refreshAccessToken() {
		return this.authService.getNewAccessToken().pipe(
			tap(() => {
				console.log('Access Token Refreshed!');
			})
		);
	}
}
