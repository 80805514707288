<div class="title-container">
	<h1 mat-dialog-title i18n>Comparte este listado</h1>
	<div class="spacer"></div>
	<button mat-icon-button matPrefix class="close-icon" (click)="dialogRef.close()">
		<mat-icon>add</mat-icon>
	</button>
</div>
<div mat-dialog-content>
	<form [formGroup]="form" action=".">
		<mat-form-field appearance="outline">
			<mat-label i18n>Correo electrónico a enviar</mat-label>
			<input matInput formControlName="sendToEmail" autocomplete="off" />
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label i18n>Mi nombre</mat-label>
			<input matInput formControlName="sentFromName" autocomplete="off" />
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label i18n>Mi correo electrónico</mat-label>
			<input matInput formControlName="sentFromEmail" autocomplete="off" />
		</mat-form-field>

		<h5 i18n>Mensaje(opcional)</h5>
		<mat-form-field appearance="outline">
			<textarea matInput id="share-message" formControlName="message" i18-placeholder
				placeholder="Escribe un nuevo mensaje..." autocomplete="off"></textarea>
		</mat-form-field>
	</form>
	<ng-container *ngIf="!requestInProgress && !messageSent; else loading">
		<div class="btn-container">
			<button mat-button class="blueButton" id="submitShare" (click)="onClickEnviar()" [disabled]="form.invalid">
				<ng-container i18n> Enviar </ng-container>
			</button>
		</div>
	</ng-container>

	<ng-template #loading>
		<div class="loadingParent" *ngIf="requestInProgress">
			<ul class="loading">
				<li></li>
				<li></li>
				<li></li>
			</ul>
		</div>
		<h6 *ngIf="messageSent" style="text-align: center" i18n>¡Su mensaje fue enviado con éxito!</h6>
	</ng-template>

	<hr />

	<h5 i18n>Copia el enlace</h5>
	<mat-form-field appearance="outline">
		<mat-label i18n>Copiar enlace</mat-label>
		<input matInput [disabled]="true" [formControl]="enlaceForm" />
	</mat-form-field>
	<div class="btn-container">
		<button mat-button class="blueButton" [cdkCopyToClipboard]="this.enlaceForm.value" (click)="openSnackBar()">
			<ng-container i18n> Copiar </ng-container>
		</button>
	</div>
</div>