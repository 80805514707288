import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class BreakpointsService {
	breakpoints: object = {
		'(max-width: 1171px)': CustomBreakpointNames.breakPointOne,
		'(max-width: 942px)': CustomBreakpointNames.breakPointPublishListings,
		'(max-width: 735px)': CustomBreakpointNames.breakPointPublishListingsNew,
		'(max-width: 1088px)': CustomBreakpointNames.breakPointConfigs
	};
	constructor() {}

	getBreakpoints(): string[] {
		return Object.keys(this.breakpoints);
	}

	getBreakpointName(breakpointValue): string {
		return this.breakpoints[breakpointValue];
	}
}

export const CustomBreakpointNames = {
	breakPointOne: 'breakPointOne',
	breakPointPublishListings: 'breakPointPublishListings',
	breakPointPublishListingsNew: 'breakPointPublishListingsNew',
	breakPointConfigs: 'breakPointConfigs'
};
