export const report_reason = [
	{ name: 'Listado no está disponible', id: 1 },
	{ name: 'Listado Fraudelente', id: 2 },
	{ name: 'Información Incorrecta', id: 3 }
];

export const type_of_people = [
	{ name: 'Soy Profesional de Bíenes Raíces', id: 1 },
	{ name: 'Soy un Usuario Regular', id: 2 }
];
