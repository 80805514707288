<div class="close-logo" (click)="onClickClose()">
    <i-feather name="X"></i-feather>
</div>
<div class="parent-container">
    <div class="child-container">
        <div class="img-biz-name-container">
            <img [src]="data.ad.img" alt="">
            <div class="text-m">{{data.ad.business_name}}</div>
        </div>

        <div class="info-item">
            <div class="text-s-sb" i18n>
                Servicios
            </div>
            <div class="text-s">
                {{locale === 'en' ? data.ad.categories_en : data.ad.categories}}
            </div>
        </div>

        <div class="info-item">
            <div class="text-s-sb" i18n>
                Áreas de servicio
            </div>
            <div class="text-s">
                {{locale === 'en' ? data.ad.service_areas_en : data.ad.service_areas}}
            </div>
        </div>

        <div class="info-item">
            <div class="text-s-sb" i18n>
                Acerca
            </div>
            <div class="text-s">{{data.ad.description}}</div>
        </div>

        <div class="info-item">
            <div class="text-s-sb" i18n>
                Teléfono
            </div>
            <a href="tel:{{ data.ad.phone }}" class="text-s">
                {{formatPhoneNumber(data.ad.phone)}}
            </a>
        </div>

        <div>
            <div class="text-s-sb">
                Website
            </div>
            <a *ngIf="data.ad.website" href="{{data.ad.website}}" target="_blank" class="text-s">
                {{data.ad.website}}
            </a>
        </div>


    </div>
</div>