<div class="parent-container" *ngIf="obs | async as data; else loadingAds">
    <div class="child-container">

        <div class="title-container">
            <h1 class="heading-s" i18n>Proveedores de servicio</h1>
            <a routerLink="/checkout/servicios" id="checkout" class="add text-s-sb" i18n>Anúnciate aquí</a>
        </div>

        <div class="filters-container">
            <div class="area-container">
                <label class="text-m-sb" i18n>Área</label>
                <div class="custom-arrow-container">
                    <select name="area" id="area" (change)="onClickChangeArea($event)" class="text-s">
                        <option value="null" i18n>
                            Mostrar todos
                        </option>
                        <option value="METRO" i18n>
                            Area Metro
                        </option>
                        <option value="NORTH" i18n>
                            Area Norte
                        </option>
                        <option value="CENTER" i18n>
                            Centro
                        </option>
                        <option value="WEST" i18n>
                            Area Oeste
                        </option>
                        <option value="EAST" i18n>
                            Area Este
                        </option>
                        <option value="SOUTH" i18n>
                            Area Sur
                        </option>
                    </select>
                    <div class="custom-arrow">
                        <i-feather name="chevron-down"></i-feather>
                    </div>
                </div>
            </div>

            <div class="category-container">
                <label class="text-m-sb" i18n>Categoria</label>
                <div class="custom-arrow-container">
                    <select name="area" id="area" (change)="onClickChangeCategory($event)" class="text-s">
                        <option value="null" i18n>
                            Mostrar todos
                        </option>
                        <option *ngFor="let cat of categories" [value]="cat.id">
                            {{cat.category}}
                        </option>
                    </select>
                    <div class="custom-arrow">
                        <i-feather name="chevron-down"></i-feather>
                    </div>
                </div>

            </div>
        </div>

        <ul class="providers-list" *ngIf="providers.length > 0; else noResults">
            <ng-container *ngIf="!loading; else loadingAds">
                <li class="provider-item" *ngFor="let ad of providers" [routerLink]="'.' + ad.slug">
                    <div class="img-biz-name-container">
                        <img [src]="ad.img" alt="">
                        <div class="text-m-sb">
                            {{ad.business_name}}
                        </div>
                    </div>
                    <div class="categoria text-s">
                        {{locale === 'en' ? ad.categories_en : ad.categories}}
                    </div>
                    <div class="add see-more text-s-sb" i18n>
                        Ver más
                    </div>
                </li>
            </ng-container>
        </ul>

        <ng-template #noResults>
            <div class="no-results-container">
                <lottie-player src="https://assets7.lottiefiles.com/private_files/lf30_e3pteeho.json"
                    background="transparent" speed="1" style="width: 100%; height: 175px" loop autoplay>
                </lottie-player>
                <div class="text-s" style="text-align: center;" i18n>
                    No se encontraron proovedores de servicios. Intente otra categoría o área.
                </div>
            </div>
        </ng-template>

        <div class="ad-info-container">
            <img src="../../assets/promo-pool.jpg" class="pool-img" alt="">
            <div class="price-info">
                <div class="text-s-sb" style="margin-bottom: 12px" i18n>PROMUEVE TU NEGOCIO</div>
                <div class="text-m" style="color: var(--grayscale-7) !important" i18n>Por solo</div>
                <div class="heading-m" style="margin-bottom: 16px; margin-top: 4px;">$34.99
                    <span class="text-s" style="font-weight: 400 !important;" i18n>
                        /mes
                    </span>
                </div>
                <button class="create-btn" routerLink="/checkout/servicios" i18n>Crear mi anuncio</button>
            </div>
            <div class="benefits-images-container">
                <img src="../../assets/disco.jpg" alt="">
                <img src="../../assets/trimmer.jpg" alt="">
            </div>
            <div class="benefits-div">
                <div class="text-s benefit-item">
                    <div class="check-circle-container">
                        <i-feather class="check-circle" name="check"></i-feather>
                    </div>
                    <ng-container i18n>
                        Exposición a más de 27,000 visitas mensuales.
                    </ng-container>
                </div>
                <div class="text-s benefit-item">
                    <div class="check-circle-container">
                        <i-feather class="check-circle" name="check"></i-feather>
                    </div>
                    <ng-container i18n>
                        Atrae más clientes potenciales.
                    </ng-container>
                </div>
                <div class="text-s benefit-item">
                    <div class="check-circle-container">
                        <i-feather class="check-circle" name="check"></i-feather>
                    </div>
                    <ng-container i18n>
                        Clasifica más alto en Google y aumenta tu visibilidad.
                    </ng-container>
                </div>
                <div class="text-s benefit-item">
                    <div class="check-circle-container">
                        <i-feather class="check-circle" name="check"></i-feather>
                    </div>
                    <ng-container i18n>
                        Escoge hasta 3 categorías por anuncio.
                    </ng-container>
                </div>
                <div class="text-s benefit-item">
                    <div class="check-circle-container">
                        <i-feather class="check-circle" name="check"></i-feather>
                    </div>
                    <ng-container i18n>
                        Cancela cuando quieras.
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="disclaimer-container">
            <div class="disclaimer-1" i18n>
                No está obligado a utilizar los servicios antes provistos. Findit recibe compensación monetaria por cada
                anuncio en este directorio.
            </div>
            <br>
            <div class="disclaimer-2" i18n>
                Bluepath Group, LLC. no es responsable de los daños a su propiedad que resulten de estos servicios. Les
                recomendamos hacer su propia investigación acerca de las credenciales de cada proveedor antes de tomar
                una decisión.
            </div>
        </div>


    </div>
</div>

<router-outlet></router-outlet>

<ng-template #loadingAds>
    <div class="parent-container">
        <div class="child-container">
            <ul class="providers-list" style="border-radius: 4px;">
                <li class="provider-item" *ngFor="let ad of [1, 2, 3]"
                    style="overflow: hidden; padding-top: 16px; padding-left: 0; border-radius: 4px;">
                    <ngx-skeleton-loader count="1" style="border-radius: 4px; " appearance="circle" [theme]="{
    					'max-width': '1040px',
    					width: '100vw',
    					'min-height': '191px',
    					'border-radius': '4px',
    					'margin': '0px'
    				}" appearance="line"></ngx-skeleton-loader>
                </li>
            </ul>
        </div>
    </div>

</ng-template>