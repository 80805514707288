import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ImageInterface } from '../../interfaces/ImageInterface';
import { LightBoxDialogComponent } from '../light-box-dialog/light-box-dialog.component';

@Component({
	selector: 'app-image-preview-dialog',
	templateUrl: './image-preview-dialog.component.html',
	styleUrls: ['./image-preview-dialog.component.css']
})
export class ImagePreviewDialogComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public images: ImageInterface[],
		public dialogRef: MatDialogRef<ImagePreviewDialogComponent>,
		public dialog: MatDialog
	) {}

	ngOnInit(): void {
		console.log(this.images);
	}

	public onClickImg(index: number): void {
		index = index + 1;
		this.dialog.open(LightBoxDialogComponent, {
			maxWidth: '100vw',
			maxHeight: '100vh',
			height: '100%',
			width: '100%',
			data: { images: this.images, index },
			panelClass: ['app-full-bleed-dialog', 'animate__animated', 'animate__slideInUp']
		});
	}

	public close(): void {
		document.getElementsByClassName('animate__animated')[0].classList.remove('animate__slideIUp');
		document.getElementsByClassName('animate__animated')[0].classList.add('animate__slideOutDown');
		setTimeout(() => {
			this.dialogRef.close();
		}, 300);
	}
}
