import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RequestCancelerService {
	private cancelRequestSubject = new Subject<void>();

	constructor() {}

	cancelRequest() {
		this.cancelRequestSubject.next();
	}

	getRequestCancelationObservable() {
		return this.cancelRequestSubject.asObservable();
	}
}
