<button class="close-logo" matRipple (click)="onClickClose()">
	<i-feather name="X"></i-feather>
</button>
<div class="dialog-container">
	<div class="dialog-wrapper">
		<div class="text-m-sb" style="margin-bottom: 12px">
			<ng-container i18n> Resultados: </ng-container>
			{{ total }}
		</div>

		<div class="text-s" i18n>Cuartos</div>
		<mat-button-toggle-group [(ngModel)]="filters.bds">
			<mat-button-toggle (change)="filterValues()" class="text-s-sb" [value]="-1"> N/A </mat-button-toggle>
			<mat-button-toggle
				(change)="filterValues()"
				class="text-s-sb"
				*ngFor="let i of [1, 2, 3, 4, 5, 6]"
				[value]="i"
			>
				{{ i }}+
			</mat-button-toggle>
		</mat-button-toggle-group>

		<div class="text-s" i18n>Baños</div>
		<mat-button-toggle-group [(ngModel)]="filters.bhs">
			<mat-button-toggle class="text-s-sb" (change)="filterValues()" [value]="-1"> N/A </mat-button-toggle>
			<mat-button-toggle
				class="text-s-sb"
				(change)="filterValues()"
				*ngFor="let i of [1, 2, 3, 4, 5, 6]"
				[value]="i"
			>
				{{ i }}+
			</mat-button-toggle>
		</mat-button-toggle-group>

		<div class="text-s" style="margin-bottom: 8px" i18n>Tipo de propiedad</div>
		<div class="properties-type-container">
			<button class="select-all-btn text-s" (click)="onClickSelectAll()" i18n>
				{selectAll, select, false {Deseleccionar Todos} true {Seleccionar Todos} }
			</button>
			<div style="display: flex; flex-flow: column">
				<mat-checkbox
					class="text-s check-box"
					[(ngModel)]="filters.home_types.casa"
					(change)="filterValues()"
					[checked]="filters.home_types.casa"
				>
					<span i18n> Casas </span>
				</mat-checkbox>
				<mat-checkbox
					class="text-s check-box"
					[(ngModel)]="filters.home_types.apartamento"
					(change)="filterValues()"
					[checked]="filters.home_types.apartamento"
				>
					<span i18n> Apartamentos </span>
				</mat-checkbox>
				<mat-checkbox
					class="text-s check-box"
					[(ngModel)]="filters.home_types.multi_familiar"
					(change)="filterValues()"
					[checked]="filters.home_types.multi_familiar"
				>
					<span i18n> Casas multi-familiares </span>
				</mat-checkbox>
				<mat-checkbox
					class="text-s check-box"
					[(ngModel)]="filters.home_types.terreno"
					(change)="filterValues()"
					[checked]="filters.home_types.terreno"
				>
					<span i18n> Terreno </span>
				</mat-checkbox>
				<mat-checkbox
					class="text-s check-box"
					[(ngModel)]="filters.home_types.local"
					(change)="filterValues()"
					[checked]="filters.home_types.local"
				>
					<span i18n> Locales Comerciales </span>
				</mat-checkbox>
				<mat-checkbox
					class="text-s check-box"
					[(ngModel)]="filters.home_types.oficina"
					(change)="filterValues()"
					[checked]="filters.home_types.oficina"
				>
					<span i18n> Oficinas </span>
				</mat-checkbox>
				<mat-checkbox
					class="text-s check-box"
					[(ngModel)]="filters.home_types.almacen"
					(change)="filterValues()"
					[checked]="filters.home_types.almacen"
				>
					<span i18n> Almacen </span>
				</mat-checkbox>
				<mat-checkbox
					class="text-s check-box"
					[(ngModel)]="filters.home_types.edificio"
					(change)="filterValues()"
					[checked]="filters.home_types.edificio"
				>
					<span i18n> Edificio </span>
				</mat-checkbox>
				<mat-checkbox
					class="text-s check-box"
					[(ngModel)]="filters.home_types.estudio"
					(change)="filterValues()"
					[checked]="filters.home_types.estudio"
				>
					<span i18n> Estudio </span>
				</mat-checkbox>
				<!-- <mat-checkbox class="text-s check-box">
					<span i18n> Habitacion </span>
				</mat-checkbox> -->
			</div>
		</div>

		<div class="text-s" style="margin-bottom: 8px; margin-top: 12px" i18n>Precio</div>
		<div class="price-container">
			<div class="min-price text-s">
				<input
					[(ngModel)]="filters.price.min"
					type="tel"
					prefix="$"
					mask="separator.2"
					thousandSeparator=","
					name="min_price"
					i18n-placeholder
					placeholder="Minímo"
					autocomplete="off"
				/>
			</div>
			<div class="hr-container">
				<hr style="width: 100%" />
			</div>
			<div class="max-price text-s">
				<input
					[(ngModel)]="filters.price.max"
					type="tel"
					name="max_price"
					prefix="$"
					mask="separator.2"
					thousandSeparator=","
					i18n-placeholder
					placeholder="Maxímo"
					autocomplete="off"
				/>
			</div>
		</div>

		<div class="text-s" style="margin-top: 12px">Keywords</div>
		<div>
			<div class="chips-container" [ngStyle]="{ 'margin-top': filters.keywords?.length > 0 ? '8px' : '0px' }">
				<div class="chip text-s" *ngFor="let keyword of filters.keywords">
					{{ keyword }}
					<button (click)="removeKeyword(keyword)">
						<i-feather class="remove-feather-icon" name="X"></i-feather>
					</button>
				</div>
			</div>
			<form action="." (ngSubmit)="onSubmitKeyword($event)" class="text-s">
				<input
					class="text-s"
					type="text"
					(focus)="onFocusIn($event)"
					(blur)="onFocusOut($event)"
					maxlength="25"
					i18n-placeholder
					placeholder="vista al mar, airbnb, oportunidad de inversion..."
					#keywordInputMobile
					autocomplete="off"
					id="keywords"
				/>
			</form>
		</div>
	</div>
	<div class="btn-container" *ngIf="keywordFocusOut">
		<button class="reset-btn text-s" (click)="resetFilters()" i18n>Remover filtros</button>
		<button class="filter-btn text-s" (click)="filterValues(); onClickClose()" i18n>Listo</button>
	</div>
</div>
<div *ngIf="requestInProgress" class="loading-layer">
	<mat-spinner color="primary" [strokeWidth]="1.5" [diameter]="50"></mat-spinner>
</div>
