import {
	Component,
	ElementRef,
	Inject,
	Input,
	LOCALE_ID,
	OnInit,
	Renderer2,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import { ImageInterface } from '../interfaces/ImageInterface';

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation, SwiperOptions, Lazy } from 'swiper';

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Lazy]);
@Component({
	selector: 'app-image-card-carousel',
	templateUrl: './image-card-carousel.component.html',
	styleUrls: ['./image-card-carousel.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class ImageCardCarouselComponent implements OnInit {
	config: SwiperOptions = {
		loop: true,
		navigation: true,
		pagination: {
			dynamicBullets: true
		},
		preloadImages: true,
		lazy: {
			enabled: true,
			// checkInView: true,
			loadOnTransitionStart: true,
			loadPrevNext: true
		}
	};

	@Input() listingLink: string;
	@Input() edit: boolean = false;

	@Input() address: string = '';

	@Input('images')
	set imageSetter(imgs: ImageInterface[]) {
		if (imgs && imgs.length > 0) {
			this.imgsArr = imgs.sort((a, b) => a.order - b.order);
		}
	}

	@Input() height: string = '224px';
	imgsArr: { image: string; order: number }[] = [];
	@Input('firstImage') currentImg = '';

	constructor(@Inject(LOCALE_ID) public locale: string, private renderer: Renderer2) {}

	ngOnInit(): void {}

	trackById(index: number, item: ImageInterface) {
		return item.id;
	}
}
