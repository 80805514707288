import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Listing } from '../interfaces/ListingInterface';
import { DotListing } from '../listings-component/map-component/map-component.component';
import { CurrencySuffixPipe } from '../listings-component/listings-component.component';
import { UtilsService } from './utils.service';

@Injectable({
	providedIn: 'root'
})
export class ListingPopupService {
	constructor(@Inject(LOCALE_ID) public locale: string) {}

	makeListingPopup(data: DotListing) {
		const getcuartosOterreno = (listing: DotListing): string => {
			if (listing.bedrooms != null) {
				const num = +listing.bedrooms === 0 ? '--' : listing.bedrooms;
				if (num === '--' || num.toLocaleString() === 'Desconocido') {
					return num.toLocaleString();
				} else {
					return `${num} ${this.locale === 'en' ? 'bd' : 'cto'}`;
				}
			} else {
				if (listing.lot_unit === 'Desconocido') {
					return '--';
				}

				if (listing.lot_size !== 0) {
					const options = {
						notation: 'compact',
						compactDisplay: 'short'
					};
					const size = new Intl.NumberFormat('en-US', options as Intl.NumberFormatOptions).format(
						+listing.lot_size
					);
					return `${size} ${listing.lot_unit}`;
				} else {
					return '-- sqft';
				}
			}
		};

		const getbanosOsubcat = (listing: DotListing): string => {
			if (listing.total_bathrooms) {
				return `${listing.total_bathrooms} ${this.locale === 'en' ? 'ba' : 'bñ'}`;
			} else {
				if (listing.land_sub_type === 'N/A') {
					return '--';
				} else {
					return this.locale === 'en' ? listing.land_sub_type_en : listing.land_sub_type;
				}
			}
		};

		return `
			<div class="hover-preview">
				<img src="${data.cover_img}" />
				<div>
					<div class="text-s-sb">
						$${new CurrencySuffixPipe().transform(data.price)}
					</div>
					<div class="text-s">
						${getcuartosOterreno(data)}
					</div>
					<div class="text-s">
						${getbanosOsubcat(data)}
					</div>
				</div>
			</div>
		`;
	}

	makeNearbyPlacePopup(data: any) {
		return ` ` + `<div>${data}</div>`;
	}

	makeYelpPopUp(element, rating) {
		return (
			` ` +
			`<div class="row" style="margin: 0 !important;">
                      <div class="col-auto" style="padding-left: 0 !important">
                          <img src="${element.image_url}" style="bject-fit: cover; width: 70px; height: 70px; border-radius: 8px;" alt="" />
                      </div>
                      <div class="col-auto" style="padding: 0 !important;">
                          <p class="mdc-typography mdc-typography--subtitle2" style="margin: 0px !important">
                              ${element.name}
                          </p>
                          <p class="mdc-typography mdc-typography--subtitle2" style="color: rgb(134, 144, 153); white-space: nowrap; text-overflow: ellipsis; overflow: hidden; margin: 0 !important;">
                              ${element.categories[0].title}
                          </p>
                          <p style="margin-bottom: 0 !important;">
                            <img src="../../assets/yelp/small${rating}.png" alt="${rating}">
                            &nbsp;<a href="${element.url}" target="_blank"> <img height="16" width="32" src="../../assets/yelp/yelp_logo_small.png" alt="Yelp"></a>
                          </p>
                          <p style="margin-bottom: 0 !important;">
                            <span style="color: rgb(134, 144, 153); font-size: 10px; line-height: 1.6;">Based on ${element.review_count} Reviews</span>
                          </p>
                          </div>
                   </div>`
		);
	}
}
