import { BrowserModule, BrowserTransferStateModule, HammerModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { CookieService } from 'ngx-cookie-service';

//Components
import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import { FooterComponent } from './footer/footer.component';
import { AppRoutingModule } from './app.routing.module';
import { SelectedPropertyComponent } from './selected-property/selected-property.component';
// Services
import { AuthenticationService } from './services/authentication.service';
import { AuthGuardService } from './services/auth-guard.service';
import { SigninSignupGuard } from './services/signup-signin.guard';
import { WebRequestInterceptorService } from './services/web-request-interceptor.service';
import { MetaTagsService } from './services/meta-tags.service';
import { RequestCancelerService } from './services/request-canceler.service';

import { SharedModule } from './Lazy-Loading-Modules/shared-module';
import { MainNavComponent } from './main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { WebsocketsService } from './services/websockets.service';
import { BreakpointsService } from './services/breakpoints.service';
import { DialogCompartirComponent } from './dialog-compartir/dialog-compartir.component';
import { SigninPopUpComponent } from './auth/signin-pop-up/signin-pop-up.component';
import { ContactUsService } from './services/contact-us.service';
import { ReportDialogComponent } from './report-dialog/report-dialog.component';
import { ContactDialogBoxComponent } from './selected-property/contact-dialog-box/contact-dialog-box.component';
import { NearbyDialogBoxComponent } from './selected-property/nearby-dialog-box/nearby-dialog-box.component';
import { ListingPopupService } from './services/listing-pop-up.service';
import { UserService } from './services/user.service';
import { NguiMapModule } from '@ngui/map';
import { ListingsService } from './services/listings.service';
import { SiteMapMainComponent } from './site-map-main/site-map-main.component';
import { SitemapCityComponent } from './site-map-main/site-map/sitemap-city/sitemap-city.component';
import { SiteMapComponent } from './site-map-main/site-map/site-map.component';
import { SiteMapBarriosComponent } from './site-map-main/site-map/sitemap-city/site-map-barrios/site-map-barrios.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AllDoneDialogComponent } from './publish-container/all-done-dialog/all-done-dialog.component';
import { AgentProfileComponent } from './agent-profile/agent-profile.component';
import { HorizontalListingCardComponent } from './agent-profile/horizontal-listing-card/horizontal-listing-card.component';
import { DialogEditComponent } from './agent-profile/dialog-edit/dialog-edit.component';
import { ColorSketchModule } from 'ngx-color/sketch';
import { ColorChromeModule } from 'ngx-color/chrome';
import { BdcWalkModule } from 'bdc-walkthrough';
import { DialogColorPickerComponent } from './agent-profile/dialog-color-picker/dialog-color-picker.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { MaterialModule } from './material-module';
import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { NgGoogleOneTapModule } from 'ng-google-one-tap';
import { LightBoxDialogComponent } from './selected-property/light-box-dialog/light-box-dialog.component';
const CLIENT_ID = '748450965496-9bkuo4cpclt8empbh1nc3eqdhlg8jhpt.apps.googleusercontent.com';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { RepoPropertyComponent } from './repo-property/repo-property.component';
import { ServiceProvidersDirectoryComponent } from './service-providers-directory/service-providers-directory.component';
import { PaymentsService } from './services/payments.service';
import { AdsService } from './services/ads.service';
import { ReviewOrderDialogComponent } from './checkout/review-order-dialog/review-order-dialog.component';
import {
	HostMoreInfoDialogComponent,
	MoreInfoDialogComponent
} from './service-providers-directory/more-info-dialog/more-info-dialog.component';
import { UtilsService } from '../app/services/utils.service';
import { PrequalificationComponent } from './prequalification/prequalification.component';
import { ImagePreviewDialogComponent } from './selected-property/image-preview-dialog/image-preview-dialog.component';
// import { PreQualificationWidgetComponent } from './pre-qualification-widget/pre-qualification-widget.component';
@NgModule({
	declarations: [
		AppComponent,
		HomePageComponent,
		SelectedPropertyComponent,
		FooterComponent,
		MainNavComponent,
		DialogCompartirComponent,
		SigninPopUpComponent,
		SiteMapMainComponent,
		SiteMapComponent,
		SitemapCityComponent,

		NearbyDialogBoxComponent,
		LightBoxDialogComponent,
		ContactDialogBoxComponent,
		ReportDialogComponent,
		SiteMapBarriosComponent,
		NotFoundComponent,
		AllDoneDialogComponent,
		AgentProfileComponent,
		HorizontalListingCardComponent,
		DialogEditComponent,
		DialogColorPickerComponent,
		RepoPropertyComponent,
		ServiceProvidersDirectoryComponent,
		ReviewOrderDialogComponent,
		HostMoreInfoDialogComponent,
		MoreInfoDialogComponent,
		PrequalificationComponent,
		ImagePreviewDialogComponent
		//   PreQualificationWidgetComponent
	],
	imports: [
		MaterialModule,
		AppRoutingModule,
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		HttpClientModule,
		BrowserTransferStateModule,
		TransferHttpCacheModule,
		BrowserAnimationsModule,
		SharedModule,
		LayoutModule,
		NguiMapModule.forRoot({
			apiUrl: 'https://maps.google.com/maps/api/js?key=AIzaSyDZ2XgkjACiRzT5-7kOOGnBJcmpyzQCcBA'
		}),
		HammerModule,
		BdcWalkModule,
		ColorSketchModule,
		ColorChromeModule,
		SocialLoginModule,
		NgGoogleOneTapModule.config({
			//Look options table for some more avaialbe options and config here.
			client_id: CLIENT_ID,
			cancel_on_tap_outside: true,
			authvalidate_by_googleapis: false,
			auto_select: false,
			disable_exponential_cooldowntime: false,
			context: 'signin'
			// state_cookie_domain: 'http://localhost'
		}),
		RecaptchaModule,
		RecaptchaFormsModule
	],
	providers: [
		RequestCancelerService,
		UtilsService,
		AdsService,
		PaymentsService,
		AuthenticationService,
		SigninSignupGuard,
		AuthGuardService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: WebRequestInterceptorService,
			multi: true
		},
		WebsocketsService,
		BreakpointsService,
		ContactUsService,
		LightBoxDialogComponent,
		ListingsService,
		MetaTagsService,
		ListingPopupService,
		UserService,
		CookieService,
		NgxImageCompressService,
		{
			provide: 'SocialAuthServiceConfig',
			useValue: {
				autoLogin: false,
				providers: [
					{
						id: GoogleLoginProvider.PROVIDER_ID,
						provider: new GoogleLoginProvider(CLIENT_ID)
					}
				]
			} as SocialAuthServiceConfig
		},
		{
			provide: RECAPTCHA_SETTINGS,
			useValue: {
				siteKey: environment.recaptcha.siteKey
			} as RecaptchaSettings
		}
	],
	entryComponents: [
		DialogCompartirComponent,
		AllDoneDialogComponent,
		SigninPopUpComponent,
		NearbyDialogBoxComponent,
		ContactDialogBoxComponent,
		LightBoxDialogComponent,
		ReportDialogComponent,
		DialogEditComponent,
		MoreInfoDialogComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	bootstrap: [AppComponent]
})
export class AppModule {}
