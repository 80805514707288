<div style="position: relative">
	<div class="title-container">
		<div class="heading-s" style="margin-bottom: 12px; color: var(--grayscale-2) !important" i18n>
			Inicia Sesión
		</div>
		<div class="text-m" style="margin-bottom: 32px; color: var(--grayscale-4) !important" i18n>
			Accede a tu cuenta para guardar tus búsquedas o listados favoritos
		</div>
		<button mat-icon-button matPrefix class="close-icon" (click)="onClickClose(false)">
			<mat-icon>add</mat-icon>
		</button>
	</div>
	<div>
		<div>
			<button name="button_3" (click)="loginGoogle()" mat-button class="google-button">
				<div class="google-btn-container">
					<mat-icon svgIcon="google-logo" class="logo-svg-size google-icon"></mat-icon>
					<div class="spacer"></div>
					<ng-container i18n> Continuar con Google </ng-container>
					<div class="spacer"></div>
				</div>
			</button>
		</div>

		<div class="a-traves" i18n>o puedes:</div>
	</div>
	<div mat-dialog-content>
		<form [formGroup]="loginForm">
			<label class="text-s-sb" for="email">
				<ng-container i18n>Correo Electrónico</ng-container>
				<span>&nbsp;*</span>
			</label>
			<input formControlName="email" maxLength="45" style="margin-bottom: 16px" type="email" name="email" />

			<label class="text-s-sb" for="email">
				<ng-container i18n>Contraseña</ng-container>
				<span>&nbsp;*</span>
			</label>
			<input
				formControlName="password"
				style="margin-bottom: 24px"
				[ngStyle]="{ 'margin-bottom': error ? '8px' : '24px' }"
				type="password"
				name="password"
			/>
			<div *ngIf="error" class="text-s error-message" style="margin-bottom: 16px">
				{{ errorText }}
				<span
					*ngIf="confirmEmailError"
					routerLink="/registrate"
					style="cursor: pointer; text-decoration: underline"
					i18n
				>
					Confirmar correo electrónico
				</span>
			</div>
			<button (click)="login()" mat-button [disabled]="this.loginForm?.invalid" class="signInButton" i18n>
				Iniciar Sesión
			</button>
		</form>
		<div class="forgot-password" routerLink="/recuperar-contrasena" (click)="dialogRef.close()" i18n>
			Olvidé mi Contraseña
		</div>
		<hr style="margin-top: 24px; border-color: #d8d8d8" />
		<div class="no-account-container">
			<h6 class="no-cuenta" i18n>¿No tienes una Cuenta?</h6>
			<div class="spacer"></div>
			<h6 class="registrate" routerLink="/registrate" (click)="dialogRef.close()" i18n>Regístrate Aquí</h6>
		</div>

		<ng-container *ngIf="requestInProgress">
			<div class="loading-div">
				<mat-spinner color="primary" [strokeWidth]="1.5" [diameter]="50"></mat-spinner>
			</div>
		</ng-container>
	</div>
</div>
