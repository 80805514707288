<div style="position: relative">
	<button class="close-logo" matRipple (click)="onClickClose()">
		<i-feather name="X"></i-feather>
	</button>

	<div class="text-m title">Guardar Búsqueda</div>
	<form *ngIf="stage === 'input'" action="." (ngSubmit)="saveSearch($event)" class="save-search-mobile-container">
		<label for="save-search-name" class="text-s"> Nombre de la búsqueda </label>
		<input
			type="text"
			i18n-placeholder
			placeholder="Ej: Apartamentos en Condado"
			class="text-s"
			name="save-search-name"
			id="save-search-name-mobile"
		/>
		<span *ngIf="minimumErrorMsg" class="text-s" style="color: var(--notification-error) !important" i18n>
			El nombre de la búsqueda debe tener un minímo de 2 caracteres
		</span>
		<button class="save-btn text-s" type="submit" i18n>Guardar</button>
		<button (click)="onClickClose()" class="cancel-btn text-s">Cancelar</button>
	</form>
	<div *ngIf="stage === 'success'" class="lottie-container">
		<lottie-player
			src="https://assets7.lottiefiles.com/packages/lf20_wkebwzpz.json"
			background="transparent"
			speed="1"
			style="width: 100%; height: 225px"
			class="lottie-player"
			autoplay
		>
		</lottie-player>
		<div class="text-s success-msg" i18n>¡Búsqueda guardada con exito!</div>
	</div>
	<div *ngIf="stage === 'error'" class="lottie-container error-container">
		<i-feather name="frown" class="sad-icon"></i-feather>
		<div class="text-s" i18n>Algo salio mal, por favor intente más tarde</div>
	</div>
	<div *ngIf="requestInProgress" class="loading-layer">
		<mat-spinner color="primary" [strokeWidth]="1.5" [diameter]="50"></mat-spinner>
	</div>
</div>
