/**
 * Creating shared modules allows you to organize and streamline your code.
 * You can put commonly used directives, pipes, and components into one module and then
 * import just that module wherever you need it in other parts of your app.
 * Link: https://angular.io/guide/sharing-ngmodules
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddCardComponent } from '../add-card/add-card.component';
// import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ImageCardCarouselComponent } from '../image-card-carousel/image-card-carousel.component';
import { SwiperModule } from 'swiper/angular';

import {
	Phone,
	Frown,
	Download,
	Layout,
	MessageSquare,
	DollarSign,
	TrendingUp,
	Settings,
	Heart,
	Bell,
	LogOut,
	Search,
	Send,
	Info,
	ArrowLeft,
	ArrowRight,
	ArrowDown,
	Check,
	HelpCircle,
	UserCheck,
	Move,
	Camera,
	Upload,
	AlertOctagon,
	AlertCircle,
	ExternalLink,
	ChevronDown,
	PlusCircle,
	X,
	Clock,
	PhoneIncoming,
	Mail,
	ChevronLeft,
	ChevronRight,
	ChevronUp,
	Flag,
	Image,
	Shield,
	User,
	Edit,
	Share,
	List,
	MapPin,
	Globe,
	CheckCircle,
	Eye,
	EyeOff,
	AlertTriangle,
	Sliders
} from 'angular-feather/icons';
import { FeatherModule } from 'angular-feather';
// import { DeviceDetectorModule } from 'ngx-device-detector';
import { ListingCardComponent, DaysOnFinditPipe } from '../listing-card/listing-card.component';
import { RouterModule } from '@angular/router';
import { DragSortImagesComponent } from '../drag-sort-images/drag-sort-images.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PropertyInformationComponent } from '../publish-forms/property-information/property-information.component';
import { FormVisualsComponent } from '../publish-forms/form-visuals/form-visuals.component';

const icons = {
	Phone,
	Eye,
	EyeOff,
	Frown,
	Download,
	AlertCircle,
	Layout,
	MessageSquare,
	DollarSign,
	TrendingUp,
	Settings,
	Heart,
	Bell,
	LogOut,
	Search,
	Send,
	Info,
	ArrowLeft,
	Check,
	HelpCircle,
	ArrowRight,
	ArrowDown,
	UserCheck,
	Move,
	Camera,
	Upload,
	AlertOctagon,
	ExternalLink,
	ChevronDown,
	PlusCircle,
	X,
	Clock,
	PhoneIncoming,
	Mail,
	ChevronLeft,
	ChevronRight,
	ChevronUp,
	Flag,
	Image,
	Shield,
	User,
	Edit,
	Share,
	List,
	MapPin,
	Globe,
	CheckCircle,
	AlertTriangle,
	Sliders
};

import { InteriorDetailsComponent } from '../publish-forms/interior-details/interior-details.component';
import { ExteriorDetailsComponent } from '../publish-forms/exterior-details/exterior-details.component';
import { RentDetailsComponent } from '../publish-forms/rent-details/rent-details.component';
import { PublishContainerComponent } from '../publish-container/publish-container.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { DragDropFileUploadDirective } from '../user-panel/mis-listados/drag-drop.directive';
import { AutoCompleteComponent } from '../auto-complete/auto-complete.component';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { EnterViewportDirective } from '../listing-card/EnterViewPortDirective';
import { EditListingCardComponent } from '../edit-listing-card/edit-listing-card.component';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
	imports: [
		NgxMaskModule.forRoot(options),
		CommonModule,
		FeatherModule.pick(icons),
		SwiperModule,
		FlexLayoutModule,
		FlexModule,
		MaterialModule,
		NgxSkeletonLoaderModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule
	],
	declarations: [
		EnterViewportDirective,
		DaysOnFinditPipe,
		ListingCardComponent,
		ImageCardCarouselComponent,
		AutoCompleteComponent,
		AddCardComponent,
		InteriorDetailsComponent,
		ExteriorDetailsComponent,
		RentDetailsComponent,
		PropertyInformationComponent,
		DragSortImagesComponent,
		FormVisualsComponent,
		DragDropFileUploadDirective,
		PublishContainerComponent,
		EditListingCardComponent
	],
	exports: [
		EnterViewportDirective,
		FlexLayoutModule,
		FlexModule,
		PublishContainerComponent,
		FormVisualsComponent,
		CommonModule,
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
		FeatherModule,
		ListingCardComponent,
		DaysOnFinditPipe,
		SwiperModule,
		ImageCardCarouselComponent,
		AutoCompleteComponent,
		AddCardComponent,
		DragSortImagesComponent,
		NgxSkeletonLoaderModule,
		NgxMaskModule,
		PropertyInformationComponent,
		InteriorDetailsComponent,
		ExteriorDetailsComponent,
		RentDetailsComponent,
		DragDropFileUploadDirective,
		EditListingCardComponent
	]
})
export class SharedModule {}
