import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ListingsService } from '../services/listings.service';
import { PropertyType } from '../listings-component/listings-component.component';

@Component({
	selector: 'app-saved-search-success-dialog',
	templateUrl: './saved-search-success-dialog.component.html',
	styleUrls: ['./saved-search-success-dialog.component.css']
})
export class SavedSearchSuccessDialogComponent implements OnInit {
	requestInProgress = false;
	minimumErrorMsg = false;
	stage: 'input' | 'success' | 'error' = 'input';

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { query: any; name: string; type: PropertyType; path: string },
		public dialogRef: MatDialogRef<SavedSearchSuccessDialogComponent>,
		private readonly listingService: ListingsService
	) {}

	ngOnInit() {}

	public onClickClose(): void {
		document.getElementsByClassName('animate__animated')[0].classList.remove('animate__slideIUp');
		document.getElementsByClassName('animate__animated')[0].classList.add('animate__slideOutDown');
		setTimeout(() => {
			this.dialogRef.close();
		}, 300);
	}

	public saveSearch($event): void {
		const value: string = $event.target[0].value?.trim();

		if (!value || value.length < 3) {
			this.minimumErrorMsg = true;
			return;
		} else {
			this.minimumErrorMsg = false;
		}

		this.requestInProgress = true;
		this.listingService
			.saveSearch(this.data.query, this.data.type as PropertyType, this.data.path, value)
			.pipe()
			.subscribe(
				() => {
					this.requestInProgress = false;
					this.stage = 'success';
				},
				(error) => {
					this.requestInProgress = false;
					this.stage = 'error';
					console.log(error);
				}
			);
	}
}
