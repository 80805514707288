import { Injectable, Inject } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { shareReplay, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserDetails } from '../interfaces/UserInterface';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SocialAuthService } from 'angularx-social-login';

@Injectable()
export class AuthenticationService {
	private token: string;
	private expiresIn = 365 * 5;
	private readonly API = `${environment.api}/users`;

	private hostName = !this.API.includes('finditpr.com') ? null : '.finditpr.com';

	constructor(
		private http: HttpClient,
		private router: Router,
		private cookieService: CookieService,
		private authService: SocialAuthService
	) {}

	public setLocale(locale: string): void {
		if (!locale) {
			return;
		}
		const current_locale: string = this.cookieService.get('locale');
		if (current_locale !== locale) {
			this.cookieService.set('locale', locale, this.expiresIn, '/', this.hostName, true, 'None');
		}
	}

	/**
	 * The purpose of this method is to decode the JWT and return the user details
	 */
	public getUserDetails(jwt?: string): UserDetails {
		const user = localStorage.getItem('user-details');
		if (user) {
			return JSON.parse(user);
		}
		const token = jwt || this.getToken();
		let payload;
		if (token) {
			payload = token.split('.')[1];
			payload = atob(payload);
			return JSON.parse(payload);
		} else {
			return null;
		}
	}

	/**
	 * The purpose of this method is to check if the user session has not expired yet
	 * return false if token is expired and true if not.
	 */
	public istokenExpired(): boolean {
		const user = this.getUserDetails();
		if (user) return user.exp > Date.now() / 1000;
		else return false;
	}

	public isAuthenticated(): boolean {
		return !!this.cookieService.get('x-access-token');
	}

	/**
	 * The purpose of this method is to check if we got a token in the local storage, if not, then return the one we got
	 * on the public variable 'this.token'
	 */
	public getToken(): string {
		if (!this.token) this.token = this.cookieService.get('x-access-token');
		return this.token;
	}

	/**
	 * This method is used to login the user. Also, this method uses
	 * 'shareReplay()' from rxjs to avoid multiple http request to the API.
	 * You can see the request by going to the network tab in developers tools on your browser.
	 * @param email Email of the user
	 * @param password Password of the user
	 */
	public signin(email: string, password: string) {
		return this.http
			.post(
				`${this.API}/auth/signin`,
				{
					email,
					password
				},
				{
					observe: 'response'
				}
			)
			.pipe(
				shareReplay(),
				tap((res: HttpResponse<any>) => {
					if (res.body.status !== 403) {
						//Call the method below to store them
						this.setSession(res.body.access_token, res.body.refresh_token);
					}
				})
			);
	}

	public signInGoogle(googleToken: string) {
		// this.removeWrongCookie();
		return this.http
			.post(
				`${this.API}/auth/sign-in/google`,
				{
					token: googleToken
				},
				{
					observe: 'response'
				}
			)
			.pipe(
				shareReplay(),
				tap((res: HttpResponse<any>) => {
					if (res.body.status !== 403) {
						//Call the method below to store them
						this.setSession(res.body.access_token, res.body.refresh_token);
					}
				})
			);
	}

	public signInFacebook(facebookToken: string) {
		return this.http
			.post(
				`${this.API}/auth/sign-in/facebook`,
				{
					token: facebookToken
				},
				{
					observe: 'response'
				}
			)
			.pipe(
				shareReplay(),
				tap((res: HttpResponse<any>) => {
					if (res.body.status !== 403) {
						//Call the method below to store them
						this.setSession(res.body.access_token, res.body.refresh_token);
					}
				})
			);
	}

	/**
	 * This method is used in the HTTP interceptor because in the backend, when the refreshToken is verified
	 * in the backend it will be automatically deleted.
	 */
	// public logOut() {
	// 	this.token = '';
	// 	this.removeSession();
	// 	if (this.router.url.includes('mi-perfil')) {
	// 		this.router.navigateByUrl('/iniciar-sesion');
	// 	}
	// }

	/**
	 * This method is used for the navigation bar button.
	 */
	public doLogout() {
		const id = this.getUserDetails().id.toString();
		const refreshToken = this.getRefreshToken();

		this.authService.signOut();
		this.removeSession();

		return this.http
			.delete(`${this.API}/auth/signout`, {
				headers: {
					'x-refresh-token': refreshToken,
					'x-user-id': id
				}
			})
			.pipe(
				tap(() => {
					if (!this.router.url.includes('mi-perfil')) {
						location.reload();
					} else {
						this.router.navigateByUrl('/iniciar-sesion');
					}
				})
			);
	}

	public revokeThirdPartyAppAccess(id: number) {
		return this.http.get(`${this.API}/revoke-thirdPartyAccess/${id}`);
	}

	public removeUserSessionFromDB(refreshToken: string) {
		const id = this.getUserDetails().id;
		return this.http.delete(`${this.API}/auth/signout`, {
			headers: {
				'x-refresh-token': refreshToken,
				'x-user-id': id.toString()
			}
		});
	}

	getRefreshToken() {
		return this.cookieService.get('x-refresh-token');
	}

	getUserID() {
		return this.cookieService.get('x-user-id');
	}

	getAccessToken() {
		return this.cookieService.get('x-access-token');
	}

	setAccessToken(accessToken: string) {
		this.cookieService.set('x-access-token', accessToken, this.expiresIn, '/', this.hostName, true, 'None');
		const userDetails = this.getUserDetails(accessToken);
		if (localStorage) {
			localStorage.setItem('user-details', JSON.stringify(userDetails));
		}
	}
	setRefreshToken(refreshToken: string) {
		this.cookieService.set('x-refresh-token', refreshToken, this.expiresIn, '/', this.hostName, true, 'None');
	}

	/**
	 * This method saves the userId, accessToken and refreshToken on the localstorage.
	 * You can see the localstorage by going to your developer tabs/application/localstorage.
	 * @param userId User id
	 * @param accessToken accessToken created by JWT
	 * @param refreshToken refreshToken created by crypto and stored in the Database
	 */
	public setSession(accessToken: string, refreshToken: string, newUser: boolean = false) {
		try {
			this.cookieService.delete('temp_email_uuid', '/', this.hostName);
		} catch (error) {
			console.log(error);
		}

		if (newUser) {
			this.setNewUser(newUser);
		}
		this.setAccessToken(accessToken);
		this.setRefreshToken(refreshToken);
		this.cookieService.set(
			'x-user-id',
			this.getUserDetails(accessToken).id.toString(),
			this.expiresIn,
			'/',
			this.hostName,
			true,
			'None'
		);
	}

	/**
	 * This methods remove the sessions
	 * @param 'x-user-id' User id
	 * @param accessToken accessToken created by JWT
	 * @param refreshToken refreshToken created by crypto and stored in the Database
	 */
	public removeSession(pushBack: boolean = false) {
		if (pushBack && this.router.url.includes('mi-perfil')) {
			this.router.navigateByUrl('/iniciar-sesion');
		}
		this.cookieService.delete('x-access-token', '/', this.hostName);
		this.cookieService.delete('x-refresh-token', '/', this.hostName);
		this.cookieService.delete('x-user-id', '/', this.hostName);
		this.cookieService.delete('X-XSRF-TOKEN', '/', this.hostName);
		localStorage.removeItem('new_user');
		localStorage.removeItem('user-details');
		localStorage.removeItem('professional_information');
	}

	/**
	 * The purpose of this method is to get a new access token from the backend when the current access token expires
	 */
	getNewAccessToken() {
		return this.http
			.get(`${this.API}/auth/renewAcessToken`, {
				headers: {
					'x-refresh-token': this.getRefreshToken(),
					'x-user-id': this.getUserID()
				},
				observe: 'response'
			})
			.pipe(
				tap((response: HttpResponse<any>) => {
					this.setAccessToken(response.body.access_token);
				})
			);
	}

	/**
	 * Get user sessions
	 */
	getActiveSessions() {
		return this.http.get(`${this.API}/get-sessions`);
	}

	getThirdPartyAuthSessions() {
		return this.http.get(`${this.API}/get-auth-apps`);
	}

	/**
	 * The purpose of this method is verify if the email is being used.
	 * @param email Email that is going to be verify
	 */
	verifyIfEmailIsInUse(email) {
		return this.http.get(`${this.API}/isEmailInUse/${email}`);
	}

	/**
	 * The purpose of this method is verify if the phone is being used.
	 * @param phone Phone that is being verify
	 */
	verifyIfPhoneIsInUse(phone) {
		return this.http.get(`${this.API}/isPhoneInUse/${phone}`);
	}

	signUp(userCredentials) {
		return this.http.post(`${this.API}/signup`, userCredentials);
	}

	public fixEmail(email: string) {
		return this.http.put(`${this.API}/fix-email`, { email });
	}

	public resendEmail(email: string) {
		return this.http.post(`${this.API}/re-send-confirmation-email`, { email });
	}

	private setNewUser(res: boolean): void {
		localStorage.setItem('new_user', res.toString());
	}

	public setUserProfessionalInformation(payload: {
		license_type?: string;
		license_number: number;
		exp_date: Date;
		business_name: string;
	}): void {
		if (!payload) {
			return;
		}
		localStorage.setItem('professional_information', JSON.stringify(payload));
	}

	public getProfessionalInformation(): {
		license_type: string;
		license_number: number;
		exp_date: Date;
		business_name: string;
	} {
		const payload = localStorage.getItem('professional_information');
		if (payload) {
			return JSON.parse(payload);
		} else {
			return null;
		}
	}

	public removeProfessionalInformation(): void {
		localStorage.removeItem('professional_information');
	}

	confirmPhone(confirmationCode: string) {
		return this.http.get(`${this.API}/confirmPhone/${confirmationCode}`, {
			responseType: 'text'
		});
	}

	//send password reset to the desired email
	sendPasswordResetEmail(userEmail: string) {
		return this.http.post(`${this.API}/sendForgotPasswordEmail`, JSON.stringify({ email: userEmail }), {
			headers: { 'Content-Type': 'application/json' }
		});
	}

	resetPassword(token: string, newPassword: string) {
		return this.http.put(`${this.API}/resetPassword`, JSON.stringify({ password: newPassword }), {
			headers: {
				'x-reset-pass': token,
				'Content-Type': 'application/json'
			}
		});
	}

	public saveNewUserDetails(userDetails: UserDetails): void {
		if (!userDetails) {
			return;
		}
		localStorage.setItem('user-details', JSON.stringify(userDetails));
	}
}
