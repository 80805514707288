import { Component, OnInit, Inject } from '@angular/core';
import { Listing } from '../interfaces/ListingInterface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { type_of_people, report_reason } from '../data/ReportData';
import { ListingsService } from '../services/listings.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../services/authentication.service';

@Component({
	selector: 'app-report-dialog',
	templateUrl: './report-dialog.component.html',
	styleUrls: [ './report-dialog.component.css' ]
})
export class ReportDialogComponent implements OnInit {
	form: FormGroup;

	requestInProgress = false;
	messageSent = false;

	type_of_people = type_of_people;
	report_reason = report_reason;

	constructor(
		public readonly dialogRef: MatDialogRef<ReportDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: Listing,
		public readonly formBuilder: FormBuilder,
		private readonly listingService: ListingsService,
		private readonly authService: AuthenticationService
	) {}

	ngOnInit() {
		this.form = this.formBuilder.group({
			name: [ '', [ Validators.required, Validators.maxLength(35) ] ],
			email: [ '', [ Validators.required, Validators.maxLength(50), Validators.email ] ],
			proposito: [ '', [ Validators.required ] ],
			usuario: [ '', [ Validators.required ] ],
			mensaje: [ '', [ Validators.required, Validators.minLength(10), Validators.maxLength(255) ] ]
		});

		if (this.authService.isAuthenticated()) {
			const { first_name, last_name, email } = this.authService.getUserDetails();
			this.form.get('name').setValue(`${first_name} ${last_name}`);
			this.form.get('email').setValue(email);
		}
	}

	onClickSend(): void {
		const payload = {
			listing_id: this.data.listing_id,
			name: this.form.get('name').value.trim(),
			email: this.form.get('email').value.trim().toLowerCase(),
			proposito: this.form.get('proposito').value,
			who: this.form.get('usuario').value,
			message: this.form.get('mensaje').value.trim()
		};

		this.requestInProgress = true;
		this.listingService.reportListing(payload).subscribe(() => {
			this.requestInProgress = false;
			this.messageSent = true;
		});
	}
}
