import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Listing } from '../interfaces/ListingInterface';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';

@Component({
	selector: 'app-dialog-compartir',
	templateUrl: './dialog-compartir.component.html',
	styleUrls: ['./dialog-compartir.component.css']
})
export class DialogCompartirComponent implements OnInit {
	domain = this.locale === 'en' ? 'https://www.finditpr.com/en' : 'https://www.finditpr.com';

	enlaceForm = new FormControl(this.domain, []);
	form: FormGroup;

	requestInProgress = false;
	messageSent = false;

	constructor(
		public readonly dialogRef: MatDialogRef<DialogCompartirComponent>,
		@Inject(MAT_DIALOG_DATA) public data: Listing,
		@Inject(LOCALE_ID) public locale: string,
		public readonly formBuilder: FormBuilder,
		private readonly userService: UserService,
		private _snackBar: MatSnackBar,
		private readonly authService: AuthenticationService
	) {}

	ngOnInit() {
		if (!!this.data) {
			this.enlaceForm.setValue(this.domain + this.data.detailURL);
			this.enlaceForm.disable();
		}

		this.form = this.formBuilder.group({
			sentFromName: ['', [Validators.required, Validators.maxLength(25)]],
			sentFromEmail: ['', [Validators.required, Validators.email]],
			sendToEmail: ['', [Validators.required, Validators.email]],
			message: [
				this.locale === 'en' ? 'Check out this listing on Findit!' : '¡Mira este listado en Findit!',
				[Validators.maxLength(255)]
			]
		});

		if (this.authService.isAuthenticated()) {
			const { first_name, last_name, email } = this.authService.getUserDetails();
			this.form.get('sentFromName').setValue(`${first_name} ${last_name}`);
			this.form.get('sentFromEmail').setValue(email || '');
		}
	}

	openSnackBar() {
		const message = this.locale === 'en' ? 'Link copied to clipboard' : '¡Enlace copiado!';
		this._snackBar.open(message, 'Ok', {
			duration: 1500
		});
	}

	onClickEnviar(): void {
		const payload = {
			sentFromName: this.form.get('sentFromName').value.trim(),
			sentFromEmail: this.form.get('sentFromEmail').value.trim().toLowerCase(),
			sendToEmail: this.form.get('sendToEmail').value.trim().toLowerCase(),

			message: this.form.get('message').value.trim(),

			listingID: this.data.listing_id,
			listingPrice: this.data['current_price'],
			cuartosOterreno: this.data.bedrooms || this.data['land_type'],
			banosOsubcat: +this.data.bathrooms || this.data['land_sub_type'],
			listingStreet: this.data.street,
			listingCity: this.data.city,
			listingImage: this.data['listing_cover_picture'],
			home_type: `${this.data['property_type']} en ${this.data.type}`
		};

		this.requestInProgress = true;
		this.userService.shareListingViaFindit(payload).subscribe(() => {
			this.requestInProgress = false;
			this.messageSent = true;
		});
	}
}
