<div class="parentContainer hero-image-night">
	<div class="search-container">
		<h3 class="heroTitle heading-m" i18n>Encuentra el lugar perfecto en Puerto Rico</h3>
		<div class="ventaAlquilerContainer">
			<div class="ventaAlquilerFonts venta" (click)="onSelectVentaAlquiler()">
				<span class="hoverEffect" [ngClass]="{ selected: ventaSelected }" i18n>Venta</span>
			</div>
			<div class="ventaAlquilerFonts alquiler" (click)="onSelectVentaAlquiler()">
				<span class="hoverEffect" [ngClass]="{ selected: !ventaSelected }" i18n>Alquiler</span>
			</div>
		</div>
		<app-auto-complete [isVenta]="ventaSelected"></app-auto-complete>
		<div class="see-all-container">
			<a [routerLink]="'/puerto-rico/' + (ventaSelected ? 'venta' : 'alquiler')">
				<span i18n> Ver todo el inventario </span>
			</a>
		</div>
	</div>
	<div (click)="scroll(target)" class="arrow-div">
		<h5 class="descubre-mas" style="margin-bottom: 0" i18n>Descubre más</h5>
		<i-feather name="arrow-down" class="descubre-mas-arrow"></i-feather>
	</div>
</div>

<div class="container-wrap">
	<div class="first-container" #target>
		<div class="heading-m" style="color: var(--grayscale-2)">
			Trending
			<img
				src="../../assets/trending.svg"
				loading="lazy"
				alt="trending-icon"
				style="vertical-align: text-top !important"
			/>
		</div>
		<div class="text-l" style="font-weight: 400 !important; color: var(--grayscale-4)" i18n>
			Propiedades más vistas en los últimos 7 días
		</div>
		<div class="carousel-container">
			<div class="carousel-by-me" #listingsContainer>
				<ng-container *ngIf="allListings; else isLoading">
					<div class="cards-margins cards-width" *ngFor="let listing of allListings">
						<app-listing-card [listing]="listing"></app-listing-card>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>

<div class="container-wrap steps-container-wrap">
	<h2 i18n>Te llevamos de la mano</h2>
	<p i18n>No importa si alquilas o compras somos los pasos necesarios para la propiedad que buscas.</p>
	<div class="newCards-container">
		<div class="newCards" routerLink="/puerto-rico/venta">
			<img src="../../assets/home-page/search.svg" alt="Search icon" />
			<div style="margin-top: 30px">
				<h5 i18n>Busca propiedades</h5>
				<p i18n>Utiliza el mapa y filtros para tu búsqueda.</p>
			</div>
			<a>
				<span class="textLink" i18n>Buscar propiedades</span>
			</a>
		</div>

		<div class="newCards" routerLink="/puerto-rico/venta">
			<img src="../../assets/home-page/save.svg" alt="Save icon" />
			<div style="margin-top: 30px">
				<h5 i18n>Guarda tus búsquedas</h5>
				<p i18n>Te notificamos nuevos listados para tus intereses.</p>
			</div>
			<a>
				<span class="textLink" i18n>Guardar mi Búsqueda</span>
			</a>
		</div>

		<div class="newCards" routerLink="precualificate">
			<img src="../../assets/home-page/verified.svg" alt="Verified icon" />
			<div style="margin-top: 30px">
				<h5 i18n>Pre-cualificate</h5>
				<p i18n>Te brindamos ayuda en el proceso de cualificación.</p>
			</div>
			<a>
				<span class="textLink" i18n>Ir a pre-cualificación</span>
			</a>
		</div>

		<div class="newCards" routerLink="/servicios">
			<img src="../../assets/home-page/groups.svg" alt="Icon of a group of people" />
			<div style="margin-top: 30px">
				<h5 i18n>Consigue proveedores</h5>
				<p i18n>Los servicios del hogar que necesitas.</p>
			</div>
			<a>
				<span class="textLink" i18n>Ver Proveedores</span>
			</a>
		</div>
	</div>
</div>

<div class="grey-section">
	<div class="publica-con-nosotros">
		<div>
			<h1 class="heading-s" i18n>¿Vendes o alquilas una propiedad?</h1>
			<h2 class="text-m" style="font-weight: 400 !important; color: var(--grayscale-4); opacity: 0.8" i18n>
				Publica tu listado sin costo y sin límite de espacios
			</h2>
		</div>
		<div class="publica-btn">
			<button mat-button class="primary-background-color" routerLink="/publica" style="color: white" i18n>
				Publicar mi listado
			</button>
		</div>
	</div>
</div>

<div class="container-wrap">
	<div class="fourth-container">
		<h6 class="heading-m" style="color: var(--grayscale-2)" i18n>Categorías populares</h6>
		<div class="cats-container">
			<div class="pics image-hover-effect">
				<a [routerLink]="'puerto-rico/casas-en-venta'">
					<img
						src="../../assets/home-page/interior-compressed.jpg"
						loading="lazy"
						width="331px"
						height="333px"
						i18n-alt
						alt="Casas en Puerto Rico"
					/>
					<h6 class="pic-desc heading-s" i18n>Casas</h6>
					<div class="cat-shadow"></div>
				</a>
			</div>
			<div class="pics image-hover-effect">
				<a [routerLink]="'puerto-rico/locales-comerciales-en-venta'">
					<img
						src="../../assets/home-page/locales-compressed.jpg"
						loading="lazy"
						width="331px"
						height="333px"
						i18n-alt
						alt="Locales Comerciales en Puerto Rico"
					/>
					<h6 class="pic-desc heading-s" i18n>Locales Comerciales</h6>
					<div class="cat-shadow"></div>
				</a>
			</div>
			<div class="pics image-hover-effect">
				<a [routerLink]="'puerto-rico/terrenos-en-venta'">
					<img
						src="../../assets/home-page/terreno-compressed.jpg"
						loading="lazy"
						width="331px"
						height="333px"
						i18n-alt
						alt="Terrenos en Puerto Rico"
					/>
					<h6 class="pic-desc heading-s" i18n>Fincas y Solares</h6>
					<div class="cat-shadow"></div>
				</a>
			</div>
		</div>
	</div>
</div>

<ng-template #isLoading>
	<div class="cards-margins cards-width" *ngFor="let i of [1, 2, 3]">
		<ngx-skeleton-loader
			count="1"
			appearance="circle"
			[theme]="{
				width: '-webkit-fill-available',
				height: '224px',
				'border-radius': '4px',
				margin: '0px'
			}"
		></ngx-skeleton-loader>
		<ngx-skeleton-loader
			count="1"
			[theme]="{
				width: '110px',
				'border-radius': '4px',
				'margin-top': '12px',
				'margin-bottom': '8px'
			}"
		></ngx-skeleton-loader>
		<br />
		<ngx-skeleton-loader
			count="1"
			[theme]="{
				width: '175px',
				'border-radius': '4px',
				margin: '0px'
			}"
		></ngx-skeleton-loader>
	</div>
</ng-template>
