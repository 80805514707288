import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
@Injectable()
export class SigninSignupGuard implements CanActivate {
	constructor(private readonly auth: AuthenticationService, private readonly router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (this.auth.isAuthenticated()) {
			const navigate = '/mi-perfil/notificaciones';
			this.router.navigate([ navigate ]);
			return false;
		}
		return true;
	}
}
