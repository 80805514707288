import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { MetaTagsService } from '../../../../services/meta-tags.service';
import { GeographyService } from '../../../../services/geography.service';

@Component({
	selector: 'app-site-map-barrios',
	templateUrl: './site-map-barrios.component.html',
	styleUrls: ['./site-map-barrios.component.css']
})
export class SiteMapBarriosComponent implements OnInit, OnDestroy {
	neighborhood: string = '';
	city: string = '';
	sitemapURI = '/mapa-de-sitio';
	isVenta: boolean = true;

	neighborObs = this.route.paramMap.pipe(
		switchMap((params) => {
			const city: string = params.get('city');
			this.city = this.getCity(city);

			const barrio: string = params.get('barrio');
			this.neighborhood = this.getBarrio(barrio);

			this.isVenta = !city.includes('alquiler');
			if (this.isVenta) {
				return this.geoService.getListingsByBarriosAlquiler(this.city, this.neighborhood);
			} else {
				return this.geoService.getListingsByBarriosVenta(this.city, this.neighborhood);
			}
		}),
		tap((res: Object[]) => {
			if (res.length > 0 && res[0]['INVALID PARAMETERS'] != null) {
				this.router.navigateByUrl(this.sitemapURI);
			}
			const title = this.isVenta ? 'Bienes Raíces' : 'Alquileres';
			const description = `${this.neighborhood} ${title}, todas las propiedades en ${
				this.isVenta ? 'venta' : 'alquiler'
			} en ${this.neighborhood}. Casas, Apartamentos, Locales Comerciales, Oficinas, Terrenos y más.`;
			this.metaTagService.setCustomTags(
				`${this.neighborhood} ${title} - Propiedades en ${this.isVenta ? 'venta' : 'alquiler'} | Findit`,
				description
			);
		})
	);

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private readonly geoService: GeographyService,
		private readonly metaTagService: MetaTagsService
	) {}

	ngOnInit() {}

	private capitalize(str: string): string {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	private getCity(str: string) {
		if (
			str === null ||
			(!str.includes('-propiedades-en-alquiler') && !str.includes('-bienes-raices')) ||
			str.length < 18 ||
			str.length > 37
		) {
			this.router.navigateByUrl(this.sitemapURI);
		}

		if (str.includes('-propiedades-en-alquiler')) {
			return str
				.substring(0, str.indexOf('propiedades') - 1)
				.replace('-', ' ')
				.trim()
				.split(' ')
				.map(this.capitalize)
				.join(' ');
		} else {
			return str
				.substring(0, str.indexOf('bienes') - 1)
				.replace('-', ' ')
				.trim()
				.split(' ')
				.map(this.capitalize)
				.join(' ');
		}
	}

	private getBarrio(str: string) {
		if (str === null || str.length < 3 || str.length > 23) {
			this.router.navigateByUrl(this.sitemapURI);
		}

		return str.split('-').join(' ').trim().split(' ').map(this.capitalize).join(' ');
	}

	ngOnDestroy(): void {
		this.metaTagService.setDefaultTags();
	}
}
