import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ContactUsService {
	readonly URI = `${environment.api}/contact-us`;
	constructor(private readonly http: HttpClient) {}

	contactStaff(data: Object) {
		return this.http.post(this.URI, JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } });
	}
}
