import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { GeographyService } from '../../../services/geography.service';
import { MetaTagsService } from '../../../services/meta-tags.service';

@Component({
	selector: 'app-sitemap-city',
	templateUrl: './sitemap-city.component.html',
	styleUrls: ['./sitemap-city.component.css']
})
export class SitemapCityComponent implements OnInit, OnDestroy {
	cityName: string = '';
	barrios: string[] = [];
	sitemapURI = '/mapa-de-sitio';
	alquilerOVenta = '';
	isVenta: boolean = true;

	cityObs = this.route.paramMap.pipe(
		switchMap((params) => {
			const str: string = params.get('city');
			this.cityName = this.getCity(str);
			this.isVenta = !str.includes('alquiler');
			this.alquilerOVenta = !this.isVenta ? 'Alquiler' : 'Venta';
			return this.geoService.getBarrios(this.cityName);
		}),
		tap((res: string[]) => {
			if (res.length === 0) {
				this.router.navigateByUrl(this.sitemapURI);
			}
			this.barrios = res;
			const title = this.alquilerOVenta === 'Alquiler' ? 'Alquileres' : 'Bienes Raíces';
			const description = `${
				this.cityName
			} ${title}, encuentra propiedades en ${this.alquilerOVenta.toLocaleLowerCase()} en ${
				this.cityName
			} por barrios. Casas, Apartamentos, Locales Comerciales, Oficinas, Terrenos y más.`;
			this.metaTagService.setCustomTags(
				`${this.cityName} ${title} - Propiedades en ${this.alquilerOVenta} | Findit`,
				description
			);
		})
	);

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private readonly geoService: GeographyService,
		private readonly metaTagService: MetaTagsService
	) {}

	ngOnInit() {}

	private capitalize(str: string): string {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	private getCity(str: string) {
		if (
			str === null ||
			(!str.includes('-propiedades-en-alquiler') && !str.includes('-bienes-raices')) ||
			str.length < 18 ||
			str.length > 37
		) {
			this.router.navigateByUrl(this.sitemapURI);
		}

		if (str.includes('-propiedades-en-alquiler')) {
			return str
				.substring(0, str.indexOf('propiedades') - 1)
				.replace('-', ' ')
				.trim()
				.split(' ')
				.map(this.capitalize)
				.join(' ');
		} else {
			return str
				.substring(0, str.indexOf('bienes') - 1)
				.replace('-', ' ')
				.trim()
				.split(' ')
				.map(this.capitalize)
				.join(' ');
		}
	}

	ngOnDestroy(): void {
		this.metaTagService.setDefaultTags();
	}

	getBarrioLink(barrio: string): string {
		return barrio.trim().toLocaleLowerCase().split(' ').join('-');
	}

	private getFormattedCityForLink(): string {
		return this.cityName.trim().toLocaleLowerCase().split(' ').join('-');
	}

	getPopularLink(concatURI: string): string {
		return '/' + this.getFormattedCityForLink() + concatURI + this.alquilerOVenta.toLowerCase();
	}
}
