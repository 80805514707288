<div class="title-container">
    <!-- <div class="right-fade"></div> -->
    <div class="btn-container">
        <div>
            <button [ngClass]="{'clear-with-primary-btn': number === 3 }" [autofocus]="false" mat-stroked-button
                (click)="onSelect(3)">
                <ng-container i18n>
                    Ver Ubicación
                </ng-container>
            </button>
        </div>
        <div>
            <button [ngClass]="{'clear-with-primary-btn': number === 1 }" [autofocus]="false" mat-stroked-button
                (click)="onSelect(1)">
                <ng-container i18n>
                    Encuentra cerca
                </ng-container>
            </button>
        </div>
        <div>
            <button [ngClass]="{'clear-with-primary-btn': number === 2 }" [autofocus]="false" mat-stroked-button
                *ngIf="hayStreetView" (click)="onSelect(2)">Street View</button> 
        </div>
        <div>
            <button [ngClass]="{'clear-with-primary-btn': number === 4 }" [autofocus]="false" mat-stroked-button
                (click)="onSelect(4)" *ngIf="this.data.listing.virtual_tour">3D Tour</button>
        </div>
    </div>
    <!-- <div class="right-fade"></div> -->
    <div class="spacer"></div>
    <button mat-icon-button matPrefix class="close-icon" (click)="dialogRef.close()">
        <mat-icon>add</mat-icon>
    </button>
</div>
<div class="main-container" mat-dialog-content>
    <div *ngIf="(this.data.listing.virtual_tour && !hideTour)" class="tour-container">
        <div class="loading-container">
            <div class="loadingParent">
                <div>
                    <ul class="loading">
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
                <div class="error-handling-div" *ngIf="tourError">
                    <h5 i18n>Si el 3D tour no carga automáticamente, intente lo siguiente:</h5>
                    <ol>
                        <li>
                            <h6 i18n>Asegurarse que la configuración de su navegador no esté bloqueando "cookies" de
                                terceros.</h6>
                        </li>
                        <li>
                            <h6 i18n>Acceder desde otro navegador.</h6>
                        </li>
                        <li>
                            <h6> <a [routerLink]="'/contactanos'" (click)="dialogRef.close()" i18n>Contáctanos</a>
                                <ng-container>
                                    acerca del problema y lo trabajeremos lo antes posible.
                                </ng-container>
                            </h6>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <iframe style="position: absolute;" [src]="trustedURL" (load)="myLoadEvent($event)" class="tour"
            frameborder="0"></iframe>
    </div>

    <div class="street-view-container" *ngIf="hayStreetView && !hideStreetView">
        <ngui-map zoom="14" *ngIf="hayStreetView && !hideStreetView" center="37.869260, -122.254811">
            <street-view-panorama position="{{data.listing.latitude}}, {{data.listing.longitude}}"
                pov="{ heading: 165, pitch: 0 }" zoom="1" #sv>
            </street-view-panorama>
        </ngui-map>
    </div>

    <div class="map-container" [ngClass]="{ 'display-none': (!hideStreetView || !hideTour) }">
        <div #mapContainerNearbyPopUp id="mapNearby"
            [ngClass]="{ 'full-mobile-map': !hideMenu, 'full-height': verUbicacion }"></div>
        <div class="overmap-label" [ngClass]="{ 'closed-yelp': !hideMenu, 'hide': verUbicacion }">
            <div class="lugar-container">
                <h6>{{lugar}}</h6>
                <div class="spacer"></div>
                <button mat-icon-button class="arrow-button" (click)="hideYelpMenu()">
                    <mat-icon>
                        {{icon}}
                    </mat-icon>
                </button>
            </div>
            <div class="yelp-reference" [ngClass]="{'no-margin-top': !hideMenu}">
                Amenity information provided by <a target="_blank"> <img height="16" width="32"
                        style="margin-bottom: 5px;" src="../../assets/yelp/yelp_logo_small.png" alt="Yelp"></a>
            </div>

            <div class="buttons-div" [ngClass]="{'hide': !hideMenu }">
                <button mat-icon-button (click)="scrollLeft()">
                    <mat-icon>
                        keyboard_arrow_left
                    </mat-icon>
                </button>
                <ul class="ulButtons" #buttonsContainer [ngStyle]="{'display': !hideMenu ? 'none' : 'flex'}">
                    <li>
                        <button mat-button (click)="onClickYelp('Restaurantes')">
                            <ng-container i18n>
                                Restaurantes
                            </ng-container>
                        </button>
                    </li>

                    <li>
                        <button mat-button (click)="onClickYelp('Vida Nocturna')">
                            <ng-container i18n>
                                Vida Nocturna
                            </ng-container>
                        </button>
                    </li>

                    <li>
                        <button mat-button (click)="onClickYelp('Fitness')">
                            <ng-container i18n>
                                Fitness
                            </ng-container>
                        </button>
                    </li>

                    <li>
                        <button mat-button (click)="onClickYelp('Compras')">
                            <ng-container i18n>
                                Compras
                            </ng-container>
                        </button>
                    </li>
                </ul>
                <button mat-icon-button (click)="scrollRight()">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </div>

            <ng-container *ngIf="!requestInProgress; else loading">
                <ul class="yelp-places">
                    <li *ngFor="let element of elements">
                        <img class="place-picture" src="{{element.image_url}}" alt="" />
                        <div class="info-container">
                            <h6 class="place-name">{{element.name}}</h6>
                            <h6 class="place-cat">{{element.categories[0].title}}</h6>
                            <div class="rating-container">
                                <img src="../../assets/yelp/small{{element.rating}}.png" height="14px"
                                    alt="{{element.rating}}">
                                <span class="review-count">
                                    {{element.review_count}}
                                    Reviews
                                </span>
                                <div class="spacer"></div>
                                <a href="{{element.url}}" class="href-yelp">
                                    <img width="32" height="16" src="../../assets/yelp/yelp_logo_small.png" alt="Yelp">
                                </a>
                            </div>
                        </div>
                    </li>
                </ul>
            </ng-container>

        </div>
    </div>
</div>

<ng-template #loading>
    <div class="loadingParent">
        <ul class="loading">
            <li></li>
            <li></li>
            <li></li>
        </ul>
    </div>
</ng-template>