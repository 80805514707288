<ng-container *ngIf="listing">
	<article class="active-listing-card">
		<app-image-card-carousel
			class="images-carousel"
			[listingLink]="listing.detailURL"
			[images]="listingImages"
			[firstImage]="listing.listing_cover_picture"
			[height]="'180px'"
		>
			<div
				class="label-container"
				*ngIf="
					listing.listing_status === 'Alquilado/Alquilada' ||
					listing.listing_status === 'Vendido/Vendida' ||
					listing.listing_status === 'Opcionado/Opcionada'
				"
			>
				<div class="text-s-sb" *ngIf="listing.isForeclosure === 1">REO</div>
				<div class="text-s-sb" *ngIf="listing.listing_status === 'Opcionado/Opcionada'" i18n>Opcionado</div>
				<div
					class="text-s-sb vendido"
					*ngIf="
						listing.listing_status === 'Alquilado/Alquilada' || listing.listing_status === 'Vendido/Vendida'
					"
				>
					{listing.listing_status, select, Alquilado/Alquilada {Alquilado} other {Vendido} }
				</div>
			</div>
			<i-feather
				name="Heart"
				class="heart-position"
				[ngClass]="{
					liked: listing.isListingSaved == 1,
					'not-liked': !listing.isListingSaved || listing.isListingSaved == 0
				}"
				(click)="onLikeAction()"
			></i-feather>
			<ng-content></ng-content>
		</app-image-card-carousel>
		<img [src]="listing.listing_cover_picture" class="mobile-img" alt="" width="134px" height="96px" />
		<div class="info-container">
			<a [routerLink]="listing.detailURL" target="_blank">
				<div class="heading-s price">
					{{ listing.price | currency : 'USD' : 'symbol' : '1.0' }}
					<span class="street text-s" *ngIf="listing.price_type !== 'Total'">
						/{{ locale === 'en' ? listing.price_type_en : listing.price_type }}</span
					>
				</div>
				<address class="street text-s text-overflow">
					{{ listing.street }}
					<br />
					<div style="margin-top: 4px"></div>
					{{ listing.city + ' ' + listing.zip + ' PR' }}
				</address>
				<div
					*ngIf="listing.property_type !== 'Terreno'; else lands"
					class="text-s bds-bths text-overflow"
					style="color: var(--grayscale-3); margin-top: 4px; margin-bottom: 4px"
				>
					<ng-container *ngIf="listing.bedrooms">
						{{ listing.bedrooms }}
						{listing.bedrooms, plural, =1 {Cuarto} other {Cuartos}}
						<span style="color: var(--grayscale-9)">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
					</ng-container>

					<ng-container *ngIf="listing.property_units">
						{{ listing.property_units }} {listing.property_units, plural, =1 {Unidad} other {Unidades}}
						<span style="color: var(--grayscale-9)">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
					</ng-container>

					<ng-container *ngIf="listing.bathrooms > 0">
						{{ +listing.bathrooms + +listing.half_bathrooms }}&nbsp;{ +listing.bathrooms +
						+listing.half_bathrooms, plural, =1 {Baño} other {Baños} }
						<span style="color: var(--grayscale-9)">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
					</ng-container>

					<ng-container *ngIf="listing.livable_area > 0; else unknownSize">
						{{ +listing.livable_area | number }} {{ listing.livable_area_unit
						}}<sup *ngIf="listing.livable_area_unit === 'm' || listing.livable_area_unit === 'ft'">2</sup>
					</ng-container>
				</div>
				<div class="text-s prop-type" style="color: var(--grayscale-3)">
					<ng-container *ngIf="!sold; else soldTemplate">
						<span [ngClass]="listing.type === 'Alquiler' ? 'dot-yellow' : 'dot-blue'"></span>
						{{ homeType }}
						{ listing.type, select, Venta {en Venta} other {en Alquiler} }
					</ng-container>
					<ng-template #soldTemplate>
						<ng-container i18n>Vendido</ng-container>
					</ng-template>
				</div>
			</a>
		</div>
	</article>
</ng-container>

<ng-template #lands>
	<div class="text-s bds-bths text-overflow" style="color: var(--grayscale-3)">
		<ng-container *ngIf="listing.land_type && listing.land_type !== 'N/A'">
			{{ locale === 'en' ? listing.land_type_en : listing.land_type }}
			<span style="color: var(--grayscale-9)">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
		</ng-container>

		{{ locale === 'en' ? listing.land_sub_type_en : listing.land_sub_type }}
		<span style="color: var(--grayscale-9)">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
		<ng-container *ngIf="listing.lot_size > 0; else unknown">
			{{ +listing.lot_size | number }} {{ listing.lot_unit
			}}<sup *ngIf="listing.lot_unit === 'm' || listing.lot_unit === 'ft'">2</sup>
		</ng-container>
	</div>
</ng-template>

<ng-template #unknownSize> -- ft<sup>2</sup> </ng-template>
