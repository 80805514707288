<div class="carousel-container">
    <div class="top-bar">
        <button mat-button class="close-btn text-s" style="visibility: hidden;">
            <i-feather class="text-s" style="color: white;" name="upload">
            </i-feather>
        </button>

        <button mat-button class="close-btn text-s" (click)="close()">
            <i-feather class="text-s" style="color: white;" name="X">
            </i-feather>
        </button>
    </div>

    <swiper #swiper [config]="config" class="mySwiper customSwiper">
        <ng-template *ngFor="let i of images; trackBy: trackById" swiperSlide>
            <img [src]="i.image" class="swiper-image" style="object-fit: cover;">
        </ng-template>
    </swiper>
</div>