<div class="close-logo" matRipple (click)="onClickClose()">
	<i-feather name="X"></i-feather>
</div>
<div class="top-container">
	<div class="text-s-sb" style="margin-bottom: 8px" i18n>Resultados: {{ userSettings.total }}</div>
	<div class="text-s-sb" style="margin-bottom: 12px" i18n>Búsqueda</div>
	<div class="search-input-container">
		<form action="." (ngSubmit)="onClickClose()">
			<input
				#searchInputMobile
				class="search-input text-s"
				type="search"
				[attr.disabled]="userSettings.selectedRegions.length >= 5 ? '' : null"
				[formControl]="userSettings.search"
			/>
			<div
				class="search-button-container"
				[ngClass]="{ 'disabled-button': userSettings.selectedRegions.length >= 5 }"
			>
				<ng-container *ngIf="userSettings.search?.value?.length > 0; else searchIconTemplate">
					<button type="button" (click)="onClickClear()">
						<i-feather class="search-feather-icon" name="X"></i-feather>
					</button>
				</ng-container>
				<ng-template #searchIconTemplate>
					<button type="submit">
						<i-feather class="search-feather-icon" name="search"></i-feather>
					</button>
				</ng-template>
			</div>
		</form>
	</div>
	<div class="chips-preview-dialog">
		<div class="chip text-s" *ngFor="let region of userSettings.selectedRegions">
			{{ region.search_term }}
			<button (click)="removeChip(region)">
				<i-feather class="remove-feather-icon" name="X"></i-feather>
			</button>
		</div>
	</div>
</div>
<div class="bottom-container">
	<ng-container *ngIf="!requestInProgress; else loadingSuggestions">
		<ng-container *ngIf="!error; else errorTemplate">
			<ng-container *ngIf="!userSettings.suggestions; else searchTemplate">
				<ng-container *ngTemplateOutlet="searchSuggestionsTemplate"></ng-container>
			</ng-container>
		</ng-container>
	</ng-container>
</div>
<div class="btn-container">
	<button class="reset-btn text-s" (click)="onClickClose()" i18n>Cancelar</button>
	<button class="filter-btn text-s" (click)="onClickClose()" i18n>Listo</button>
</div>

<div *ngIf="requestInProgressListings" class="loading-layer">
	<mat-spinner color="primary" [strokeWidth]="1.5" [diameter]="50"></mat-spinner>
</div>

<ng-template #loadingSuggestions>
	<ul role="listbox">
		<li role="listitem" class="text-s">
			<div>
				<mat-spinner [strokeWidth]="1" [diameter]="20"></mat-spinner>
			</div>
		</li>
	</ul>
</ng-template>
<ng-template #errorTemplate>
	<div class="error-container">
		<div class="text-s" i18n>Algo anda mal :(</div>
		<div style="font-size: 14px" i18n>Por favor intente más tarde</div>
	</div>
</ng-template>
<ng-template #searchSuggestionsTemplate>
	<ng-container *ngIf="userSettings.selectedRegions?.length >= 5; else showSuggestions">
		<div class="text-s five-cat-msg" i18n>Solo puedes buscar 5 áreas a la vez.</div>
	</ng-container>

	<ng-template #showSuggestions>
		<ul>
			<li class="text-s" *ngFor="let suggestion of searchSuggestions" (click)="selectSuggestion(suggestion)">
				<div>
					<span class="text-s-sb">
						{{ locale === 'en' ? suggestion.category_en : suggestion.category }}
					</span>
					<span>
						<i-feather name="chevron-right" style="height: 16px; width: 16px"></i-feather>
					</span>
					<span>
						{{ suggestion.search_term }}
					</span>
				</div>
				<ng-container *ngIf="suggestion.category !== 'Pueblo' && suggestion.category !== 'Area'">
					<div style="font-size: 12px; font-weight: 400 !important">{{ getSource(suggestion) }}</div>
				</ng-container>
			</li>
		</ul>
	</ng-template>
</ng-template>
<ng-template #searchTemplate>
	<div class="recomendations-container">
		<div class="text-s not-found-bold">
			<ng-container i18n> No encontramos </ng-container>
			"<span> {{ userSettings.search.value }} </span>"
		</div>
		<div class="not-found-recomendations" i18n>nuestras recomendaciones:</div>
	</div>
	<ng-container *ngTemplateOutlet="searchSuggestionsTemplate"></ng-container>
</ng-template>
