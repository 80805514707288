import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IServiceProviderFormData } from '../checkout/service-providers/service.providers.checkout.component';
import { CardBrand } from '../user-panel/configuraciones/pagos-y-suscripciones/pagos-y-suscripciones.component';
import { cancel_reason } from '../user-panel/configuraciones/pagos-y-suscripciones/cancel-subscription/cancel-subscription.component';
import { shareReplay } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class PaymentsService {
	private readonly URI = `${environment.api}/payments`;

	constructor(private readonly http: HttpClient) {}

	public payWithSavedCard(client_secret: string, pm_id: string): Observable<Object> {
		return this.http
			.post(`${this.URI}/pay`, {
				client_secret,
				pm_id
			})
			.pipe(shareReplay());
	}

	public getNewPaymentMethodIntent(): Observable<Object> {
		return this.http.get(`${this.URI}/intents/create-payment-method`);
	}

	public getServiceProviderSubscriptionIntent(ad: IServiceProviderFormData) {
		return this.http.post(`${this.URI}/intents/service-provider`, ad);
	}

	public updateServiceProviderDraft(subscription_id: string, ad: IServiceProviderFormData) {
		return this.http.put(`${this.URI}/update/service-provider/checkout-draft`, { ad, subscription_id });
	}

	public getUserSubscriptions(): Observable<Object> {
		return this.http.get(`${this.URI}/user/subscriptions`);
	}

	public getUserPaymentMethods(): Observable<Object> {
		return this.http.get(`${this.URI}/user/payment-methods`);
	}

	public setDefaultPaymentMethod(pm_id: string): Observable<Object> {
		return this.http.post(`${this.URI}/user/payment-methods/default`, {
			pm_id
		});
	}

	public getUserTransactions(param_name: string, param_value: string): Observable<Object> {
		const params = {};
		if (param_value) {
			params[param_name] = param_value;
		}
		return this.http.get(`${this.URI}/user/transactions`, {
			params
		});
	}

	public cancelSubscription(cancel_reason: cancel_reason, ad_id: number): Observable<Object> {
		return this.http.delete(`${this.URI}/cancel-subscription`, {
			body: {
				cancel_reason,
				ad_id
			}
		});
	}

	public removePaymentMethod(pm_id: string): Observable<Object> {
		return this.http
			.delete(`${this.URI}/user/payment-methods/remove`, {
				body: {
					pm_id
				}
			})
			.pipe(shareReplay());
	}

	public reactivateSubscription(subscription_id: number): Observable<Object> {
		return this.http.put(`${this.URI}/reactivate-subscription/${subscription_id}`, {});
	}

	public getCardIcon(card: CardBrand): string {
		const data = {
			amex: '../../../../assets/cards/amex.svg',
			diners: '',
			discover: '../../../../assets/cards/discover.svg',
			jcb: '',
			mastercard: '../../../../assets/cards/mc.svg',
			unionpay: '',
			visa: '../../../../assets/cards/visa.svg',
			unknown: ''
		};
		return data[card];
	}

	public getCardName(card: CardBrand): string {
		const data = {
			amex: 'Amex',
			diners: '',
			discover: 'Discover',
			jcb: '',
			mastercard: 'MasterCard',
			unionpay: '',
			visa: 'Visa',
			unknown: ''
		};
		return data[card];
	}
}
