import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Listing } from '../interfaces/ListingInterface';
import { IServiceProviderAd } from '../service-providers-directory/service-providers-directory.component';
import { PropertyType } from '../listings-component/listings-component.component';

export type HomeTypeSEO =
	| 'propiedades'
	| 'terrenos'
	| 'apartamentos'
	| 'casas'
	| 'locales'
	| 'edificios'
	| 'oficinas'
	| 'almacenes'
	| 'estudios';
@Injectable({
	providedIn: 'root'
})
export class MetaTagsService {
	constructor(
		@Inject(LOCALE_ID) private locale: string,
		private readonly titleService: Title,
		private readonly meta: Meta
	) {}

	private getEnglishPropertyName(prop: string): string {
		prop = prop.trim().toLowerCase();
		if (prop === 'propiedades') {
			return 'Homes';
		} else if (prop === 'terrenos') {
			return 'Lands';
		} else if (prop === 'apartamentos') {
			return 'Apartments';
		} else if (prop === 'casas') {
			// return 'Single Family Homes';
			return 'Homes';
		} else if (prop === 'locales') {
			return 'Commercial Properties';
		}
		return prop;
	}

	public setSearchTags(str: string, for_: PropertyType, propiedad: HomeTypeSEO): void {
		const defaultImage = 'https://www.finditpr.com/assets/og-image.jpg';
		let description = '';
		if (for_ === 'for_sale') {
			if (this.locale === 'en') {
				const enProp = this.getEnglishPropertyName(propiedad);
				const ogStr = str;
				str = `${str} Real Estate - ${enProp} for sale | Findit`;
				description = `${enProp} for sale in ${ogStr}. Browse photos and use detailed real estate filters to find the perfect place.`;
			} else {
				str = `${str} Bienes Raíces - ${propiedad} en venta | Findit`;
				description = `${propiedad} en venta en ${str}. Vea fotos y utilize los filtros para encontrar su proxima propiedad.`;
			}
		} else if (for_ === 'for_rent') {
			if (this.locale === 'en') {
				const enProp = this.getEnglishPropertyName(propiedad);
				const ogStr = str;
				str = `${str} Real Estate - ${enProp} for rent | Findit`;
				description = `${enProp} for rent in ${ogStr}. Browse photos and use detailed real estate filters to find the perfect place.`;
			} else {
				str = `${str} - ${propiedad} en alquiler | Findit`;
				description = `${propiedad} en alquiler en ${str}. Vea fotos, utilize los filtros y guarde su búsqueda.`;
			}
		} else {
			if (this.locale === 'en') {
				const enProp = this.getEnglishPropertyName(propiedad);
				const ogStr = str;
				str = `${str} Real Estate - ${enProp} sold | Findit`;
				description = `${enProp} for rent in ${ogStr}. Browse photos and use detailed real estate filters to find the perfect place.`;
			} else {
				str = `${str} - ${propiedad} comparables | Findit`;
				description = `${propiedad} comparables en ${str}. Vea fotos, utilize los filtros y guarde su búsqueda.`;
			}
		}

		this.titleService.setTitle(str);
		this.meta.updateTag({ name: 'description', content: description });
		this.meta.updateTag({ name: 'title', content: str });

		// Social Media (Facebook, iMessage, Whatsapp)
		this.meta.updateTag({ property: 'og:type', content: 'website' });
		// // this.meta.updateTag({property: 'og:url', content: URL});
		this.meta.updateTag({ property: 'og:title', content: str });
		this.meta.updateTag({ property: 'og:description', content: description });
		this.meta.updateTag({ property: 'og:image', content: defaultImage });

		// Twitter
		// this.meta.updateTag({property: 'twitter:url', content: URL});
		this.meta.updateTag({ property: 'twitter:title', content: str });
		this.meta.updateTag({ property: 'twitter:description', content: description });
		this.meta.updateTag({ property: 'twitter:image', content: defaultImage });
	}

	public getTitleMetaTag(): string {
		return this.meta.getTag('name=title').content;
	}

	public setListingTags(listing: Listing, route: string): void {
		const URL = `https://www.finditpr.com${route}`;

		this.titleService.setTitle(this.getListingTitle(listing));
		this.meta.updateTag({ name: 'description', content: listing.listing_description });
		this.meta.updateTag({ name: 'title', content: this.getListingTitle(listing) });

		// OpenGraph (Facebook, iMessage, Whatsapp)
		this.meta.updateTag({ property: 'og:type', content: 'website' });
		this.meta.updateTag({ property: 'og:url', content: URL });
		this.meta.updateTag({ property: 'og:title', content: this.getListingTitle(listing) });
		this.meta.updateTag({ property: 'og:description', content: listing.listing_description });
		this.meta.updateTag({ property: 'og:image', content: listing.listing_cover_picture });

		this.meta.addTag({ property: 'product:price:amount', content: `${listing.current_price}.00` });
		this.meta.addTag({ property: 'product:price:currency', content: 'USD' });

		// Twitter
		this.meta.updateTag({ property: 'twitter:url', content: URL });
		this.meta.updateTag({ property: 'twitter:title', content: this.getListingTitle(listing) });
		this.meta.updateTag({ property: 'twitter:description', content: listing.listing_description });
		this.meta.updateTag({ property: 'twitter:image', content: listing.listing_cover_picture });
	}

	public setDefaultTags(): void {
		let URL = `https://www.finditpr.com`;
		const defaultImage = 'https://www.finditpr.com/assets/og-image.jpg';
		let defaultTitle = 'Findit: Puerto Rico Bienes Raíces';
		let defaultDescription =
			'Encuentra propiedades en venta o alquiler en Puerto Rico. Búsqueda con mapa de casas, apartamentos, locales comerciales, terrenos, y mucho más.';

		if (this.locale === 'en') {
			defaultTitle = 'Findit: Puerto Rico Real Estate';
			defaultDescription =
				'Find homes for sale in Puerto Rico. Use our interactive map to find homes, apartments, lands and more.';
			URL = URL + '/en';
		}

		this.titleService.setTitle(defaultTitle);
		this.meta.updateTag({ name: 'description', content: defaultDescription });
		this.meta.updateTag({ name: 'title', content: defaultTitle });

		// Social Media (Facebook, iMessage, Whatsapp)
		this.meta.updateTag({ property: 'og:type', content: 'website' });
		this.meta.updateTag({ property: 'og:url', content: URL });
		this.meta.updateTag({ property: 'og:title', content: defaultTitle });
		this.meta.updateTag({ property: 'og:description', content: defaultDescription });
		this.meta.updateTag({ property: 'og:image', content: defaultImage });

		this.meta.removeTag("property='product:price:amount'");
		this.meta.removeTag("property='product:price:currency'");

		// Twitter
		this.meta.updateTag({ property: 'twitter:url', content: URL });
		this.meta.updateTag({ property: 'twitter:title', content: defaultTitle });
		this.meta.updateTag({ property: 'twitter:description', content: defaultDescription });
		this.meta.updateTag({ property: 'twitter:image', content: defaultImage });
	}

	private getListingTitle(listing: Listing): string {
		return `${listing.street}, ${listing.city}, PR ${listing.zip} | Findit`;
	}

	public setCustomTags(
		title: string,
		description: string,
		image: string = 'https://www.finditpr.com/assets/og-image.jpg',
		ogType: string = 'website',
		URL: string = 'https://www.finditpr.com'
	): void {
		this.titleService.setTitle(title);
		this.meta.updateTag({ name: 'description', content: description });
		this.meta.updateTag({ name: 'title', content: title });

		// Social Media (Facebook, iMessage, Whatsapp)
		this.meta.updateTag({ property: 'og:type', content: ogType });
		this.meta.updateTag({ property: 'og:url', content: URL });
		this.meta.updateTag({ property: 'og:title', content: title });
		this.meta.updateTag({ property: 'og:description', content: description });
		this.meta.updateTag({ property: 'og:image', content: image });

		// Twitter
		this.meta.updateTag({ property: 'twitter:url', content: URL });
		this.meta.updateTag({ property: 'twitter:title', content: title });
		this.meta.updateTag({ property: 'twitter:description', content: description });
		this.meta.updateTag({ property: 'twitter:image', content: image });
	}

	public setServiceProviderTags(ad: IServiceProviderAd): void {
		let description = ad.description;
		if (description.length > 160) {
			description = description.substring(0, 160);
		}
		const URL = `https://www.finditpr.com${ad.slug}`;

		this.titleService.setTitle(ad.business_name);
		this.meta.updateTag({ name: 'description', content: description });
		this.meta.updateTag({ name: 'title', content: ad.business_name });

		// OpenGraph (Facebook, iMessage, Whatsapp)
		this.meta.updateTag({ property: 'og:type', content: 'website' });
		this.meta.updateTag({ property: 'og:url', content: URL });
		this.meta.updateTag({ property: 'og:title', content: ad.business_name });
		this.meta.updateTag({ property: 'og:description', content: description });
		this.meta.updateTag({ property: 'og:image', content: ad.img });

		// Twitter
		this.meta.updateTag({ property: 'twitter:url', content: URL });
		this.meta.updateTag({ property: 'twitter:title', content: ad.business_name });
		this.meta.updateTag({ property: 'twitter:description', content: description });
		this.meta.updateTag({ property: 'twitter:image', content: ad.img });
	}

	public setDirectoryAdsDefaultTags(): void {
		let default_URL = `https://www.finditpr.com`;
		let URL = `${default_URL}/servicios`;
		const defaultImage = 'https://www.finditpr.com/assets/og-image.jpg';
		let defaultTitle = 'Proveedores de Servicios en Puerto Rico | Findit';
		let defaultDescription =
			'Encuentra proveedores de servicios como Tasadores, Desarrolladores y Abogados en Puerto Rico';

		if (this.locale === 'en') {
			defaultTitle = 'Service Providers in Puerto Rico | Findit';
			defaultDescription = 'Find service providers such as Appraisers, Developers and Lawyers in Puerto Rico';
			URL = `${default_URL}/en/servicios`;
		}

		this.titleService.setTitle(defaultTitle);
		this.meta.updateTag({ name: 'description', content: defaultDescription });
		this.meta.updateTag({ name: 'title', content: defaultTitle });

		// Social Media (Facebook, iMessage, Whatsapp)
		this.meta.updateTag({ property: 'og:type', content: 'website' });
		this.meta.updateTag({ property: 'og:url', content: URL });
		this.meta.updateTag({ property: 'og:title', content: defaultTitle });
		this.meta.updateTag({ property: 'og:description', content: defaultDescription });
		this.meta.updateTag({ property: 'og:image', content: defaultImage });

		this.meta.removeTag("property='product:price:amount'");
		this.meta.removeTag("property='product:price:currency'");

		// Twitter
		this.meta.updateTag({ property: 'twitter:url', content: URL });
		this.meta.updateTag({ property: 'twitter:title', content: defaultTitle });
		this.meta.updateTag({ property: 'twitter:description', content: defaultDescription });
		this.meta.updateTag({ property: 'twitter:image', content: defaultImage });
	}
}
