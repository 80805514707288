import { AfterViewInit, Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user.service';
//QR Code
// import QRCodeStyling from 'qr-code-styling';
import { getOptions } from '../../data/profileQROptions';
import { DialogColorPickerComponent } from '../dialog-color-picker/dialog-color-picker.component';
import { Color } from 'ngx-color';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

@Component({
	selector: 'app-dialog-edit',
	templateUrl: './dialog-edit.component.html',
	styleUrls: ['./dialog-edit.component.css']
})
export class DialogEditComponent implements OnInit, AfterViewInit {
	aboutForm = new FormControl('', [Validators.maxLength(2000)]);
	addressForm = new FormControl('', [Validators.maxLength(250)]);
	websiteForm = new FormControl('', [Validators.maxLength(250)]);
	timeForm: FormGroup;
	qrForm: FormGroup = new FormGroup({});
	requestInProgress: boolean = false;

	default_primary_color: Color = {
		hex: '#3d98f1',
		hsl: {
			a: 1,
			h: 209.66666666666666,
			l: 0.592156862745098,
			s: 0.8653846153846153
		},
		hsv: {
			a: 1,
			h: 209.66666666666666,
			s: 0.7468879668049792,
			v: 0.9450980392156862
		},
		oldHue: 209.66666666666666,
		rgb: {
			a: 1,
			b: 241,
			g: 152,
			r: 61
		},
		source: 'hex'
	};
	default_secondary_color: Color = {
		hex: '#3d4d5c',
		hsl: {
			a: 1,
			h: 209.03225806451616,
			l: 0.3,
			s: 0.2026143790849673
		},
		hsv: {
			a: 1,
			h: 209.03225806451616,
			s: 0.3369565217391304,
			v: 0.3607843137254902
		},
		oldHue: 209.03225806451616,
		rgb: {
			a: 1,
			b: 92,
			g: 77,
			r: 61
		},
		source: 'hex'
	};
	default_gray_color = '#3d4d5c';
	findit_primary_color = '#3d98f1';
	options = getOptions(this.findit_primary_color, this.default_gray_color);
	color_options = {
		default: getOptions(this.default_gray_color, this.default_gray_color),
		custom: this.options
	};
	qrCode = null;
	QRCodeStyling = null;
	link = '';

	constructor(
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		public dialogRef: MatDialogRef<DialogEditComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		@Inject(LOCALE_ID) public locale: string,
		private readonly userService: UserService,
		private readonly _fb: FormBuilder,
		private readonly _snackBar: MatSnackBar,
		private readonly dialog: MatDialog,
		private sant: DomSanitizer
	) {
		this.matIconRegistry.addSvgIcon(
			'f-logo',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/fb-logo.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'tw-logo',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/tw_logo_neutral.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'wa-logo',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/wa-logo.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'copy-logo',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/copy-link.svg')
		);
	}

	ngAfterViewInit(): void {
		if (this.data.edit === 'qrcode') {
			this.link = 'https://www.finditpr.com' + this.data.agentInfo.slug;
			this.color_options.custom.data = this.link;
			this.color_options.default.data = this.link;
			this.options.data = this.link;

			if (isPlatformBrowser) {
				this.qrCode = new this.QRCodeStyling(this.options as any);

				const parent = document.getElementById('QRCode');
				this.qrCode.append(parent);
				this.qrForm.get('custom_type').valueChanges.subscribe((isCustom) => {
					this.updateQRCodeColors(isCustom);
				});
			}
		}
	}

	async ngOnInit(): Promise<void> {
		if (isPlatformBrowser) {
			this.QRCodeStyling = await import('qr-code-styling');
		}

		this.aboutForm.setValue(this.data.agentInfo.about || '');
		this.addressForm.setValue(this.data.agentInfo.address || '');
		this.websiteForm.setValue(this.data.agentInfo.website || '');

		this.timeForm = this._fb.group({
			opening: [this.data.agentInfo.opening_hours || ''],
			closing: [this.data.agentInfo.closing_hours || '']
		});

		this.qrForm = this._fb.group({
			custom_type: [true]
		});
	}

	get isCustomQRCode() {
		return this.qrForm.get('custom_type').value;
	}

	private async updateQRCodeColors(isCustom: boolean = true) {
		if (isCustom) {
			const primary_color = `${this.default_primary_color.hex || this.default_gray_color}`;
			const secondary_color = `${this.default_secondary_color.hex || this.default_gray_color}`;
			const tempImg = this.color_options.custom.image;

			this.color_options.custom = getOptions(primary_color, secondary_color);
			this.color_options.custom['image'] = tempImg;
			this.options = this.color_options.custom;
		} else {
			this.options = this.color_options.default;
		}
		this.options.data = this.link;

		this.qrCode.update(this.options as any);
		const qrCodeContainer = document.getElementById('QRCode');
		qrCodeContainer.innerHTML = '';
		qrCodeContainer.append(this.qrCode._svg);
	}

	private getBase64Img(currentPicture: File) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(currentPicture);
			reader.onload = (event) => {
				resolve(event.target.result);
			};
			reader.onerror = (error) => reject(error);
		});
	}

	async onFileSelected($event) {
		if ($event.target.files.length > 0) {
			const fileSelected = $event.target.files[0];
			const base64Img = await this.getBase64Img(fileSelected);
			this.color_options.custom.image = base64Img;
			this.updateQRCodeColors(true);
		}
	}

	onClickSave(): void {
		const edit = this.data.edit;
		const _ = this.userService;
		switch (edit) {
			case 'about': {
				this.requestInProgress = true;
				_.updateAgentAcerca(this.aboutForm.value).subscribe(
					(res: string) => this.response(res),
					(err) => this.error(err)
				);
				break;
			}
			case 'address': {
				this.requestInProgress = true;
				_.updateAgentAddress(this.addressForm.value).subscribe(
					(res: string) => this.response(res),
					(err) => this.error(err)
				);
				break;
			}
			case 'website': {
				this.requestInProgress = true;
				_.updateAgentWebsite(this.websiteForm.value).subscribe(
					(res: string) => this.response(res),
					(err) => this.error(err)
				);
				break;
			}
			case 'time': {
				this.requestInProgress = true;
				let opening = this.timeForm.get('opening').value;
				if (opening === '') {
					opening = null;
				}
				let closing = this.timeForm.get('closing').value;
				if (closing === '') {
					closing = null;
				}
				_.updateAgentTime({ opening, closing }).subscribe(
					(res: string) => this.response(res),
					(err) => this.error(err)
				);
				break;
			}
			case 'qrcode': {
				const tempOptions = { ...this.options };
				tempOptions.width = 1080;
				tempOptions.height = 1080;
				const tempQR = new this.QRCodeStyling(tempOptions as any);
				tempQR.download('png');
				break;
			}
		}
	}

	openColorPicker(isPrimary: boolean) {
		const config = {
			disableClose: true,
			data: isPrimary ? this.default_primary_color : this.default_secondary_color
		};
		const dialogRef = this.dialog.open(DialogColorPickerComponent, config);

		dialogRef.afterClosed().subscribe((color: Color) => {
			if (color) {
				if (isPrimary) {
					this.default_primary_color = color;
				} else {
					this.default_secondary_color = color;
				}
				this.updateQRCodeColors();
			}
		});
	}

	private response(response: string): void {
		const edit = this.data.edit;

		switch (edit) {
			case 'about': {
				this.data.agentInfo.about = this.aboutForm.value;
				break;
			}
			case 'address': {
				this.data.agentInfo.address = this.addressForm.value;
				break;
			}
			case 'website': {
				this.data.agentInfo.website = this.websiteForm.value;
				break;
			}
			case 'time': {
				let opening = this.timeForm.get('opening').value;
				if (opening === '') {
					opening = null;
				}
				let closing = this.timeForm.get('closing').value;
				if (closing === '') {
					closing = null;
				}

				this.data.agentInfo.opening_hours = opening;
				this.data.agentInfo.closing_hours = closing;
				break;
			}
		}

		this.dialogRef.close(this.data.agentInfo);
		this.requestInProgress = false;
		let message = '¡Los cambios fueron realizados exitosamente!';
		if (this.locale === 'en') {
			message = 'The changes were saved successfully!';
		}
		this._snackBar.open(message, 'OK', { duration: 3000 });
	}

	private error(error: string): void {
		console.log(error);
		this.requestInProgress = false;
	}

	openSnackBar() {
		let message = '¡Enlace copiado!';
		if (this.locale === 'en') {
			message = 'Link copied to clipboard!';
		}
		this._snackBar.open(message, '', {
			duration: 1500
		});
	}
}
