import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageInterface } from 'src/app/interfaces/ImageInterface';
// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation, SwiperOptions, Lazy, Thumbs, EffectFade, Keyboard } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Lazy, Thumbs, EffectFade, Keyboard]);

@Component({
	selector: 'app-light-box-dialog',
	templateUrl: './light-box-dialog.component.html',
	styleUrls: ['./light-box-dialog.component.css']
})
export class LightBoxDialogComponent implements OnInit {
	@ViewChild('swiper', { static: false }) swiper: SwiperComponent;
	effect: 'slide' | 'fade' = 'slide';

	config: SwiperOptions = {
		speed: 300,
		keyboard: {
			enabled: true
		},
		loop: true,
		navigation: true,
		pagination: {
			type: 'fraction'
		},
		preloadImages: true,
		effect: this.effect
	};

	images = [];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { images: ImageInterface[]; index: number },
		public dialogRef: MatDialogRef<LightBoxDialogComponent>
	) {}

	ngOnInit(): void {
		this.images = this.data.images;
		const width = window.innerWidth;
		if (width < 768) {
			this.effect = 'slide';
		} else {
			this.effect = 'fade';
		}

		this.config.effect = this.effect;

		if (this.swiper) {
			this.swiper.config = this.config;
		}

		setTimeout(() => {
			this.swiper.swiperRef.slideTo(this.data.index || 0, 10, false);
		}, 50);
	}

	trackById(index: number, item: ImageInterface) {
		return item.id;
	}

	close() {
		let index = 0;
		if (document.getElementsByClassName('animate__animated').length > 1) {
			index = 1;
		}
		document.getElementsByClassName('animate__animated')[index].classList.remove('animate__slideIUp');
		document.getElementsByClassName('animate__animated')[index].classList.add('animate__slideOutDown');
		setTimeout(() => {
			this.dialogRef.close();
		}, 300);
	}
}
