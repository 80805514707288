import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService } from '../../services/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { NgOneTapService } from 'ng-google-one-tap';

@Component({
	selector: 'app-signin-pop-up',
	templateUrl: './signin-pop-up.component.html',
	styleUrls: ['./signin-pop-up.component.css']
})
export class SigninPopUpComponent implements OnInit {
	//Sign in form
	loginForm: FormGroup;

	requestInProgress: boolean = false;

	error: boolean = false;
	confirmEmailError: boolean = false;
	errorText: string = 'Credenciales Inválidas';

	constructor(
		private formBuilder: FormBuilder,
		private matIconRegistry: MatIconRegistry,
		private auth: AuthenticationService,
		private domSanitizer: DomSanitizer,
		public dialogRef: MatDialogRef<SigninPopUpComponent>,
		private socialAuthService: SocialAuthService,
		private onetap: NgOneTapService,
		@Inject(LOCALE_ID) public locale: string
	) {
		this.matIconRegistry.addSvgIcon(
			'google-logo',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/google-logo.svg')
		);
	}

	ngOnInit() {
		this.loginForm = this.formBuilder.group({
			//The patter is to not allow 'jaime@ginorio'
			email: [
				null,
				[Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]
			],
			password: [null, [Validators.required]]
		});
	}

	login(): void {
		this.requestInProgress = true;
		this.auth.signin(this.email, this.password).subscribe(
			(res) => {
				this.requestInProgress = false;
				if (res.body.status === 403) {
					this.errorText = 'Necesita confirmar su correo electrónico antes de poder acceder a su cuenta.';
					if (this.locale === 'en') {
						this.errorText = 'You need to confirm your email before you can access your account.';
					}
					this.error = true;
					this.confirmEmailError = true;
					this.loginForm.get('password').setValue('');
				} else {
					this.onClickClose(true);
				}
			},
			(err) => {
				this.requestInProgress = false;
				console.log(err);
				this.loginForm.controls['password'].setValue('');
				this.error = true;
				this.errorText = 'Algo salió mal';
				if (this.locale === 'en') {
					this.errorText = 'Something went wrong';
				}
				if (err instanceof HttpErrorResponse) {
					if (err.status === 401) {
						this.errorText = 'Credenciales Inválidas';
						if (this.locale === 'en') {
							this.errorText = 'Invalid credentials';
						}
					} else if (err.status === 403) {
						this.errorText = 'Necesita confirmar su correo electrónico antes de poder acceder a su cuenta.';
						if (this.locale === 'en') {
							this.errorText = 'You need to confirm your email before you can access your account.';
						}
					}
				}
			}
		);
	}

	loginGoogle(): void {
		this.requestInProgress = true;
		this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
	}

	public onClickClose(refresh: boolean): void {
		//QUICK FIX
		try {
			document.getElementsByClassName('animate__animated')[0].classList.remove('animate__slideIUp');
			document.getElementsByClassName('animate__animated')[0].classList.add('animate__slideOutDown');
			setTimeout(() => {
				this.dialogRef.close({ refresh });
			}, 300);
		} catch (error) {
			this.dialogRef.close({ refresh });
		}
	}

	/*
	 * Form getters
	 */
	get email() {
		return this.loginForm.get('email').value;
	}
	get password() {
		return this.loginForm.get('password').value;
	}
}
