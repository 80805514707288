import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdsService } from 'src/app/services/ads.service';
import { MetaTagsService } from 'src/app/services/meta-tags.service';
import { IServiceProviderAd } from '../service-providers-directory.component';

@Component({
	selector: 'app-more-info-dialog',
	templateUrl: './more-info-dialog.component.html',
	styleUrls: ['./more-info-dialog.component.css']
})
export class MoreInfoDialogComponent implements OnInit {
	constructor(
		@Inject(LOCALE_ID) public locale: string,
		public readonly dialogRef: MatDialogRef<MoreInfoDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { ad: IServiceProviderAd }
	) {}

	ngOnInit(): void {}

	public onClickClose(): void {
		document.getElementsByClassName('animate__animated')[0].classList.remove('animate__slideIUp');
		document.getElementsByClassName('animate__animated')[0].classList.add('animate__slideOutDown');
		setTimeout(() => {
			this.dialogRef.close();
		}, 300);
	}

	public formatPhoneNumber(phoneNumberString: string): string {
		const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
		const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			return '(' + match[1] + ')-' + match[2] + '-' + match[3];
		}
		return null;
	}
}

@Component({
	template: ''
})
export class HostMoreInfoDialogComponent implements OnDestroy {
	destroy = new Subject<any>();
	currentDialog: MatDialogRef<MoreInfoDialogComponent> = null;

	constructor(
		private readonly dialog: MatDialog,
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		private readonly adService: AdsService,
		private readonly metaTagsService: MetaTagsService
	) {
		route.params.pipe(takeUntil(this.destroy)).subscribe((params) => {
			if (params['id']) {
				if (this.currentDialog) {
					this.currentDialog.close();
				}
				this.openDialog(params['id']);
			}
		});
	}

	async openDialog(id: number) {
		const [ad] = (await this.adService.getServiceProvider(id).toPromise()) as any;
		this.metaTagsService.setServiceProviderTags(ad);
		this.currentDialog = this.dialog.open(MoreInfoDialogComponent, {
			maxWidth: '765px',
			width: '100%',
			panelClass: [
				'animate__animated',
				'animate__slideInUp',
				'app-full-bleed-dialog',
				'mobile-bottom-transition'
			],
			data: {
				ad
			},
			minHeight: '340px'
		});

		this.currentDialog.afterClosed().subscribe(() => {
			this.router.navigate(['../../'], { relativeTo: this.route });
		});
	}

	ngOnDestroy(): void {
		this.metaTagsService.setDirectoryAdsDefaultTags();
		this.destroy.next();
	}
}
