export const ServiceProviderCategories = [
	{
		id: 4,
		category: 'Abogados (Notarios)'
	},
	{
		id: 31,
		category: 'Albañilería'
	},
	{
		id: 32,
		category: 'Almacenes Privados'
	},
	{
		id: 33,
		category: 'Corredor de bienes raíces'
	},
	{
		id: 30,
		category: 'Construcción'
	},
	{
		id: 6,
		category: 'Desarrollador'
	},
	{
		id: 8,
		category: 'Diseño de Interiores'
	},
	{
		id: 29,
		category: 'Ebanisteria'
	},
	{
		id: 28,
		category: 'Electricistas'
	},
	{
		id: 3,
		category: 'Estudio de Titulo'
	},
	{
		id: 14,
		category: 'Fotografia'
	},
	{
		id: 27,
		category: 'Fumigación'
	},
	{
		id: 26,
		category: 'Handyman'
	},
	{
		id: 5,
		category: 'Inspectores de Propiedades'
	},
	{
		id: 10,
		category: 'Landscaping'
	},
	{
		id: 12,
		category: 'Limpieza de patio'
	},
	{
		id: 13,
		category: 'Limpieza del hogar'
	},
	{
		id: 25,
		category: 'Mudanzas'
	},
	{
		id: 24,
		category: 'Pintura'
	},
	{
		id: 15,
		category: 'Piscinas'
	},
	{
		id: 23,
		category: 'Pisos'
	},
	{
		id: 11,
		category: 'Placas Solares'
	},
	{
		id: 16,
		category: 'Plomeria'
	},
	{
		id: 7,
		category: 'Prestamista'
	},
	{
		id: 17,
		category: 'Refrigeración'
	},
	{
		id: 18,
		category: 'Rejas'
	},
	{
		id: 19,
		category: 'Remodelación'
	},
	{
		id: 20,
		category: 'Screens'
	},
	{
		id: 9,
		category: 'Seguros'
	},
	{
		id: 1,
		category: 'Servicios Legales'
	},
	{
		id: 2,
		category: 'Tasadores'
	},
	{
		id: 21,
		category: 'Techos'
	},
	{
		id: 22,
		category: 'Terrazas'
	}
];

export const ServiceProviderAreas = [
	{
		id: 7,
		area: 'Todo Puerto Rico'
	},
	{
		id: 1,
		area: 'Area Metro'
	},
	{
		id: 2,
		area: 'Area Norte'
	},
	{
		id: 3,
		area: 'Centro'
	},
	{
		id: 4,
		area: 'Area Oeste'
	},
	{
		id: 5,
		area: 'Area Este'
	},
	{
		id: 6,
		area: 'Area Sur'
	}
];

export const ServiceProviderAreasEN = [
	{
		id: 7,
		area: 'All Puerto Rico'
	},
	{
		id: 3,
		area: 'Center'
	},
	{
		id: 5,
		area: 'East Area'
	},
	{
		id: 1,
		area: 'Metro Area'
	},
	{
		id: 2,
		area: 'North Area'
	},
	{
		id: 6,
		area: 'South Area'
	},
	{
		id: 4,
		area: 'West Area'
	}
];

export const ServiceProviderCategoriesEN = [
	{
		id: 2,
		category: 'Appraisals'
	},
	{
		id: 31,
		category: 'Brickwork'
	},
	{
		id: 29,
		category: 'Cabinet making'
	},
	{
		id: 30,
		category: 'Construction'
	},
	{
		id: 6,
		category: 'Developer'
	},
	{
		id: 28,
		category: 'Electricians'
	},
	{
		id: 27,
		category: 'Exterminating Services'
	},
	{
		id: 23,
		category: 'Floor Services'
	},
	{
		id: 26,
		category: 'Handyman'
	},
	{
		id: 13,
		category: 'Home Cleaning'
	},
	{
		id: 5,
		category: 'Home Inspectors'
	},
	{
		id: 19,
		category: 'Home remodeling services'
	},
	{
		id: 9,
		category: 'Insurances'
	},
	{
		id: 8,
		category: 'Interior Design'
	},
	{
		id: 10,
		category: 'Landscaping'
	},
	{
		id: 1,
		category: 'Legal Services'
	},
	{
		id: 7,
		category: 'Lender'
	},
	{
		id: 25,
		category: 'Moving Services'
	},
	{
		id: 4,
		category: 'Notary Services'
	},
	{
		id: 24,
		category: 'Painting Services'
	},
	{
		id: 14,
		category: 'Photography'
	},
	{
		id: 16,
		category: 'Plumbers'
	},
	{
		id: 15,
		category: 'Pool Services'
	},
	{
		id: 32,
		category: 'Private Warehouses'
	},
	{
		id: 33,
		category: 'Real Estate Broker'
	},
	{
		id: 17,
		category: 'Refrigeration Services'
	},
	{
		id: 21,
		category: 'Roofing Contractors'
	},
	{
		id: 11,
		category: 'Solar Panels'
	},
	{
		id: 22,
		category: 'Terraces'
	},
	{
		id: 3,
		category: 'Title Search'
	},
	{
		id: 18,
		category: 'Window guards installation '
	},
	{
		id: 20,
		category: 'Window Screens'
	},
	{
		id: 12,
		category: 'Yard Cleaning'
	}
];
