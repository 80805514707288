<div style="position: relative">
	<form action="." class="search-input-container" [ngClass]="{ highLightSearch: highLightSearch }">
		<input
			type="search"
			[formControl]="search"
			list="suggestions"
			i18n-placeholder
			class="search-bar"
			(keyup.enter)="onClickSearch()"
			(focusout)="onFocusOutEvent($event)"
			(focus)="onFocusInEvent($event)"
			placeholder="Busque por pueblo, zip, barrio, MLS ID"
			autocomplete="off"
		/>
		<button mat-button class="search-btn" (click)="onClickSearch()">
			<i-feather name="search" style="vertical-align: baseline; color: var(--primary-color-1)"> </i-feather>
		</button>
	</form>
	<div class="auto-suggestion" id="suggestions">
		<ul role="listbox" *ngIf="requestInProgress; else suggestionsTemplate">
			<li role="listitem" class="text-s">
				<div>
					<mat-spinner [strokeWidth]="1" [diameter]="20"></mat-spinner>
				</div>
			</li>
		</ul>

		<ng-template #suggestionsTemplate>
			<ul role="listbox">
				<li
					role="listitem"
					class="text-s"
					*ngFor="let suggestion of options"
					(mousedown)="onClickSearch(suggestion)"
				>
					<div style="position: relative">
						<span style="font-weight: 600 !important">{{
							locale === 'en' ? suggestion.category_en : suggestion.category
						}}</span>
						<span>
							<i-feather name="chevron-right" style="height: 16px; width: 16px"></i-feather>
						</span>
						<span class="hide-overflow">
							{{ suggestion.search_term }}
						</span>
						<ng-container *ngIf="suggestion.category !== 'Pueblo' && suggestion.category !== 'Area'">
							<div style="position: absolute; top: 20px; font-size: 12px">
								{{ getSource(suggestion) }}
							</div>
						</ng-container>
					</div>
				</li>
			</ul>
		</ng-template>
	</div>
</div>
