<div class="profile-wrapper" *ngIf="data$ | async as data">
	<div *ngIf="data?.agentInfo; else notFoundTemplate" class="profile-container">
		<div class="cover-container">
			<div class="cover profile-banner-image">
				<ng-container *ngIf="isMyProfile">
					<mat-progress-bar mode="determinate" style="position: absolute; z-index: 99"
						[value]="uploadProgressCover" *ngIf="requestInProgress"></mat-progress-bar>

					<div class="update-cover" *ngIf="showSaveOptions">
						<button mat-button [disabled]="savingPicture" (click)="onClickCancel()" class="cancelarBtn">
							<ng-container i18n> Cancelar </ng-container>
						</button>
						<button mat-button [disabled]="savingPicture" (click)="updatePicture()" class="saveBtn">
							<ng-container i18n> Guardar </ng-container>
						</button>
					</div>

					<div class="change-bg-container text-s-sb" matRipple [matRippleCentered]="true"
						(click)="file.click()" *ngIf="!requestInProgress && !showSaveOptions"
						[bdcWalkTriggerFor]="example2Step1" [enabled]="isMyProfile">
						<i-feather class="camera-icon" name="camera"></i-feather>

						<div class="hide-mobile" style="margin-left: 8px">
							<ng-container i18n> Actualizar foto de portada </ng-container>
						</div>

						<input type="file" hidden #file (change)="onFileSelected($event)" accept="image/*" />
					</div>
				</ng-container>
				<img [src]="coverImage || '../../assets/stock-profile.jpg'" #coverPic id="coverPic" class="margin-auto"
					alt="Banner image" />
			</div>
			<div class="dummy-container">
				<div class="profile-pic-container">
					<div class="profile-pic">
						<img id="agent_pp" *ngIf="!isMLSAgent; else MLSAgentPP" [src]="agentInfo.profile_picture"
							[alt]="agentInfo.name" />
						<h1 class="heading-s" style="margin-top: 16px">{{ agentInfo.name }}</h1>
						<div class="text-s" style="color: var(--grayscale-5) !important" *ngIf="!isMLSAgent">
							<ng-container i18n> Licencia </ng-container>
							{{ agentInfo.licence }}
						</div>
						<div class="text-s" style="color: var(--grayscale-5) !important">
							{{ agentInfo.business_name }}
						</div>
					</div>
				</div>
			</div>
			<div (click)="onClickEdit('share')" class="share-btn text-s-sb" *ngIf="isMyProfile" matRipple
				[matRippleCentered]="true">
				<span>
					<i-feather class="share-icon" name="share"></i-feather>
				</span>
				<span class="hide-mobile" i18n> Compartir perfil </span>
			</div>
		</div>
		<div class="main-grid">
			<div class="about" *ngIf="!isMLSAgent && (agentInfo.about || isMyProfile)"
				[bdcWalkTriggerFor]="example2Step2">
				<div class="acerca">
					<div class="heading-s title-icon">
						<div i18n>Acerca</div>
						<button *ngIf="isMyProfile" mat-flat-button style="color: var(--primary-color-1)"
							(click)="onClickEdit('about')">
							<ng-container i18n> Editar </ng-container>
						</button>
					</div>
					<div #acercaChild [class.acerca-see-more]="condition" id="acerca-child" class="text-m acerca-child">
						<ng-container *ngIf="agentInfo.about; else writeAboutTemplate">
							{{ agentInfo.about }}
						</ng-container>
						<ng-template #writeAboutTemplate i18n> ¡Escribe una breve descripción sobre ti! </ng-template>
					</div>
					<span class="text-m-sb ver-mas" *ngIf="acercaChild.offsetHeight >= 300" (click)="onClickSeeMore()"
						style="text-decoration: underline" i18n>Ver {seeMore, select, false {más} other {menos} }</span>
				</div>
			</div>
			<div class="extra-info">
				<div *ngIf="!isMyProfile" style="margin-bottom: 16px" class="contact-card" #contactFormEl
					id="contactFormEl">
					<h3 class="heading-s" style="margin-bottom: 4px" i18n>Contacto</h3>
					<h6 class="text-s" style="margin-bottom: 20px">
						<ng-container i18n> Informacion Requerida </ng-container>
						<span style="color: var(--notification-error)">*</span>
					</h6>
					<form [formGroup]="contactForm">
						<label for="name" class="text-s-sb">
							<ng-container i18n> Nombre Completo </ng-container>
							<span style="color: var(--notification-error) !important">*</span>
						</label>
						<input type="text" formControlName="name" id="name" />

						<label for="phone" class="text-s-sb">
							<ng-container i18n> Numero de Teléfono </ng-container>
							<span style="color: var(--notification-error) !important">*</span>
						</label>
						<input type="text" formControlName="phone" id="phone" mask="(000)-000-0000" />

						<label for="email" class="text-s-sb">
							<ng-container i18n> Correo Electrónico </ng-container>
							<span style="color: var(--notification-error) !important">*</span>
						</label>
						<input type="email" formControlName="email" id="email" />

						<label for="message" class="text-s-sb">
							<ng-container i18n> Mensaje </ng-container>
							<span style="color: var(--grayscale-4); font-weight: normal" i18n>(opcional)</span>
						</label>
						<textarea id="message" formControlName="message" cols="30" rows="2"></textarea>
						<button mat-button class="contactarBtn text-m" [ngStyle]="{
								'background-color': contactMessagePending ? 'var(--notification-warning)' : ''
							}" [ngClass]="{ 'success-bg': contactMessageSent }" [disabled]="contactForm.invalid || disabledContactBtn"
							(click)="sendContactMessage()">
							<span *ngIf="!contactMessageSent && !contactMessagePending" i18n>Enviar</span>
							<span *ngIf="contactMessagePending" i18n>Enviando...</span>
							<span *ngIf="contactMessageSent" style="color: var(--grayscale-1)">
								<ng-container i18n> ¡Mensaje Enviado! </ng-container>
								<i-feather name="check" class="success-color"></i-feather>
							</span>
						</button>
						<div class="text-s" style="color: var(--grayscale-4)" i18n>
							*Al oprimir el botón de contactar, usted acepta nuestros términos y política de privacidad.
							Además, usted está de acuerdo que el profesional de bienes raíces o anunciante se comunique
							con usted para discutir su solicitud de información. Esta solicitud no lo obliga a usted a
							comprar o alquilar una propiedad.
						</div>
					</form>
				</div>

				<div>
					<div [bdcWalkTriggerFor]="example2StepInfo" *ngIf="isMyProfile"></div>
					<ng-container *ngTemplateOutlet="additionalInformationTemplate"></ng-container>
				</div>
				<!-- <div [bdcWalkTriggerFor]="example2Step4" *ngIf="isMyProfile">
					<ng-container *ngTemplateOutlet="qrCodeInfo"></ng-container>
				</div> -->
			</div>
			<div class="listings">
				<div class="active-listings">
					<div class="listings-title" style="margin-bottom: 24px; flex-wrap: wrap">
						<div class="heading-s margin-bottom-title">
							<ng-container i18n> Listados Activos </ng-container>
						</div>
						<mat-button-toggle-group (change)="onChangeActive($event)" class="toggle-group"
							[(ngModel)]="isActiveVenta">
							<mat-button-toggle [ngClass]="{ bold: isActiveVenta }" [value]="true">
								<ng-container i18n> Venta </ng-container>
								({{ activeListingsSale.total }})
							</mat-button-toggle>
							<mat-button-toggle [ngClass]="{ bold: !isActiveVenta }" [value]="false">
								<ng-container i18n> Alquiler </ng-container>
								({{ activeListingsRent.total }})
							</mat-button-toggle>
						</mat-button-toggle-group>
					</div>

					<ng-container *ngIf="!loadingActive; else loadingActiveTemp">
						<ng-container *ngIf="isActiveVenta; else activeRent">
							<ng-container *ngIf="activeListingsSale.total > 0; else noactiveListingsSale">
								<div class="listings-container">
									<app-horizontal-listing-card *ngFor="let listing of activeListingsSale.listings"
										[listing]="listing">
									</app-horizontal-listing-card>
								</div>
							</ng-container>
						</ng-container>
					</ng-container>
					<div style="text-align: end">
						<ng-container *ngIf="isActiveVenta; else activeRentPaginator">
							<ng-container *ngIf="activeListingsSale.total > 0">
								<span class="text-s" style="color: var(--grayscale-3)">{{ activeListingsSale.pageNum
									}}/{{ activeListingsSale.numPages }}</span>
								<button (click)="onClickPreviousActive(activeListingsSale)" mat-icon-button
									class="chevron-btn" style="margin-left: 12px; margin-right: 12px">
									<mat-icon class="chevron-icon material-icons-round">chevron_left</mat-icon>
								</button>

								<button (click)="onClickNextActive(activeListingsSale)" mat-icon-button
									class="chevron-btn">
									<mat-icon class="chevron-icon material-icons-round">chevron_right</mat-icon>
								</button>
							</ng-container>
						</ng-container>
					</div>
				</div>

				<div class="out-of-market-listings">
					<div class="listings-title">
						<div class="heading-s" style="margin-bottom: 24px">
							<ng-container i18n> Listados Vendidos </ng-container>
							({{ soldListings.total }})
						</div>
					</div>
					<ng-container *ngIf="soldListings.total > 0; else noSoldListings">
						<ng-container *ngIf="!loadingSold; else loadingActiveTemp">
							<div class="listings-container">
								<app-horizontal-listing-card *ngFor="let listing of soldListings.listings" [sold]="true"
									[listing]="listing">
								</app-horizontal-listing-card>
							</div>
						</ng-container>
						<div style="text-align: end">
							<span class="text-s" style="color: var(--grayscale-3)">{{ soldListings.pageNum }}/{{
								soldListings.numPages }}</span>
							<button (click)="onClickPreviousSold()" mat-icon-button class="chevron-btn"
								style="margin-left: 12px; margin-right: 12px">
								<mat-icon class="chevron-icon material-icons-round">chevron_left</mat-icon>
							</button>
							<button (click)="onClickNextSold()" mat-icon-button class="chevron-btn">
								<mat-icon class="chevron-icon material-icons-round">chevron_right</mat-icon>
							</button>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="mobile-contact-card" *ngIf="agentInfo && !isMyProfile">
	<div class="tablet-contact-card-wrapper">
		<div class="tablet-contact-card-container">
			<div class="landlord-pic-name">
				<img [src]="agentInfo.profile_picture" style="border-radius: 50%; object-fit: cover"
					onerror="this.onerror=null;this.src='https://images.finditpr.com/default-images/user-picture.svg';"
					[alt]="agentInfo.name" width="55px" height="55px" />

				<div class="agent-image-grid-info">
					<div class="text-s-sb" style="margin-bottom: 4px">{{ agentInfo.name }}</div>
					<div class="text-s" style="margin-bottom: 4px" *ngIf="agentInfo.business_name">
						{{ agentInfo.business_name }}
					</div>
				</div>
			</div>
		</div>
		<button mat-button (click)="onClickContact()" class="contactarBtn">
			<ng-container i18n> Contactar </ng-container>
		</button>
	</div>
	<div class="mobile-contact-card-container">
		<button mat-button class="clear-with-primary-btn mobile-contact-card-button" *ngIf="agentInfo.phone"
			style="margin-right: 16px">
			<a href="tel:{{ agentInfo.phone }}" class="clean-a-tag" i18n>Llamar</a>
		</button>
		<button mat-button id="contactViaEmailMobile" class="primary-background-btn mobile-contact-card-button"
			(click)="onClickContact()" i18n>
			Contacto
		</button>
	</div>
</div>

<mat-paginator style="display: none" [length]="activeListingsSale.total" [pageSize]="3"> </mat-paginator>

<mat-paginator style="display: none" [length]="activeListingsRent.total" [pageSize]="3"> </mat-paginator>

<mat-paginator style="display: none" [length]="soldListings.total" [pageSize]="3"> </mat-paginator>

<ng-template #notFoundTemplate>
	<div class="not-found-container">
		<img src="../../assets/profile-not-found.svg" alt="Profile not found" style="max-width: 100%" height="256" />
		<div class="heading-m not-active-heading">
			{not_found.isMyProfile, select, true {Tu perfil profesional no esta activado} other {Este perfil no esta
			activo} }
		</div>
		<div class="text-l" style="font-weight: normal !important">
			{ not_found.isMyProfile, plural, true {Activa tu perfil como corredor o vendedor de bienes raices. Muestra
			informacion de tu negocio, inventario de propiedades e historial de ventas.} other {Intente regresar en otro
			momento. Mientras tanto, te invitamos a explorar Findit.} }
		</div>
		<button *ngIf="!requestInProgress" (click)="onClickNotFoundBtn()" mat-button class="not-found-btn"
			[ngClass]="{ 'success-bg-btn': not_found.isMyProfile }">
			{not_found.isMyProfile, select, true {Activar} other {Ir a la pagina principal} }
		</button>
		<mat-progress-bar style="margin-top: 32px" *ngIf="requestInProgress" mode="buffer"></mat-progress-bar>
	</div>
</ng-template>

<ng-template #additionalInformationTemplate>
	<ng-container *ngIf="isMyProfile; else visitorAdditionalInfo">
		<div class="additional-information">
			<div class="heading-s" style="margin-bottom: 16px" i18n>Información Adicional</div>

			<div class="text-s edit-additional-info">
				<div i18n>Dirección</div>
				<i-feather name="edit" class="edit-icon" (click)="onClickEdit('address')"></i-feather>
			</div>
			<div class="text-s" style="margin-top: 8px; margin-bottom: 16px">
				<ng-container *ngIf="agentInfo.address; else addAddress">
					{{ agentInfo.address }}
				</ng-container>

				<ng-template #addAddress i18n> Añada su dirección 📍 </ng-template>
			</div>

			<div class="text-s edit-additional-info">
				<div i18n>Teléfono</div>
				<i-feather name="info" class="info-icon" (click)="onClickEdit('phone')"></i-feather>
			</div>
			<div class="text-s" style="margin-top: 8px; margin-bottom: 16px">
				{{ formatPhoneNumber(agentInfo.phone) }}
			</div>

			<div class="text-s edit-additional-info">
				<div>Website</div>
				<i-feather name="edit" (click)="onClickEdit('website')" class="edit-icon"></i-feather>
			</div>
			<div class="text-s" style="margin-top: 8px; margin-bottom: 16px">
				<ng-container *ngIf="agentInfo.website; addWebsiteTemplate"> {{ agentInfo.website }} </ng-container>
				<ng-template #addWebsiteTemplate> Añada su website de tener uno 💻 </ng-template>
			</div>

			<ng-container *ngIf="!isMLSAgent">
				<div class="text-s edit-additional-info">
					<div i18n>Horario</div>
					<i-feather name="edit" class="edit-icon" (click)="onClickEdit('time')"></i-feather>
				</div>
				<div class="text-s" style="margin-top: 8px; margin-bottom: 16px">
					<ng-container *ngIf="agentInfo.time; else addTime">
						{{ agentInfo.time }}
					</ng-container>
					<ng-template #addTime i18n> Añada su horario ⏰ </ng-template>
				</div>
			</ng-container>
		</div>
	</ng-container>
</ng-template>

<ng-template #qrCodeInfo>
	<!-- <div class="qr-code-div">
		<div>
			<div class="text-s">
				<ng-container i18n> Descarga el código QR de tu perfil </ng-container>
				<i-feather name="download" (click)="onClickEdit('qrcode')" class="download-icon"> </i-feather>
			</div>
			<div class="text-s" style="color: var(--grayscale-5) !important; font-weight: 400 !important" i18n>
				¡Añade el QR Code a tu tarjeta de negocios!
			</div>
		</div>
		<button mat-button class="primary-background-color download-qr-btn" (click)="onClickEdit('qrcode')"
			style="color: white">
			<ng-container i18n> Descargar </ng-container>
		</button>
	</div> -->
</ng-template>

<ng-template #activeRent>
	<ng-container *ngIf="activeListingsRent.total > 0; else noactiveListingsSale">
		<div class="listings-container">
			<app-horizontal-listing-card *ngFor="let listing of activeListingsRent.listings" [listing]="listing">
			</app-horizontal-listing-card>
		</div>
	</ng-container>
</ng-template>

<ng-template #activeRentPaginator>
	<ng-container *ngIf="activeListingsRent.total > 0">
		<span class="text-s" style="color: var(--grayscale-3)">{{ activeListingsRent.pageNum }}/{{
			activeListingsRent.numPages }}</span>
		<button (click)="onClickPreviousActive(activeListingsRent)" mat-icon-button class="chevron-btn"
			style="margin-left: 12px; margin-right: 12px">
			<mat-icon class="chevron-icon material-icons-round">chevron_left</mat-icon>
		</button>

		<button (click)="onClickNextActive(activeListingsRent)" mat-icon-button class="chevron-btn">
			<mat-icon class="chevron-icon material-icons-round">chevron_right</mat-icon>
		</button>
	</ng-container>
</ng-template>

<ng-template #noactiveListingsSale>
	<div class="no-content">
		<div>
			<img src="../../assets/add-venta.svg" alt="Logo de venta" />
			<div class="heading-s" style="margin-bottom: 8px; margin-top: 16px" i18n>
				No hay listados activos en {isActiveVenta, select, true {venta} other {alquiler} } disponibles
			</div>
			<div class="text-s" style="color: var(--grayscale-5) !important">
				<ng-container i18n>
					Aquí se mostrarán las propiedades en {isActiveVenta, select, true {venta} other {alquiler} } de
				</ng-container>
				<b>{{ agentInfo.name }}</b>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #noSoldListings>
	<div class="no-content">
		<div *ngIf="isMyProfile && has_inactive_mls_listings; else nonMLS">
			<img src="../../assets/add-venta.svg" alt="Logo de venta" />
			<div class="heading-s" style="margin-bottom: 8px; margin-top: 16px" i18n>
				Tenemos listados del MLS que puedes marcar como vendidos
			</div>
			<a [routerLink]="['/mi-perfil/mis-listados']" [queryParams]="{ filters: filters }"
				style="text-decoration: underline; color: var(--primary-color-1) !important" class="text-m-sb">
				Marcalos aquí
			</a>
		</div>
		<ng-template #nonMLS>

			<div>
				<img src="../../assets/add-venta.svg" alt="Logo de venta" />
				<div class="heading-s" style="margin-bottom: 8px; margin-top: 16px" i18n>
					No tenemos información de listados vendidos
				</div>
			</div>
		</ng-template>

	</div>
</ng-template>

<ng-template #loadingActiveTemp>
	<div class="listings-container">
		<div class="active-listing-card" *ngFor="let listing of [1, 2, 3]">
			<div style="max-height: 180px">
				<ngx-skeleton-loader count="1" appearance="circle" [theme]="{
						width: '280px',
						height: '180px',
						'border-radius': '4px',
						margin: '0px'
					}">
				</ngx-skeleton-loader>
			</div>
			<div>
				<ngx-skeleton-loader count="1" [theme]="{
						width: '175px',
						'border-radius': '4px',
						'margin-top': '12px',
						'margin-bottom': '8px'
					}">
				</ngx-skeleton-loader>
				<br />
				<ngx-skeleton-loader count="1" [theme]="{
						width: '200px',
						'border-radius': '4px',
						'margin-bottom': '8px'
					}">
				</ngx-skeleton-loader>
				<br />
				<ngx-skeleton-loader count="1" [theme]="{
						width: '225px',
						'border-radius': '4px',
						margin: '0px'
					}">
				</ngx-skeleton-loader>
			</div>
		</div>

		<div class="vertical-card-loading" *ngFor="let listing of [1, 2, 3]" style="margin-bottom: 16px">
			<div>
				<ngx-skeleton-loader count="1" appearance="circle" [theme]="{
						width: '135px',
						'max-height': '97px',
						height: '97px',
						'border-radius': '4px',
						margin: '0px'
					}">
				</ngx-skeleton-loader>
			</div>
			<div>
				<ngx-skeleton-loader count="1" [theme]="{
						width: '75px',
						height: '12px',
						'border-radius': '4px',
						'margin-top': '0px',
						'margin-bottom': '0px'
					}">
				</ngx-skeleton-loader>
				<br />
				<ngx-skeleton-loader count="1" [theme]="{
						width: '100px',
						height: '12px',

						'border-radius': '4px',
						margin: '0px'
					}">
				</ngx-skeleton-loader>
				<br />
				<ngx-skeleton-loader count="1" [theme]="{
						width: '125px',
						height: '12px',

						'border-radius': '4px',
						margin: '0px'
					}">
				</ngx-skeleton-loader>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #MLSAgentPP>
	<img [src]="agentInfo.profile_picture"
		onerror="this.onerror=null;this.src='https://images.finditpr.com/default-images/user-picture.svg';"
		[alt]="agentInfo.name" />
</ng-template>

<ng-template #visitorAdditionalInfo>
	<div class="additional-information">
		<div class="heading-s" style="margin-bottom: 16px" i18n>Información Adicional</div>

		<ng-container *ngIf="agentInfo.address">
			<div class="text-s" style="color: var(--grayscale-5) !important" i18n>Dirección</div>
			<div class="text-s" style="margin-top: 8px; margin-bottom: 16px">
				{{ agentInfo.address }}
			</div>
		</ng-container>

		<div class="text-s" style="color: var(--grayscale-5) !important" i18n>Teléfono</div>
		<div class="text-s" style="margin-top: 8px; margin-bottom: 16px">{{ formatPhoneNumber(agentInfo.phone) }}</div>

		<ng-container *ngIf="agentInfo.website">
			<div class="text-s" style="color: var(--grayscale-5) !important">Website</div>
			<div class="text-s" style="margin-top: 8px; margin-bottom: 16px">
				<a style="margin-bottom: 4px" [href]="agentInfo.website" target="_blank">
					{{ agentInfo.website }}
				</a>
			</div>
		</ng-container>

		<ng-container *ngIf="!isMLSAgent && agentInfo.time">
			<div class="text-s" style="color: var(--grayscale-5) !important" i18n>Horario</div>
			<div class="text-s" style="margin-top: 8px; margin-bottom: 16px">{{ agentInfo.time }}</div>
		</ng-container>
	</div>
</ng-template>

<bdc-walk-popup class="backdropBackground-selected-property" #example2Step1 name="example2Step1" i18n-header
	header="Foto de portada ✨" xPosition="after" yPosition="below" sideNoteText="1/3" [showButton]="true"
	i18n-buttonText buttonText="Próximo" [onButtonCompleteTask]="{ example2Next: 1 }">
	<div class="text-s" i18n>Cambia tu foto de portada y dale un toque único a tu perfil</div>
</bdc-walk-popup>

<bdc-walk-popup #example2Step2 name="example2Step2" header="Bio ✏️" yPosition="above" xPosition="after"
	sideNoteText="2/3" [showButton]="true" i18n-buttonText buttonText="Próximo" [mustCompleted]="{ example2Next: 1 }"
	[onButtonCompleteTask]="{ example2Next: 2 }">
	<div class="text-s" i18n>Agrega una breve descripción acerca de ti y tus servicios</div>
</bdc-walk-popup>

<bdc-walk-popup #example2StepInfo name="example2Step3" i18n-header header="Información ✅" yPosition="above"
	sideNoteText="3/3" [showButton]="true" [mustCompleted]="{ example2Next: 2 }" i18n-buttonText buttonText="Próximo"
	[onButtonCompleteTask]="{ example2Next: 3 }">
	<div class="text-s" i18n>Déjale saber a potenciales clientes cómo, cuándo y dónde es mejor conseguirte</div>
</bdc-walk-popup>

<!-- <bdc-walk-popup #example2Step4 name="example2Step4" header="QR Code 💡" yPosition="above" sideNoteText="4/4"
	[showButton]="true" [mustCompleted]="{ example2Next: 3 }" i18n-buttonText buttonText="¡Listo!"
	[onButtonCompleteTask]="{ example2Next: 4 }">
	<div class="text-s" i18n>Tus clientes pueden ver tu perfil escaneando el QR Code</div>
</bdc-walk-popup> -->