<div class="container-content">
	<nav class="navbar-findit" *ngIf="!isHidden()">
		<button
			class="mobile-btn hamburger hamburger--squeeze"
			type="button"
			(click)="drawer.toggle()"
			[ngClass]="{ 'is-active': drawer.opened }"
		>
			<span class="hamburger-box">
				<span class="hamburger-inner"></span>
			</span>
		</button>
		<img
			class="logo-img"
			routerLink="/"
			style="z-index: 99; cursor: pointer"
			type="image/png"
			src="../../assets/logo-oficial.png"
			alt="Findit logo"
		/>
		<ul class="navbar-list">
			<li class="text-m desktop">
				<a routerLink="/puerto-rico/venta" [routerLinkActive]="['selected-link']">
					<span>
						<ng-container i18n>Propiedades</ng-container>
						<i-feather name="chevron-down" class="chevron-icon"></i-feather>
					</span>
				</a>
				<ul class="show-on-hover" style="width: max-content">
					<li class="text-m">
						<a routerLink="/puerto-rico/venta" i18n> En venta </a>
					</li>
					<li class="text-m">
						<a routerLink="/puerto-rico/alquiler" i18n> En alquiler </a>
					</li>
					<li class="text-m" style="width: 100%">
						<a routerLink="/publica">
							<ng-container i18n>Publica tu propiedad</ng-container
							><span class="gratis-tag primary-color" i18n>Gratis</span>
						</a>
					</li>
				</ul>
			</li>
			<li class="text-m desktop">
				<a routerLink="/precualificate" [routerLinkActive]="['selected-link']" i18n> Precualificación </a>
			</li>
			<li class="text-m desktop">
				<a routerLink="/blog" [routerLinkActive]="['selected-link']">
					<span>
						<ng-container i18n> Explora </ng-container>
						<i-feather name="chevron-down" class="chevron-icon"></i-feather>
					</span>
				</a>
				<ul class="show-on-hover" style="min-width: 175px">
					<li class="text-m">
						<a routerLink="/acerca" i18n>Acerca</a>
					</li>
					<li class="text-m">
						<a routerLink="/blog">Blog</a>
					</li>
					<li class="text-m">
						<a routerLink="/servicios" i18n>Servicios al hogar</a>
					</li>
				</ul>
			</li>
			<li class="desktop" style="position: relative; cursor: pointer">
				<i-feather name="globe" class="chevron-icon"></i-feather>
				<i-feather name="chevron-down" class="chevron-icon"></i-feather>
				<div class="text-s lng">{{ locale === 'en' ? 'EN' : 'ES' }}</div>
				<ul class="show-on-hover" style="inset: 50px -16px auto auto">
					<li class="text-m">
						<a [href]="'..' + router.url">Español</a>
					</li>
					<li class="text-m">
						<a [href]="'../en' + router.url">English</a>
					</li>
				</ul>
			</li>
			<li class="text-m" [bdcWalkTriggerFor]="activateProfileStep2">
				<ng-container *ngIf="auth.isAuthenticated(); else notSignedIn">
					<button class="account-img" (click)="onClickProfile()" id="mainBtn">
						<img [src]="auth?.getUserDetails().profile_picture" width="" alt="Foto de perfil" />
					</button>
					<ul class="user-ul" [ngClass]="{ show: showWindow }">
						<div class="chevron-up"></div>
						<li class="text-m">
							<a routerLink="/mi-perfil/notificaciones" (click)="onClickProfile()">
								<span i18n> Notificaciones </span>
							</a>
						</li>
						<li class="text-m">
							<a routerLink="/mi-perfil/listados-guardados" (click)="onClickProfile()">
								<span i18n> Listados Guardados </span>
							</a>
						</li>

						<li class="text-m">
							<a routerLink="/mi-perfil/busquedas-guardadas" (click)="onClickProfile()">
								<span i18n> Búsquedas Guardadas </span>
							</a>
						</li>
						<li class="text-m">
							<a routerLink="/mi-perfil/mis-listados" (click)="onClickProfile()">
								<span i18n> Mis Listados </span>
							</a>
						</li>
						<li
							class="text-m"
							id="profile"
							[ngStyle]="{ display: auth.getUserDetails()?.agente ? 'flex' : 'none' }"
						>
							<a
								routerLink="/agente/usuario/{{ auth.getUserDetails()?.id }}"
								(click)="onClickProfile()"
								[bdcWalkTriggerFor]="activateProfileStep2"
							>
								<span i18n> Perfil Profesional </span>
							</a>
						</li>
						<li class="text-m">
							<a routerLink="/mi-perfil/configuraciones" (click)="onClickProfile()">
								<span i18n> Configuraciones </span>
							</a>
						</li>
						<li class="text-m logout-li">
							<button (click)="doLogoutDesktop()" style="text-align: initial">
								<span i18n> Cerrar Sesión </span>
							</button>
						</li>
					</ul>
					<div [ngClass]="{ show: showWindow }" class="arrow-up"></div>
					<div *ngIf="showWindow" class="blocker" (click)="onClickProfile()"></div>
				</ng-container>

				<ng-template #notSignedIn>
					<a routerLink="/iniciar-sesion" id="mainBtn" class="login-btn" i18n>Iniciar Sesión</a>
				</ng-template>
			</li>
		</ul>
		<bdc-walk-popup
			style="position: absolute"
			#activateProfileStep2
			name="activateProfileStep2"
			i18n-header
			header="Accede a tu perfil"
			xPosition="before"
			yPosition="below"
			[showButton]="true"
			[horizontal]="true"
			i18n-buttonText
			buttonText="¡Listo!"
			[mustCompleted]="{ activateProfileStepNext: 1 }"
		>
			<div class="text-s" i18n>Listo. Ahora puedes acceder a tu perfil profesional desde este menu.</div>
		</bdc-walk-popup>
	</nav>

	<mat-sidenav-container class="sidenav-container">
		<mat-sidenav
			#drawer
			class="sidenav"
			[ngClass]="{ hidden: !responsive$ }"
			fixedInViewport="false"
			[attr.role]="responsive$ ? 'dialog' : 'navigation'"
		>
			<ul class="mobile-ul">
				<li class="text-m mobile-li">
					<a (click)="drawer.toggle()" routerLink="/" i18n>Inicio</a>
				</li>
			</ul>
			<ul class="mobile-ul">
				<li class="text-m mobile-li" style="padding-bottom: 0">
					<div style="margin-bottom: 0.75rem" i18n>Propiedades</div>
					<ul style="list-style: none; padding-left: 12px">
						<li class="text-m mobile-li">
							<a (click)="drawer.toggle()" routerLink="/puerto-rico/venta" i18n>En venta</a>
						</li>
						<li class="text-m mobile-li">
							<a (click)="drawer.toggle()" routerLink="/puerto-rico/alquiler" i18n>En alquiler</a>
						</li>
						<li class="text-m mobile-li">
							<a (click)="drawer.toggle()" routerLink="/publica" i18n>Publicar listados</a>
						</li>
					</ul>
				</li>
			</ul>
			<ul class="mobile-ul">
				<li class="text-m mobile-li">
					<a (click)="drawer.toggle()" routerLink="/precualificate" i18n>Precualificación</a>
				</li>
				<li class="text-m mobile-li">
					<a (click)="drawer.toggle()" routerLink="/servicios" i18n>Servicios al hogar</a>
				</li>
				<li class="text-m mobile-li">
					<a (click)="drawer.toggle()" routerLink="/blog" i18n>Blog</a>
				</li>
				<li class="text-m mobile-li">
					<a (click)="drawer.toggle()" routerLink="/acerca" i18n>Acerca</a>
				</li>
				<li class="text-m mobile-li">
					<a (click)="drawer.toggle()" routerLink="/contactanos" i18n>Contactanos</a>
				</li>
				<li class="text-m mobile-li">
					<div class="language-container">
						<div>
							<i-feather name="globe" class="globe-icon"></i-feather>
						</div>
						<div class="text-m">
							<a
								style="font-weight: 400 !important"
								class="clean-a-tag"
								[ngClass]="{ 'selected-lng': locale === 'en' }"
								[href]="'../en' + router.url"
								>English</a
							>
						</div>
						<div class="text-m">
							<span style="color: var(--grayscale-9); margin-left: 8px; margin-right: 8px"> | </span>
							<a
								style="font-weight: 400 !important"
								class="clean-a-tag"
								[ngClass]="{ 'selected-lng': locale !== 'en' }"
								[href]="'..' + router.url"
								>Español</a
							>
						</div>
					</div>
				</li>
			</ul>
		</mat-sidenav>

		<mat-sidenav-content>
			<ng-content></ng-content>
		</mat-sidenav-content>
	</mat-sidenav-container>
</div>
