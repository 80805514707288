import { Injectable, OnDestroy, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { AuthenticationService } from './authentication.service';

@Injectable({
	providedIn: 'root'
})
export class WebsocketsService implements OnDestroy {
	connection$: WebSocketSubject<any>;
	private readonly wsURI = 'wss://6d378vpg9e.execute-api.us-east-1.amazonaws.com/production';
	// private readonly wsURI = 'ws://192.168.0.12:3001';
	// RETRY_SECONDS = 10;

	constructor() {}

	connect() {
		// if (this.connection$) {
		//   return this.connection$;
		// } else {
		//   this.connection$ = webSocket(`${this.wsURI}?x-access-token=${this.getAccessToken()}`);
		//   return this.connection$;
		// }
	}

	sendMessage(
		text: string,
		sendTo: string,
		filesNames: string = null,
		sharedURL: string = null,
		message_sentID: string
	) {
		if (this.connection$) {
			if (filesNames === null) {
				this.connection$.next({
					action: 'sendMessage',
					data: text,
					sendTo: sendTo,
					sharedURL: sharedURL,
					accessToken: this.getAccessToken(),
					message_sentId: message_sentID
				});
			} else {
				this.connection$.next({
					action: 'sendMessage',
					data: text,
					sendTo: sendTo,
					files: filesNames,
					sharedURL: sharedURL,
					accessToken: this.getAccessToken(),
					message_sentId: message_sentID
				});
			}
		} else {
			console.error('Open a connection before sending a message');
		}
	}

	wantUserStatus(id: string) {
		if (this.connection$) {
			this.connection$.next({ action: 'wantUserStatus', targetUserID: id });
		}
	}

	closeConnection() {
		if (this.connection$) {
			this.connection$.complete();
			this.connection$ = null;
		}
	}

	ngOnDestroy(): void {
		this.closeConnection();
	}

	getAccessToken() {
		// this.localStorage.getItem('x-access-token')
		return null;
	}
}
