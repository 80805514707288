import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
	showVenta: boolean = false;
	showAlquiler: boolean = false;

	constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
		this.matIconRegistry.addSvgIcon(
			'f-logo',
			this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.finditpr.com/assets/f_logo_1.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'ig-logo',
			this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.finditpr.com/assets/ig_logo_1.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'tw-logo',
			this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.finditpr.com/assets/tw_logo_1.svg')
		);
	}

	public showNHideVenta(): void {
		this.showVenta = !this.showVenta;
	}

	public showNHideAlquiler(): void {
		this.showAlquiler = !this.showAlquiler;
	}
}
