import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	LOCALE_ID,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ListingsService } from '../../services/listings.service';
import { Region, RegionType, SearchQuery } from '../../interfaces/BusquedaInterface';

export interface ISearchDialog {
	search: FormControl;
	selectedRegions: Region[];
	total: number;
	suggestions: boolean;
}

@Component({
	selector: 'app-search-dialog',
	templateUrl: './search-dialog.component.html',
	styleUrls: ['./search-dialog.component.css']
})
export class SearchDialogComponent implements OnInit, OnDestroy, AfterViewInit {
	error = false;
	requestInProgress = false;
	requestInProgressListings = false;
	@ViewChild('searchInputMobile') searchInput: ElementRef<HTMLInputElement>;

	private ngUnsubscribe = new Subject<void>();
	parentPipe = new EventEmitter();

	userSettings = {
		search: new FormControl(''),
		selectedRegions: [],
		total: 0,
		suggestions: false
	};

	searchSuggestions = [];

	constructor(
		private readonly listingService: ListingsService,
		public readonly dialogRef: MatDialogRef<SearchDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ISearchDialog,
		@Inject(LOCALE_ID) public readonly locale: string
	) {
		this.userSettings = { ...this.data, search: new FormControl(this.data.search?.value) };
	}
	ngAfterViewInit(): void {
		this.searchInput.nativeElement.focus();
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	ngOnInit(): void {
		this.userSettings.search.valueChanges
			.pipe(debounceTime(275), distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.userSettings.suggestions = false;
				const search = this.userSettings.search.value;
				if (
					!this.requestInProgress &&
					search &&
					search !== '' &&
					this.userSettings.selectedRegions.length < 5
				) {
					this.requestInProgress = true;
					this.listingService
						.getSuggestionsV1(search)
						.pipe(takeUntil(this.ngUnsubscribe))
						.subscribe(
							(response: []) => {
								this.searchSuggestions = response;
								this.requestInProgress = false;
								this.error = false;
							},
							(error) => this.searchIndexError(error)
						);
				} else {
					this.searchSuggestions = [];
				}
			});

		this.listingService.totalEventEmitter.pipe(takeUntil(this.ngUnsubscribe)).subscribe((total: number) => {
			this.userSettings.total = total;
		});

		this.listingService.matDialogChildrenPipe.pipe(takeUntil(this.ngUnsubscribe)).subscribe((loading: boolean) => {
			this.requestInProgressListings = loading;
		});
	}

	public selectSuggestion(suggestion: Region): void {
		if (this.userSettings.selectedRegions.length >= 5 && suggestion.region_type !== RegionType.NOT_REGION) {
			return;
		}
		if (suggestion.region_type !== RegionType.NOT_REGION) {
			suggestion = {
				category: suggestion.category,
				city: suggestion.city,
				db_id: suggestion.db_id,
				search_term: suggestion.search_term,
				region_type: suggestion.region_type
			};

			this.userSettings.search.setValue(null);
			if (
				!this.userSettings.selectedRegions.find(
					($) => $.db_id === suggestion.db_id && $.region_type === suggestion.region_type
				)
			) {
				this.userSettings.selectedRegions.push(suggestion);
				this.updateQueryParams(suggestion, false);
			}
		} else {
			window.open(`https://www.finditpr.com${suggestion['link']}`, '_blank');
		}
	}

	public removeChip(suggestion: Region): void {
		const index = this.userSettings.selectedRegions.indexOf(suggestion);
		if (index >= 0) {
			this.userSettings.selectedRegions.splice(index, 1);
		}
		this.updateQueryParams(suggestion, true);
	}

	public onClickGetSuggestions($event): void {
		$event.preventDefault();

		if (this.userSettings.selectedRegions.length >= 5) return;

		const q = this.userSettings.search.value;
		this.requestInProgress = true;
		this.listingService
			.searchSimilar(q)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				(_) => {
					this.error = false;
					this.userSettings.suggestions = true;
					this.searchSuggestions = _;
					this.requestInProgress = false;
				},
				(error) => this.searchIndexError(error)
			);
	}

	private updateQueryParams(suggestion: Region, removed: boolean): void {
		const { selectedRegions } = this.userSettings;
		this.parentPipe.emit({ selectedRegions, suggestion, removed });
	}

	private searchIndexError(error: any): void {
		console.log(error);
		this.error = true;
		this.requestInProgress = false;
		this.searchSuggestions = [];
	}

	public onClickClose(): void {
		document.getElementsByClassName('animate__animated')[0].classList.remove('animate__slideIUp');
		document.getElementsByClassName('animate__animated')[0].classList.add('animate__slideOutDown');
		this.resetSettings();
		setTimeout(() => {
			this.dialogRef.close(this.userSettings);
		}, 300);
	}

	public onClickClear(): void {
		this.userSettings.search.setValue('');
		this.searchInput.nativeElement.focus();
	}

	private resetSettings(): void {
		this.userSettings.suggestions = false;
	}

	public getSource(suggestion: any): string {
		const on = this.locale === 'en' ? 'on ' : 'en ';
		if (suggestion.city) {
			return on + suggestion.city;
		}

		if (suggestion.office) {
			return on + suggestion.office;
		}

		if (suggestion.source) {
			return suggestion.source;
		}
	}
}
