import { EventEmitter, Injectable } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { Listing } from '../interfaces/ListingInterface';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SearchQuery } from '../interfaces/BusquedaInterface';
import { PropertyType } from '../listings-component/listings-component.component';

@Injectable({
	providedIn: 'root'
})
export class ListingsService {
	readonly API_URI = `${environment.api}/listings`;
	readonly filtersEventEmitter = new EventEmitter();
	readonly refreshUserListings = new EventEmitter();
	readonly matDialogChildrenPipe = new EventEmitter<boolean>();
	readonly resetMapPositionMobile = new EventEmitter();
	readonly totalEventEmitter = new EventEmitter<number>();
	readonly setPolygonBoundsEmitter = new EventEmitter<{ polygons: any[]; setBounds: boolean }>();
	readonly mapLoaded = new EventEmitter();

	constructor(private http: HttpClient) {}

	getSuggestions(search: string) {
		return this.http.get(`https://search.finditpr.com/search?search=${search.trim().toLowerCase()}`);
	}

	getSuggestionsV1(search: string) {
		if (!search || search === '') return;
		return this.http
			.get(
				`https://aq3pn7k5qlrlqkwhg2mj2jpetm0onkzb.lambda-url.us-east-1.on.aws/search?search=${search
					.trim()
					.toLowerCase()}`
			)
			.pipe(shareReplay());
	}

	searchSimilar(q: string): Observable<any> {
		if (!q || q === '') return;
		return this.http
			.get(`https://aq3pn7k5qlrlqkwhg2mj2jpetm0onkzb.lambda-url.us-east-1.on.aws/suggestions?search=${q.trim()}`)
			.pipe(shareReplay());
	}

	public getAgentProfile(agentID: number, isMLS: boolean) {
		return this.http.get(`${this.API_URI}/get-agent-profile/${agentID}?isMLSAgent=${isMLS}`);
	}

	public getNextAgentListings(agentID: number, referenceID: number, isVenta: boolean, isMLSAgent: boolean) {
		return this.http.get(
			`${this.API_URI}/get-next-active-listings/${agentID}/${referenceID}?forsale=${isVenta}&isMLSAgent=${isMLSAgent}`
		);
	}

	public getPrevAgentListings(agentID: number, referenceID: number, isVenta: boolean, isMLSAgent: boolean) {
		return this.http.get(
			`${this.API_URI}/get-prev-active-listings/${agentID}/${referenceID}?forsale=${isVenta}&isMLSAgent=${isMLSAgent}`
		);
	}
	public getNextAgentSoldListings(agentID: number, referenceID: number, isMLSAgent: boolean) {
		return this.http.get(
			`${this.API_URI}/get-next-sold-listings/${agentID}/${referenceID}?isMLSAgent=${isMLSAgent}`
		);
	}

	public getPrevAgentSoldListings(agentID: number, referenceID: number, isMLSAgent: boolean) {
		return this.http.get(
			`${this.API_URI}/get-prev-sold-listings/${agentID}/${referenceID}?isMLSAgent=${isMLSAgent}`
		);
	}

	public getPolygons(link: string): Observable<{ polygon: any }> {
		return this.http.get<{ polygon: any }>(link);
	}

	// Listing by Id getter
	getListingById(id: string | number) {
		return this.http.get<Listing[]>(`${this.API_URI}/getOne/${id}`).pipe(shareReplay());
	}

	// Listing by Id getter
	getRepoListingById(id: string | number) {
		return this.http.get<Listing[]>(`${this.API_URI}/repo/${id}`).pipe(shareReplay());
	}

	getUserListingById(id: string) {
		return this.http.get(`${this.API_URI}/getUserListing/${id}`);
	}

	getValue() {
		return 'stub value';
	}

	// Get last 3 listings
	getNewListings() {
		return this.http.get(`${this.API_URI}/newListings`).pipe(shareReplay());
	}

	getTrendingListings() {
		return this.http.get(`${this.API_URI}/trending-listings`).pipe(shareReplay());
	}

	public searchListings(query: SearchQuery, for_sale: PropertyType): Observable<any> {
		const params = { query: JSON.stringify(query) };
		if (for_sale === 'for_rent' || for_sale === 'for_sale') {
			const URIPath = for_sale === 'for_sale' ? '/search-listings-sale' : '/search-listings-rent';
			const link = this.API_URI + URIPath;
			return this.http.get(link, { params });
		}
		const URIPath = '/search-sold';
		const link = this.API_URI + URIPath;
		return this.http.get(link, { params });
	}

	public saveSearch(query: SearchQuery, for_sale: PropertyType, url: string, name: string) {
		const params = { query: JSON.stringify(query) };

		let link = null;
		if (for_sale === 'for_rent' || for_sale === 'for_sale') {
			const URIPath = for_sale === 'for_sale' ? '/save-search/for-sale' : '/save-search/for-rent';
			link = this.API_URI + URIPath;
		} else {
			const URIPath = '/save-search/sold';
			link = this.API_URI + URIPath;
		}
		return this.http.post(link, { name, url }, { params }).pipe(shareReplay());
	}

	getListingImages(listingId: number, limit: boolean = false) {
		return this.http
			.get(`${this.API_URI}/images/${listingId}`, {
				params: {
					limit
				}
			})
			.pipe(shareReplay());
	}

	createListingVenta(listing: Object): Observable<any> {
		return this.http.post(`${this.API_URI}/publicarListadoVenta`, listing);
	}

	createListingAlquiler(listing: Object): Observable<any> {
		return this.http.post(`${this.API_URI}/publicarListadoAlquiler`, listing);
	}

	public getFavoriteListings(index: number) {
		return this.http.get(`${this.API_URI}/userFavoriteListings/${index}`);
	}

	public getUserListings(offset: number, filters: Object, getCities: boolean = false) {
		return this.http
			.get(`${this.API_URI}/user-listings/${offset}`, {
				params: {
					filters: JSON.stringify(filters),
					cities: getCities
				}
			})
			.pipe(shareReplay());
	}

	public updateListingStatus(listingID: number, queryParams: Object) {
		return this.http.put(
			`${this.API_URI}/update-listing-status/${listingID}?date=${queryParams['date']}&price=${
				queryParams['price']
			}&status=${queryParams['status']}&mls=${queryParams['mls'] || false}`,
			{}
		);
	}

	public updateTourLink(listingID: number, URI: string) {
		return this.http.put(`${this.API_URI}/updateTour/${listingID}`, { URI: URI });
	}

	public uploadListingPicture(data: FormData) {
		let URL = 'https://upload.finditpr.com/listings/addPicture';
		if (!environment.production) {
			URL = 'https://upload.finditpr.com/listings/upload-picture-test';
		}
		return this.http.post(URL, data, {
			reportProgress: true,
			observe: 'events'
		});
	}

	public deleteListing(listingID: number) {
		return this.http.delete(`${this.API_URI}/delete-listing/${listingID}`);
	}

	public reportListing(payload: Object) {
		return this.http.post(`${this.API_URI}/reportListing`, JSON.stringify(payload), {
			headers: { 'Content-Type': 'application/json' }
		});
	}

	public updateListing(listingID: number, payload) {
		return this.http.put(`${this.API_URI}/update-listing/${listingID}`, JSON.stringify(payload), {
			headers: { 'Content-Type': 'application/json' }
		});
	}

	public verifyHUDcaseID(caseID: string) {
		return this.http.get(
			`https://5xywzx4dwa5ft4jx4oyzkgme4q0ibaet.lambda-url.us-east-1.on.aws/?case_number=${caseID}`
		);
	}

	public getCRIMPolygon(catastro: string) {
		const URL = `${this.API_URI}/crim-polygon?tax_id=${catastro}`;
		return this.http.get(URL);
	}
}
