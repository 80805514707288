<div class="parent-container">
	<div
		class="child-wrapper"
		[ngClass]="{ 'content-on-top': !stages.clicked_start, 'min-height-child-wrapper': !stages.form_ready }"
	>
		<div class="content" *ngIf="!stages.clicked_start" [@slideFade]>
			<div class="image-container">
				<img src="../../assets/prequalification/familyRidingBicycle.png" alt="Image 1" />
				<img src="../../assets/prequalification/coupleInCouch.png" alt="Image 2" />
				<img src="../../assets/prequalification/motherWithChild.jpg" alt="Image 3" />
			</div>
			<div class="mainInfo">
				<h2 i18n>Obtén tu pre-cualificación</h2>
				<p i18n>
					Contesta unas breves preguntas y un consultor hipotecario se comunicará contigo para ayudarte a
					encontrar el préstamo adecuado para ti. Libre de costo y sin verificación de crédito.
				</p>
				<div class="start-btn-n-logos">
					<button class="start-form-btn" (click)="onClickStart()">
						<ng-container i18n> Comenzar </ng-container>
					</button>
					<div class="logos">
						<img
							src="../../assets/prequalification/risingEntrepeneurs.png"
							alt="rising entrepeneurs logo"
						/>
						<div class="line"></div>
						<img
							src="../../assets/prequalification/anualHousingOportunity.png"
							alt="Anual housing oportunity logo"
						/>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="stages.loading" [@slideFade] class="loading-container">
			<h2 class="loading-tag" i18n>Cargando tu experiencia de consulta hipotecaria</h2>
			<div class="spinner-container">
				<mat-progress-spinner
					class="progress-spinner"
					[value]="progressValue"
					mode="determinate"
				></mat-progress-spinner>
				<mat-progress-spinner class="overlay-spinner" value="100" mode="determinate"></mat-progress-spinner>
			</div>
		</div>
		<ng-container *ngTemplateOutlet="formTemplate"></ng-container>
		<div *ngIf="stages.sending_request" [@slideFade] class="loading-container">
			<h2 class="loading-tag" i18n>Enviando todas sus respuestas</h2>
			<div class="spinner-container">
				<mat-progress-spinner
					class="progress-spinner"
					[value]="progressValue"
					mode="determinate"
				></mat-progress-spinner>
				<mat-progress-spinner class="overlay-spinner" value="100" mode="determinate"></mat-progress-spinner>
			</div>
		</div>
		<div class="success-step" [@slideFade] *ngIf="stages.success">
			<ng-container *ngIf="!error; else somethingWentWrongTemplate">
				<i-feather name="check-circle" class="check-circle"></i-feather>
				<ng-container *ngIf="qualifies; else didNotQualify">
					<h2 i18n>¡Gracias! Nos estaremos comunicando de 1 a 3 días.</h2>
					<p i18n>Si necesitas comunicarte directamente con nosotros nos puedes llamar al (787)-501-3712.</p>
				</ng-container>
				<ng-template #didNotQualify>
					<h2 i18n>¡Gracias! Recibimos tus respuestas exitosamente</h2>
					<p i18n>
						Estaremos evaluando las respuestas y brindando comunicación por correo electrónico próximamente.
					</p>
				</ng-template>
				<button routerLink="/puerto-rico/venta" i18n>Ver Más Propiedades</button>
				<a routerLink="/" i18n> Ir de Regreso al Home </a>
			</ng-container>
		</div>
	</div>
	<footer>
		<div class="footer-container">
			<div>
				<div i18n>Servicio proveído por</div>
				<img
					src="../../assets/prequalification/gsm.jpeg"
					width="124px"
					height="88px"
					style="object-fit: contain"
					alt="GSM Financial Puerto Rico"
				/>
			</div>
			<p i18n class="disclaimer">
				Al llenar este formulario, usted autoriza a un representante de GSM Financial Consultants, corredor de
				hipotecas con licencias NMLS 1896859 y OCIF MB 005, a contactarlo para discutir su solicitud. El
				servicio que será ofrecido es libre de costo para usted. Bluepath Group, LLC es una empresa de bienes
				raíces licenciada (E-401) y recibe compensación monetaria por recibir la información de contacto de los
				consumidores con el propósito de brindar seguimiento a su solicitud. Usted no está obligado a utilizar
				los servicios que les serán ofrecidos. Es libre de buscar opciones de productos hipotecarios o servicios
				relacionados.
			</p>
		</div>
	</footer>
</div>

<ng-template #formTemplate>
	<div class="mobile-back-btn" *ngIf="stages.form_ready">
		<ng-container *ngTemplateOutlet="backButton"></ng-container>
	</div>
	<div class="steps-container" [@slideFade] *ngIf="stages.form_ready">
		<div
			class="step step-1"
			[@fadeInOut]="{ value: '', params: { direction: fadeDirection } }"
			*ngIf="current_step === 1"
		>
			<h4 class="questions" i18n>¿Qué tipo de propiedad te interesa comprar?</h4>
			<div class="options">
				<button
					[ngClass]="{ selected: preQualification.home_type === 'SINGLE_FAMILY' }"
					(click)="onClickStep(2, 'home_type', 'SINGLE_FAMILY')"
				>
					<img src="../../assets/prequalification/home.svg" alt="" />
					<span i18n>Casa Unifamiliar</span>
				</button>
				<button
					[ngClass]="{ selected: preQualification.home_type === 'MULTI_FAMILY' }"
					(click)="onClickStep(2, 'home_type', 'MULTI_FAMILY')"
				>
					<img src="../../assets/prequalification/multipleHouses.svg" alt="" />
					<span i18n>Casa Multifamiliar</span>
				</button>
				<button
					[ngClass]="{ selected: preQualification.home_type === 'APARTMENT' }"
					(click)="onClickStep(2, 'home_type', 'APARTMENT')"
				>
					<img src="../../assets/prequalification/building.svg" alt="" />
					<span i18n>Apartamento</span>
				</button>
				<button
					[ngClass]="{ selected: preQualification.home_type === 'LAND' }"
					(click)="onClickStep(2, 'home_type', 'LAND')"
				>
					<img src="../../assets/prequalification/mountain.svg" alt="" />
					<span>
						<ng-container i18n>Terreno</ng-container>
						<br />
						<span style="font-size: 14px" i18n>(ó construcción)</span>
					</span>
				</button>
				<button
					[ngClass]="{ selected: preQualification.home_type === 'COMMERCIAL' }"
					(click)="onClickStep(2, 'home_type', 'COMMERCIAL')"
				>
					<img src="../../assets/prequalification/store.svg" alt="" />
					<span i18n>Comercial</span>
				</button>
			</div>
		</div>
		<div
			class="step step-2"
			[@fadeInOut]="{ value: '', params: { direction: fadeDirection } }"
			*ngIf="current_step === 2"
		>
			<h4 class="questions" i18n>¿Cómo estará comprado?</h4>
			<div class="options">
				<button
					[ngClass]="{ selected: preQualification.buying_with === 'ALONE' }"
					(click)="onClickStep(3, 'buying_with', 'ALONE')"
				>
					<img src="../../assets/prequalification/user.svg" alt="" />
					<span i18n>Yo Solo/Sola</span>
				</button>
				<button
					[ngClass]="{ selected: preQualification.buying_with === 'PARTNER' }"
					(click)="onClickStep(3, 'buying_with', 'PARTNER')"
				>
					<img src="../../assets/prequalification/userWithShadow.svg" alt="" />
					<span i18n>Con Mi Pareja</span>
				</button>
				<button
					[ngClass]="{ selected: preQualification.buying_with === 'BUSINESS_PARTNER' }"
					(click)="onClickStep(3, 'buying_with', 'BUSINESS_PARTNER')"
				>
					<img src="../../assets/prequalification/multiplePeople.svg" alt="" />
					<span i18n>Con Mi Socio/Socia</span>
				</button>
			</div>
		</div>
		<div
			class="step step-3"
			[@fadeInOut]="{ value: '', params: { direction: fadeDirection } }"
			*ngIf="current_step === 3"
		>
			<h4 class="questions" i18n>¿Cómo planificas utilizar la propiedad?</h4>
			<div class="options">
				<button
					[ngClass]="{ selected: preQualification.residence_type === 'PRIMARY' }"
					(click)="onClickStep(4, 'residence_type', 'PRIMARY')"
				>
					<img src="../../assets/prequalification/home.svg" alt="" />
					<span i18n>Residencia Principal</span>
				</button>
				<button
					[ngClass]="{ selected: preQualification.residence_type === 'VACATION' }"
					(click)="onClickStep(4, 'residence_type', 'VACATION')"
				>
					<img src="../../assets/prequalification/umbrella.svg" alt="" />
					<span>
						<ng-container i18n> Para Vacacionar </ng-container>
						<br />
						<span style="font-size: 14px" i18n> (Segunda Residencia) </span>
					</span>
				</button>
				<button
					[ngClass]="{ selected: preQualification.residence_type === 'INVESTMENT' }"
					(click)="onClickStep(4, 'residence_type', 'INVESTMENT')"
				>
					<img src="../../assets/prequalification/currencyExchange.svg" alt="" />
					<span i18n>Inversión</span>
				</button>
				<button
					[ngClass]="{ selected: preQualification.residence_type === 'BUSINESS' }"
					(click)="onClickStep(4, 'residence_type', 'BUSINESS')"
				>
					<img src="../../assets/prequalification/store.svg" alt="" />
					<span i18n>Para Mi Negocio</span>
				</button>
			</div>
		</div>
		<div
			class="step step-4"
			[@fadeInOut]="{ value: '', params: { direction: fadeDirection } }"
			*ngIf="current_step === 4"
		>
			<h4 class="questions" i18n>¿Dónde te encuentras en el proceso de compra?</h4>
			<div class="options">
				<button
					[ngClass]="{ selected: preQualification.process === 'THINKING' }"
					(click)="onClickStep(5, 'process', 'THINKING')"
				>
					<img src="../../assets/prequalification/psychology.svg" alt="" />
					<span i18n
						>Estoy Pensando <br />
						En Comprar</span
					>
				</button>
				<button
					[ngClass]="{ selected: preQualification.process === 'VISITING' }"
					(click)="onClickStep(5, 'process', 'VISITING')"
				>
					<img src="../../assets/prequalification/personWithHome.svg" alt="" />
					<span i18n> Estoy Visitando Propiedades </span>
				</button>
				<button
					[ngClass]="{ selected: preQualification.process === 'READY' }"
					(click)="onClickStep(5, 'process', 'READY')"
				>
					<img src="../../assets/prequalification/moneyCheck.svg" alt="" />
					<span i18n>Estoy Listo Para Hacer Una Oferta</span>
				</button>
				<button
					[ngClass]="{ selected: preQualification.process === 'SIGNED_CONTRACT' }"
					(click)="onClickStep(5, 'process', 'SIGNED_CONTRACT')"
				>
					<img src="../../assets/prequalification/scroll.svg" alt="" />
					<span i18n
						>Firmé Contrato <br />
						De Compraventa</span
					>
				</button>
			</div>
		</div>
		<div
			class="step step-5"
			[@fadeInOut]="{ value: '', params: { direction: fadeDirection } }"
			*ngIf="current_step === 5"
		>
			<h4 class="questions" i18n>¿Cuál es su situación de empleo actual?</h4>
			<div class="options">
				<button
					[ngClass]="{ selected: preQualification.employment_info === 'EMPLOYED' }"
					(click)="onClickStep(5.1, 'employment_info', 'EMPLOYED')"
				>
					<img src="../../assets/prequalification/badge.svg" alt="" />
					<span i18n
						>Empleado <br />
						Por Nómina</span
					>
				</button>
				<button
					[ngClass]="{ selected: preQualification.employment_info === 'SELF_EMPLOYED' }"
					(click)="onClickStep(5.4, 'employment_info', 'SELF_EMPLOYED')"
				>
					<img src="../../assets/prequalification/computer.svg" alt="" />
					<span i18n>
						Trabajo Por <br />
						Cuenta Propia
					</span>
				</button>
				<button
					[ngClass]="{ selected: preQualification.employment_info === 'RETIRED' }"
					(click)="onClickStep(6, 'employment_info', 'RETIRED')"
				>
					<img src="../../assets/prequalification/workSuitCase.svg" alt="" />
					<span i18n>Retirado</span>
				</button>
			</div>
		</div>
		<!-- CONDITIONALS START -->
		<div
			class="step step-5.1"
			[@fadeInOut]="{ value: '', params: { direction: fadeDirection } }"
			*ngIf="current_step === 5.1"
		>
			<h4 class="questions" i18n>¿Tiene Permanencia?</h4>
			<div class="options">
				<button
					[ngClass]="{ selected: preQualification.job_permanence === false }"
					(click)="onClickStep(5.2, 'job_permanence', false)"
				>
					<img src="../../assets/prequalification/no.svg" alt="" />
					<span i18n>No Tengo Permanencia</span>
				</button>
				<button
					[ngClass]="{ selected: preQualification.job_permanence === true }"
					(click)="onClickStep(5.3, 'job_permanence', true)"
				>
					<img src="../../assets/prequalification/yes.svg" alt="" />
					<span i18n>Tengo Permanencia</span>
				</button>
			</div>
		</div>
		<div
			class="step step-5.2"
			[@fadeInOut]="{ value: '', params: { direction: fadeDirection } }"
			*ngIf="current_step === 5.2"
		>
			<h4 class="questions" i18n>¿Llevas más de 1 año en el empleo?</h4>
			<div class="options">
				<button
					[ngClass]="{ selected: preQualification.less_than_a_year_employed === true }"
					(click)="onClickStep(6, 'less_than_a_year_employed', true)"
				>
					<img src="../../assets/prequalification/minus.svg" alt="" />
					<span i18n>Menos de 1 año</span>
				</button>
				<button
					[ngClass]="{ selected: preQualification.less_than_a_year_employed === false }"
					(click)="onClickStep(6, 'less_than_a_year_employed', false)"
				>
					<img src="../../assets/prequalification/plus.svg" alt="" />
					<span i18n>Más de 1 año</span>
				</button>
			</div>
		</div>
		<div
			class="step step-5.3"
			[@fadeInOut]="{ value: '', params: { direction: fadeDirection } }"
			*ngIf="current_step === 5.3"
		>
			<h4 class="questions" i18n>¿Cuánto tiempo llevas en tu trabajo actual?</h4>
			<div class="options">
				<button
					class="numbers center-mobile"
					(click)="onClickStep(6, 'permanent_job_time', 'LESS_THAN_6_MONTHS')"
					[ngClass]="{ selected: preQualification.permanent_job_time === 'LESS_THAN_6_MONTHS' }"
					i18n
				>
					Menos de 6 meses
				</button>
				<button
					class="numbers center-mobile"
					(click)="onClickStep(6, 'permanent_job_time', 'LESS_THAN_2_YEARS')"
					[ngClass]="{ selected: preQualification.permanent_job_time === 'LESS_THAN_2_YEARS' }"
					i18n
				>
					Menos de 2 años
				</button>
				<button
					class="numbers center-mobile"
					(click)="onClickStep(6, 'permanent_job_time', 'MORE_THAN_2_YEARS')"
					[ngClass]="{ selected: preQualification.permanent_job_time === 'MORE_THAN_2_YEARS' }"
					i18n
				>
					Más de 2 años
				</button>
			</div>
		</div>

		<div
			class="step step-5.4"
			[@fadeInOut]="{ value: '', params: { direction: fadeDirection } }"
			*ngIf="current_step === 5.4"
		>
			<h4 class="questions" i18n>¿Cuánto tiempo lleva en su empleo actual?</h4>
			<div class="options">
				<button
					[ngClass]="{ selected: preQualification.two_years_employed === false }"
					(click)="onClickStep(6, 'two_years_employed', false)"
				>
					<img src="../../assets/prequalification/minus.svg" alt="" />
					<span i18n>Menos de 2 años</span>
				</button>
				<button
					[ngClass]="{ selected: preQualification.two_years_employed === true }"
					(click)="onClickStep(6, 'two_years_employed', true)"
				>
					<img src="../../assets/prequalification/plus.svg" alt="" />
					<span i18n>2 años o más</span>
				</button>
			</div>
		</div>
		<!-- CONDITIONALS END -->
		<div
			class="step step-6"
			[@fadeInOut]="{ value: '', params: { direction: fadeDirection } }"
			*ngIf="current_step === 6"
			style="display: flex; flex-flow: column; align-items: center"
		>
			<h4 class="questions" style="max-width: 627px" i18n>
				¿Cuál es el ingreso anual bruto (antes de impuestos) de su hogar?
			</h4>
			<div class="options">
				<button
					[ngClass]="{ selected: preQualification.income === 'Menos de $30,000' }"
					class="numbers center-mobile"
					(click)="onClickStep(7, 'income', 'Menos de $30,000')"
					i18n
				>
					Menos de $30,000
				</button>
				<button
					[ngClass]="{ selected: preQualification.income === '$30,000 - $50,000' }"
					class="numbers center-mobile"
					(click)="onClickStep(7, 'income', '$30,000 - $50,000')"
				>
					$30,000 - $50,000
				</button>
				<button
					[ngClass]="{ selected: preQualification.income === '$50,000 - $75,000' }"
					class="numbers center-mobile"
					(click)="onClickStep(7, 'income', '$50,000 - $75,000')"
				>
					$50,000 - $75,000
				</button>
				<button
					[ngClass]="{ selected: preQualification.income === '$75,000 - $100,000' }"
					class="numbers center-mobile"
					(click)="onClickStep(7, 'income', '$75,000 - $100,000')"
				>
					$75,000 - $100,000
				</button>
				<button
					[ngClass]="{ selected: preQualification.income === 'Más de $100,000' }"
					class="numbers center-mobile"
					(click)="onClickStep(7, 'income', 'Más de $100,000')"
					i18n
				>
					Más de $100,000
				</button>
			</div>
		</div>
		<div
			class="step step-7"
			[@fadeInOut]="{ value: '', params: { direction: fadeDirection } }"
			*ngIf="current_step === 7"
		>
			<h4 class="questions" style="margin-bottom: 60px !important" i18n>
				¿Cuánto dinero tienes disponible para el pronto?
			</h4>
			<div class="options">
				<div class="input-container">
					<input
						[formControl]="downPaymentFormControl"
						[ngClass]="{
							'invalid-input': downPaymentFormControl.touched && downPaymentFormControl.invalid
						}"
						type="tel"
						prefix="$"
						mask="separator.0"
						thousandSeparator=","
						placeholder="e.g. $5,000"
					/>
					<button (click)="onClickStep(8, 'down_payment', 0)" i18n>Someter</button>
				</div>
				<div class="error-message">
					<p
						[ngClass]="{
							'show-error-message': downPaymentFormControl.touched && downPaymentFormControl.invalid
						}"
						i18n
					>
						Para proseguir debe ingresar un número aún sea 0 su respuesta, debe insertar un número para
						continuar. Puede ser una cifra estimada.
					</p>
				</div>
			</div>
		</div>
		<div
			class="step step-8"
			[@fadeInOut]="{ value: '', params: { direction: fadeDirection } }"
			*ngIf="current_step === 8"
		>
			<h4 class="questions" i18n>¿Cuál es tu puntaje de crédito (puntuación crediticia)?</h4>
			<div class="options">
				<button
					[ngClass]="{ selected: preQualification.credit_score === 'EXCELLENT' }"
					class="numbers center-mobile"
					(click)="onClickStep(9, 'credit_score', 'EXCELLENT')"
				>
					<ng-container i18n>Excelente</ng-container>
					<br class="mobile-no-display" />
					(720+)
				</button>

				<button
					[ngClass]="{ selected: preQualification.credit_score === 'GOOD' }"
					class="numbers center-mobile"
					(click)="onClickStep(9, 'credit_score', 'GOOD')"
				>
					<ng-container i18n> Bueno </ng-container>
					<br class="mobile-no-display" />
					(680 - 719)
				</button>
				<button
					[ngClass]="{ selected: preQualification.credit_score === 'FAIR' }"
					class="numbers center-mobile"
					(click)="onClickStep(9, 'credit_score', 'FAIR')"
				>
					<ng-container i18n> Razonable </ng-container>
					<br class="mobile-no-display" />
					(620 - 679)
				</button>
				<button
					[ngClass]="{ selected: preQualification.credit_score === 'POOR' }"
					class="numbers center-mobile"
					(click)="onClickStep(9, 'credit_score', 'POOR')"
				>
					<ng-container i18n> Deficiente </ng-container>
					<br class="mobile-no-display" />
					<ng-container i18n> (619 o menor) </ng-container>
				</button>
			</div>
		</div>
		<div
			class="step step-9"
			[@fadeInOut]="{ value: '', params: { direction: fadeDirection } }"
			*ngIf="current_step === 9"
		>
			<h4 class="questions" i18n>¿Usted o su cónyuge han servido en el ejército de los EE. UU.?</h4>
			<div class="options">
				<button
					[ngClass]="{ selected: preQualification.veteran === true }"
					(click)="onClickStep(10, 'veteran', true)"
				>
					<img class="wider-imgs" src="../../assets/prequalification/yes.svg" alt="" />
					<span class="bigger-fonts" i18n>Sí</span>
				</button>
				<button
					[ngClass]="{ selected: preQualification.veteran === false }"
					(click)="onClickStep(10, 'veteran', false)"
				>
					<img class="wider-imgs" src="../../assets/prequalification/no.svg" alt="" />
					<span class="bigger-fonts">No</span>
				</button>
			</div>
		</div>
		<div
			class="step step-10"
			[@fadeInOut]="{ value: '', params: { direction: fadeDirection } }"
			*ngIf="current_step === 10"
		>
			<h4 class="questions" i18n>¿Está trabajando activamente con un agente de bienes raíces?</h4>
			<div class="options">
				<button
					[ngClass]="{ selected: preQualification.working_with_agent === true }"
					(click)="onClickStep(11, 'working_with_agent', true)"
				>
					<img src="../../assets/prequalification/yes.svg" class="wider-imgs" alt="" />
					<span class="bigger-fonts" i18n>Sí</span>
				</button>
				<button
					[ngClass]="{ selected: preQualification.working_with_agent === false }"
					(click)="onClickStep(11, 'working_with_agent', false)"
				>
					<img src="../../assets/prequalification/no.svg" class="wider-imgs" alt="" />
					<span class="bigger-fonts">No</span>
				</button>
			</div>
		</div>
		<div
			class="step step-11"
			[@fadeInOut]="{ value: '', params: { direction: fadeDirection } }"
			*ngIf="current_step === 11"
			style="display: flex; flex-flow: column; align-items: center"
		>
			<h4 class="questions" i18n>¿A dónde nos comunicamos con usted para su pre-cualificación?</h4>
			<div class="options" style="width: 100%; margin: auto">
				<form [formGroup]="contactForm" (ngSubmit)="onSubmitContactInfo()">
					<div class="input-label">
						<label for="name" i18n>Nombre y Apellido</label>
						<input
							formControlName="name"
							[ngClass]="{
								'invalid-input': name.touched && name.invalid
							}"
							name="name"
							type="text"
							placeholder="Bruce Wayne"
						/>
					</div>

					<div class="input-label">
						<label for="phone" i18n>Teléfono</label>
						<input
							formControlName="phone"
							name="phone"
							[ngClass]="{
								'invalid-input': phone.touched && phone.invalid
							}"
							type="tel"
							mask="(000)-000-0000"
							placeholder="(787)-901-3985"
						/>
					</div>

					<div class="input-label">
						<label for="email">Email</label>
						<input
							formControlName="email"
							[ngClass]="{
								'invalid-input': email.touched && email.invalid
							}"
							style="min-width: 302px !important"
							type="email"
							placeholder="bruce@wayne.com"
						/>
					</div>
					<button type="submit" i18n>Someter</button>
				</form>
			</div>
		</div>
	</div>
	<ng-container *ngTemplateOutlet="formFooterTemplate"></ng-container>
</ng-template>

<ng-template #formFooterTemplate>
	<div class="back-btn-n-counter" *ngIf="stages.form_ready">
		<ng-container *ngTemplateOutlet="backButton"></ng-container>
		<ng-container *ngTemplateOutlet="stepsCounter"></ng-container>
	</div>
	<div class="mobile-steps-counter" *ngIf="stages.form_ready">
		<ng-container *ngTemplateOutlet="stepsCounter"></ng-container>
	</div>
</ng-template>

<ng-template #backButton>
	<button class="back-btn" (click)="stepBack()" [ngStyle]="{ visibility: current_step === 1 ? 'hidden' : 'unset' }">
		<i-feather name="arrow-left" class="chevron-icon"></i-feather>
		<span i18n>Regresar</span>
	</button>
</ng-template>

<ng-template #stepsCounter>
	<p class="completed-steps">
		{{ current_step_display }}/{{ total_steps }}
		<ng-container i18n>PREGUNTAS COMPLETADAS</ng-container>
	</p>
</ng-template>

<ng-template #somethingWentWrongTemplate>
	<i-feather name="alert-triangle" class="warning-triangle"></i-feather>
	<h2 i18n>Algo salió mal</h2>
	<p i18n>Disculpe los inconvenientes, por favor inténtalo de nuevo más tarde.</p>
	<button routerLink="/puerto-rico/venta" i18n>Ver Más Propiedades</button>
	<a routerLink="/" i18n> Ir de Regreso al Home </a>
</ng-template>
