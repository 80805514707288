import { Injectable } from '@angular/core';
import { SearchQuery } from '../interfaces/BusquedaInterface';
@Injectable({
	providedIn: 'root'
})
export class SearchListingsService {
	constructor() {}

	public validateSearch(query: SearchQuery): SearchQuery {
		try {
			if (query.selectedRegions) {
				query.selectedRegions = query.selectedRegions.slice(0, 5);
			} else {
				query.selectedRegions = [];
			}

			if (query.filters.keywords) {
				query.filters.keywords = query.filters.keywords.slice(0, 5);
				query.filters.keywords = query.filters.keywords.map((_) => {
					return _.substring(0, 25).trim();
				});
			} else {
				query.filters.keywords = [];
			}
			return query;
		} catch (error) {
			console.log(error);
			return this.defaultSearch();
		}
	}

	public defaultSearch() {
		const searchQuery: SearchQuery = {
			filters: {
				bds: -1,
				bhs: -1,
				home_types: {
					local: true,
					terreno: true,
					oficina: true,
					apartamento: true,
					almacen: true,
					edificio: true,
					casa: true,
					multi_familiar: true,
					estudio: true
					// habitacion: true,
				},
				keywords: [],
				price: {
					max: null,
					min: null
				},
				sortBy: 'NEWEST'
			},
			isMapVisible: true,
			bounds: null,
			pagina: 0,
			search: 'Puerto Rico',
			selectedRegions: []
		};

		return searchQuery;
	}
}
