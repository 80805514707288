import { HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, ElementRef, Inject, LOCALE_ID, OnDestroy, QueryList } from '@angular/core';
import { AfterViewInit, Component, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { Listing } from '../interfaces/ListingInterface';
import { ListingsService } from '../services/listings.service';
import { UserService } from '../services/user.service';
import { DialogEditComponent } from './dialog-edit/dialog-edit.component';
import { Location } from '@angular/common';

//Hashing
import { v4 as uuidv4 } from 'uuid';
import { switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MetaTagsService } from '../services/meta-tags.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Guided tour
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { ContactDialogBoxComponent } from '../selected-property/contact-dialog-box/contact-dialog-box.component';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import { AuthenticationService } from '../services/authentication.service';
@Component({
	selector: 'app-agent-profile',
	templateUrl: './agent-profile.component.html',
	styleUrls: ['./agent-profile.component.css']
})
export class AgentProfileComponent implements AfterViewInit, OnDestroy {
	requestInProgress: boolean = false;

	activeListingsSale: ListingsObj = {
		listings: [],
		total: 0,
		numPages: 0,
		pageNum: 1
	};
	activeListingsRent: ListingsObj = {
		listings: [],
		total: 0,
		numPages: 0,
		pageNum: 1
	};
	soldListings: ListingsObj = {
		listings: [],
		total: 0,
		numPages: 0,
		pageNum: 1
	};
	has_inactive_mls_listings: boolean = false;
	filters = JSON.stringify({ listing_type: 'INACTIVE_MLS', page: 0, sort: 'OLDEST' });

	isMyProfile: boolean = false;

	isMLSAgent: boolean = false;
	agentInfo: AgentInfo = null;

	@ViewChildren(MatPaginator) paginators: QueryList<MatPaginator>;
	@ViewChild('acercaChild') acercaChild: ElementRef;

	agentID: number = null;
	loadingActive: boolean = false;
	loadingSold: boolean = false;

	isActiveVenta: boolean = true;

	// Contact form
	disabledContactBtn: boolean = false;
	contactMessagePending: boolean = false;
	contactMessageSent: boolean = false;
	contactForm: FormGroup = this._fb.group({
		name: ['', [Validators.required, Validators.maxLength(50), Validators.minLength(3)]],
		phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
		email: ['', [Validators.required, Validators.email]],
		message: [
			this.locale === 'en'
				? "I'd like to know more about your services"
				: `Me interesa saber más información sobre sus servicios.`
		]
	});

	//Cover
	coverImage: string;
	selectedImage: File = null;
	uploadProgressCover = 0;
	requestInProgressProfile: boolean = false;
	showSaveOptions: boolean = false;
	pictureName: string = null;
	originalImage: string;
	savingPicture: boolean = false;

	not_found = {};
	data$: Observable<any> = this.route.paramMap.pipe(
		switchMap((params) => {
			if (params.get('mlsID')) {
				this.isMLSAgent = true;
				this.agentID = +params.get('mlsID');
			} else {
				this.agentID = +params.get('id');
			}
			return this.listingService.getAgentProfile(this.agentID, this.isMLSAgent);
		}),
		tap(async (response) => {
			if (response.agentInfo) {
				const { soldListings, activeListings, agentInfo, isMyProfile } = response;
				if (this.router.url !== agentInfo.slug) {
					this._location.go(agentInfo.slug);
				}
				this.activeListingsSale = { ...activeListings.sale };
				this.activeListingsRent = { ...activeListings.rent };
				this.soldListings = { ...soldListings };
				this.agentInfo = agentInfo;
				this.isMyProfile = isMyProfile;
				this.coverImage = this.agentInfo.cover_picture;
				this.originalImage = this.coverImage;

				if (soldListings?.has_inactive_mls_listings) {
					this.has_inactive_mls_listings = soldListings?.has_inactive_mls_listings;
				}

				const image = this.agentInfo.profile_picture;
				if (this.locale === 'en') {
					this.metaTagService.setCustomTags(
						this.agentInfo.meta_title_en,
						this.agentInfo.meta_description_en,
						image,
						'website',
						`https://www.finditpr.com/en${this.router.url}`
					);
				} else {
					this.metaTagService.setCustomTags(
						this.agentInfo.meta_title,
						this.agentInfo.meta_description,
						image,
						'website',
						`https://www.finditpr.com${this.router.url}`
					);
				}
			} else {
				// not found
				this.not_found = response;
				if (!this.not_found['inactive_profile']) {
					this.router.navigateByUrl('**');
				}
				if (this.router.url !== this.not_found['slug']) {
					this._location.go(this.not_found['slug']);
				}
			}
		})
	);

	isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);
	seeMore: boolean = false;
	constructor(
		private imageCompress: NgxImageCompressService,
		private readonly _fb: FormBuilder,
		private readonly listingService: ListingsService,
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		private readonly dialog: MatDialog,
		private readonly userService: UserService,
		private readonly metaTagService: MetaTagsService,
		private readonly _location: Location,
		private readonly cdr: ChangeDetectorRef,
		private readonly breakpointObserver: BreakpointObserver,
		private readonly authService: AuthenticationService,
		@Inject(LOCALE_ID) public locale: string
	) {}

	ngOnDestroy(): void {
		this.metaTagService.setDefaultTags();
	}

	ngAfterViewInit(): void {
		this.paginators.first.length = this.activeListingsSale.total;
		this.paginators.toArray()[1].length = this.activeListingsRent.total;
		this.paginators.last.length = this.soldListings.total;

		this.activeListingsSale.numPages = this.paginators.first.getNumberOfPages();
		this.activeListingsRent.numPages = this.paginators.toArray()[1].getNumberOfPages();
		this.soldListings.numPages = this.paginators.last.getNumberOfPages();

		this.activeListingsSale.pageNum = this.paginators.first.pageIndex + 1;
		this.activeListingsRent.pageNum = this.paginators.toArray()[1].pageIndex + 1;
		this.soldListings.pageNum = this.paginators.last.pageIndex + 1;
		this.cdr.detectChanges();

		if (this.authService.isAuthenticated()) {
			const { first_name, last_name, phone, email } = this.authService.getUserDetails();
			this.contactForm.get('name').setValue(`${first_name} ${last_name}`);
			this.contactForm.get('email').setValue(email);
			this.contactForm.get('phone').setValue(phone);
		}
	}

	onClickEdit(edit: string): void {
		const panelClass = 'dialog-edit';
		let dialogHeight = '368px';
		let dialogWidth = edit === 'about' ? '600px' : '437px';

		const editTitle = {
			about: this.locale === 'en' ? 'Edit about' : 'Edita tu descripción',
			address: this.locale === 'en' ? 'Edit address' : 'Edita la dirección',
			website: this.locale === 'en' ? 'Edit website' : 'Edita tu website',
			qrcode: this.locale === 'en' ? 'Download QR code' : 'Descarga tu QR code',
			time: this.locale === 'en' ? 'Edit business hours' : 'Edita tu horario',
			share: this.locale === 'en' ? 'Share profile' : 'Comparte tu perfil'
		};

		const currentDialog = this.dialog.open(DialogEditComponent, {
			data: {
				edit,
				editTitle: editTitle[edit],
				agentInfo: this.agentInfo
			},
			width: dialogWidth,
			panelClass,
			disableClose: true,
			maxWidth: '100vw'
		});

		const xsmallDialogSubscription = this.isExtraSmall.subscribe((size) => {
			if (size.matches) {
				currentDialog.updateSize('100vw', '100vh');
			} else {
				currentDialog.updateSize(dialogWidth, 'auto');
			}
		});

		currentDialog.afterClosed().subscribe((data) => {
			xsmallDialogSubscription.unsubscribe();
			if (data) {
				this.agentInfo = data;
			}
		});
	}

	onClickContact() {
		let dialogWidth = '437px';
		const payload = {
			findit_id: this.agentID,
			isMLSAgent: this.isMLSAgent,
			name: this.contactForm.get('name').value.trim(),
			email: this.contactForm.get('email').value.trim().toLowerCase(),
			phone: this.contactForm.get('phone').value,
			message: this.contactForm.get('message').value.trim()
		};
		const currentDialog = this.dialog.open(ContactDialogBoxComponent, {
			data: { payload },
			width: dialogWidth,
			disableClose: true,
			maxWidth: '100vw'
		});

		const xsmallDialogSubscription = this.isExtraSmall.subscribe((size) => {
			if (size.matches) {
				currentDialog.updateSize('100vw', '100vh');
			} else {
				currentDialog.updateSize(dialogWidth, 'auto');
			}
		});

		currentDialog.afterClosed().subscribe((data) => {
			xsmallDialogSubscription.unsubscribe();
			if (data) {
				this.agentInfo = data;
			}
		});
	}

	onClickNextActive(activeListings: ListingsObj): void {
		if (this.loadingActive) return;

		const activeListingsPaginator = this.isActiveVenta ? this.paginators.first : this.paginators.toArray()[1];
		activeListingsPaginator.nextPage();
		const page = activeListingsPaginator.pageIndex + 1;
		if (activeListings.pageNum !== page) {
			activeListings.pageNum = activeListingsPaginator.pageIndex + 1;
			const referenceID = activeListings.listings[activeListings.listings.length - 1].listing_id;
			this.loadingActive = true;
			this.listingService
				.getNextAgentListings(this.agentID, referenceID, this.isActiveVenta, this.isMLSAgent)
				.subscribe((listings: Listing[]) => {
					this.loadingActive = false;
					if (this.isActiveVenta) {
						this.activeListingsSale.listings = listings;
					} else {
						this.activeListingsRent.listings = listings;
					}
				});
		}
	}
	onClickPreviousActive(activeListings: ListingsObj): void {
		if (this.loadingActive) return;

		const activeListingsPaginator = this.isActiveVenta ? this.paginators.first : this.paginators.toArray()[1];
		activeListingsPaginator.previousPage();
		const page = activeListingsPaginator.pageIndex + 1;
		if (activeListings.pageNum !== page) {
			activeListings.pageNum = page;
			const referenceID = activeListings.listings[0].listing_id;
			this.loadingActive = true;
			this.listingService
				.getPrevAgentListings(this.agentID, referenceID, this.isActiveVenta, this.isMLSAgent)
				.subscribe((listings: Listing[]) => {
					if (this.isActiveVenta) {
						this.activeListingsSale.listings = listings;
					} else {
						this.activeListingsRent.listings = listings;
					}
					this.loadingActive = false;
				});
		}
	}

	onClickSeeMore() {
		this.seeMore = !this.seeMore;
	}

	get condition() {
		if (!this.acercaChild) {
			return false;
		} else {
			return (
				this.acercaChild.nativeElement.offsetHeight < 300 ||
				(this.seeMore && this.acercaChild.nativeElement.offsetHeight >= 300)
			);
		}
	}

	onClickNextSold(): void {
		if (this.loadingSold) return;

		const soldListingsPaginator = this.paginators.last;
		soldListingsPaginator.nextPage();

		const page = soldListingsPaginator.pageIndex + 1;
		if (this.soldListings.pageNum !== page) {
			this.soldListings.pageNum = page;
			const referenceID = this.soldListings.listings[this.soldListings.listings.length - 1].listing_id;
			this.loadingSold = true;
			this.listingService
				.getNextAgentSoldListings(this.agentID, referenceID, this.isMLSAgent)
				.subscribe((listings: Listing[]) => {
					this.soldListings.listings = listings;
					this.loadingSold = false;
				});
		}
	}
	onClickPreviousSold(): void {
		if (this.loadingSold) return;

		const soldListingsPaginator = this.paginators.last;
		soldListingsPaginator.previousPage();

		const page = soldListingsPaginator.pageIndex + 1;
		if (this.soldListings.pageNum !== page) {
			this.soldListings.pageNum = page;
			const referenceID = this.soldListings.listings[0].listing_id;
			this.loadingSold = true;
			this.listingService
				.getPrevAgentSoldListings(this.agentID, referenceID, this.isMLSAgent)
				.subscribe((listings: Listing[]) => {
					this.soldListings.listings = listings;
					this.loadingSold = false;
				});
		}
	}

	public formatPhoneNumber(phoneNumberString: string): string {
		const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
		const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			return '(' + match[1] + ')-' + match[2] + '-' + match[3];
		}
		return null;
	}

	private getBase64Img(img: File): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(img);
			reader.onload = ({ target: { result } }) => resolve(result.toString());
			reader.onerror = (error) => reject(error);
		});
	}

	private async compressImg(imgFile: File, picSize: number, picExt: string) {
		const base64Img = await this.getBase64Img(imgFile);

		const fiveHundredKiloBytes = 555776;
		let ratio = 100;
		let quality = 75;
		if (picSize >= fiveHundredKiloBytes) {
			ratio = 55;
			quality = 55;
		}
		const compressedImg = await this.imageCompress.compressFile(
			base64Img,
			DOC_ORIENTATION.NotDefined,
			ratio,
			quality
		);
		return this.base64toBlob(compressedImg.split(',')[1], picExt) as File;
	}

	//Convert from base64 to Blob
	private base64toBlob(base64Img: string, picExtension: string) {
		const byteString = window.atob(base64Img);
		const arrayBuffer = new ArrayBuffer(byteString.length);
		const int8Array = new Uint8Array(arrayBuffer);
		for (let i = 0; i < byteString.length; i++) {
			int8Array[i] = byteString.charCodeAt(i);
		}

		if (picExtension.toLowerCase() == 'jpg') picExtension = 'jpeg';

		const blob = new Blob([int8Array], { type: `image/${picExtension}` });
		return blob;
	}

	async onFileSelected({ target: { files } }) {
		if (files.length < 1) {
			return;
		}

		this.uploadProgressCover = 0;
		//Get the image
		const imgFile = files[0];

		//This is used to get the extension of the picture
		const extension = imgFile.name.split('.').pop();

		//Compress the Img
		this.selectedImage = await this.compressImg(imgFile, imgFile.size, extension);

		//Append each image name to a string array that will be used to store the information in the database
		this.pictureName = `${uuidv4()}.${extension}`;

		//Append the image
		const fd = new FormData();
		fd.append('CoverImage', this.selectedImage, this.pictureName);
		//Notify that there is a request in progress
		this.requestInProgress = true;

		//Make the request
		this.userService.uploadCoverPicture(fd).subscribe(
			(res: any) => {
				//If the response type is uploadprogress, update the upload progress
				if (res.type === HttpEventType.UploadProgress) {
					const progress = Math.round((res.loaded / res.total) * 100);
					if (progress > this.uploadProgressCover) {
						this.uploadProgressCover = progress;
					}
				} else if (res.type === HttpEventType.Response) {
					this.requestInProgress = false;
					this.coverImage = `https://images.finditpr.com/agents-cover-pictures/${this.pictureName}`;
					this.showSaveOptions = true;
				}
			},
			(err) => {
				console.log(err);
				this.requestInProgress = false;
			}
		);
	}

	onClickCancel() {
		this.showSaveOptions = false;
		this.coverImage = this.originalImage;
	}

	updatePicture() {
		const tempPosition: string = 'translate3d(0px, 0px, 0px)';
		this.savingPicture = true;
		const payload = {
			imageName: this.pictureName,
			imagePos: tempPosition
		};
		this.originalImage = this.coverImage;
		this.userService.updateCoverPicture(payload).subscribe(() => {
			this.showSaveOptions = false;
			this.savingPicture = false;
		});
	}

	public sendContactMessage(): void {
		if (this.contactForm.valid) {
			const payload = {
				findit_id: this.agentID,
				isMLSAgent: this.isMLSAgent,
				name: this.contactForm.get('name').value.trim(),
				email: this.contactForm.get('email').value.trim().toLowerCase(),
				phone: this.contactForm.get('phone').value.trim(),
				message: this.contactForm.get('message').value.trim()
			};

			this.contactForm.disable();
			this.contactMessagePending = true;
			this.userService.sendAgentProfileContact(payload).subscribe(
				() => {
					console.log('message sent');

					this.contactMessagePending = false;
					this.contactMessageSent = true;
					this.disabledContactBtn = true;
				},
				(error) => {
					this.contactForm.enable();
					this.contactMessagePending = false;
					console.log(error);
				}
			);
		}
	}

	onClickNotFoundBtn(): void {
		if (!this.not_found['isMyProfile']) {
			this.router.navigateByUrl('/');
		} else if (this.not_found['can_activate_profile']) {
			//Make HTTP Call to activate profile
			this.activateProfile();
		} else {
			//Redirect user a configuraciones
			this.router.navigateByUrl('/mi-perfil/configuraciones/general');
		}
	}

	private activateProfile() {
		this.requestInProgress = true;
		this.userService.updateAgentProfileStatus(true).subscribe(
			() => {
				this.requestInProgress = false;
				window.location.reload();
			},
			(err) => {
				console.log(err);
				this.requestInProgress = false;
			}
		);
	}
}

interface ListingsObj {
	listings: Listing[];
	total: number;
	numPages: number;
	pageNum: number;
}

interface AgentInfo {
	meta_title: string;
	meta_title_en: string;
	meta_description: string;
	meta_description_en: string;
	slug: string;
	cover_picture: string;
	cover_picture_position: string;
	about?: string;
	mls?: number;
	mls_agent_id?: string;
	business_name: string;
	email: string;
	licence: string;
	name: string;
	phone: string;
	profile_picture: string;
	website: string;
}
