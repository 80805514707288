import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { debounceTime } from 'rxjs/operators';
import { ListingsService } from '../services/listings.service';
import { RegionType } from '../interfaces/BusquedaInterface';

@Component({
	selector: 'app-auto-complete',
	templateUrl: './auto-complete.component.html',
	styleUrls: ['./auto-complete.component.css']
})
export class AutoCompleteComponent implements OnInit {
	highLightSearch = false;
	tempOptions = [];
	options: TypeSenseResult[] = [];
	requestInProgress = false;

	@Input() isVenta = true;

	// Search form group
	search: FormControl = new FormControl([]);
	constructor(
		private apartmentService: ListingsService,
		private router: Router,
		private formBuilder: FormBuilder,
		private cookieService: CookieService,
		@Inject(LOCALE_ID) public readonly locale: any
	) {}

	ngOnInit(): void {
		this.search.valueChanges.pipe(debounceTime(275)).subscribe(() => {
			const search = this.search.value;
			if (search != '') {
				this.requestInProgress = true;
				this.apartmentService.getSuggestionsV1(search).subscribe((response: TypeSenseResult[]) => {
					this.requestInProgress = false;
					this.options = response;
				});
			} else {
				this.options = [];
			}
		});
	}

	onFocusOutEvent($event) {
		this.tempOptions = this.options;
		this.options = [];
		this.highLightSearch = false;
	}

	onFocusInEvent($event) {
		this.options = this.tempOptions;
		this.highLightSearch = true;
	}

	onClickSearch(data?: TypeSenseResult): void {
		if (data?.region_type === RegionType.NOT_REGION) {
			this.router.navigate([data.link]);
			return;
		}

		let userSearch: string;
		if (data != null) {
			userSearch = data.search_term.trimRight().trimLeft();
		} else {
			userSearch = this.search.value.trimRight().trimLeft();
		}

		userSearch = this.removeAccents(userSearch);
		userSearch = userSearch.split(' ').join('-').toLowerCase();
		let URI = this.isVenta ? '/venta' : '/alquiler';
		this.router.navigate([`${userSearch}${URI}`]);
	}

	getDetailURL(data: TypeSenseResult) {
		let userSearch: string = data.search_term.trim();
		userSearch = this.removeAccents(userSearch);
		userSearch = userSearch.split(' ').join('-').toLowerCase();
		let URI = this.isVenta ? '/venta' : '/alquiler';
		return `${userSearch}${URI}`;
	}

	removeAccents(s) {
		let r = s;
		r = r.replace(new RegExp(/[,]/g), '');

		r = r.replace(new RegExp(/[àá]/g), 'a');
		r = r.replace(new RegExp(/[ÀÁ]/g), 'A');

		r = r.replace(new RegExp(/[èé]/g), 'e');
		r = r.replace(new RegExp(/[ÈÉ]/g), 'E');

		r = r.replace(new RegExp(/[ìí]/g), 'i');
		r = r.replace(new RegExp(/[ÌÍ]/g), 'I');

		r = r.replace(new RegExp(/ñ/g), 'n');
		r = r.replace(new RegExp(/Ñ/g), 'N');

		r = r.replace(new RegExp(/[òó]/g), 'o');
		r = r.replace(new RegExp(/[ÒÓ]/g), 'O');

		r = r.replace(new RegExp(/[ùúü]/g), 'u');
		r = r.replace(new RegExp(/[ÙÚŪ]/g), 'U');

		return r;
	}

	public getSource(suggestion: any): string {
		const on = this.locale === 'en' ? 'on ' : 'en ';
		if (suggestion.city) {
			return on + suggestion.city;
		}

		if (suggestion.office) {
			return on + suggestion.office;
		}

		if (suggestion.source) {
			return suggestion.source;
		}
	}
}

interface TypeSenseResult {
	db_id: number;
	search_term: string;
	region_type: number;
	link: string;
	category: string;
	category_en: string;
	snippet: string;
}
