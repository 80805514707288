export interface SearchQuery {
	pagina: number;
	search: string;
	filters: Filters;
	bounds: MapBounds;
	isMapVisible: boolean;
	selectedRegions: Region[];
	zoom?: number;
}
export enum RegionType {
	REGION = 6,
	AREA = 5,
	PUEBLO = 4,
	BARRIO = 3,
	SECTOR = 2,
	ZIP = 1,
	SUBURB_CONDOS = 0,
	NOT_REGION = -1
}
export interface Region {
	category: 'Pueblo' | 'Sector' | 'Zip' | 'Urbanización' | 'Area' | 'Profesional';
	city: string;
	db_id: number;
	region_type: RegionType;
	search_term: string;
}

export interface MapBounds {
	north: number;
	south: number;
	east: number;
	west: number;
}

export interface HomeTypesFilter {
	local?: boolean;
	terreno?: boolean;
	oficina?: boolean;
	apartamento?: boolean;
	almacen?: boolean;
	edificio?: boolean;
	casa?: boolean;
	multi_familiar?: boolean;
	habitacion?: boolean;
	estudio?: boolean;
	// todas?: boolean;
}

export interface MinMax {
	min: number;
	max: number;
}

enum SortTypeEnum {
	PRICE_MAXMIN,
	PRICE_MINMAX,
	NEWEST,
	OLDEST,
	MOST_PHOTOS,
	BH,
	BD
}
/**
 * This is equivalent to:
 * type SortType = 'PRICE_MINMAX' | 'PRICE_MAXMIN' | 'NEWEST' | 'OLDEST';
 */
export type SortType = keyof typeof SortTypeEnum;

export interface Filters {
	home_types: HomeTypesFilter;
	keywords: string[];
	price: MinMax;
	bds: number;
	bhs: number;
	sortBy: SortType;
}
