import { isPlatformBrowser } from '@angular/common';
import { Inject, LOCALE_ID, PLATFORM_ID, Pipe, PipeTransform } from '@angular/core';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SigninPopUpComponent } from '../auth/signin-pop-up/signin-pop-up.component';
import { ImageInterface } from '../interfaces/ImageInterface';
import { ListingCard } from '../interfaces/ListingCardInterface';
import { AuthenticationService } from '../services/authentication.service';
import { ListingsService } from '../services/listings.service';
import { UserService } from '../services/user.service';
import { ListingCardAbstract } from './listing-card.abstract';

@Component({
	selector: 'app-listing-card',
	templateUrl: './listing-card.component.html',
	styleUrls: ['./listing-card.component.css']
})
export class ListingCardComponent extends ListingCardAbstract implements OnInit, AfterViewInit, OnDestroy {
	@Input() listing: ListingCard = null;
	@Input() showHeart: boolean = true;
	@Output() disliked = new EventEmitter<number>();
	listingImages: ImageInterface[] = [];
	currentImg: string = '';
	loading: boolean = false;
	protected ngUnsubscribe: Subject<void> = new Subject<void>();
	constructor(
		@Inject(PLATFORM_ID) private platformId: any,
		public dialog: MatDialog,
		public authService: AuthenticationService,
		private readonly listingService: ListingsService,
		private readonly userService: UserService,
		private cdr: ChangeDetectorRef,
		@Inject(LOCALE_ID) public locale: string
	) {
		super();
		this._dialog = this.dialog;
		this._cdr = this.cdr;
		this._authService = this.authService;
		this._userService = this.userService;
	}

	ngOnDestroy(): void {
		// This aborts all HTTP requests.
		this.ngUnsubscribe.next();
		// This completes the subject properlly.
		this.ngUnsubscribe.complete();
	}

	ngOnInit() {
		if (this.listing) {
			this.currentImg = this.listing.listing_cover_picture;
		}
	}

	ngAfterViewInit(): void {
		if (this.authService.isAuthenticated()) {
			this.cdr.detectChanges();
		}
	}

	get address(): string {
		try {
			const { street, city, zip } = this.listing;
			return `${street} ${city} ${zip} PR`;
		} catch (error) {
			console.log(error);
			return '';
		}
	}

	get homeType(): string {
		const listing = this.listing;
		if (listing.property_type === 'Casa' && !listing.bedrooms) {
			return this.locale === 'en' ? 'Multi-family home' : 'Multi-familiar';
		}

		return this.locale === 'en' ? listing['property_type_en'] : listing.property_type;
	}

	get baGetter(): number {
		return +this.listing.bathrooms + +this.listing.half_bathrooms;
	}

	public _visibilityChangeHandler($event) {
		if ($event === 'VISIBLE' && !this.listing?.images) {
			//Make request on client side
			if (isPlatformBrowser(this.platformId)) {
				this.listingService
					.getListingImages(+this.listing.listing_id, true)
					.pipe(takeUntil(this.ngUnsubscribe))
					.subscribe((imgs: ImageInterface[]) => {
						this.listing.images = imgs;
					});
			}
		}
	}

	get listingLink(): string {
		if (+this.listing.listing_agents > 1) {
			return this.listing.detailURL.replace('propiedad', 'propiedad-reposeida');
		}
		return this.listing.detailURL;
	}

	public get daysOnFindit(): number {
		return new DaysOnFinditPipe().transform(this.listing['added_on'], 'days');
	}
	public get hoursOnFindit(): number {
		return new DaysOnFinditPipe().transform(this.listing['added_on'], 'hours');
	}
}

@Pipe({
	name: 'DaysOnFindit'
})
export class DaysOnFinditPipe implements PipeTransform {
	transform(startDate: string, format: 'hours' | 'days'): any {
		const endDate = new Date().toISOString();
		const diffInMs = new Date(endDate).valueOf() - new Date(startDate).valueOf();
		const diff = format === 'days' ? diffInMs / (1000 * 60 * 60 * 24) : diffInMs / (1000 * 60 * 60);
		return diff < 1 ? 0 : Math.round(diff);
	}
}
