<div class="contenedor">
    <div class="child-container" *ngIf="cityObs | async">
        <h1 class="heading-s">
            <ng-container i18n>
                Propiedades en {isVenta, select, true {venta} other {alquiler}} en el Pueblo de
            </ng-container>
            {{cityName}}
        </h1>
        <h2 class="text-m" style="color: var(--grayscale-4) !important;">
            <ng-container i18n>
                Barrios en
            </ng-container>
            {{cityName}}
            <ng-container i18n>
                , Puerto Rico con propiedades en {isVenta, select, true {venta} other {alquiler}}.
            </ng-container>
        </h2>
        <hr>
        <div class="barrios-grid">
            <a *ngFor="let barrio of barrios" [routerLink]="[getBarrioLink(barrio)]">{{barrio}}</a>
        </div>

        <h1 class="heading-s">
            <ng-container i18n>
                Mercados Populares en
            </ng-container>
            {{cityName}}, Puerto Rico
        </h1>
        <hr>
        <div class="popular-cats">
            <a [routerLink]="[getPopularLink('/casas-en-')]">
                <ng-container i18n>
                    Casas en {isVenta, select, true {venta} other {alquiler}} en
                </ng-container>
                {{cityName}}
            </a>
            <a [routerLink]="[getPopularLink('/apartamentos-en-')]">
                <ng-container i18n>
                    Apartamentos en {isVenta, select, true {venta} other {alquiler}} en
                </ng-container>
                {{cityName}}
            </a>
            <a [routerLink]="[getPopularLink('/locales-comerciales-en-')]">
                <ng-container i18n>
                    Locales Comerciales en {isVenta, select, true {venta} other {alquiler}} en
                </ng-container>
                {{cityName}}
            </a>
            <a [routerLink]="[getPopularLink('/terrenos-en-')]">
                <ng-container i18n>
                    Terrenos en {isVenta, select, true {venta} other {alquiler}} en
                </ng-container>
                {{cityName}}
            </a>
        </div>
    </div>
</div>