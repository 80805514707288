import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ListingsService } from '../services/listings.service';
import { Listing } from '../interfaces/ListingInterface';
import { Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { MetaTagsService } from '../services/meta-tags.service';

@Component({
	selector: 'app-home-page',
	templateUrl: './home-page.component.html',
	styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit, OnDestroy {
	defaultImage = '../../assets/loading.svg';
	imageURL = '../assets/ogimage.jpg';
	allListings: Listing[];

	ventaSelected: boolean = true;

	@ViewChild('listingsContainer', { static: false, read: ElementRef })
	public listingsContainer: ElementRef<any>;

	private subscription: Subscription;
	isHandset$: Observable<boolean> = this.breakpointObserver
		.observe(Breakpoints.Handset)
		.pipe(map((result) => result.matches));

	constructor(
		private apartmentService: ListingsService,
		private router: Router,
		private breakpointObserver: BreakpointObserver,
		private readonly metaTagsService: MetaTagsService
	) {}

	ngOnInit() {
		this.metaTagsService.setDefaultTags();
		//Get the apartments and put them on a array
		this.subscription = this.apartmentService.getTrendingListings().subscribe((res: Listing[]) => {
			this.allListings = res;
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	scroll(el: HTMLElement) {
		el.scrollIntoView({ behavior: 'smooth', block: 'center' });
	}

	onClickBuscar(): void {
		this.router.navigate(['puerto-rico/venta']);
	}

	onSelectVentaAlquiler(): void {
		this.ventaSelected = !this.ventaSelected;
	}
}
