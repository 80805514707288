import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, LOCALE_ID, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Listing } from '../interfaces/ListingInterface';
import { ListingsService } from '../services/listings.service';
import { MetaTagsService } from '../services/meta-tags.service';
import { Location } from '@angular/common';
import { LightBoxDialogComponent } from '../selected-property/light-box-dialog/light-box-dialog.component';
@Component({
	selector: 'app-repo-property',
	templateUrl: './repo-property.component.html',
	styleUrls: ['./repo-property.component.css', '../selected-property/selected-property.component.css']
})
export class RepoPropertyComponent implements OnInit {
	listingAgents = [];
	defaultImage = '../../assets/loading.svg';
	similarListings = [];
	nearbyCities = [];
	imageObject: any[] = [];
	imageURL = '../assets/ogimage.jpg';
	listing: Listing = null;
	params: ParamMap;

	listing$: Observable<any> = this.route.paramMap.pipe(
		switchMap((params) => {
			this.params = params;
			return this.listingService.getRepoListingById(+params.get('id'));
		}),
		tap(async ({ listing, images, nearbyCities, nearbyListings, not_found_info, listings_agents }) => {
			if (!not_found_info && !listing) {
				this.router.navigateByUrl('**');
			}
			this.listing = listing;
			this.imageObject = images.map((value) => {
				return { image: `${value.image}`, thumbImage: `${value.image}`, alt: '', title: '' };
			});

			this.listingAgents = listings_agents;
			this.similarListings = nearbyListings;
			this.nearbyCities = nearbyCities;

			this.metaTagsService.setListingTags(this.listing, this.router.url);
		})
	);

	constructor(
		private route: ActivatedRoute,
		private listingService: ListingsService,
		private readonly breakpointObserver: BreakpointObserver,
		public dialog: MatDialog,
		private router: Router,
		private readonly metaTagsService: MetaTagsService,
		private _location: Location,
		@Inject(PLATFORM_ID) private platformId: any,
		@Inject(LOCALE_ID) public locale: any
	) {}

	ngOnInit(): void {}

	formatPhoneNumber(phoneNumberString: string): string {
		const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
		const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			return '(' + match[1] + ')-' + match[2] + '-' + match[3];
		}
		return null;
	}

	public openImgs(): void {
		this.dialog.open(LightBoxDialogComponent, {
			maxWidth: '100vw',
			maxHeight: '100vh',
			height: '100%',
			width: '100%',
			data: this.imageObject,
			panelClass: 'app-full-bleed-dialog'
		});
	}

	get propSubType(): string {
		if (this.locale === 'en' && this.listing.property_sub_type === 'Multi-familiar') {
			return 'Multi-family home';
		}
		return this.listing.property_sub_type;
	}

	get homeType(): string {
		if (this.listing.property_sub_type === 'Multi-familiar') {
			return this.propSubType;
		}

		const listing = this.listing;
		return this.locale === 'en' ? listing.property_type_en : listing.property_type;
	}
}
