<div style="position: relative" class="animated-bg" [style.height]="height">
	<ng-content></ng-content>
	<swiper
		[style.height]="height"
		*ngIf="imgsArr.length > 0"
		[config]="config"
		class="mySwiper"
		[ngClass]="{ 'no-bullets': edit }"
	>
		<ng-template *ngFor="let i of imgsArr; trackBy: trackById" swiperSlide>
			<ng-container *ngIf="edit">
				<img
					[src]="i.image"
					height="100%"
					width="100%"
					[style.maxHeight]="height"
					style="object-fit: cover"
					[alt]="address"
				/>
			</ng-container>
			<ng-container *ngIf="!edit">
				<a [routerLink]="listingLink" target="_blank">
					<img
						class="swiper-lazy"
						[attr.data-src]="i.image"
						height="100%"
						width="100%"
						[style.maxHeight]="height"
						style="object-fit: cover"
						[alt]="address"
					/>
				</a>
			</ng-container>
		</ng-template>
	</swiper>
</div>
