<ng-container *ngIf="listing$ | async as data; else isLoading">
	<ng-container *ngIf="data?.listing; else outOfMarket">
		<div class="sticky-header" [ngClass]="{ 'display-sticky': showHeader }">
			<div class="wrapper-sticky">
				<div class="container-property">
					<div class="grid-sticky">
						<div>
							<h1 class="text-l-sb grid-sticky-street">{{ data.listing.street }}</h1>
							<h1 class="text-m" style="grid-column-start: 1">
								{{ data.listing.city + ', PR ' + data.listing.zip }}
							</h1>
						</div>
						<div style="display: flex; justify-content: center">
							<div class="heading-s">
								<i-feather
									*ngIf="
										data.listing.starting_price &&
										+data.listing.starting_price > +data.listing.current_price
									"
									name="arrow-down"
									class="arrow-down-icon"
									style="color: var(--primary-color-1)"
								></i-feather>
								{{ data.listing.current_price | currency : 'USD' : 'symbol' : '1.0'
								}}<span
									*ngIf="
										data.listing.type === 'Alquiler' && listing.price_type === 'Total';
										else perSqft
									"
									class="mes"
									i18n
									>/mes</span
								>
								<ng-template #perSqft>
									<span class="mes" *ngIf="listing.price_type !== 'Total'">
										/{{ locale === 'en' ? listing.price_type_en : listing.price_type }}</span
									>
								</ng-template>
								<div
									*ngIf="
										data.listing.starting_price &&
										+data.listing.starting_price > +data.listing.current_price
									"
									class="text-s"
									style="color: var(--grayscale-4)"
								>
									<ng-container i18n> Precio inicial: </ng-container>
									{{ data.listing.starting_price | currency : 'USD' : 'symbol' : '1.0' }}
								</div>
							</div>
						</div>
						<div class="text-m-sb grid-sticky-div">
							<div>
								<span (click)="onClickLike(data.listing.listing_id)">
									<i-feather
										[ngClass]="{ 'heart-liked': data.listing.isListingSaved == 1 }"
										name="Heart"
										class="heart-icon"
									>
									</i-feather
									><span class="hide-mobile cursor-pointer">
										{ +data.listing.isListingSaved, select, 1 {Guardado} other {Guardar} }
									</span>
								</span>

								<i-feather name="upload" (click)="onClickShare(data.listing)" class="upload-icon">
								</i-feather>
								<span (click)="onClickShare(data.listing)" class="hide-mobile cursor-pointer" i18n
									>Compartir</span
								>

								<ng-container
									*ngIf="
										data.listing.listing_status !== 'Alquilado/Alquilada' &&
										data.listing.listing_status !== 'Vendido/Vendida'
									"
								>
									<button
										mat-button
										style="margin-left: 16px"
										(click)="scrollContactForm()"
										class="primary-background-btn"
									>
										<ng-container i18n> Contactar </ng-container>
									</button>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="wrapper">
			<div class="container-property" cdkScrollable>
				<div class="grid-1">
					<h1 class="text-l-sb hide-mobile">{{ data.listing.street }}</h1>
					<h1 class="text-m hide-mobile" style="grid-column-start: 1">
						{{ data.listing.city + ', PR ' + data.listing.zip }}
					</h1>
					<i-feather name="Chevron-left" style="visibility: hidden !important" class="show-mobile">
					</i-feather>
					<div class="text-m-sb grid-1-div">
						<div (click)="onClickLike(data.listing.listing_id)">
							<i-feather
								[ngClass]="{ 'heart-liked': data.listing.isListingSaved == 1 }"
								name="Heart"
								class="heart-icon"
							>
							</i-feather
							><span class="hide-mobile cursor-pointer"
								>{ +data.listing.isListingSaved, select, 1 {Guardado} other {Guardar} }</span
							>
						</div>
						<div id="shareBtn" (click)="onClickShare(data.listing)">
							<i-feather name="upload" class="upload-icon"></i-feather>
							<span class="hide-mobile cursor-pointer" i18n>Compartir</span>
						</div>
						<div (click)="onClickReport(data.listing)">
							<i-feather name="flag" class="reportar-icon"></i-feather>
							<span class="hide-mobile cursor-pointer" i18n>Reportar</span>
						</div>
					</div>
				</div>

				<div class="images-hero" style="overflow: hidden">
					<div class="label-container">
						<div class="opcionado" *ngIf="data.listing.isForeclosure === 1" i18n>REO</div>
						<div class="opcionado" *ngIf="data.listing.listing_status === 'Opcionado/Opcionada'" i18n>
							Opcionado
						</div>
						<div
							class="vendido"
							*ngIf="
								data.listing.listing_status === 'Alquilado/Alquilada' ||
								data.listing.listing_status === 'Vendido/Vendida'
							"
						>
							<h6>
								{{ data.listing.listing_status === 'Alquilado/Alquilada' ? 'Alquilado' : 'Vendido' }}
								<span *ngIf="data.listing.status_update_date !== null"
									>el {{ getSpanishDate(data.listing.status_update_date) }}</span
								>
							</h6>
						</div>
					</div>
					<div class="image-box">
						<div class="images-grid" (click)="openImgs()">
							<div class="hero-image">
								<img
									[src]="data.listing.listing_cover_picture"
									alt="{{ data.listing.street }} {{ data.listing.city }}, PR {{ data.listing.zip }}"
								/>
							</div>
							<div class="first-image">
								<img
									[src]="
										imageObject.length > 1
											? imageObject[1].image
											: data.listing.listing_cover_picture
									"
									alt="{{ data.listing.street }} {{ data.listing.city }}, PR {{ data.listing.zip }}"
								/>
							</div>
							<div class="second-image">
								<img
									[src]="
										imageObject.length > 2
											? imageObject[2].image
											: data.listing.listing_cover_picture
									"
									alt="{{ data.listing.street }} {{ data.listing.city }}, PR {{ data.listing.zip }}"
								/>
							</div>
						</div>
					</div>
					<div class="pictureQuantity">
						<i-feather name="Image" class="img-icon"></i-feather>
						<span class="text-m">{{ imageObject.length }}</span>
					</div>

					<div class="new-tag-div" *ngIf="data.listing.new_listing === 'true'">
						<ng-container *ngTemplateOutlet="newTag"></ng-container>
					</div>
				</div>

				<div class="grid-wrapper">
					<div style="width: 100%; position: relative">
						<div class="first-section">
							<div
								class="text-m"
								style="color: var(--grayscale-1)"
								*ngIf="
									data.listing.listing_status !== 'Alquilado/Alquilada' &&
										data.listing.listing_status !== 'Vendido/Vendida';
									else fueraDelMercadoTemplate
								"
							>
								<span [ngClass]="data.listing.type === 'Alquiler' ? 'dot-yellow' : 'dot-blue'"></span>
								{{ homeType }}
								<ng-container i18n>
									{data.listing.type, select, Venta {en venta} other {en alquiler}}
								</ng-container>
							</div>
							<div class="heading-s text-align-end">
								<i-feather
									*ngIf="
										data.listing.starting_price &&
										+data.listing.starting_price > +data.listing.current_price
									"
									name="arrow-down"
									class="arrow-down-icon"
									style="color: var(--primary-color-1)"
								></i-feather>
								{{ data.listing.current_price | currency : 'USD' : 'symbol' : '1.0'
								}}<span
									*ngIf="
										data.listing.type === 'Alquiler' && listing.price_type === 'Total';
										else perSqft
									"
									class="mes"
									i18n
									>/mes</span
								>
							</div>
							<ng-container [ngSwitch]="data.listing.property_type">
								<ng-container *ngSwitchCase="'Terreno'" [ngTemplateOutlet]="showLandInfo">
								</ng-container>
								<ng-container *ngSwitchDefault [ngTemplateOutlet]="showUnitInfo"></ng-container>
							</ng-container>
							<ng-container
								*ngIf="
									data.listing.starting_price &&
									+data.listing.starting_price > +data.listing.current_price
								"
							>
								<div class="text-s text-align-end" style="color: var(--grayscale-4)">
									<ng-container i18n> Precio inicial: </ng-container>
									{{ data.listing.starting_price | currency : 'USD' : 'symbol' : '1.0' }}
								</div>
							</ng-container>
							<ng-container
								*ngIf="
									!(
										data.listing.starting_price &&
										+data.listing.starting_price > +data.listing.current_price
									)
								"
							>
								<ng-container *ngTemplateOutlet="preQualificationTemplate"></ng-container>
							</ng-container>
						</div>
						<div class="hide-tablet">
							<ng-container *ngTemplateOutlet="timeOnFindit"></ng-container>
							<ng-container
								*ngIf="
									data.listing.starting_price &&
									+data.listing.starting_price > +data.listing.current_price
								"
							>
								<ng-container *ngTemplateOutlet="preQualificationTemplate"></ng-container>
							</ng-container>
						</div>

						<div class="first-section-mobile">
							<div>
								<h1 class="text-l-sb">{{ data.listing.street }}</h1>
								<h1 class="text-m" style="margin-bottom: 24px">
									{{ data.listing.city + ', PR ' + data.listing.zip }}
								</h1>
								<div class="heading-s" style="margin-bottom: 24px">
									<i-feather
										*ngIf="
											data.listing.starting_price &&
											+data.listing.starting_price > +data.listing.current_price
										"
										name="arrow-down"
										class="arrow-down-icon"
										style="color: var(--primary-color-1)"
									>
									</i-feather>
									{{ data.listing.current_price | currency : 'USD' : 'symbol' : '1.0'
									}}<span
										*ngIf="
											data.listing.type === 'Alquiler' && listing.price_type === 'Total';
											else perSqft
										"
										class="mes"
										i18n
										>/mes</span
									>
									<div
										class="text-s"
										style="color: var(--grayscale-4)"
										*ngIf="
											data.listing.starting_price &&
											+data.listing.starting_price > +data.listing.current_price
										"
									>
										<ng-container i18n> Precio inicial: </ng-container>
										{{ data.listing.starting_price | currency : 'USD' : 'symbol' : '1.0' }}
									</div>
									<ng-container *ngTemplateOutlet="preQualificationTemplate"></ng-container>
								</div>
								<div
									class="text-m"
									style="color: var(--grayscale-1)"
									style="margin-bottom: 8px"
									*ngIf="
										data.listing.listing_status !== 'Alquilado/Alquilada' &&
											data.listing.listing_status !== 'Vendido/Vendida';
										else fueraDelMercadoTemplate
									"
								>
									<span
										[ngClass]="data.listing.type === 'Alquiler' ? 'dot-yellow' : 'dot-blue'"
									></span>
									{{ homeType }}
									<ng-container i18n>
										{data.listing.type, select, Venta {en venta} other {en alquiler}}
									</ng-container>
								</div>
								<ng-container [ngSwitch]="data.listing.property_type">
									<ng-container *ngSwitchCase="'Terreno'" [ngTemplateOutlet]="showLandInfo">
									</ng-container>
									<ng-container *ngSwitchDefault [ngTemplateOutlet]="showUnitInfo"></ng-container>
								</ng-container>

								<ng-container *ngTemplateOutlet="timeOnFindit"></ng-container>
							</div>
						</div>

						<div>
							<hr class="margin-24-top-bottom" />
							<div style="position: relative">
								<div class="second-section" #slider (scroll)="onScroll($event)">
									<div (click)="openDialog(3)">
										<img src="../../assets/pin-grid.svg" width="22px" height="22px" alt="" />
										<ng-container i18n> Ubicación </ng-container>
									</div>

									<div (click)="link.click()">
										<img src="../../assets/navigation-grid.svg" width="22px" height="22px" alt="" />
										<ng-container i18n> Direcciones </ng-container>
										<a
											#link
											style="display: none"
											href="http://maps.google.com/maps?q={{ data.listing.latitude }},{{
												data.listing.longitude
											}}"
											target="_blank"
										></a>
									</div>

									<div (click)="openDialog(1)">
										<img src="../../assets/nearby-grid.svg" width="22px" height="22px" alt="" />
										<ng-container i18n> Lugares Cercanos </ng-container>
									</div>

									<div *ngIf="streetViewAvailable" style="margin-bottom: 0" (click)="openDialog(2)">
										<img
											src="../../assets/street-view-grid.svg"
											width="22px"
											height="22px"
											alt=""
										/>
										Street View
									</div>

									<div
										*ngIf="data.listing.virtual_tour !== null && data.listing.virtual_tour !== ''"
										(click)="openDialog(4)"
										style="margin-bottom: 0"
									>
										<img src="../../assets/tour-grid.svg" width="22px" height="22px" alt="" />
										3D Tour
									</div>
								</div>
								<div class="leftBlur" [ngStyle]="{ display: blurLeft ? 'inherit' : 'none' }"></div>
								<div class="rightBlur" [ngStyle]="{ display: blurRight ? 'inherit' : 'none' }"></div>
							</div>
							<hr class="margin-24-top-bottom" />
						</div>

						<div class="third-section">
							<h2 class="text-l-sb section-headings" i18n>Descripción</h2>
							<p class="description text-m">{{ data.listing.listing_description }}</p>
							<hr class="content-separator" />
						</div>

						<div class="fourth-section">
							<h2 class="text-l-sb section-headings" i18n>Información de la Propiedad</h2>
							<div class="details-grid">
								<div class="detalles-fonts" *ngIf="data.listing.year_built != 'Desconocido'">
									<ng-container i18n> Año de Construcción: </ng-container>
									{{ data.listing.year_built }}
								</div>
								<div class="detalles-fonts" *ngIf="data.listing.HOA > 0">
									HOA: {{ data.listing.HOA | currency : 'USD' : 'symbol' : '1.0' }}
									<ng-container i18n>/mes</ng-container>
								</div>
								<div class="detalles-fonts">
									<ng-container i18n> Tipo de propiedad: </ng-container>
									{{ homeType }}
								</div>
								<div class="detalles-fonts" *ngIf="data.listing.CRIM > 0">
									<ng-container i18n> CRIM: </ng-container>
									{{ data.listing.CRIM | currency : 'USD' : 'symbol' : '1.0' }}
								</div>
								<div
									class="detalles-fonts"
									*ngIf="
										data.listing.property_sub_type &&
										data.listing.property_sub_type !== 'Multi-familiar'
									"
								>
									<ng-container i18n> Subtipo: </ng-container>
									{{ propSubType }}
								</div>
								<!-- <div class="detalles-fonts">Zonificación:
                R-3</div> -->
								<!-- <div class="detalles-fonts"
                *ngIf="(data.listing.unit != 'Desconocido' && data.listing.price_per_square)">
                Precio/{{data.listing.unit}}<sup>2</sup>: {{data.listing.price_per_square ?
                (data.listing.price_per_square | currency:'USD':'symbol':'1.0') : 'Desconocido'}}</div> -->
								<!-- <div class="detalles-fonts" *ngIf="data.listing.banco_reposedor != 'N/A'">
                Banco Reposeedor: {{data.listing.banco_reposedor}}</div> -->
							</div>
							<hr class="content-separator" />
						</div>

						<div class="fifth-section" *ngIf="data.listing.property_type !== 'Terreno'">
							<h2 class="text-l-sb section-headings" i18n>Detalles del interior</h2>
							<div class="details-grid">
								<div class="detalles-fonts" *ngIf="data.listing.unit_storeys">
									<ng-container i18n> Niveles de la unidad: </ng-container>
									{{ data.listing.unit_storeys }}
								</div>
								<div class="detalles-fonts" *ngIf="data.listing.livable_area > 0">
									<ng-container i18n> Área interior: </ng-container>
									{{ data.listing.livable_area | number }} {{ ' ' + data.listing.livable_area_unit
									}}<sup
										*ngIf="
											data.listing.livable_area_unit != 'Desconocido' &&
											data.listing.livable_area_unit != 'acres' &&
											data.listing.livable_area_unit != 'cuerdas'
										"
										>2</sup
									>
								</div>
								<div class="detalles-fonts" *ngIf="data.listing.bedrooms">
									<ng-container i18n> Habitaciones: </ng-container>
									{{ data.listing.bedrooms }}
								</div>
								<div class="detalles-fonts" *ngIf="data.listing.bathrooms > 0">
									<ng-container i18n> Baños Completos: </ng-container>
									{{ +data.listing.bathrooms }}
								</div>
								<div class="detalles-fonts" *ngIf="data.listing.half_bathrooms">
									<ng-container i18n> Medios Baños: </ng-container>
									{{ data.listing.half_bathrooms }}
								</div>
								<div class="detalles-fonts" *ngIf="data.listing.flooring_type">
									<ng-container i18n> Piso: </ng-container>
									{{ locale === 'en' ? data.listing.flooring_type_en : data.listing.flooring_type }}
								</div>
								<div class="detalles-fonts" *ngIf="data.listing.cooling_type">
									<ng-container i18n> Aire Condicionado: </ng-container>
									{{ locale === 'en' ? data.listing.cooling_type_en : data.listing.cooling_type }}
								</div>
							</div>
							<hr class="content-separator" />
						</div>

						<div [ngClass]="{ 'show-hidden-stuff': seeMore }" class="hidden-div">
							<div
								class="six-section"
								*ngIf="
									data.listing.parking_type || data.listing.parking_spaces || data.listing.lot_size
								"
							>
								<h2 class="text-l-sb section-headings" i18n>Detalles del Exterior</h2>
								<div class="details-grid">
									<div class="detalles-fonts" *ngIf="data.listing.parking_type">
										<ng-container i18n> Estacionamiento: </ng-container>
										{{ locale === 'en' ? data.listing.parking_type_en : data.listing.parking_type }}
									</div>
									<div class="detalles-fonts" *ngIf="data.listing.parking_spaces">
										<ng-container i18n> Espacios de estacionamiento: </ng-container>
										{{ data.listing.parking_spaces }}
									</div>
									<div class="detalles-fonts" *ngIf="data.listing.lot_size">
										<ng-container i18n> Área exterior: </ng-container>
										{{ data.listing.lot_size | number }}
										{{ ' ' + data.listing.lot_unit
										}}<sup
											*ngIf="
												data.listing.lot_unit != 'Desconocido' &&
												data.listing.lot_unit != 'acres' &&
												data.listing.lot_unit != 'cuerdas'
											"
											>2</sup
										>
									</div>
								</div>
								<hr class="content-separator" />
							</div>

							<div class="seven-section" *ngIf="amenidadesUnidad.length > 0">
								<h2 class="text-l-sb section-headings" i18n>Amenidades de la Propiedad</h2>
								<div class="details-grid">
									<div
										*ngFor="let item of amenidadesUnidad"
										class="detalles-fonts neutral-dark-color"
									>
										{{ item }}
									</div>
								</div>
								<!-- <div *ngIf="amenidadesUnidad.length == 0" class="detalles-fonts neutral-dark-color"
									i18n>
									No hay información de las amenidades de unidad.
								</div> -->
								<hr class="content-separator" />
							</div>

							<div class="eight-section" *ngIf="amenidadesComplejo.length > 0">
								<h2 class="text-l-sb section-headings" i18n>Amenidades en Áreas Comunes</h2>
								<div class="details-grid">
									<div *ngFor="let item of amenidadesComplejo" class="detalles-fonts">{{ item }}</div>
								</div>
								<!-- <div *ngIf="amenidadesComplejo.length == 0" class="detalles-fonts" i18n>
									No hay información de las amenidades del complejo.
								</div> -->
								<hr class="content-separator" />
							</div>

							<!-- MLS ADDITIONAL INFO -->
							<ng-container *ngIf="data.listing.mls === 'Stellar MLS' && data.listing.metadata">
								<div class="eight-section">
									<h2 class="text-l-sb section-headings" i18n>Información addicional</h2>

									<ng-container
										*ngIf="
											data.listing.metadata.Appliances &&
											data.listing.metadata.Appliances.length > 0
										"
									>
										<h2 class="text-m-sb chip-heading" i18n>Appliances</h2>
										<div class="chip-container">
											<div
												class="text-s chip"
												*ngFor="let appliance of data.listing.metadata.Appliances"
											>
												{{ appliance }}
											</div>
										</div>
									</ng-container>

									<ng-container
										*ngIf="
											data.listing.metadata.ExteriorFeatures &&
											data.listing.metadata.ExteriorFeatures.length > 0
										"
									>
										<h2 class="text-m-sb chip-heading" i18n>Exterior Features</h2>
										<div class="chip-container">
											<div
												*ngFor="let feature of data.listing.metadata.ExteriorFeatures"
												class="text-s chip"
											>
												{{ feature }}
											</div>
										</div>
									</ng-container>

									<ng-container
										*ngIf="
											data.listing.metadata.AssociationFeeIncludes &&
											data.listing.metadata.AssociationFeeIncludes.length > 0
										"
									>
										<h2 class="text-m-sb chip-heading" i18n>Association Fee Includes</h2>
										<div class="chip-container">
											<div
												*ngFor="let feature of data.listing.metadata.AssociationFeeIncludes"
												class="text-s chip"
											>
												{{ feature }}
											</div>
										</div>
									</ng-container>

									<ng-container
										*ngIf="data.listing.metadata.Zoning && data.listing.metadata.Zoning != 0"
									>
										<h2 class="text-m-sb chip-heading" i18n>Zonificación</h2>
										<div class="chip-container">
											<div class="text-s chip">
												{{ data.listing.metadata.Zoning }}
											</div>
										</div>
									</ng-container>

									<ng-container *ngIf="data.listing.metadata.ModificationTimestamp">
										<div class="text-m-sb chip-heading" i18n>Ultima actualizacion:</div>
										<div class="detalles-fonts">
											{{
												data.listing.metadata.ModificationTimestamp | date : 'MMM d, y, h:mm a'
											}}
										</div>
									</ng-container>

									<hr class="content-separator" />
								</div>
							</ng-container>
							<!-- MLS ADDITIONAL INFO END -->

							<ng-container *ngIf="data.listing.type == 'Alquiler' && showRentDetails">
								<div class="seven-section">
									<h2 class="text-l-sb section-headings" i18n>Detalles de Alquiler</h2>
									<div class="details-grid" style="grid-template-columns: auto">
										<div class="detalles-fonts" *ngIf="data.listing.pet_friendly" i18n>
											Admite Mascotas
										</div>
										<div class="detalles-fonts" *ngIf="data.listing.leasing_term">
											<ng-container i18n> Término de Arrendamiento: </ng-container>
											{{
												locale === 'en'
													? data.listing.leasing_term_en
													: data.listing.leasing_term
											}}
										</div>
										<div class="detalles-fonts" *ngIf="this.utilitiesIncluded.length !== 0">
											<ng-container i18n> Servicios Incluidos: </ng-container>
											{{ utilitiesIncluded.join(', ') }}
										</div>
										<div *ngIf="data.listing.available_date" class="detalles-fonts">
											<ng-container i18n> Fecha Disponible: </ng-container>
											{{ data.listing.available_date | date : 'longDate' }}
										</div>
									</div>
									<hr class="content-separator" />
								</div>
							</ng-container>
						</div>
						<div *ngIf="!seeMore" style="position: relative">
							<div class="fade-div"></div>
						</div>
						<button
							[ngStyle]="{ 'margin-bottom': seeMore ? '16px' : '0px' }"
							(click)="onClickSeeMore()"
							mat-button
							class="clear-with-primary-btn see-more"
						>
							<ng-container i18n>
								{seeMore, select, true {Ver menos} other {Ver todos los detalles} }
							</ng-container>
							<!-- {{ seeMore ? 'Ver menos' : 'Ver todos los detalles' }} -->
						</button>
						<hr style="margin-bottom: 24px; margin-top: 24px" *ngIf="!seeMore" />

						<ng-container *ngIf="data.listing.mls === 'Stellar MLS' || !data.listing.mls">
							<h2 class="text-l-sb section-headings" i18n>Historial del listado:</h2>
							<div>
								<div class="history-table">
									<div class="history-table-grid history-table-headings">
										<div i18n>Evento</div>
										<div i18n>Precio</div>
										<div i18n>Fecha</div>
									</div>
									<div class="history-table-grid" *ngFor="let item of data.listing_history">
										<div>
											{{ locale === 'en' ? item.event_en : item.event }}
										</div>
										<div>
											{{ +item.price | currency : 'USD' : 'symbol' : '1.0' }}
										</div>
										<div>
											{{ item.timestamp | date : 'MMM d, y' }}
										</div>
									</div>
								</div>
								<hr style="margin-bottom: 24px; margin-top: 24px" />
							</div>
						</ng-container>

						<ng-container *ngIf="data.listing.mls">
							<h2 class="text-l-sb section-headings" i18n>Información de la Fuente:</h2>
							<div class="detalles-fonts">MLS: {{ data.listing.mls }}</div>
							<div class="detalles-fonts">MLS ID: {{ data.listing.mls_id }}</div>

							<ng-container *ngIf="data.listing.mls !== 'Stellar MLS'; else stellarMLSTemplate">
								<a href="https://properties.listingspuertorico.com/" target="_blank">
									<img
										[src]="data.listing.mls_logo"
										style="object-fit: cover"
										[alt]="data.listing.mls"
										width="170px"
										height="60px"
									/>
								</a>
							</ng-container>
							<ng-template #stellarMLSTemplate>
								<img
									[src]="data.listing.mls_logo"
									[alt]="data.listing.mls"
									width="170px"
									height="60px"
								/>
							</ng-template>

							<hr style="margin-bottom: 24px; margin-top: 24px" />
						</ng-container>

						<div style="margin-bottom: 24px">
							<ng-container
								*ngIf="
									data.listing.listing_status !== 'Alquilado/Alquilada' &&
									data.listing.listing_status !== 'Vendido/Vendida'
								"
							>
								<h2 class="text-l-sb section-headings">
									<ng-container i18n> Listado por</ng-container>
									<span *ngIf="data.listing.isAgent && data.listing.isAgent !== '0'" i18n
										>&nbsp;agente</span
									>:
								</h2>
								<div class="agent-image-grid">
									<ng-container
										*ngIf="data.listing.mls_id && !data.agent_profile; else pictureTemplate"
									>
										<img
											[src]="getAgentPicture()"
											onerror="this.onerror=null;this.src='https://images.finditpr.com/default-images/user-picture.svg';"
											[alt]="data.listing.agent_name"
											width="60px"
											height="60px"
										/>
									</ng-container>
									<ng-template #pictureTemplate>
										<img
											[src]="data.listing.agent_picture"
											[alt]="data.listing.agent_name"
											width="60px"
											height="60px"
										/>
									</ng-template>

									<div class="agent-image-grid-info">
										<div class="detalles-fonts" style="margin-bottom: 4px">
											{{ data.listing.agent_name }}
										</div>
										<div
											class="detalles-fonts"
											style="margin-bottom: 4px"
											*ngIf="data.listing.business_name"
										>
											{{ data.listing.business_name }}
										</div>
										<div
											class="detalles-fonts"
											style="margin-bottom: 4px"
											*ngIf="data.listing.agent_phone"
										>
											<ng-container i18n> Teléfono: </ng-container>
											{{ formatPhoneNumber(data.listing.agent_phone) }}
										</div>
										<a
											*ngIf="data.agent_profile || listing.agent_slug"
											style="margin-bottom: 4px"
											[routerLink]="data.agent_profile ? data.agent_profile : listing.agent_slug"
											target="_blank"
										>
											<span i18n> Visitar perfil </span>
											<i-feather name="external-link" class="external-link-icon"></i-feather>
										</a>
									</div>
								</div>
							</ng-container>
							<div class="disclosure" *ngIf="data.listing.mls" i18n>
								Copyright,
								{{
									data.listing.mls === 'Stellar MLS'
										? 'Stellar MLS'
										: 'Realty Server
								Systems, Inc'
								}}. La información se considera confiable pero no está garantizada. La información
								proporcionada es exclusivamente para el uso personal y no comercial de los consumidores.
								Se puede utilizar solo con el propósito de identificar posibles propiedades que los
								consumidores puedan estar interesado en comprar o alquilar.
							</div>
							<div style="margin-top: 24px" class="text-s detalles-fonts">
								Findit ID: <span class="text-s-sb">{{ data?.listing.listing_id }}</span>
							</div>
						</div>
						<ng-container *ngIf="data.listing.isForeclosure === 1">
							<div class="eight-section">
								<h2 class="text-l-sb section-headings" i18n>Propiedad Reposeída</h2>
								<div class="detalles-fonts" style="margin-bottom: 4px">
									<ng-container i18n> Proveedor: </ng-container>
									{{ data.listing.repo_source }}
								</div>
								<div class="detalles-fonts" style="margin-bottom: 0">
									ID: {{ data.listing.repo_id || 'N/A' }}
								</div>
							</div>
						</ng-container>
					</div>

					<div
						class="agent-column"
						*ngIf="
							data.listing.listing_status !== 'Alquilado/Alquilada' &&
							data.listing.listing_status !== 'Vendido/Vendida'
						"
					>
						<div
							class="agent-card"
							[ngClass]="{ 'flash-contact-card': flashContactCard }"
							#contactFormEl
							id="contactFormEl"
						>
							<h3 class="heading-s" style="margin-bottom: 4px" i18n>Contacto</h3>
							<h6 class="text-s" style="margin-bottom: 20px">
								<ng-container i18n> Informacion Requerida </ng-container>
								<span style="color: var(--notification-error)">*</span>
							</h6>
							<form id="contactFormDesktop" [formGroup]="contactForm">
								<label for="name" class="text-s-sb">
									<ng-container i18n> Nombre Completo </ng-container>
									<span style="color: var(--notification-error) !important">*</span>
								</label>
								<input type="text" formControlName="name" id="name" />

								<label for="phone" class="text-s-sb">
									<ng-container i18n> Numero de Teléfono </ng-container>
									<span style="color: var(--notification-error) !important">*</span>
								</label>
								<input type="text" formControlName="phone" id="phone" mask="(000)-000-0000" />

								<label for="email" class="text-s-sb">
									<ng-container i18n> Correo Electrónico </ng-container>
									<span style="color: var(--notification-error) !important">*</span>
								</label>
								<input type="email" formControlName="email" id="email" />

								<label for="message" class="text-s-sb">
									<ng-container i18n> Mensaje </ng-container>
									<span i18n>(opcional)</span>
								</label>
								<textarea id="message" formControlName="message" cols="30" rows="2"></textarea>

								<!-- CAPTCHA -->
								<re-captcha id="recaptcha" name="recaptcha" formControlName="token"> </re-captcha>
								<!-- CAPTCHA END -->

								<button
									mat-button
									id="contactDesktop"
									class="contactarBtn text-m"
									[ngStyle]="{
										'background-color': contactMessagePending ? 'var(--notification-warning)' : ''
									}"
									[ngClass]="{ 'success-bg': contactMessageSent }"
									[disabled]="contactForm.invalid || disabledContactBtn"
									(click)="sendContactMessage()"
								>
									<span *ngIf="!contactMessageSent && !contactMessagePending" i18n>Enviar</span>
									<span *ngIf="contactMessagePending" i18n>Enviando...</span>
									<span *ngIf="contactMessageSent" style="color: var(--grayscale-1)">
										<ng-container i18n> ¡Mensaje Enviado! </ng-container>
										<i-feather name="check" class="success-color"></i-feather>
									</span>
								</button>
								<div class="text-s" style="color: var(--grayscale-4); font-size: 12px !important" i18n>
									*Al oprimir el botón de "Enviar", usted acepta nuestros
									<a routerLink="/terminos-condiciones"> términos de uso </a>
									y
									<a routerLink="/politicas-privacidad"> política de privacidad. </a>
									Además, usted está de acuerdo con recibir llamadas o mensajes para discutir su
									solicitud de información. Esta solicitud no lo obliga a comprar o alquilar la
									propiedad.
								</div>
							</form>
						</div>
					</div>
				</div>

				<ng-template #sold>
					<div class="sold-grid">
						<h6 class="sold-street">
							{{ data.listing.street }} {{ data.listing.city }}, PR {{ data.listing.zip }}
						</h6>
						<h6 class="precio-h6">{{ data.listing.finalPrice | currency : 'USD' : 'symbol' : '1.0' }}</h6>
						<h6 class="listado-en">{{ data.listing.property_type }}</h6>
						<h6 class="vendido-en">
							<ng-container i18n> Vendido el </ng-container>
							<span *ngIf="data.listing.dateByUser !== null">{{
								getSpanishDate(data.listing.dateByUser)
							}}</span>
						</h6>
						<h6 class="beds">
							<ng-container *ngIf="data.listing.bedrooms">
								{{ data.listing.bedrooms }} { data.listing.bedrooms, plural, =1 {Cuarto} other {Cuartos}
								}
								<span> | </span>
							</ng-container>

							<ng-container *ngIf="data.listing.bathrooms > 0">
								{{ +data.listing.bathrooms + +data.listing.half_bathrooms }}
								&nbsp;
								<ng-container i18n>
									{ +data.listing.bathrooms + +data.listing.half_bathrooms, plural, =1 {Baño} other
									{Baños} }
								</ng-container>
								<span> | </span>
							</ng-container>

							<span
								class="detalles-fonts"
								*ngIf="
									data.listing.unidadSize != 0 && data.listing.unidadSize !== null;
									else desconocidoSold
								"
								>{{ data.listing.unidadSize | number }} {{ ' ' + data.listing.unidadUnit
								}}<sup
									*ngIf="
										data.listing.unidadUnit != 'Desconocido' &&
										data.listing.unidadUnit != 'acres' &&
										data.listing.unidadUnit != 'cuerdas'
									"
									>2</sup
								></span
							>
						</h6>
						<ng-template #desconocidoSold>
							<span class="detalles-fonts">Tamaño de Unidad: Desconocido</span>
						</ng-template>
					</div>
				</ng-template>
			</div>
		</div>

		<ng-container *ngTemplateOutlet="additionalWrappers"></ng-container>

		<div
			class="mobile-contact-card"
			*ngIf="
				data.listing.listing_status !== 'Alquilado/Alquilada' &&
				data.listing.listing_status !== 'Vendido/Vendida'
			"
		>
			<div class="tablet-contact-card-wrapper">
				<div class="tablet-contact-card-container">
					<div class="landlord-pic-name">
						<ng-container *ngIf="data.listing.mls_id && !data.agent_profile; else pictureTemplate">
							<img
								[src]="getAgentPicture()"
								style="border-radius: 50%; object-fit: cover"
								onerror="this.onerror=null;this.src='https://images.finditpr.com/default-images/user-picture.svg';"
								[alt]="data.listing.agent_name"
								width="60px"
								height="60px"
							/>
						</ng-container>
						<ng-template #pictureTemplate>
							<img
								style="border-radius: 50%; object-fit: cover"
								[src]="data.listing.agent_picture"
								[alt]="data.listing.agent_name"
								width="60px"
								height="60px"
							/>
						</ng-template>
						<div class="agent-image-grid-info">
							<div class="detalles-fonts" style="margin-bottom: 4px">{{ data.listing.agent_name }}</div>
							<div class="detalles-fonts" style="margin-bottom: 4px" *ngIf="data.listing.business_name">
								{{ data.listing.business_name }}
							</div>
						</div>
					</div>
				</div>
				<button mat-button id="contactTablet" class="contactarBtn" (click)="openContactBox()">
					<ng-container i18n> Contactar </ng-container>
				</button>
			</div>

			<div class="mobile-contact-card-container">
				<button
					(click)="phone.click()"
					mat-button
					class="clear-with-primary-btn mobile-contact-card-button"
					*ngIf="data.listing.agent_phone"
					style="margin-right: 16px"
				>
					<a href="tel:{{ data.listing.agent_phone }}" #phone class="clean-a-tag" i18n>Llamar</a>
				</button>
				<button
					mat-button
					id="contactMobile"
					class="primary-background-btn mobile-contact-card-button"
					(click)="openContactBox()"
				>
					<ng-container i18n> Contacto </ng-container>
				</button>
			</div>
		</div>
	</ng-container>

	<ng-template #additionalWrappers>
		<div class="additional-wrappers" style="border-bottom: 1px solid var(--grayscale-9)">
			<div class="container-listings">
				<h2 class="text-l-sb section-headings" i18n>Listados Similares</h2>
				<ul class="ul-listings">
					<li *ngFor="let listing of similarListings">
						<app-listing-card [listing]="listing"></app-listing-card>
					</li>
				</ul>
			</div>
		</div>

		<div class="additional-wrappers">
			<div class="nearby-cities">
				<h2 class="text-l-sb section-headings" i18n>Pueblos Cercanos</h2>
				<div class="nearby-grid">
					<a class="text-m" *ngFor="let item of nearbyCities" [routerLink]="item.url">{{ item.name }}</a>
				</div>
			</div>
		</div>
	</ng-template>

	<ng-template #outOfMarket>
		<div class="wrapper">
			<div class="container-property" style="text-align: center; padding-top: 64px">
				<img src="../../assets/404-property.svg" alt="Propiedad fuera del mercado" width="100%" height="175" />
				<a [routerLink]="data.not_found_info.link">
					<button
						mat-raised-button
						color="primary"
						style="box-shadow: none; margin-top: 32px; margin-bottom: 32px; font-size: 18px"
					>
						{{ locale === 'en' ? data.not_found_info.btn_message_en : data.not_found_info.btn_message }}
					</button>
				</a>
				<div class="heading-s" i18n>Este listado no está activo.</div>
				<div class="text-m" style="color: var(--grayscale-5)" i18n>
					Es posible que esta propiedad ya no este en el mercado.
				</div>
				<div class="text-m" style="color: var(--grayscale-5)" i18n>
					Intente buscar otras propiedades similares.
				</div>
			</div>
		</div>
		<ng-container *ngTemplateOutlet="additionalWrappers"></ng-container>
	</ng-template>

	<ng-template #unknownSize> -- ft<sup>2</sup> </ng-template>

	<ng-template #showUnitInfo>
		<div class="text-s" style="color: var(--grayscale-4)">
			<ng-container *ngIf="data.listing.bedrooms">
				<span> {{ data.listing.bedrooms }} { data.listing.bedrooms, plural, =1 {Cuarto} other {Cuartos}} </span>
				<span style="opacity: 0.2"> | </span>
			</ng-container>

			<ng-container *ngIf="data.listing.property_units">
				{{ data.listing.property_units }}

				<ng-container i18n>
					{ data.listing.property_units, plural, =1 {Unidad} other {Unidades}}
					<!-- {{ data.listing.property_units > 1 ? 'Unidades' : 'Unidad' }} -->
				</ng-container>

				<span style="color: var(--grayscale-9)"> | </span>
			</ng-container>

			<ng-container *ngIf="data.listing.bathrooms > 0">
				<span>
					{{ +data.listing.bathrooms + +data.listing.half_bathrooms }}&nbsp;
					<ng-container i18n>
						{ +data.listing.bathrooms + +data.listing.half_bathrooms, plural, =1 {Baño} other {Baños} }
					</ng-container>
				</span>
				<span style="opacity: 0.2"> | </span>
			</ng-container>

			<ng-container *ngIf="data.listing.livable_area > 0; else unknownSize">
				<span
					>{{ data.listing.livable_area | number }} {{ ' ' + data.listing.livable_area_unit
					}}<sup
						*ngIf="
							data.listing.livable_area_unit != 'Desconocido' &&
							data.listing.livable_area_unit != 'acres' &&
							data.listing.livable_area_unit != 'cuerdas'
						"
						>2</sup
					></span
				>
			</ng-container>
		</div>
	</ng-template>
	<ng-template #showLandInfo>
		<div class="text-s" style="color: var(--grayscale-4)">
			<span *ngIf="data.listing.land_type !== 'N/A'">
				{{ locale === 'en' ? data.listing.land_type_en : data.listing.land_type }}
			</span>
			<span style="opacity: 0.2" *ngIf="data.listing.land_type !== 'N/A'"> | </span>
			<span>
				{{ locale === 'en' ? data.listing.land_sub_type_en : data.listing.land_sub_type }}
			</span>
			<ng-container *ngIf="data.listing.lot_size > 0">
				<span style="opacity: 0.2"> | </span>
				<span>
					{{ data.listing.lot_size | number }} {{ ' ' + data.listing.lot_unit }}
					<sup
						*ngIf="
							data.listing.lot_unit != 'Desconocido' &&
							data.listing.lot_unit != 'acres' &&
							data.listing.lot_unit != 'cuerdas'
						"
					>
						2
					</sup>
				</span>
			</ng-container>
		</div>
	</ng-template>

	<ng-template #fueraDelMercadoTemplate>
		<div class="text-m" style="color: var(--grayscale-1); margin-bottom: 8px" i18n>Fuera del mercado</div>
	</ng-template>

	<ng-template #timeOnFindit>
		<div
			class="time-on-findit"
			*ngIf="data.listing.new_listing === 'true'; else daysOnFindit"
			[ngSwitch]="data.listing.time_on_findit"
		>
			<ng-container *ngSwitchCase="0" i18n> Publicado hace menos de 1 hora </ng-container>
			<ng-container *ngSwitchDefault i18n>
				Publicado hace
				{{ data.listing.time_on_findit }} { data.listing.time_on_findit, plural, =1 {hora} other {horas}}
			</ng-container>
		</div>
	</ng-template>
	<ng-template #daysOnFindit>
		<div class="time-on-findit" i18n>
			Publicado hace {{ data.listing.time_on_findit }} { data.listing.time_on_findit, plural, =1 {día} other
			{días} }
		</div>
	</ng-template>

	<ng-template #newTag>
		<div class="new-tag" i18n>NUEVO</div>
	</ng-template>
</ng-container>

<ng-template #isLoading>
	<div class="wrapper">
		<div class="container-property">
			<div>
				<ngx-skeleton-loader count="1" [theme]="{ width: '100%' }"></ngx-skeleton-loader>
			</div>

			<div class="images-hero" style="overflow: hidden">
				<div class="image-box">
					<div class="images-grid">
						<div style="width: 100%" class="hero-image animated-bg"></div>
						<div class="first-image animated-bg"></div>
						<div class="second-image animated-bg"></div>
					</div>
				</div>
			</div>

			<div class="grid-wrapper" style="margin-top: 24px">
				<div style="overflow: hidden">
					<div class="first-section">
						<ngx-skeleton-loader count="2" [theme]="{ width: '100%' }"></ngx-skeleton-loader>
					</div>
					<div class="first-section-mobile">
						<ngx-skeleton-loader count="2"></ngx-skeleton-loader>
						<div class="bds-bths-terreno"></div>
					</div>
					<hr style="margin-bottom: 32px; margin-top: 24px" />
					<div class="second-section">
						<div style="width: 160px" class="animated-bg animated-bg-text"></div>
						<div style="width: 160px" class="animated-bg animated-bg-text"></div>
						<div style="width: 203px" class="animated-bg animated-bg-text"></div>
						<div style="width: 160px" class="animated-bg animated-bg-text"></div>
						<div style="width: 160px" class="animated-bg animated-bg-text"></div>
					</div>
					<hr style="margin-top: 24px; margin-bottom: 24px" />
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #preQualificationTemplate>
	<ng-container *ngIf="listing.type === 'Venta'">
		<div class="prequalification-container">
			<img src="../../assets/pre-qualification/dollar.svg" width="20" height="20" alt="Dollar Sign" />
			<a class="prequalify-a" routerLink="/precualificate" i18n>Obtén tu pre-cualificación</a>
		</div>
	</ng-container>
</ng-template>
