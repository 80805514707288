<div class="title-container">
    <h1 mat-dialog-title i18n>Reportar un Problema</h1>
    <div class="spacer"></div>
    <button mat-icon-button matPrefix class="close-icon" (click)="dialogRef.close()">
        <mat-icon>add</mat-icon>
    </button>
</div>
<div mat-dialog-content>

    <form [formGroup]="form" action=".">
        <mat-form-field appearance="outline">
            <mat-label i18n>Nombre</mat-label>
            <input matInput formControlName="name" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label i18n>Correo Electrónico</mat-label>
            <input matInput formControlName="email" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label i18n>Proposito</mat-label>
            <mat-select formControlName="proposito">
                <mat-option [value]="1">
                    <ng-container i18n>
                        Listado no está disponible
                    </ng-container>
                </mat-option>
                <mat-option [value]="2">
                    <ng-container i18n>
                        Listado Fraudelente
                    </ng-container>
                </mat-option>
                <mat-option [value]="3">
                    <ng-container i18n>
                        Información Incorrecta
                    </ng-container>
                </mat-option>



            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label i18n>Tipo de usuario</mat-label>
            <mat-select formControlName="usuario">

                <mat-option [value]="1">
                    <ng-container i18n>
                        Soy Profesional de Bíenes Raíces
                    </ng-container>
                </mat-option>
                <mat-option [value]="2">
                    <ng-container i18n>
                        Soy un Usuario Regular
                    </ng-container>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <h5 i18n>Mensaje</h5>
        <mat-form-field appearance="outline">
            <textarea matInput i18n-placeholder placeholder="Ej: La dirección es incorrecta..."
                formControlName="mensaje" autocomplete="off"></textarea>
        </mat-form-field>
    </form>
    <ng-container *ngIf="!requestInProgress && !messageSent; else loading">
        <div class="btn-container">
            <button mat-button class="blueButton" (click)="onClickSend()" [disabled]="form.invalid">
                <ng-container i18n>
                    Enviar
                </ng-container>

            </button>
        </div>
    </ng-container>


    <ng-template #loading>
        <div class="loadingParent" *ngIf="requestInProgress">
            <ul class="loading">
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>
        <h6 *ngIf="messageSent" style="text-align: center;" i18n>¡Su mensaje fue enviado con éxito!</h6>
    </ng-template>
</div>